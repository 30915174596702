import { createSlice } from '@reduxjs/toolkit'

import { createRequestAdapter, RequestAdapterState } from '../utils/slices'

export type WebPushSubscriptionState = {
	createSubscription: RequestAdapterState
	subscriptions: RequestAdapterState
	deleteSubscription: RequestAdapterState
}

const createSubscriptionRequestAdapter = createRequestAdapter({
	name: 'createSubscription',
})

const deleteSubscriptionRequestAdapter = createRequestAdapter({
	name: 'deleteSubscription',
})

const subscriptionsRequestAdapter = createRequestAdapter({
	name: 'subscriptions',
})

const initialState: WebPushSubscriptionState = {
	createSubscription: createSubscriptionRequestAdapter.getInitialState(),
	deleteSubscription: deleteSubscriptionRequestAdapter.getInitialState(),
	subscriptions: subscriptionsRequestAdapter.getInitialState(),
}

export const webPushSubscription = createSlice({
	name: 'webPushSubscription',
	initialState,
	reducers: {
		...createSubscriptionRequestAdapter.caseReducers,
		...deleteSubscriptionRequestAdapter.caseReducers,
		...subscriptionsRequestAdapter.caseReducers,
	},
})

export default webPushSubscription
