import { SphereEventModel as BaseSphereEventModel } from '../../SphereEvent'

import {
	compareDatesToDayPrecision,
	compareDatesByHoursAndMinutes,
	getDatesAreOnSameDay,
	getIsBrowserLocale24h,
	compareDatesToPrecision,
} from '../../../utils/time'
import { modelGuard } from '../../shared/guards'

import {
	SphereEventInput,
	RequiredSphereEventFields,
	SphereEventModel,
} from './types'

function combineDayAndTimeIntoSingleISODate(input: {
	day: Date
	time: Date
}): string {
	const draftDate = new Date(input.day)
	draftDate.setUTCHours(input.time.getUTCHours())
	draftDate.setUTCMinutes(input.time.getUTCMinutes())
	return draftDate.toISOString()
}

class SphereEventForm {
	static isUseCaseSphereEventInviteeModel = modelGuard<
		BaseSphereEventModel,
		SphereEventModel
	>(RequiredSphereEventFields)

	static isSphereEventInput(
		partial: Partial<SphereEventInput>
	): partial is SphereEventInput {
		return Boolean(
			partial.title &&
				partial.startDay &&
				partial.startTime &&
				partial.imageFilename
		)
	}

	static isStartDayBeforeToday({
		startDay,
		currentDayDate,
	}: {
		startDay?: Date
		currentDayDate: Date
	}): boolean {
		return Boolean(
			startDay && compareDatesToDayPrecision(startDay, currentDayDate) < 0
		)
	}

	static isEndDayBeforeToday({
		endDay,
		currentDayDate,
	}: {
		endDay?: Date
		currentDayDate: Date
	}): boolean {
		return Boolean(
			endDay && compareDatesToDayPrecision(endDay, currentDayDate) < 0
		)
	}

	static isStartDateAfterEndDateOnSameDay({
		startDay,
		endDay,
		startTime,
		endTime,
	}: {
		startDay?: Date
		endDay?: Date
		startTime?: Date
		endTime?: Date
	}): boolean {
		const isStartDayEqualToEndDay = Boolean(
			startDay && endDay && getDatesAreOnSameDay(startDay, endDay)
		)
		const isStartTimeAfterEndTime = Boolean(
			startTime &&
				endTime &&
				compareDatesByHoursAndMinutes(startTime, endTime) > 0
		)
		return isStartDayEqualToEndDay && isStartTimeAfterEndTime
	}

	static isStartDayAfterEndDay({
		startDay,
		endDay,
	}: {
		startDay?: Date
		endDay?: Date
	}): boolean {
		return Boolean(
			startDay && endDay && compareDatesToDayPrecision(startDay, endDay) > 0
		)
	}

	static getDefaultEndTime({
		startTime,
	}: {
		startTime?: Date | string
	}): Date | undefined {
		// Set end time to 1 minute after start time
		const defaultEndTime = startTime ? new Date(startTime) : undefined
		defaultEndTime?.setMinutes(defaultEndTime.getMinutes() + 1)
		return defaultEndTime
	}

	static getDefaultEndDay({ startDay }: { startDay?: Date }): Date | undefined {
		return startDay ? new Date(startDay) : undefined
	}

	static getInitialStartDate({ nowDate }: { nowDate: Date }): Date {
		const nowDateCopy = new Date(nowDate)
		nowDateCopy.setHours(nowDate.getHours() + 1, 0, 0, 0)
		return nowDateCopy
	}

	static getMinStartDay({ currentDayDate }: { currentDayDate: Date }): Date {
		return currentDayDate
	}
	static getMaxStartDay({ endDay }: { endDay?: Date }): Date | undefined {
		return endDay
	}
	static getMinEndDay({
		startDay,
		currentDayDate,
	}: {
		startDay?: Date
		currentDayDate: Date
	}): Date {
		return startDay ?? currentDayDate
	}
	static getMaxEndDay(): undefined {
		return undefined
	}

	static getEndDateFromFormInput({
		startDay,
		endDay,
		startTime,
		endTime,
	}: {
		startDay: Date
		endDay?: Date
		startTime: Date
		endTime?: Date
	}): string | null {
		if (endDay && endTime) {
			return combineDayAndTimeIntoSingleISODate({
				day: endDay,
				time: endTime,
			})
		}
		if (endDay && !endTime) {
			// Has chosen an end day, default the time
			const defaultEndTime = SphereEventForm.getDefaultEndTime({ startTime })
			return combineDayAndTimeIntoSingleISODate({
				day: endDay,
				time: defaultEndTime ?? startTime,
			})
		}
		if (!endDay && endTime) {
			// Different time, default to same day
			return combineDayAndTimeIntoSingleISODate({
				day: startDay,
				time: endTime,
			})
		}
		return null
	}

	static getStartDateFromFormInput({
		startDay,
		startTime,
	}: {
		startDay: Date
		startTime: Date
	}): string {
		return combineDayAndTimeIntoSingleISODate({
			day: startDay,
			time: startTime,
		})
	}

	static getTimePickerFormat(): '24' | '12' {
		return getIsBrowserLocale24h() ? '24' : '12'
	}

	static hasStringFieldBeenEdited(
		existing?: string | null,
		current?: string | null
	) {
		if (!existing && !current) {
			// undefined, null, and empty string are equivalent
			return false
		}
		return existing !== current
	}

	/**
	 * @returns
	 *  - null if the previous value was deleted
	 *  - undefined if there was no edit made to the previous value
	 *  - the new value if the field was edited
	 */
	static getStringFieldEditFormInput({
		existing,
		edited,
	}: {
		existing?: string | null
		edited?: string | null
	}): string | null | undefined {
		const hasFieldBeenEdited = SphereEventForm.hasStringFieldBeenEdited(
			existing,
			edited
		)
		const hasFieldBeenDeleted = hasFieldBeenEdited && !edited

		if (hasFieldBeenDeleted) {
			return null
		}
		if (hasFieldBeenEdited) {
			return edited
		}
		return undefined
	}

	static hasDateBeenEdited({
		initialStartOrEndDate,
		startOrEndDate,
	}: {
		initialStartOrEndDate?: Date | string
		startOrEndDate?: Date | string
	}): boolean {
		if (initialStartOrEndDate === undefined || startOrEndDate === undefined) {
			return initialStartOrEndDate !== startOrEndDate
		}

		const parsedInitialStartOrEndDate = new Date(initialStartOrEndDate)
		const parsedStartOrEndDate = new Date(startOrEndDate)

		return (
			compareDatesToPrecision(
				parsedStartOrEndDate,
				parsedInitialStartOrEndDate,
				'minute'
			) !== 0
		)
	}

	static isCreatedStartDateValid({
		nowDate,
		startDay,
		startTime,
	}: {
		nowDate: Date
		startDay?: Date
		startTime?: Date
	}): boolean {
		if (!startDay || !startTime) {
			return false
		}
		const startDateString = SphereEventForm.getStartDateFromFormInput({
			startDay,
			startTime,
		})
		const startDate = new Date(startDateString)

		const isStartDateBeforeNow =
			compareDatesToPrecision(startDate, nowDate, 'minute') < 0

		return !isStartDateBeforeNow
	}

	static isEditedStartDateValid({
		previousStartDate,
		nowDate,
		startDay,
		startTime,
	}: {
		previousStartDate: Date
		nowDate: Date
		startDay?: Date
		startTime?: Date
	}): boolean {
		if (!startDay || !startTime) {
			return false
		}
		const startDateString = SphereEventForm.getStartDateFromFormInput({
			startDay,
			startTime,
		})
		const startDate = new Date(startDateString)

		const hasStartDateBeenEdited = SphereEventForm.hasDateBeenEdited({
			initialStartOrEndDate: previousStartDate,
			startOrEndDate: startDate,
		})
		const canStartDateBeInThePast = !hasStartDateBeenEdited
		if (canStartDateBeInThePast) {
			return true
		}

		const isStartDateBeforeNow =
			compareDatesToPrecision(startDate, nowDate, 'minute') < 0

		return !isStartDateBeforeNow
	}

	static determineEditedFields({
		formInput,
		existingSphereEventModel,
	}: {
		formInput: SphereEventInput
		existingSphereEventModel?: SphereEventModel
	}): Record<typeof RequiredSphereEventFields[number], boolean> {
		if (!existingSphereEventModel) {
			return {
				name: false,
				displayImageFilename: false,
				description: false,
				location: false,
				link: false,
				startDate: false,
				endDate: false,
			}
		}

		const editedStartDate = SphereEventForm.getStartDateFromFormInput(formInput)
		const editedEndDate = SphereEventForm.getEndDateFromFormInput(formInput)

		return {
			name: formInput.title !== existingSphereEventModel.name,
			displayImageFilename:
				formInput.imageFilename !==
				existingSphereEventModel.displayImageFilename,
			description: SphereEventForm.hasStringFieldBeenEdited(
				existingSphereEventModel.description,
				formInput.description
			),
			location: SphereEventForm.hasStringFieldBeenEdited(
				existingSphereEventModel.location?.name,
				formInput.locationName
			),
			link: SphereEventForm.hasStringFieldBeenEdited(
				existingSphereEventModel.link,
				formInput.link
			),
			startDate: SphereEventForm.hasDateBeenEdited({
				startOrEndDate: editedStartDate,
				initialStartOrEndDate: existingSphereEventModel.startDate,
			}),
			endDate: SphereEventForm.hasDateBeenEdited({
				startOrEndDate: editedEndDate ?? undefined,
				initialStartOrEndDate: existingSphereEventModel.endDate ?? undefined,
			}),
		}
	}

	static shouldPromptForEditNotificationLevel({
		formInput,
		existingSphereEventModel,
	}: {
		formInput: SphereEventInput
		existingSphereEventModel?: SphereEventModel
	}): boolean {
		const editedFields = SphereEventForm.determineEditedFields({
			formInput,
			existingSphereEventModel,
		})
		const editedFieldNames = Object.keys(editedFields).filter(
			field => editedFields[field as keyof typeof editedFields]
		)

		const nothingChanged = editedFieldNames.length === 0
		const onlyChangedImage =
			editedFieldNames.length === 1 &&
			editedFieldNames.includes('displayImageFilename')

		return !nothingChanged && !onlyChangedImage
	}
}

export default SphereEventForm
