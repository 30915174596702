import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { initServiceWorker, initPushSubscription } from '../actions/browser'
import { fetchWebPushSubscriptions } from '../actions/webPushSubscriptions'

import { ViewerAuthInfo } from './useViewerAuthInfo'

export function useWebPushSubscription({ userAgentId }: ViewerAuthInfo) {
	const pushManager = useSelector(store => store.serviceWorker.pushManager)
	const dispatch = useDispatch()

	useEffect(() => {
		if (userAgentId) {
			dispatch(fetchWebPushSubscriptions())
		}
	}, [dispatch, userAgentId])

	useEffect(() => {
		if (userAgentId) {
			if (pushManager) {
				dispatch(initPushSubscription(pushManager))
			} else {
				dispatch(initServiceWorker())
			}
		}
	}, [dispatch, userAgentId, pushManager])
}

export default useWebPushSubscription
