import styled, { css } from 'styled-components'

import { ButtonKind, ButtonVariant } from '../AKButton'
import Button from '../AKButton/Button'
import { FootnoteUppercase, LabelMedium, TitleBold } from '../AKTypography'
import { FluidWell } from '../AKWell'

export const Root = styled.div`
	padding-top: ${({ theme }) => theme.grid.gutter}px;
`

export const PrimaryMinimalButton = styled(Button).attrs({
	kind: ButtonKind.Minimal,
	variant: ButtonVariant.Primary,
})`
	${({ theme }) => theme.typography.ParagraphMedium};
`

export const CenteringContainer = styled.div`
	display: flex;
	align-items: center;
	flex-flow: column;
`

export const ImageSpacing = styled.div`
	margin-bottom: ${({ theme }) => theme.sizing.scale600};
`

export const Title = styled(TitleBold)`
	display: flex;
	align-items: center;
	word-wrap: break-word;
	text-align: center;
`

export const Email = styled(LabelMedium)`
	color: ${({ theme }) => theme.colors.contentSecondary};
	word-wrap: break-word;
`

export const SignoutButton = styled(Button).attrs({
	kind: ButtonKind.Minimal,
	variant: ButtonVariant.Danger,
})``

export const Section = styled.div`
	margin-bottom: ${({ theme }) => theme.grid.margin}px;
`

export const SectionLabel = styled(FootnoteUppercase)(
	({ theme }) => css`
		margin-left: ${theme.grid.margin}px;
		margin-bottom: ${theme.sizing.scale400};
	`
)

export const IconPositioner = styled.div`
	margin-right: ${({ theme }) => theme.grid.gutter}px;
	display: flex;
	flex-flow: row;
	align-items: center;
`

export const SlimFluidWell = styled(FluidWell)`
	padding: ${({ theme }) => theme.sizing.scale100} 0;
`

export const Row = styled.div`
	position: relative;
	display: flex;
	flex-flow: row;
	width: 100%;
	flex-wrap: wrap;
`

export const RowItem = styled.div`
	flex: 1 1 100%;
	min-width: 100%;
	margin: ${({ theme }) => theme.sizing.scale100} 0;

	${({ theme }) => theme.mediaQueries.md} {
		flex: 1 1 0px;
		min-width: 0px;
		height: 100%;
		margin: 0 ${({ theme }) => theme.sizing.scale100};
	}
`
