import { SetRequired } from 'type-fest'

import {
	SphereEventInviteeModel,
	SphereEventRsvp,
	SphereEventInviteeRsvpState,
} from './types'

export class SphereEventInvitee {
	static getRsvp(
		sphereEventInvitee: SetRequired<SphereEventInviteeModel, 'rsvp'>
	): SphereEventRsvp | null {
		return sphereEventInvitee.rsvp
	}

	static getRsvpState(
		sphereEventInvitee: SetRequired<SphereEventInviteeModel, 'rsvp'>
	): SphereEventInviteeRsvpState {
		const rsvp = SphereEventInvitee.getRsvp(sphereEventInvitee)
		switch (rsvp) {
			case SphereEventRsvp.Going:
				return SphereEventInviteeRsvpState.Going
			case SphereEventRsvp.NotGoing:
				return SphereEventInviteeRsvpState.NotGoing
			case SphereEventRsvp.Thinking:
				return SphereEventInviteeRsvpState.Thinking
			case null:
				return SphereEventInviteeRsvpState.Pending
		}
	}
}

export default SphereEventInvitee
