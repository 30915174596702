import styled, { css } from 'styled-components'

import { ColorToken } from '../../styles'
import { FontNames, fontWeights } from '../../styles/typography'

import { Typography as TypographyType, BlockProps } from './types'

function BlockCreator(
	font: FontNames,
	defaultColor: ColorToken = 'contentPrimary'
) {
	const component = styled.div<BlockProps>(
		({
			theme,
			$font = font,
			$marginLeft = 0,
			$marginTop = 0,
			$marginRight = 0,
			$marginBottom = 0,
			$color,
			$colorToken = defaultColor,
		}) => css`
			${theme.typography[$font]};
			/* TODO: add a helper function for this behaviour
			 * would be nice if theme was a object, so we could do theme.utils.colorOrColorToken($color,$colorToken) */
			color: ${$color ?? theme.colors[$colorToken]};
			margin-left: ${$marginLeft}px;
			margin-top: ${$marginTop}px;
			margin-right: ${$marginRight}px;
			margin-bottom: ${$marginBottom}px;
		`
	)
	component.displayName = font
	return component
}

export const DisplayBold = BlockCreator('DisplayBold')
export const DisplayMedium = BlockCreator('DisplayMedium')
export const DisplayRegular = BlockCreator('DisplayRegular')
export const HeaderBold = BlockCreator('HeaderBold')
export const HeaderMedium = BlockCreator('HeaderMedium')
export const HeaderRegular = BlockCreator('HeaderRegular')
export const TitleBold = BlockCreator('TitleBold')
export const TitleMedium = BlockCreator('TitleMedium')
export const TitleRegular = BlockCreator('TitleRegular')
export const ParagraphBold = BlockCreator('ParagraphBold')
export const ParagraphMedium = BlockCreator('ParagraphMedium')
export const ParagraphRegular = BlockCreator('ParagraphRegular')
export const LabelBold = BlockCreator('LabelBold')
export const LabelMedium = BlockCreator('LabelMedium')
export const LabelRegular = BlockCreator('LabelRegular')
export const FootnoteUppercase = BlockCreator(
	'FootnoteUppercase',
	'contentSecondary'
)
export const FootnoteBold = BlockCreator('FootnoteBold', 'contentSecondary')
export const FootnoteMedium = BlockCreator('FootnoteMedium', 'contentSecondary')
export const FootnoteRegular = BlockCreator(
	'FootnoteRegular',
	'contentSecondary'
)

export const Bold = styled.b`
	font-weight: ${fontWeights['bold']};
`

const Typography: TypographyType = {
	DisplayBold,
	DisplayMedium,
	DisplayRegular,
	HeaderBold,
	HeaderMedium,
	HeaderRegular,
	TitleBold,
	TitleMedium,
	TitleRegular,
	ParagraphBold,
	ParagraphMedium,
	ParagraphRegular,
	LabelBold,
	LabelMedium,
	LabelRegular,
	FootnoteUppercase,
	FootnoteBold,
	FootnoteMedium,
	FootnoteRegular,
}

export default Typography
