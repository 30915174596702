export const MESSAGE_BUTTON_CLICK = 'Clicked message button'

export const SUBMIT_MESSAGE_BUTTON_CLICK = 'Clicked submit message button'
export const SUBMIT_MESSAGE_KEY_ENTER = 'Entered submit message key'

export const VISIBILITY_CHANGE = 'Changed page visibility'

export const WEB_NOTIFICATIONS_PROMPT_SHOWN = 'Shown web notification prompt'
export const WEB_NOTIFICATIONS_PROMPT_ACCEPTED =
	'Accepted web notification prompt'
export const WEB_NOTIFICATIONS_PROMPT_DISMISSED =
	'Dismissed web notification prompt'
export const WEB_NOTIFICATIONS_GRANTED = 'Granted web notifications'
export const WEB_NOTIFICATIONS_REJECTED = 'Rejected web notifications'

export const ENABLE_BROWSER_NOTIFICATIONS_CLICK =
	'Clicked enable browser notifications'
export const PUSH_NOTIFICATIONS_ENABLE_CLICK =
	'Clicked enable push notifications'
export const PUSH_NOTIFICATIONS_DISABLE_CLICK =
	'Clicked disable push notifications'

export const PUSH_NOTIFICATIONS_PROMPT_ACCEPTED =
	'PUSH_NOTIFICATIONS_PROMPT_ACCEPTED'
export const PUSH_NOTIFICATIONS_PROMPT_DISMISSED =
	'PUSH_NOTIFICATIONS_PROMPT_DISMISSED'

export const WEBAPP_INSTALL_PROMPT_SHOWN = 'Shown webapp install prompt'
export const WEBAPP_INSTALL_PROMPT_ACCEPTED = 'Accepted webapp install prompt'
export const WEBAPP_INSTALL_PROMPT_DISMISSED = 'Dismissed webapp install prompt'

export const PUSH_NOTIFICATION_REQUESTED_EXPERT_ONLINE_SHOWN =
	'Show requested expert online push notification'
export const PUSH_NOTIFICATION_REQUESTED_EXPERT_ONLINE_CLICK =
	'Clicled requested expert online push notification'
export const PUSH_NOTIFICATION_FAVOURITED_REQUEST_SHOWN =
	'Shown favourited request push notification'
export const PUSH_NOTIFICATION_FAVOURITED_REQUEST_CLICK =
	'Clicked favourited request push notification'

export const PUSH_NOTIFICATION_FAVOURITE_CREATE_SHOWN =
	'Shown favourited push notification'
export const PUSH_NOTIFICATION_FAVOURITE_CREATE_CLICK =
	'Clicked favourited push notification'

export const MESSAGE_LIKE_CLICK = 'Clicked like message'
export const MESSAGE_UNLIKE_CLICK = 'Clicked unlike message'

// Naming dictated by data team
export const MESSAGE_REACTION_DATA_RECEIVED = 'Message reaction received'

export const MESSAGE_INPUT_PASTED_TEXT = 'Pasted text in message input'

export const GENERIC_NOTIFICATION_DISMISSED = 'Dismissed generic notification'

export const NOTIFICATION_LINK_CLICKED = 'Clicked link in generic notification'

// User clicks a link on the chat message directly (NOT a link preview)
// message LinkClicked {
//	optional string schema_name = 1 [default = 'link_clicked'];
//	required string conversation_id = 2;
//	required string url = 3; // url of the link clicked
//	optional string message_id = 4; // Pass if available
//	optional string message_unique_id = 5; // unique message id generated by the mobile clients
// }
export const MESSAGE_LINK_CLICK = 'link clicked'
export const MESSAGE_MENTION_CLICK = 'Mention message clicked'

export const MENTION_AGENT_SELECTED = 'Mention agent selected'
export const MENTION_LIST_OPENED = 'Mention list opened'

export const MESSAGE_MUTE_CLICK = 'Clicked mute message'

export const ATTENDANT_MUTE_DATA_RECEIVED = 'Received attendant mute data'
export const NOTIFICATION_DATA_RECEIVED = 'Notification data received'

export const PASSWORD_VERIFICATION_SUBMIT =
	'Submitted a request to verify password'
export const VERIFY_PASSWORD_DISMISSED = 'Dismissed the verify password form'

export const LANDING_PAGE_CTA_CLICK = 'Clicked landing page call to action'
export const LANDING_PAGE_CTA_ERROR = 'Landing page call to action error'
export const HARD_WAITLIST_EMAIL_SUBMIT = 'Email submitted to hard waitlist'
export const HARD_WAITLIST_EMAIL_RESEND =
	'Email resent for hard waitlist verification'
export const LANDING_MODAL_CLOSE = 'Landing modal close'
export const LANDING_MODAL_OPEN = 'Landing modal open'
export const LOGIN_MODAL_SUBMIT = 'Login modal submit'
export const LOGIN_MODAL_ERROR = 'Login modal error'
export const RESET_PASSWORD_SUBMIT = 'Reset password form submit'
export const HARD_WAITLIST_PAGE_SEEN = 'Hard waitlist page seen'
export const HARD_WAITLIST_APP_STORE_CLICK =
	'Hard waitlist app store link click'
export const HARD_WAITLIST_PLAY_CLICK = 'Hard waitlist google play link click'

export const VERIFY_EMAIL_AND_LOGIN_SUCCESS = 'verify email and login success'
export const VERIFY_EMAIL_AND_LOGIN_ERROR = 'verify email and login error'

export const MOBILE_APP_RECOMMENDATION_CLOSE =
	'Recommendation to use apps on mobile closed'

export const MOBILE_APP_RECOMMENDATION_APP_STORE_CLICK =
	'_ clicked from recommendation to use apps on mobile'
export const MOBILE_APP_RECOMMENDATION_PLAY_CLICK =
	'_ clicked from recommendation to use apps on mobile'

export const CHAT_SCREEN_VIEWED = 'Chat screen viewed'
export const NO_ACTIVE_CHAT_SCREEN_VIEWED = 'No active chat screen viewed'
export const MAGIC_LINK_EMAIL_REQUESTED =
	'Magic link email requested from landing'

export const SPHERE_VIEWED = 'Sphere viewed'
export const FEED_UPCOMING_VIEWED = 'Upcoming feed viewed'
export const OPENED_CHAT = 'Opened Chat'
export const FEED_MENU_OPENED = 'Feed menu opened'
export const FEED_MENU_CLOSED = 'Feed menu closed'

// User clicks on the button to open the chat info screen
// message ChatInfoOpened {
//     optional string schema_name = 1 [default = 'chat_info_opened'];
//     required string conversation_id = 2;
// }
export const CHAT_INFO_OPENED = 'Chat info opened'

export const REACTION_REPLY_CLICKED = 'Reaction reply clicked'
export const REMOVE_REPLY_CLICK = 'Remove reply from input click'
export const REPLIED_TO_MESSAGE_CLICK = 'Replied to message click'
export const REPLYING_TO_MESSAGE_CLICK = 'Message replying to click'
export const REACTION_TRAY_OPEN_CLICK = 'Reaction tray open clicked'
export const REACTION_TRAY_CLOSE_CLICK = 'Reaction tray closed'

export const MUTED_CHAT = 'Muted chat'

export const OFFLINE_BANNER_RELOAD_CLICK = 'Reload banner clicked'
export const OFFLINE_BANNER_SHOWN = 'Offline banner shown'
export const RECONNECTING_BANNER_SHOWN = 'Reconnecting banner shown'
export const CHAT_MESSAGE_SEND_FAILED = 'Chat message send failed'

export const SUGGESTED_CHAT_CLICK = 'Suggested chat click'
export const JOINED_SUGGESTED_QUERY_CLICK = 'Join suggested query click'
export const DECLINE_SUGGESTED_QUERY_CLICK =
	'Decline to join suggested query click'

export const CHAT_LEAVE_CLICK = 'Leave chat click'
export const CONFIRM_CHAT_LEAVE_CLICK = 'Left chat'
export const CANCEL_CHAT_LEAVE_CLICK = 'Leave chat cancel click'

export const DELETE_MESSAGE_CLICK = 'Delete message clicked'
export const CONFIRM_DELETE_MESSAGE_CLICK = 'Confirm delete message clicked'
export const CANCEL_DELETE_MESSAGE_CLICK = 'Cancel delete message clicked'

export const TYPING_INDICATOR_SHOWN = 'Typing indicator shown'

export const LEAVE_CHAT_SUCCESS_PROMPT_CLOSE =
	'Left chat successfully modal dismissed'

export const SETTINGS_SHARE_INVITE_LINK_PROMPT_DISMISS =
	'Settings share invite link prompt dismiss'
export const SHARE_INVITE_LINK_CLICK = 'Clicked share invite link'
export const INVITE_LINK_SHARED_NATIVELY = 'Invite link shared natively'
export const SHARE_INVITE_LINK_PROMPT_DISMISS =
	'Dismissed invite link share prompt'

export const CHAT_DELETE = 'Chat deleted'
export const CHAT_FOLLOW = 'Chat followed'

export const SAVED_PROFILE = 'Saved profile edit changes'

// User views profile info of a user (including themselves) from one of these 4 sources :
// source = 'sphere_info' when user clicks on someone's profile from the Sphere Info Page
// source = 'chat' when user clicks on someone's profile from the Chat View
// source = 'chat_info' when user clicks on someone's profile from the Chat Info Page
// source = 'settings' when user clicks on their profile from the Settings Menu
// message ProfileViewed {
// 	optional string schema_name = 1 [default = 'profile_viewed'];
// 	optional string profile_agent_id = 2; // Pass for the sources : 'sphere_info' and 'settings'. NULL otherwise.
// 	required string source = 3; // in ['sphere_info', 'chat', 'chat_info', 'settings']
// 	optional string conversation_id = 4; // Pass for the sources : 'chat' and 'chat_info'. NULL otherwise.
// 	optional string profile_attendant_id = 5; // Pass for the sources : 'chat_info' and 'chat_info'. NULL otherwise.
// }
export const PROFILE_VIEWED = 'Profile viewed'

export const EDIT_PROFILE_CLICKED = 'Edit profile clicked'
export const EDIT_PROFILE_PHOTO = 'Attempted to edit profile photo'
export const EDIT_PROFILE_DESCRIPTION = 'Attempted to edit profile description'
export const PROFILE_IMAGE_UPLOAD_START = 'Profile image upload start'
export const PROFILE_EDIT_CANCEL_CLICK = 'Profile edit cancel click'
export const MESSAGE_SENDER_PROFILE_CLOSE_CLICK = 'Close profile click'
export const MENTION_PROFILE_CLOSE_CLICK = 'Close mention profile click'

export const HEADER_PROFILE_CLOSED = 'Header profile closed'
export const SETTINGS_MENU_OPENED = 'Settings menu opened'
export const SETTINGS_MENU_CLOSED = 'Settings menu closed'
export const SIGN_OUT_CLICKED = 'Sign out clicked'
export const GIVE_FEEDBACK_CLICKED = 'Give feedback clicked'
export const APPSTORE_BUTTON_CLICKED_IN_HEADER_MENU =
	'AppStore button clicked in header menu'
export const PLAY_STORE_BUTTON_CLICKED_IN_HEADER_MENU =
	'Play Store button clicked in header menu'
export const GET_THE_APP_CLICK = 'Get the app click'
export const TERMS_CLICK = 'Terms and conditions clicked'
export const POLICY_CLICK = 'Privacy policy clicked'

// User clicks on a button to initiate the creation of a new Sphere-Level Content (Group Chat/ DM /Event)
// message CreateSphereContentClicked {
//     optional string schema_name = 1 [default = 'create_sphere_content_clicked'];
//     optional string sphere_id = 2;
// }
export const CREATE_SPHERE_CONTENT_CLICKED = 'create_sphere_content_clicked'

export const FEED_CREATE_CHAT_CLOSED = 'Feed create chat closed'
export const CHAT_NAME_TYPED = 'Chat name typed'
export const CHAT_DESCRIPTION_TYPED = 'Chat description typed'
export const EDIT_IMAGE_CLICKED = 'Edit image clicked'
export const IMAGE_SELECTED = 'Image selected'
export const CHAT_CREATE_TIME_LIMIT_TOGGLE = 'create chat time limit toggled'
export const CREATE_CHAT_SECRET_TOGGLED = 'create chat secret toggled'

// User clicks on the 'Add people' from one of those two sources :
// source = 'chat_creation' when the user clicks adds members from the Chat Creation Screen
// source = 'chat_info' when the user clicks adds members from the Chat Info Screen
// message ChatAddPeopleClicked{
//     optional string schema_name = 1 [default = 'chat_add_people_clicked'];
//     required string source = 2; // in ['chat_creation', 'chat_info']
//     optional string conversation_id = 3; // Pass if source is 'chat_info'
// }
export const CHAT_ADD_PEOPLE_CLICKED = 'Chat add people clicked'

// User toggles the checkbox for selecting which members (target_agent_id) to add to a chat
// message ChatAddMemberToggled{
//     optional string schema_name = 1 [default = 'chat_add_member_toggled'];
//     optional string toggled_state = 2; // selected, unselected
//     optional string target_agent_id = 3;
//     required string source = 4; // in ['chat_creation', 'chat_info']
//     optional string conversation_id = 5; // Pass if source is 'chat_info'
// }
export const CHAT_ADD_MEMBER_TOGGLED = 'Chat add member toggled'

// User clicks on the 'Add' option after selecting at least one person to add to a chat
// message ChatAddPeopleConfirmed{
//     optional string schema_name = 1 [default = 'chat_add_people_confirmed'];
//     optional int64 members_count = 2; // number of members that are being added to the chat
//     required string source = 3; // in ['chat_creation', 'chat_info']
//     optional string conversation_id = 4; // Pass if source is 'chat_info'
// }
export const CHAT_ADD_PEOPLE_CONFIRMED = 'Chat add people confirmed'

export const CONFIRM_CREATE_CHAT_CLICKED = 'Confirm create chat clicked'
export const EDIT_CHAT_CLICKED = 'Edit chat clicked'
export const CHAT_IMAGE_SEARCHED = 'Chat image searched'
export const FEED_CHAT_DELETE_CLICK = 'Feed chat delete click'
export const FEED_CHAT_DELETE_CANCEL = 'Feed chat delete cancel'
export const SHARE_CHAT_CLICK = 'Share chat click'

export const FEED_CREATE_SPHERE_CLICKED = 'Create sphere clicked'
export const CREATE_SPHERE_FORM_VIEWED = 'Create sphere page viewed'
export const FEED_CREATE_SPHERE_CLOSED = 'Create sphere page closed'
export const EDIT_SPHERE_IMAGE_CLICKED = 'Edit sphere image clicked'
export const EDIT_SPHERE_CLICKED = 'Edit sphere clicked'
export const SPHERE_IMAGE_SELECTED = 'Sphere image added'
export const CONFIRM_CREATE_SPHERE_CLICKED = 'Confirm create sphere clicked'
export const CREATE_SPHERE_CONFIRMED = 'Create sphere confirmed'
export const SPHERE_NAME_TYPED = 'Sphere name typed'
export const SPHERE_DESCRIPTION_TYPED = 'Sphere description typed'

export const SPHERE_NAVIGATION_CLICK = 'Sphere navigation click'
export const SPHERE_JOIN_CLICK = 'Join sphere clicked'
export const SPHERE_LEAVE_CLICK = 'Leave sphere clicked'
export const SPHERE_LEAVE_CONFIRM = 'Leave sphere confirmed'
export const SPHERE_LEAVE_CANCEL = 'leave sphere cancelled'
export const SPHERE_INFO_CLOSE_CLICK = 'Sphere info close click'
export const SPHERE_INFO_CLICKED = 'Sphere info clicked'

export const CHANGED_ADMIN_STATUS_CLICKED = 'Changed admin status clicked'
export const REMOVE_USER_CLICKED = 'Remove user clicked'
export const REMOVE_USER_CONFIRMED = 'Remove user confirmed'
export const REMOVE_USER_CANCELLED = 'Remove user cancelled'
export const LEAVE_SPHERE_REJECTION_VIEWED = 'Leave sphere rejection viewed'
export const REVOKE_ADMIN_REJECTION_VIEWED = 'Revoke admin rejection viewed'

export const CHECK_EMAIL_PAGE_VIEWED = 'Check email page viewed'
export const CHECK_EMAIL_DISMISSED = 'Check email dismissed'

export const SPHERE_SUMMARY_SHARE_SPHERE_CLICK =
	'Clicked share sphere from sphere summary'
export const SPHERE_SUMMARY_SHARE_SPHERE_PROMP_CLOSE_CLICK =
	'Clicked close share sphere from sphere summary invite prompt'
export const SPHERE_SUMMARY_MEMBER_MENU_OPEN_CLICK =
	'Clicked open menu for sphere member'

export const USER_CONFIGURATION_RETRY_CLICKED =
	'User configuration retry clicked'

// User chooses attachment type from menu
export const MESSAGE_ATTACHMENT_SELECTED = 'Message attachment selected'
export const ANNOUNCEMENT_TYPED = 'Announcement typed'
export const IMPORTANT_MESSAGE_SENT = 'Important message sent'
export const MESSAGE_ATTACHMENT_OPENED = 'Message attachment opened'

// User clicks on the button to open important message details
// message ImportantMessageOpened {
//     optional string schema_name = 1 [default = 'important_message_opened'];
//     required string message_id = 2;
//     required string conversation_id = 3;
//     required string from = 4; // chat, inbox
// }
export const IMPORTANT_MESSAGE_OPENED = 'Important message opened'

// User clicks on the initial remind all button before confirmation prompt
// message ImportantMessageRemindAllViewed {
//     optional string schema_name = 1 [default = 'important_message_remind_all_viewed'];
//     required string message_id = 2;
//     required string conversation_id = 3;
// }
export const IMPORTANT_MESSAGE_REMIND_ALL_VIEWED =
	'Important message remind all viewed'

// User clicks on the confirmation button to remind either one agent or all agents of the important info
// message ImportantMessageRemindAllConfirmed {
//     optional string schema_name = 1 [default = 'important_message_remind_all_confirmed'];
//     required string message_id = 2;
//     required string conversation_id = 3;
// }
export const IMPORTANT_MESSAGE_REMIND_ALL_CONFIRMED =
	'Important message remind all confirmed'

// User clicks on the remind agent button
// message ImportantMessageRemindAttendantSent {
//     optional string schema_name = 1 [default = 'important_message_remind_attendant_sent'];
//     required string message_id = 2;
//     required string conversation_id = 3;
//     required string target_attendant_id = 4; // attendant_id of user being reminded
// }
export const IMPORTANT_MESSAGE_REMIND_ATTENDANT_SENT =
	'Important message remind attendant sent'

// User opens the important information inbox
// message ImportantInboxOpened {
//     optional string schema_name = 1 [default = 'important_inbox_opened'];
//     required string from = 2; // chat_info, chat_screen
// }
export const IMPORTANT_INBOX_LINK_CLICKED = 'Important inbox opened'

// User navigates to another important info inbox tab
// message ImportantInboxTabViewed {
//     optional string schema_name = 1 [default = 'important_inbox_tab_viewed'];
//     required string type = 2; // active, all
// }
export const IMPORTANT_INBOX_TAB_VIEWED = 'Important inbox tab viewed'

export const DIRECT_MESSAGE_CLICK = 'Clicked direct message'

export const FETCH_MORE_IMPORTANT_MESSAGES_CLICK =
	'Fetch more important messages click'

export const FETCH_MORE_OUTSTANDING_IMPORTANT_MESSAGES_CLICK =
	'Fetch more outstanding important messages click'
export const IMPORTANT_INBOX_BACK_CLICKED = 'Important inbox back click'
export const POLL_QUESTION_TYPED = 'Poll question typed'

export const POLL_OPTION_TYPED = 'Poll option typed'

export const ADD_POLL_OPTION_CLICKED = 'Add poll option clicked'

export const REMOVE_POLL_OPTION_CLICKED = 'Remove poll option clicked'

export const POLL_MULTIPLE_CHOICE_TOGGLED = 'Poll multiple choice toggled'

export const POLL_OPTION_SELECTED = 'Poll option selected'

export const POLL_VOTE_CLICKED = 'Poll vote clicked'

export const POLL_UNDO_VOTE_CLICKED = 'Poll undo vote clicked'

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////
// FILE SHARING
// Product Specs : https://www.notion.so/heysphere/File-sharing-97dd393e5a8440d0836bf92c88300673
// User has chosen a file to share and will attempt to upload it
// message fileUploadAttempted {
//     optional string schema_name = 1 [default = 'file_upload_attempted'];
//     required string conversation_id = 2;
// TODO: we don't have this id on web at this point
//     required string message_unique_id = 3; // unique message id generated by the client
//     optional string file_name = 4;
//     optional string mime_type = 5; // File type (see https://en.wikipedia.org/wiki/Media_type)
//     optional int64 file_size = 6; // File size in bytes
// }

export const FILE_UPLOAD_ATTEMPTED = 'File upload attempted'

// User clicks the file-upload-cancel button before the file-upload is complete
// message fileUploadCancel {
//     optional string schema_name = 1 [default = 'file_upload_cancel'];
//     required string conversation_id = 2;
// TODO: we don't have this id on web at this point
//     required string message_unique_id = 3; // unique message id generated by the client
// }

export const FILE_UPLOAD_CANCEL = 'File upload cancel'

// File failed to upload for one of these 3 reasons
// size_too_large : File size is over the limit and can't be uploaded
// format_unsupported : File is in an unsupported format and can't be uploaded
// connectivity : There was a connectivity issue and the file did not upload, message did not send
// message fileUploadFailed {
//     optional string schema_name = 1 [default = 'file_upload_failed'];
//     required string conversation_id = 2;
//     required string error_message = 3; // Error message provided by Cloudinary
//     optional string file_name = 4;
//     optional string mime_type = 5; // File type (see https://en.wikipedia.org/wiki/Media_type)
//     optional int64 file_size = 6; // File size in bytes
//     optional int64 uniform_type_identifier = 7; // For iOS (see https://en.wikipedia.org/wiki/Uniform_Type_Identifier)
// }

export const FILE_UPLOAD_FAILED = 'File upload failed'

// User clicks the red error icon next to a file that failed to upload - thus expanding the options modal
// message fileUploadFailedModalExpanded {
//     optional string schema_name = 1 [default = 'file_upload_failed_modal_expanded'];
//     required string conversation_id = 2;
//     required string error_message = 3; // Error message provided by Cloudinary
//     required string message_unique_id = 4; // unique message id generated by the client
// }

// we don't have this modal on web
export const FILE_UPLOAD_FAILED_MODAL_EXPANDED = 'File upload modal expanded'

// User clicks retry from the file upload error option modal
// message fileUploadRetry {
//     optional string schema_name = 1 [default = 'file_upload_retry'];
//     required string conversation_id = 2;
//     required string message_unique_id = 3; // unique message id generated by the client
// }

// we don't have this modal on web
export const FILE_UPLOAD_RETRY = 'File upload retry'

// User clicks delete message from the file upload error option modal
// message fileUploadFailedDeleteMessage {
//     optional string schema_name = 1 [default = 'file_upload_failed_delete_message'];
//     required string conversation_id = 2;
//     required string message_unique_id = 3; // unique message id generated by the client
// }

// we don't have this modal on web
export const FILE_UPLOAD_FAILED_DELETE_MESSAGE =
	'File upload failed delete message'

// Attendant opens a file in the conversation
// message fileOpened {
//     optional string schema_name = 1 [default = 'file_opened'];
//     required string conversation_id = 2;
//     required string message_id = 3;
//     required string file_id = 4;
//     optional string attachment_type = 5; // as per backend specs
// }

export const FILE_OPENED = 'File opened'

export const FAVICON_UNREAD_CONTENT_INDICATOR_SHOWN =
	'Favicon unread content indicator shown'
export const FAVICON_UNREAD_CONTENT_INDICATOR_REMOVED =
	'Favicon unread content indicator removed'

// User clicks a link preview
// message LinkPreviewClicked {
//	optional string schema_name = 1 [default = 'link_preview_clicked'];
//	required string conversation_id = 2;
//	required string url = 3; // url of the link clicked
//	optional string message_id = 4; // Pass if available
//	optional string message_unique_id = 5; // unique message id generated by the mobile clients
//	optional int64 link_preview_index = 6; // position of the link-preview in the list of link-previews showing for a message
// }
export const LINK_PREVIEW_CLICK = 'Link preview clicked'

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Message Reactions
// Product Specs : https://www.notion.so/heysphere/Reactions-WIP-6b07040fe1a747a18dc3ef0e6f36803f

// User adds a reaction to a message through one of these 3 flows :
// source = 'message_menu' when the reaction is added after long-pressing a message to get the message_menu
// source = 'message_reaction' when the reaction is added after clicking an existing reaction on the message's reaction bar
// source = 'add_reaction' when the reaction is added after clicking the + button at the end of the message's reaction bar
// message MessageReactionAdded {
//     optional string schema_name = 1 [default = 'message_reaction_added'];
//     required string conversation_id = 2;
//     required string message_id = 3;
//     required string source = 4; // in ['message_menu', 'message_reaction', 'add_reaction']
//     required string reaction_type = 5; // as named on the backend
// }

// export const MESSAGE_REACTION_ADDED = 'Message reaction added' <-- replaced by version from appreciations

// User removes a reaction from a message through one of these 2 flows :
// source = 'message_menu' when a reaction is removed after long-pressing a message to get the message_menu
// source = 'message_reaction' when a reaction is removed after clicking an existing reaction on the message's reaction bar
// message MessageReactionRemoved {
//     optional string schema_name = 1 [default = 'message_reaction_removed'];
//     required string conversation_id = 2;
//     required string message_id = 3;
//     required string source = 4; // in ['message_menu', 'message_reaction']
//     required string reaction_type = 5; // as named on the backend
// }

// export const MESSAGE_REACTION_REMOVED = 'Message reaction removed' <-- replaced by version from appreciations

// User clicks the + button at the end of the message's reaction bar (in view to add a reaction that has not been added to the message yet)
// message MessageAddReactionOptionActioned {
// 	optional string schema_name = 1[default = 'message_add_reaction_option_actioned'];
// 	required string conversation_id = 2
// 	required string message_id = 3
// }
// not on web
// export const MESSAGE_ADD_REACTION_OPTION_ACTIONED =
// 	'Message add reaction option actioned'

// User long presses a reaction that was added to a message on the message's reaction bar (in view to open the details of the message's reactions)
// message MessageReactionLongPressed {
//     optional string schema_name = 1 [default = 'message_reaction_long_pressed'];
//     required string conversation_id = 2;
//     required string message_id = 3;
//     required string reaction_type = 4; // as named on the backend
// }
// not on web
// export const MESSAGE_REACTION_LONG_PRESSED = 'Message reaction long pressed'

// User interacts with a reaction from the list of all reactions that have been added to a message (thus opening the profile of the reaction creator)
// message ReactorItemActioned {
//     optional string schema_name = 1 [default = 'reactor_item_actioned'];
//     required string conversation_id = 2;
//     required string message_id = 3;
//     required string reaction_type = 4; // as named on the backend
//     required string reactor_attendant_id = 5;
//     optional int64 reactor_index = 6; // Reactor index in the list
// }
// not on web
// export const MESSAGE_REACTION_ITEM_ACTIONED = 'Message reaction item actioned'

// REMOVE CHAT MEMBERS TRACKS
// User expands the chat-member-action-menu for a given chat member from the members-list of the chat-info-page
// message ChatMemberActionMenuExpanded {
//     optional string schema_name = 1 [default = 'chat_member_action_menu_expanded'];
//     required string conversation_id = 2;
//     required string target_attendant_id = 3; // attendant_id of the attendant affected by the action flow
// }
export const CHAT_MEMBER_ACTION_MENU_EXPANDED =
	'Chat member action menu expanded'

// User starts the chat-member-removal flow by clicking "delete" on the chat-member-action-menu
// message RemoveChatMemberInitiated {
//     optional string schema_name = 1 [default = 'remove_chat_member_initiated'];
//     required string conversation_id = 2;
//     required string target_attendant_id = 3; // attendant_id of the attendant affected by the action flow
// }
export const REMOVE_CHAT_MEMBER_INITIATED = 'Remove chat member initiated'

// User confirms they would like to remove this member fom the chat after being prompted
// message RemoveChatMemberConfirmed {
//     optional string schema_name = 1 [default = 'remove_chat_member_confirmed'];
//     required string conversation_id = 2;
//     required string target_attendant_id = 3; // attendant_id of the attendant affected by the action flow
// }
export const REMOVE_CHAT_MEMBER_CONFIRMED = 'Remove chat member confirmed'

// User abandons the chat-member-removal flow after being asked to confirm they want to remove the member from the chat
// message RemoveChatMemberCancelled {
//     optional string schema_name = 1 [default = 'remove_chat_member_cancelled'];
//     required string conversation_id = 2;
//     required string target_attendant_id = 3; // attendant_id of the attendant affected by the action flow
// }
export const REMOVE_CHAT_MEMBER_CANCELLED = 'Remove chat member cancelled'

// The attempt to remove a chat member failed, the user who intiated the removal flow is shown a remove_chat_member_failed modal
// message RemoveChatMemberFailed {
//     optional string schema_name = 1 [default = 'remove_chat_member_failed'];
//     required string conversation_id = 2;
//     required string target_attendant_id = 3; // attendant_id of the attendant affected by the action flow
// }
export const REMOVE_CHAT_MEMBER_FAILED = 'Remove chat member failed'

// User clicks retry on the remove_chat_member_failed modal to start a new attempt to remove the chat member
// not on web
// message FailedRemoveChatMemberRetry {
//     optional string schema_name = 1 [default = 'failed_remove_chat_member_retry'];
//     required string conversation_id = 2;
//     required string target_attendant_id = 3; // attendant_id of the attendant affected by the action flow
// }
export const FAILED_REMOVE_CHAT_MEMBER_RETRY = 'Failed remove chat member retry'

// User clicks cancel on the remove_chat_member_failed modal to abandon the chat-member-removal flow
// not on web
// message FailedRemoveChatMemberCancel {
//     optional string schema_name = 1 [default = 'failed_remove_chat_member_cancel'];
//     required string conversation_id = 2;
//     required string target_attendant_id = 3; // attendant_id of the attendant affected by the action flow
// }
export const FAILED_REMOVE_CHAT_MEMBER_CANCEL =
	'Failed remove chat member cancel'

// User was on a chat screen when they got removed from said chat. They are shown a "Oops, this chat is not available" screen
// message ChatUnavailableViewed {
//     optional string schema_name = 1 [default = 'chat_unavailable_viewed'];
//     required string conversation_id = 2;
// }
export const CHAT_UNAVAILABLE_VIEWED = 'Chat unavailable viewed'

// The attempt to add chat members failed, the user who intiated the addition flow is shown an add_chat_member_failed modal
// message AddChatMemberFailed {
//     optional string schema_name = 1 [default = 'add_chat_member_failed'];
//     required string conversation_id = 2;
// }
export const ADD_CHAT_MEMBER_FAILED = 'Add chat member failed'

// User clicks retry on the add_chat_member_failed modal to start a new attempt to add chat members
// message FailedAddChatMemberRetry {
//     optional string schema_name = 1 [default = 'failed_add_chat_member_retry'];
//     required string conversation_id = 2;
// }
export const FAILED_ADD_CHAT_MEMBER_RETRY = 'Failed add chat member retry'

// User clicks cancel on the add_chat_member_failed modal to abandon the chat-member-addition flow
// message FailedAddChatMemberCancel {
//     optional string schema_name = 1 [default = 'failed_add_chat_member_cancel'];
//     required string conversation_id = 2;
// }
export const FAILED_ADD_CHAT_MEMBER_CANCEL = 'Failed add chat member cancel'

// User initiates a new / joins an existing Direct Message with a Sphere Member from one of these two sources
// source = 'create_chat'; when user goes to DM after going through create-chat-from-feed -> direct-message -> click-on-a-member-to-dm
// source = 'member_profile'; when user goes to DM after clicking on "Send a Message" from someone's profile
// NOTE 1 : Make sure at least one of target_agent_id and target_attendant_id is passed with this track (depending on which of the two is available)
// NOTE 2 : Do not trigger this track when user clicks on the existing DM from the feed to reach it. This is covered by OpenedChat.
export const DIRECT_MESSAGE_ACTIONED = 'direct_message_actioned'

// After initiating the chat creation flow, user selects the type of chat they want to create; either 'group_chat' or 'direct_message'.
// message CreateChatTypeSelected {
// 	optional string schema_name = 1 [default = 'create_chat_type_selected'];
// 	required string chat_type = 2; // in ['group_chat', 'direct_message']
// }
export const CREATE_SPHERE_CONTENT_TYPE_SELECTED =
	'create_sphere_content_type_selected'

// SPECIFIC GIF KEYBOARD TRACKS (https://www.notion.so/heysphere/Gifs-WIP-23799ac4baab464094d226b10be8fb3b)
// User expands the GIF keyboard (from keyboard GIF button or from the message attachment menu)
// source = 'message_attachment' when expanding the GIF keyboard through clicking + button (to select message attachments) -> get shown attachment types -> choose GIF -> expands GIF keyboard
// source = 'keyboard' when expanding the GIF keyboard after clicking the GIF button on the (right-hand-side) of the Sphere keyboard-composer
// message GifKeyboardExpanded {
//     optional string schema_name = 1 [default = 'gif_keyboard_expanded'];
//     required string conversation_id = 2;
//     required string source = 3; // in ['keyboard', 'message_attachment']
// }
export const GIF_KEYBOARD_EXPANDED = 'Gif keyboard expanded'

// A GIF search is started for a given search_term
// either through auto-search (search_trigger = 'auto') or via the user tapping the search button (search_trigger = 'manual')
// message GifSearchStarted {
//     optional string schema_name = 1 [default = 'gif_search_started'];
//     required string conversation_id = 2;
//     required string search_term = 3;
//     required string search_trigger = 4; // in ['auto', 'manual']
// }
export const GIF_SEARCH_STARTED = 'Gif search started'

// User scrolls through the suggested GIFs
// message GifSuggestionsScrolled {
//    optional string schema_name = 1 [default = 'gif_suggestions_scrolled'];
//     required string conversation_id = 2;
//     required string search_term = 3;
// }
export const GIF_SUGGESTIONS_SCROLLED = 'Gif suggestions scrolled'

// User selects a GIF (to send into chat) from the GIF keyboard's suggestions
// message GifSelected {
//     optional string schema_name = 1 [default = 'gif_selected'];
//     required string conversation_id = 2;
//     required string search_term = 3;
// }
export const GIF_SELECTED = 'Gif selected'

// Gif search returned no results; "No GIFs found" is shown
// message GifSearchNoResultsFound {
//     optional string schema_name = 1 [default = 'gif_search_no_results_found'];
//     required string conversation_id = 2;
//     required string search_term = 3;
// }
export const GIF_SEARCH_NO_RESULTS_FOUND = 'Gif search no results found'

// Gif search failed; "Something went wrong" is shown
// message GifSearchFailed {
//     optional string schema_name = 1 [default = 'gif_search_failed'];
//     required string conversation_id = 2;
//     required string search_term = 3;
// }
export const GIF_SEARCH_FAILED = 'Gif search failed'

// User closes the GIF keyboard
// message GifKeyboardClosed {
//     optional string schema_name = 1 [default = 'gif_keyboard_closed'];
//     required string conversation_id = 2;
// }
export const GIF_KEYBOARD_CLOSED = 'Gif keyboard closed'

// EVENT CREATION FLOW

// User starts typing in the event-Name (send once)
// message EventNameTyped {
// 	optional string schema_name = 1 [default = 'event_name_typed'];
// }
export const EVENT_NAME_TYPED = 'event_name_typed'

// User clicks the event-Image icon to edit/add the event's image
// message EventImageActioned {
// 	optional string schema_name = 1 [default = 'event_image_actioned'];
// }
export const EVENT_IMAGE_ACTIONED = 'event_image_actioned'

// User starts typing in the event-Description (send once)
// message EventDescriptionTyped {
// 	optional string schema_name = 1 [default = 'event_description_typed'];
// }
export const EVENT_DESCRIPTION_TYPED = 'event_description_typed'

// User starts typing in the event-Location (send once)
// message EventLocationTyped {
// 	optional string schema_name = 1 [default = 'event_location_typed'];
// }
export const EVENT_LOCATION_TYPED = 'event_location_typed'

// User starts typing in the event-Link (send once)
// message EventLinkTyped {
// 	optional string schema_name = 1 [default = 'event_link_typed'];
// }
export const EVENT_LINK_TYPED = 'event_link_typed'

// User clicks on the event-Date field to edit it
// message EventDateActioned {
// 	optional string schema_name = 1 [default = 'event_date_actioned'];
// }
export const EVENT_DATE_ACTIONED = 'event_date_actioned'

// User clicks on the event-Starts field to edit it
// message EventStartTimeActioned {
// 	optional string schema_name = 1 [default = 'event_start_time_actioned'];
// }
export const EVENT_START_TIME_ACTIONED = 'event_start_time_actioned'

// User clicks on the event-Ends field to edit it
// message EventEndTimeActioned {
// 	optional string schema_name = 1 [default = 'event_end_time_actioned'];
// }
export const EVENT_END_TIME_ACTIONED = 'event_end_time_actioned'

// User clicks the Create button from the event-creation screen to confirm creating the event after filling the necessary fields
// message CreateEventConfirmed {
// 	optional string schema_name = 1 [default = 'create_event_confirmed'];
// }
export const CREATE_EVENT_CONFIRMED = 'create_event_confirmed'

// EVENT DELETION

// User initiates the event deletion flow by clicking the delete icon on the event page. The user is then showed a prompt to confirm the deletion or cancel.
// message DeleteEventInitiated {
// 	optional string schema_name = 1 [default = 'delete_event_initiated'];
// 	required string event_id = 2;
// }
export const DELETE_EVENT_INITIATED = 'delete_event_initiated'

// User confirms the deletion of the event after being shown the 'Delete The Event?' modal
// message DeleteEventConfirmed {
// 	optional string schema_name = 1 [default = 'delete_event_confirmed'];
// 	required string event_id = 2;
// }
export const DELETE_EVENT_CONFIRMED = 'delete_event_confirmed'

// User cancels the deletion of the event after being shown the 'Delete The Event?' modal
// message DeleteEventCanceled {
// 	optional string schema_name = 1 [default = 'delete_event_canceled'];
// 	required string event_id = 2;
// }
export const DELETE_EVENT_CANCELED = 'delete_event_canceled'

// EVENT RSVP FLOW

// User clicks RSVP button to initiate the RSVP-to-an-event flow
// source = 'feed' when user is in the RSVP from feed flow
// source = 'event_screen' when user is in the RSVP from event-screen flow
// message EventRsvpInitiated {
// 	optional string schema_name = 1 [default = 'event_rsvp_initiated'];
// 	required string event_id = 2;
// 	required string source = 3; // in ['feed', 'event_screen']
// }
export const EVENT_RSVP_INITIATED = 'event_rsvp_initiated'

// User submits their event RSVP
// source = 'feed' when user is in the RSVP from feed flow
// source = 'event_screen' when user is in the RSVP from event-screen flow
// message EventRsvpSubmitted {
// 	optional string schema_name = 1 [default = 'event_rsvp_submitted'];
// 	required string event_id = 2;
// 	required string source = 3; // in ['feed', 'event_screen']
// 	required string rsvp = 4; // in ['going', 'not_going', 'thinking']
// }
export const EVENT_RSVP_SUBMITTED = 'event_rsvp_submitted'

// User's attempt to RSVP failed
// source = 'feed' when user is in the RSVP from feed flow
// source = 'event_screen' when user is in the RSVP from event-screen flow
// message EventRsvpFailed {
// 	optional string schema_name = 1 [default = 'event_rsvp_failed'];
// 	required string event_id = 2;
// 	required string source = 3; // in ['feed', 'event_screen']
// }
export const EVENT_RSVP_FAILED = 'event_rsvp_failed'

// Event screen Interactions

// User views the event screen
// message EventScreenViewed {
// 	optional string schema_name = 1 [default = 'event_screen_viewed'];
// 	required string event_id = 2;
// }
export const EVENT_SCREEN_VIEWED = 'event_screen_viewed'

// User clicks the event link from the event screen
// message EventLinkClicked {
// 	optional string schema_name = 1 [default = 'event_link_clicked'];
// 	required string event_id = 2;
// }
export const EVENT_LINK_CLICKED = 'event_link_clicked'

// User clicks into the event chat from the event screen
// message EventChatClicked {
// 	optional string schema_name = 1 [default = 'event_chat_clicked'];
// 	required string event_id = 2;
// }
export const EVENT_CHAT_CLICKED = 'event_chat_clicked'

// User selects an invitee list section
// message EventAttendeeSectionClicked {
// 	optional string schema_name = 1 [default = 'event_attendee_section_clicked'];
// 	required string event_id = 2;
// 	required string section = 3; // in ['going', 'not_going', 'thinking']
// }
export const EVENT_ATTENDEE_SECTION_CLICKED = 'event_attendee_section_clicked'

// User clicks on the profile of one of the event invitees
// message EventAttendeeProfileClicked {
// 	optional string schema_name = 1 [default = 'event_attendee_profile_clicked'];
// 	required string event_id = 2;
// 	required string section = 3; // in ['going', 'not_going', 'thinking']
// 	required string agent_id = 4;
// }
export const EVENT_ATTENDEE_PROFILE_CLICKED = 'event_attendee_profile_clicked'

// User clicks on the profile of the event creator from the event screen
// message EventCreatorProfileClicked {
// 	optional string schema_name = 1 [default = 'event_creator_profile_clicked'];
// 	required string event_id = 2;
// 	required string agent_id = 3;
// }
export const EVENT_CREATOR_PROFILE_CLICKED = 'event_creator_profile_clicked'

// non tracks.proto sphereEvent eventsNames
export const EVENT_START_DATE_SELECTED = 'event_start_date_selected'
export const EVENT_END_DATE_SELECTED = 'event_end_date_selected'
export const EVENT_RSVP_RETRY_CLICK = 'event_rsvp_retry_click'
export const FEED_EVENT_CLICKED = 'feed_event_clicked'
export const FEED_EVENT_BANNER_CLICKED = 'feed_event_banner_clicked'
export const LOAD_ALL_EVENT_INVITEES_CLICK = 'load_all_event_invitees_click'

// User clicks the Edit button from the event screen to initiate the Edit Event Flow
// Track event_edit_initiated with event_id = ?
export const EVENT_EDIT_INITIATED = 'event_edit_initiated'

// User clicks Save after editing event details (which will trigger the "notify everyone" menu)
// Track event_edit_save_initiated with event_id = ?
export const EVENT_EDIT_SAVE_INITIATED = 'event_edit_save_initiated'

// - User chooses to save the event edits (and either notify everyone or not notify anyone
// 	Track `event_edit_save_confirmed` with `event_id = ?` and `notify = true | false`
export const EVENT_EDIT_SAVE_CONFIRMED = 'event_edit_save_confirmed'

// 	- User chooses to cancel the edit event flow (either from the "notify everyone" menu after clicking save (→ source = 'notify_everyone') OR straight from the edit event modal (→ source = 'edit_event'))
// 	Track `event_edit_canceled` with `event_id = ?` and `source = 'edit_event' | 'notify_everyone'`
export const EVENT_EDIT_CANCELED = 'event_edit_canceled'

export const REFRESH_ALL_EVENTS_ERROR_CLICK = 'refresh_all_events_error_click'

// - User sees an empty Event-Board view (right now, the view is either "active" or "past")
// → Track `event_board_empty_viewed` with `sphere_id = ?` and `view = 'active' | 'past'`
export const EVENT_BOARD_EMPTY_VIEWED = 'event_board_empty_viewed'
// - User opens a view ("active" or "past") of the Events-Board of a Sphere (either by clicking into the Event-Board from the sphere info OR by clicking on a tab (active/past) in the Event-Board)
//     → Track `event_board_opened` with `sphere_id = ?` and `view = 'active' | 'past'`
export const EVENT_BOARD_OPENED = 'event_board_opened'

export const EVENT_LISTS_LINK_CLICKED = 'event_board_link_clicked'

// - User clicks on the remind button of one of the event-invitee sections to initiate the event-reminder-flow
// Track `event_reminder_initiated` with `event_id = ?` and `section = 'thinking' | 'pending'`
export const EVENT_REMINDER_INITIATED = 'event_reminder_initiated'
// - User clicks on the "remind everyone" button to send reminders to all invitee of a given section
// Track `event_remind_everyone_actioned` with `event_id = ?` and `section = 'thinking' | 'pending'`
export const EVENT_REMIND_EVERYONE_ACTIONED = 'event_remind_everyone_actioned'
// - User clicks on the "remind" button to send a reminder to a specific invitee
// Track `event_remind_invitee_actioned` with `event_id = ?` and `agent_id = ?` (the agent_id of the invitee that will get the reminder) and `section = 'thinking' | 'pending'`
export const EVENT_REMIND_INVITEE_ACTIONED = 'event_remind_invitee_actioned'

export const FEED_SEARCH_INITIATED = 'feed_search_initiated'
export const FEED_SEARCH_CLEARED = 'feed_search_cleared'

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////
// ADMIN PINBOARD
// Product Specs : https://www.notion.so/heysphere/Admin-Pinboard-6bf34a3b97c74e90901c037daa600647

// PINNING A MESSAGE

// User pins the message by clicking on Pin from the message menu
// message MessagePinned {
//     optional string schema_name = 1 [default = 'message_pinned'];
//     required string conversation_id = 2;
//     required string message_id = 3;
// }
export const MESSAGE_PINNED = 'message_pinned'

// User views the first_message_pinned_menu after pinning a message for the first time
// message FirstMessagePinnedMenuViewed {
//     optional string schema_name = 1 [default = 'first_message_pinned_menu_viewed'];
// }
export const FIRST_MESSAGE_PINNED_MENU_VIEWED =
	'first_message_pinned_menu_viewed'

// User interacts (go to pinboard OR dismiss) with the first_message_pinned_menu (shown after pinning a message for the first time)
// message FirstMessagePinnedMenuActioned {
//     optional string schema_name = 1 [default = 'first_message_pinned_menu_actioned'];
//     required string action = 2; // in ['go_to_pinboard', 'dismiss']
// }
export const FIRST_MESSAGE_PINNED_MENU_ACTIONED =
	'first_message_pinned_menu_actioned'

// UNPINNING A MESSAGE

// User unpins the message by clicking on Unpin from the message menu
// message MessageUnpinned {
//     optional string schema_name = 1 [default = 'message_unpinned'];
//     required string conversation_id = 2;
//     required string message_id = 3;
// }
export const MESSAGE_UNPINNED = 'message_unpinned'

// ACCESSING THE PINBOARD

// User opens the pinboard of a Sphere (from the sphere info)
// message PinboardOpened {
//     optional string schema_name = 1 [default = 'pinboard_opened'];
//     required string sphere_id = 2;
// }
export const PINBOARD_OPENED = 'pinboard_opened'

// INTERACTING WITH MESSAGES ON THE PINBOARD

// User taps on a pinned message inside the pinboard to go to the chat where the message was pinned
// message PinnedMessageClicked {
//     optional string schema_name = 1 [default = 'pinned_message_clicked'];
//     required string conversation_id = 2;
//     required string message_id = 3;
// }
export const PINNED_MESSAGE_CLICKED = 'pinned_message_clicked'

// User copies link to the message by clicking on Copy Link from the message menu
// message CopyLinkToMessageClicked {
//     optional string schema_name = 1 [default = 'copy_link_to_message_clicked'];
//     required string conversation_id = 2;
//     required string message_id = 3;
// }
export const COPY_LINK_TO_MESSAGE_CLICKED = 'copy_link_to_message_clicked'

export const MESSAGE_ACTIONS_OVERFLOW_MENU_OPENED =
	'message_actions_overflow_menu_opened'

// Content Feed
// Missing proto defs as of now
export const SPHERE_FEED_VIEWED = 'sphere_feed_viewed'
export const SPHERE_CHAT_LIST_VIEWED = 'sphere_chat_list_viewed'
export const CARD_ACTION_EXPANDED = 'card_action_expanded'
export const HIDE_FROM_FEED_ACTIONED = 'hide_from_feed_actioned'
export const CHAT_FIRST_MESSAGE_TYPED = 'chat_first_message_typed'
export const DISABLED_APPRECIATION_CLICK = 'disabled_appreciation_click'

// Appreciations

// **Create an Appreciation**

// - User initiates the Appreciation Creation Flow
//     → Track `create_appreciation_initiated` with `source = 'appreciation_board' | 'send_appreciation'` and `sphere_id = ? | conversation_id = ?`
export const CREATE_APPRECIATION_INITIATED = 'create_appreciation_initiated'
// - User Cancels the Appreciation Creation Flow
//     → Track `create_appreciation_canceled`  with `sphere_id = ? | conversation_id = ?`
export const CREATE_APPRECIATION_CANCELED = 'create_appreciation_canceled'

// - User Confirms the creation of an Appreciation
//     → Track `create_appreciation_confirmed`  with `sphere_id = ?| conversation_id = ?`
export const CREATE_APPRECIATION_CONFIRMED = 'create_appreciation_confirmed'

// **Edit/Delete Sphere Appreciations**

// - User opens a sphere appreciation menu (to get the options to edit or delete an appreciation)
// → Track `sphere_appreciation_menu_expanded` with `sphere_id = ? | conversation_id = ?` & `appreciation_id = ?`
export const SPHERE_APPRECIATION_MENU_EXPANDED =
	'sphere_appreciation_menu_expanded'
// - User initiates the edition flow for a sphere appreciation
// → Track `sphere_appreciation_edit_initiated` with `sphere_id = ? | conversation_id = ?` & `appreciation_id = ?`
export const SPHERE_APPRECIATION_EDIT_INITIATED =
	'sphere_appreciation_edit_initiated'
// - User completes the edition flow for a sphere appreciation
// → Track `sphere_appreciation_edit_confirmed` with `sphere_id = ? | conversation_id = ?` & `appreciation_id = ?`
export const SPHERE_APPRECIATION_EDIT_CONFIRMED =
	'sphere_appreciation_edit_confirmed'
// - User initiates the deletion flow for a sphere appreciation
// → Track `sphere_appreciation_delete_initiated` with `sphere_id = ? | conversation_id = ?` & `appreciation_id = ?`
export const SPHERE_APPRECIATION_DELETE_INITIATED =
	'sphere_appreciation_delete_initiated'
// - User completes the deletion flow for a sphere appreciation
// → Track `sphere_appreciation_delete_confirmed` with `sphere_id = ? | conversation_id = ?` & `appreciation_id = ?`
export const SPHERE_APPRECIATION_DELETE_CONFIRMED =
	'sphere_appreciation_delete_confirmed'

// **Give/remove Appreciations (I want to reuse our MessageReaction tracks with a few tweaks if you all feel this is appropriate)**

// - User adds a reaction to a message
// → Track `message_reaction_added` with `conversation_id = ?` & `message_id = ?` & `source = 'message_menu' | 'message_reaction' | 'add_reaction'` & `reaction_type = 'emoji' | 'appreciation'` & `emoji_id = ?` & `appreciation_id = ?`
export const MESSAGE_REACTION_ADDED = 'message_reaction_added'
// - User removes a reaction from a message
// → Track `message_reaction_removed` with `conversation_id = ?` & `message_id = ?` & `source = 'message_menu' | 'message_reaction'` & `reaction_type = 'emoji' | 'appreciation'` & `emoji_id = ?` & `appreciation_id = ?`
export const MESSAGE_REACTION_REMOVED = 'message_reaction_removed'
// - User clicks the + button at the end of the message's reaction bar (in view to add a reaction that has not been added to the message yet) — NOT ON WEB
// → Track `message_add_reaction_option_actioned` with `conversation_id = ?` & `message_id = ?`
// export const MESSAGE_ADD_REACTION_OPTION_ACTIONED =
// 	'message_add_reaction_option_actioned'
// - User Clicks on the "React" item of the message meny to open the Reaction Tray
//  — WEB ONLY
// → Track `reaction_tray_open_clicked` with `conversation_id = ?` & `message_id = ?`
// export const REACTION_TRAY_OPEN_CLICKED = 'reaction_tray_open_clicked' // defined above under the name reaction_tray_open_click
// - User long presses a message reaction — NOT ON WEB
// → Track `message_reaction_long_pressed` with `conversation_id = ?` & `message_id = ?` & `reaction_type = 'emoji' | 'appreciation'`
// export const MESSAGE_REACTION_LONG_PRESSED = 'message_reaction_long_pressed'
// - User interacts with a reaction from the list of all reactions that have been added to a message (thus opening the profile of the reaction creator) — NOT ON WEB
// → Track `reactor_item_actioned` with `conversation_id = ?` & `message_id = ?` & `reaction_type = 'emoji' | 'appreciation'` & `reactor_attendant_id = ?` & `reactor_index = ?`
// export const REACTOR_ITEM_ACTIONED = 'reactor_item_actioned'

// **View Sphere Appreciation Board**

// - User opens the sphere info
// → Track `sphere_info_clicked` with `sphere_id = ?`
// export const SPHERE_INFO_CLICKED = 'sphere_info_clicked' // already covered above with no changes for appreciations
// - User opens the Sphere's Appreciation Board (from the sphere info)
//     → Track `sphere_appreciation_board_opened` with `sphere_id = ?`
export const SPHERE_APPRECIATION_BOARD_OPENED =
	'sphere_appreciation_board_opened'

// Custom appreciation tracks
export const APPRECIATION_PICKER_CLOSE_CLICK =
	'appreciation_picker_close_clicked'
export const APPRECIATION_PICKER_OPEN_CLICK = 'appreciation_picker_open_clicked'
export const SPHERE_APPRECIATION_DELETE_DISMISSED =
	'sphere_appreciation_delete_dismissed'
