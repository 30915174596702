import { takeEvery } from 'typed-redux-saga/macro'

import localMessages from '../slices/localMessages'
import { SuperSegment } from '../utils/segmentTypes'

export default function* messageErrorsSaga() {
	yield* takeEvery(
		localMessages.actions.messageCreateFailure.match,
		function* (action) {
			SuperSegment.CHAT_MESSAGE_SEND_FAILED({
				message_random_id: action.payload.uniqueId,
				conversation_id: action.payload.conversationId,
			})
			yield
		}
	)
}
