import { FC } from 'react'
import { useTheme } from 'styled-components'

import {
	CheckMark,
	HiddenInput,
	ToggleContainer,
	HiddenSvg,
} from './styled-components'
import { Props, CheckboxMarkStyle } from './types'

const Checkbox: FC<Props> = ({
	onClick,
	checked,
	markColor,
	circleColor,
	markStyle,
}) => {
	markStyle = markStyle ?? CheckboxMarkStyle.Checkmark
	const theme = useTheme()
	return (
		<ToggleContainer onClick={onClick}>
			<HiddenInput type="checkbox" checked={checked} readOnly />
			<CheckMark>
				<HiddenSvg
					width={theme.sizing.scale800}
					height={theme.sizing.scale800}
					viewBox="0 0 24 24"
					version="1.1"
				>
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g>
							<circle
								fill={circleColor ?? theme.colors.primary}
								cx="12"
								cy="12"
								r="12"
							/>
							{markStyle === CheckboxMarkStyle.Checkmark ? (
								<polyline
									stroke={markColor ?? theme.colors.iconFillLight}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
									transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
									points="12 8 9 11 15 16"
								/>
							) : (
								<circle
									fill={markColor ?? theme.colors.iconFillLight}
									cx="12"
									cy="12"
									r="4"
								/>
							)}
						</g>
					</g>
				</HiddenSvg>
			</CheckMark>
		</ToggleContainer>
	)
}

export default Checkbox
