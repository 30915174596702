// Loads segment SDK
// https://segment.com/docs/sources/website/analytics.js/quickstart/
import { browserReportSync, Report } from './browserReport'

function installSegment(segmentProxyEndpoint: string, writeKey: string) {
	// Create a queue, but don't obliterate an existing one!
	// this type (wrongly) reduces to SegmentAnalytics.AnalyticsJS as the window.analytics is typed definitely, i.e. the type system always considers its present
	window.analytics = window.analytics || []
	// thus this type is correct
	// but the flow analyzer remove the array variant
	// thus the one place below needs to cast to array
	const analytics: (SegmentAnalytics.AnalyticsJS | Array<string>) & {
		initialize?(): void
		invoked?: boolean
		methods?: string[]
		factory?(method: string): (...args: any[]) => SegmentAnalytics.AnalyticsJS
		_loadOptions?: SegmentAnalytics.SegmentOpts
		SNIPPET_VERSION?: string
	} = window.analytics

	// If the real analytics.js is already on the page return.
	if (analytics.initialize) return

	// If the snippet was invoked already throw an error.
	if (analytics.invoked) {
		throw new Error('Segment snippet included twice.')
	}

	// Invoked flag, to make sure the snippet
	// is never invoked twice.
	analytics.invoked = true

	// A list of the methods in Analytics.js to stub.
	analytics.methods = [
		'trackSubmit',
		'trackClick',
		'trackLink',
		'trackForm',
		'pageview',
		'identify',
		'reset',
		'group',
		'track',
		'ready',
		'alias',
		'debug',
		'page',
		'once',
		'off',
		'on',
	]

	// Define a factory to create stubs. These are placeholders
	// for methods in Analytics.js so that you never have to wait
	// for it to load to actually record data. The `method` is
	// stored as the first argument, so we can replay the data.
	analytics.factory =
		(method: string) =>
		(...args: any[]) => {
			args.unshift(method)
			if (Array.isArray(analytics)) {
				analytics.push(args)
			}
			return analytics
		}

	// For each of our methods, generate a queueing stub.
	for (let i = 0; i < analytics.methods.length; i += 1) {
		const key = analytics.methods[i]
		;(analytics as any)[key] = analytics.factory(key)
	}

	// Define a method to load Analytics.js from our CDN,
	// and that will be sure to only ever load it once.
	analytics.load = (key: string, options?: SegmentAnalytics.SegmentOpts) => {
		// Create an async script element based on your key.
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.async = true
		script.src = [
			document.location.protocol === 'https:' ? 'https://' : 'http://',
			segmentProxyEndpoint,
			`/s.${key}.js`,
		].join('')

		// Insert our script next to the first script element.
		const first = document.getElementsByTagName('script')[0]
		if (!first) {
			return
		}

		first?.parentNode?.insertBefore(script, first)
		analytics._loadOptions = options
	}

	// Add a version to keep track of what's in the wild.
	analytics.SNIPPET_VERSION = '4.1.0'

	// Load Analytics.js with your key, which will automatically
	// load the tools you've enabled for your account. Boosh!
	analytics.load(writeKey)
}

function contextFromSystemInfo(systemInfo: Report, instanceId: string) {
	return {
		ip: systemInfo.ip,
		app_name: 'web',
		locale: Array.isArray(systemInfo.lang)
			? systemInfo.lang[0]
			: systemInfo.lang,
		os: systemInfo.os,
		page: {
			hash: document.location.hash,
			path: document.location.pathname,
			referrer: document.referrer,
			search: document.location.search,
			title: document.title,
			url: document.location.href,
		},
		screen: {
			width: (systemInfo.screen || {}).width,
			height: (systemInfo.screen || {}).height,
			density: (systemInfo.screen || {}).dppx,
		},
		userAgent: systemInfo.userAgent,
		instance_id: instanceId,
	}
}

export class SegmentClient {
	static instance: SegmentClient

	instanceId: string = ''
	identifiedUserId: string | null = null

	constructor(
		config: { statsEndpoint: string; segmentWriteKey: string },
		instanceId: string
	) {
		if (SegmentClient.instance) {
			return SegmentClient.instance
		}

		const { statsEndpoint, segmentWriteKey } = config

		installSegment(statsEndpoint, segmentWriteKey)

		this.instanceId = instanceId
		this.identifiedUserId = null

		SegmentClient.instance = this

		return SegmentClient.instance
	}

	static getContext() {
		return contextFromSystemInfo(
			browserReportSync() || {},
			SegmentClient.instance.instanceId
		)
	}

	static identify(userId: string, traits: Object) {
		if (!SegmentClient.instance) {
			return
		}
		// Block unnecesary calls to identify
		if (!traits && SegmentClient.instance.identifiedUserId === userId) {
			return
		}
		SegmentClient.instance.identifiedUserId = userId
		window.analytics.identify(userId, traits)
	}

	static track(
		event: string,
		properties?: Object,
		options: SegmentAnalytics.SegmentOpts = {}
	) {
		if (!SegmentClient.instance) {
			return
		}
		window.analytics.track(event, properties, {
			...options,
			context: SegmentClient.getContext(),
		})
	}

	static page(
		name: string,
		properties?: Object,
		options: SegmentAnalytics.SegmentOpts = {}
	) {
		if (!SegmentClient.instance) {
			return
		}
		window.analytics.page(name, properties, {
			...options,
			context: SegmentClient.getContext(),
		})
	}

	static reset() {
		if (!SegmentClient.instance) {
			return
		}
		window.analytics.reset()
	}
}

export default SegmentClient
