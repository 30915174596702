import { FunctionComponent } from 'react'
import { Route, RouteProps } from 'react-router'

import useConfig from '../../hooks/useConfig'

import Button, {
	ButtonVariant,
	ButtonShape,
	ButtonSize,
} from '../../components/AKButton'
import AppLoadingScreen from '../../components/AppLoadingScreen'

import { Container, ErrorText } from './styled-components'

function defaultRedirectTo(url: string) {
	window.location.href = url
}

export interface OwnProps {
	auth: {
		authenticating: boolean
		authenticated: boolean
		authenticationFailed: boolean
		waitlistStatusLoaded: boolean
		waitlistStatusFailed: boolean
		isOnHardWaitlist: boolean
	}
	onRetryClick(): void
	redirectTo?(url: string): void
}

type Props = OwnProps & RouteProps

const ProtectedRoute: FunctionComponent<Props> = props => {
	const config = useConfig()
	if (!config) {
		return null
	}

	const {
		auth,
		onRetryClick,
		redirectTo = defaultRedirectTo,
		...restProps
	} = props

	if (auth.authenticating) {
		return <AppLoadingScreen message="Authenticating you..." />
	}

	// TODO: Most of the time this will happen because the user is unauthenticated
	// Theoretically, however the auth check could have failed due to network errors
	// In that case, it would be better to give them an option to retry the request
	if (auth.authenticationFailed) {
		redirectTo(config.staticEndpoint)
		return <AppLoadingScreen message="Authenticating you..." />
	}

	if (auth.waitlistStatusFailed) {
		return (
			<Container>
				<ErrorText>Failed to authenticate you</ErrorText>
				<Button
					shape={ButtonShape.Default}
					size={ButtonSize.Default}
					variant={ButtonVariant.Primary}
					onClick={onRetryClick}
				>
					Try again
				</Button>
			</Container>
		)
	}

	if (!auth.waitlistStatusLoaded) {
		return <AppLoadingScreen message="Authenticating you..." />
	}

	if (auth.waitlistStatusLoaded && auth.isOnHardWaitlist) {
		redirectTo(config.staticEndpoint)
		return <AppLoadingScreen message="Authenticating you..." />
	}

	return <Route {...restProps} />
}

export default ProtectedRoute
