import { getContext, takeEvery } from 'typed-redux-saga/macro'

import { OwnAttendantFragment } from '../network/graphql'
import { OWN_ATTENDANT_FRAGMENT } from '../network/graphql/fragments'

import { GQL_CLIENT_SAGA_CONTEXT_KEY } from '../constants/config'
import { actions } from '../slices/firestoreAttendants'

type AttendantUpdatedAction = ReturnType<typeof actions.attendantUpdated>

function* persistInApolloCache(action: AttendantUpdatedAction) {
	const gqlClient = yield* getContext(GQL_CLIENT_SAGA_CONTEXT_KEY)
	if (!gqlClient) {
		throw new Error('No gqlClient in saga context')
	}

	const attendant = action.payload

	const attendantFragment = gqlClient.readFragment<OwnAttendantFragment>({
		fragment: OWN_ATTENDANT_FRAGMENT,
		id: gqlClient.cache.identify({
			__typename: 'Attendant',
			id: attendant.id,
			conversation_id: attendant.conversationId,
		}),
	})

	if (attendantFragment) {
		gqlClient.writeFragment<OwnAttendantFragment>({
			fragment: OWN_ATTENDANT_FRAGMENT,
			data: {
				...attendantFragment,
				display_name: attendant.displayName,
				display_color: attendant.displayColor,
				display_image_filename: attendant.displayImageFilename,
				muted: attendant.mutedConversation,
				follows_conversation: attendant.followsConversation,
				outstanding_important_messages: attendant.outstandingImportantMessages,
			},
		})
	}
}

export default function* observeAttendantUpdates() {
	yield* takeEvery(actions.attendantUpdated.match, persistInApolloCache)
}
