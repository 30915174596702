import { constructUrl } from '../../utils/constructUrl'
import { AuthClient } from '../auth/authClient'

import { FetchManager } from './fetchManager'
import {
	CloudinaryUploadOptions,
	FetchResult,
	UserConfigurationResponsePayload,
} from './types'

export enum ApiVersion {
	V1,
	V2,
	Graphql,
}

export class ApiClient {
	constructor(
		private fetchManager: FetchManager,
		private authClient: AuthClient,
		private apiRoot: string
	) {}

	private getBaseUrlForApiVersion(version: ApiVersion): string {
		switch (version) {
			case ApiVersion.V1:
				return `${this.apiRoot}/api`
			case ApiVersion.V2:
				return `${this.apiRoot}/v2`
			case ApiVersion.Graphql:
				return `${this.apiRoot}/graphql`
		}
	}

	// TODO: get accessToken from authClient
	public getUserConfiguration(): Promise<
		FetchResult<UserConfigurationResponsePayload>
	> {
		const API_CONFIGURATION_FIELDS: (keyof UserConfigurationResponsePayload)[] =
			[
				'person',
				'intercom_agent_id_hash',
				'gkGates',
				'ip',
				'clientConfig',
				'firebaseToken',
				'firebaseChatWebConfig',
				'sphereFirebaseToken',
				'firebaseSphereWebConfig',
				'giphyApiKey',
				'launchDarklyToken',
			]

		const url = constructUrl(this.getBaseUrlForApiVersion(ApiVersion.V1), '', {
			fields: API_CONFIGURATION_FIELDS,
		})

		return this.fetchManager.get(url, {
			headers: this.authClient.getAuthorizationHeader(),
		}) as Promise<FetchResult<UserConfigurationResponsePayload>>
	}

	public getFileSignature(
		options?: any
	): Promise<FetchResult<CloudinaryUploadOptions>> {
		const url = constructUrl(
			this.getBaseUrlForApiVersion(ApiVersion.V1),
			'/imageSignatures'
		)

		return this.fetchManager.post(url, options, {
			headers: this.authClient.getAuthorizationHeader(),
		}) as Promise<FetchResult<CloudinaryUploadOptions>>
	}

	public createPasswordResetToken(email: string): Promise<FetchResult> {
		return this.fetchManager.post(
			constructUrl(this.apiRoot, '/loginPasswordRetrieveTokens'),
			{ email },
			AuthClient.withCookiesRequestInit
		)
	}
}
