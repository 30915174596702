import { FC } from 'react'

import Icon, { IconProps } from './Icon'

const ImageUploadChange: FC<IconProps> = props => (
	<Icon {...props}>
		<g fill="none">
			<path
				d="M13.9375 22.1875H24.6375C24.8032 22.1875 24.9375 22.0532 24.9375 21.8875V9.425C24.9375 9.25931 24.8032 9.125 24.6375 9.125H8.7375C8.57181 9.125 8.4375 9.25931 8.4375 9.425V16.6875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5625 14.9688C13.5117 14.9688 14.2813 14.1992 14.2813 13.25C14.2813 12.3008 13.5117 11.5312 12.5625 11.5312C11.6133 11.5312 10.8438 12.3008 10.8438 13.25C10.8438 14.1992 11.6133 14.9688 12.5625 14.9688Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.9654 20.2094L18.0252 14.1496C18.1423 14.0325 18.3323 14.0325 18.4494 14.1496L24.9283 20.6285"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.9217 16.7718L22.5744 14.4246C22.4573 14.3075 22.2673 14.3075 22.1502 14.4246L20.4897 16.0851"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.71726 16.0327L14.9589 23.2744L15.933 26.193L13.0161 25.2207L5.77272 17.9773L7.71726 16.0327Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M7.40699 19.4663L9.28376 17.5896" />
			<path d="M12.9031 25.2077L14.7997 23.311" />
		</g>
	</Icon>
)

export default ImageUploadChange
