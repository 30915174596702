import * as LDClient from 'launchdarkly-js-client-sdk'
import { xor } from 'lodash'
import { eventChannel } from 'redux-saga'
import {
	apply,
	call,
	cancelled,
	fork,
	put,
	race,
	select,
	take,
	takeEvery,
} from 'typed-redux-saga/macro'

import { Person } from '../network/api'
import api from '../slices/api'
import auth from '../slices/auth'
import { actions as ldActions } from '../slices/launchDarkly'
import sphereSidebar from '../slices/sphereSidebar'
import { toSafeError } from '../utils/error'

const LAUNCH_DARKLY_BASE_OPTIONS: LDClient.LDOptions = {
	// The client will store the latest flag settings in local storage.
	// On page load, the previous settings will be used and the 'ready' event
	// will be emitted immediately.
	// https://docs.launchdarkly.com/sdk/client-side/javascript#bootstrapping
	bootstrap: 'localStorage',
	allAttributesPrivate: true,
}

type LDEventType =
	| { type: 'initialized' }
	| {
			type: 'failed'
			error: Error
	  }
	| {
			type: 'error'
			error: Error
	  }
	| {
			type: 'ready'
			payload: LDClient.LDFlagSet
	  }
	| {
			type: 'change'
			payload: LDClient.LDFlagChangeset
	  }

function createLDChannel(ldClient: LDClient.LDClient) {
	return eventChannel<LDEventType>(emit => {
		const initializedHandler = () => {
			emit({ type: 'initialized' })
		}

		const failureHandler = (error: Error) => {
			emit({ type: 'failed', error })
		}

		const errorHandler = (error: Error) => {
			emit({ type: 'error', error })
		}

		const changeHandler = (payload: LDClient.LDFlagChangeset) => {
			emit({
				type: 'change',
				payload,
			})
		}

		const readyHandler = () => {
			emit({
				type: 'ready',
				payload: ldClient.allFlags(),
			})
		}

		ldClient.on('initialized', initializedHandler)
		ldClient.on('failed', failureHandler)
		ldClient.on('ready', readyHandler)
		ldClient.on('change', changeHandler)
		ldClient.on('error', errorHandler)

		return () => {
			ldClient.off('initialized', initializedHandler)
			ldClient.off('failed', failureHandler)
			ldClient.off('ready', readyHandler)
			ldClient.off('change', changeHandler)
			ldClient.off('error', errorHandler)
		}
	})
}

function getLDUserAttributes(
	person: Person,
	ip: string,
	isStaffAdmin: boolean
): LDClient.LDUser {
	const {
		agent_id: agentId,
		first_name: firstName,
		last_name: lastName,
		email,
	} = person
	return {
		key: agentId,
		ip,
		firstName: firstName ?? undefined,
		lastName: lastName ?? undefined,
		email: email ?? undefined,
		custom: {
			isGod: isStaffAdmin,
			platform: 'web',
		},
	}
}

function getLDOptions(userHash: string): LDClient.LDOptions {
	return {
		...LAUNCH_DARKLY_BASE_OPTIONS,
		hash: userHash,
	}
}

function* closeLaunchDarklyConnection(ldClient: LDClient.LDClient) {
	try {
		yield* put(ldActions.closeRequest())
		yield* apply(ldClient, ldClient.close, [undefined])
		yield* put(ldActions.closeSuccess())
	} catch (unknownError) {
		const err = toSafeError(unknownError)
		yield* put(
			ldActions.closeFailure(
				typeof err?.message === 'string'
					? err.message
					: 'Failed to close connection'
			)
		)
	}
}

function* updateUserAttributes(ldClient: LDClient.LDClient) {
	yield* takeEvery(
		sphereSidebar.actions.spheresFinishedLoading.match,
		function* (spheresFinishedLoadingAction) {
			const sphereIdsUserIsMemberOf = spheresFinishedLoadingAction.payload

			const currentUser = yield* apply(ldClient, ldClient.getUser, [])
			const currentSphereMembership = currentUser.custom?.sphereMembership ?? []

			// Update only if sphere membership changed
			if (
				Array.isArray(currentSphereMembership) &&
				xor(currentSphereMembership, sphereIdsUserIsMemberOf).length === 0
			) {
				return
			}

			yield* apply(ldClient, ldClient.identify, [
				{
					...currentUser,
					custom: {
						...currentUser.custom,
						sphereMembership: sphereIdsUserIsMemberOf,
					},
				},
			])
		}
	)

	yield* takeEvery(
		sphereSidebar.actions.switchedToSphere.match,
		function* (switchedToSphereAction) {
			const selectedSphereId = switchedToSphereAction.payload.sphereId ?? ''

			const currentUser = yield* apply(ldClient, ldClient.getUser, [])
			const currSelectedSphereId = currentUser.custom?.selectedSphereId
			if (currSelectedSphereId === selectedSphereId) {
				return
			}

			yield* apply(ldClient, ldClient.identify, [
				{
					...currentUser,
					custom: {
						...currentUser.custom,
						selectedSphereId,
					},
				},
			])
		}
	)
}

function* initializeLaunchDarkly(
	userHash: string,
	person: Person,
	ip: string,
	isStaffAdmin: boolean
) {
	let ldClient: LDClient.LDClient | null = null
	try {
		const launchDarklyClientId = yield* select(
			state => state.config.launchDarklyClientId
		)

		const ldUser = yield* call(getLDUserAttributes, person, ip, isStaffAdmin)

		const ldOptions = yield* call(getLDOptions, userHash)

		yield* put(ldActions.initializeRequest())
		ldClient = yield* call(
			LDClient.initialize,
			launchDarklyClientId,
			ldUser,
			ldOptions
		)

		const ldChannel = yield* call(createLDChannel, ldClient!)

		yield* fork(updateUserAttributes, ldClient!)

		while (true) {
			const payload = yield* take(ldChannel)
			yield* call(handleLdChannelEvents, payload)
		}
	} finally {
		if (yield* cancelled()) {
			if (ldClient) {
				yield* fork(closeLaunchDarklyConnection, ldClient)
			}
		}
	}
}

function* handleLdChannelEvents(event: LDEventType) {
	switch (event.type) {
		case 'initialized':
			yield* put(ldActions.initializeSuccess())
			return
		case 'failed':
			yield* put(ldActions.initializeFailure(event.error.message))
			return
		case 'error':
			yield* put(ldActions.error(event.error.message))
			return
		case 'change': {
			const flagsUpdate = Object.keys(event.payload).reduce(
				(acc, nextKey) => ({
					...acc,
					[nextKey]: event.payload[nextKey].current,
				}),
				{}
			)
			yield* put(ldActions.flagUpdated(flagsUpdate))
			return
		}
		case 'ready':
			yield* put(ldActions.flagUpdated(event.payload))
			return
	}
}

export default function* launchDarklyWatcherSaga() {
	while (true) {
		const action = yield* take(api.actions.initSuccess.type)

		if (api.actions.initSuccess.match(action)) {
			const { person, isStaffAdmin, ip, launchDarklyToken } = action.payload
			yield* race({
				task: call(
					initializeLaunchDarkly,
					launchDarklyToken,
					person,
					ip,
					isStaffAdmin
				),
				signout: take(auth.actions.signOutRequest.type),
			})
		}
	}
}
