import firebase from 'firebase/app'
import 'firebase/firestore'
import { RequireExactlyOne } from 'type-fest'

import { Pagination, UpdateType } from '../pagination'

import { Entity, FetchOptions, OrderByOption } from './types'

export function firestoreTimestampFromDate(date: Date) {
	return firebase.firestore.Timestamp.fromDate(date)
}

const DEFAULT_ORDER_BY_FIELD = 'createdAt'
export function extractOrderByItem(
	option?: OrderByOption
): readonly [string, 'asc' | 'desc'] {
	if (option === undefined) {
		return [DEFAULT_ORDER_BY_FIELD, 'asc']
	} else if (option.length === 1) {
		// Firestore sorts ascending by default
		// https://cloud.google.com/firestore/docs/query-data/order-limit-data#order_and_limit_data
		// this is making this more explicit but doesn't change behaviour
		return [option[0], 'asc']
	} else {
		return option
	}
}

// Determines whether to prepend or append the results to an ascending list
export function getUpdateTypeFromFetchOptions(
	options: FetchOptions
): UpdateType | undefined {
	if (options.orderBy && options.orderBy.length > 1) {
		throw new Error(
			'getUpdateTypeFromFetchOptions is currently only supported for one orderBy entry'
		)
	}
	const [, order] = extractOrderByItem(options.orderBy?.[0])

	const hasEndOption =
		options.endBefore !== undefined || options.endAt !== undefined
	const hasStartOption =
		options.startAfter !== undefined || options.startAt !== undefined

	const isRange = hasEndOption && hasStartOption
	if (isRange) {
		// With cursor pagination it should be impossible to know both the start and
		// end cursors of the next/previous page.
		// This could happen when re-fetching a page of data you already have,
		// or in an attempt to fill a gap between two pages
		// Either way, the "UpdateType" is meaningless in this case
		return undefined
	}

	if (order === 'desc') {
		// 10 9 8 7 6 5 4 3 2 1
		//    |existing|—————>
		if (hasStartOption) {
			return UpdateType.Prepend
		}

		// 10 9 8 7 6 5 4 3 2 1
		//     —————>|existing|
		if (hasEndOption) {
			return UpdateType.Append
		}

		// First page
		// 10 9 8 7 6 5 4 3 2 1
		// |—————>
		if (!hasStartOption && !hasEndOption) {
			return UpdateType.Prepend
		}
	}

	const isAscendingOrder = order === 'asc'
	if (isAscendingOrder) {
		// 1 2 3 4 5 6 7 8 9 10
		//   |existing|—————>
		if (hasStartOption) {
			return UpdateType.Append
		}
		// 1 2 3 4 5 6 7 8 9 10
		//    —————>|existing|
		if (hasEndOption) {
			return UpdateType.Prepend
		}

		// First page
		// 1 2 3 4 5 6 7 8 9 10
		// |—————>
		if (!hasStartOption && !hasEndOption) {
			return UpdateType.Append
		}
	}
}

export function makePaginationObject<
	T extends { id: string; createdAt: string }
>({
	entities,
	options,
	size,
}: {
	entities: T[]
	options: FetchOptions
	size: number
}): Pagination {
	if (options.orderBy && options.orderBy.length > 1) {
		throw new Error(
			'makePaginationObject is currently only supported for one orderBy entry'
		)
	}
	const [, order] = extractOrderByItem(options.orderBy?.[0])

	const oldestEntity =
		order === 'asc' ? entities[0] : entities[entities.length - 1]
	const newestEntity =
		order === 'asc' ? entities[entities.length - 1] : entities[0]

	const entitiesInAscendingOrder =
		order === 'desc' ? [...entities].reverse() : entities

	const limitUsed = options.limitToLast ?? options.limit

	return {
		ascendingIds: entitiesInAscendingOrder.map(entity => entity.id),
		before: size ? oldestEntity.createdAt : null,
		after: size ? newestEntity.createdAt : null,
		fetchedAll: size === 0 || !limitUsed || size < limitUsed,
		order: order,
		updateType: getUpdateTypeFromFetchOptions(options),
	}
}

export function parametriseCollectionReference<
	FSCollectionType = firebase.firestore.DocumentData
>(
	collectionReference: firebase.firestore.CollectionReference<FSCollectionType>,
	options: FetchOptions
): firebase.firestore.Query<FSCollectionType> {
	const {
		where,
		startAt,
		startAfter,
		endAt,
		endBefore,
		limit,
		limitToLast,
		orderBy = [],
	} = options
	let parametrisedCollectionReference: firebase.firestore.Query<FSCollectionType> =
		collectionReference

	for (const orderByItem of orderBy) {
		parametrisedCollectionReference = parametrisedCollectionReference.orderBy(
			orderByItem[0],
			orderByItem[1]
		)
	}

	if (limitToLast) {
		parametrisedCollectionReference =
			parametrisedCollectionReference.limitToLast(limitToLast)
	} else if (limit) {
		parametrisedCollectionReference =
			parametrisedCollectionReference.limit(limit)
	}

	if (startAt) {
		const startAtTimestamp = firestoreTimestampFromDate(new Date(startAt))
		parametrisedCollectionReference =
			parametrisedCollectionReference.startAt(startAtTimestamp)
	}

	if (startAfter) {
		const startAfterTimestamp = firestoreTimestampFromDate(new Date(startAfter))
		parametrisedCollectionReference =
			parametrisedCollectionReference.startAfter(startAfterTimestamp)
	}

	if (endAt) {
		const endAtTimestamp = firestoreTimestampFromDate(new Date(endAt))
		parametrisedCollectionReference =
			parametrisedCollectionReference.endAt(endAtTimestamp)
	}

	if (endBefore) {
		const endBeforeTimestamp = firestoreTimestampFromDate(new Date(endBefore))
		parametrisedCollectionReference =
			parametrisedCollectionReference.endBefore(endBeforeTimestamp)
	}

	if (where) {
		for (const whereItem of where) {
			parametrisedCollectionReference = parametrisedCollectionReference.where(
				...whereItem
			)
		}
	}

	return parametrisedCollectionReference
}

export function convertFirestoreDocumentToFSEntity<T extends Entity>(
	messageDocument: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
) {
	return {
		id: messageDocument.id,
		...messageDocument.data(),
		createdAt: (
			messageDocument.data()?.createdAt as firebase.firestore.Timestamp
		)
			?.toDate()
			.toISOString(),
	} as any as T
}

type LimitOptions = Pick<FetchOptions, 'limit' | 'limitToLast'>
/**
 * Makes sure that the given limit is in the range [1, maxLimit] (inclusive)
 *
 * Ensures that exactly one of 'limit' & 'limitToLast' is defined
 *
 * If both limit types are defined, prefers limitToLast over limit
 * If both types are undefined, prefers limit
 */
export function getSafeLimit(
	{ limit, limitToLast }: LimitOptions,
	maxLimit: number
): RequireExactlyOne<LimitOptions> {
	function getSafeValueForLimit(value: number): number {
		return Math.max(Math.min(value, maxLimit), 1)
	}

	if (limit && limitToLast) {
		return {
			limit: undefined,
			limitToLast: getSafeValueForLimit(limitToLast),
		}
	}

	if (!limit && limitToLast) {
		return {
			limit: undefined,
			limitToLast: getSafeValueForLimit(limitToLast),
		}
	}

	if (limit && !limitToLast) {
		return {
			limit: getSafeValueForLimit(limit),
			limitToLast: undefined,
		}
	}

	return {
		limit: maxLimit,
		limitToLast: undefined,
	}
}
