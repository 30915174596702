import emojiRegex from 'emoji-regex'
import GraphemeSplitter from 'grapheme-splitter'

export const isEmojiOnly = (text: string) =>
	text.length > 0 &&
	text.replace(emojiRegex(), '').replace(/\s/g, '').length === 0

const splitter = new GraphemeSplitter()

export const emojiSafeStringLength = (string: string = ''): number => {
	const emojiMatches = [...string.matchAll(emojiRegex())]
	const emojiCount = emojiMatches.length
	const stringWithoutEmojis = string.replace(emojiRegex(), '')

	return splitter.countGraphemes(stringWithoutEmojis) + emojiCount
}

export const emojiSafeSubstring = (
	string: string = '',
	startAt: number = 0,
	endAt?: number
): string => {
	const emojiMatches = [...string.matchAll(emojiRegex())]

	const stringSplitByEmojis = string.split(emojiRegex())

	let nextEmojiIndex = 0
	const charArray = stringSplitByEmojis
		.map(substring => {
			if (emojiMatches[nextEmojiIndex]) {
				return [
					...splitter.splitGraphemes(substring),
					emojiMatches[nextEmojiIndex++][0],
				]
			} else {
				return splitter.splitGraphemes(substring)
			}
		})
		.reduce(
			(accCharArray, nextCharArray) => [...accCharArray, ...nextCharArray],
			[]
		)

	// Match String.substring behaviour
	let sliceAtStart = startAt
	let sliceAtEnd = endAt
	if (endAt && startAt > endAt) {
		sliceAtStart = endAt
		sliceAtEnd = startAt
	}

	return charArray
		.slice(
			Math.min(Math.max(sliceAtStart, 0), charArray.length),
			sliceAtEnd
				? Math.min(Math.max(sliceAtEnd, 0), charArray.length)
				: undefined
		)
		.join('')
}
