import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getExpiresAtMsFromJWT, getPropertyFromJWT } from '../utils/jwtHelper'
import { createRequestAdapter, RequestAdapterState } from '../utils/slices'

export interface AuthState {
	accessToken?: string | null
	accessTokenExpiresAt?: number
	agentId: string | null
	refreshLoading: boolean
	refreshError: string | null
	signIn: RequestAdapterState
	signOut: RequestAdapterState
	loginPasswordRetrieveTokenCreate: RequestAdapterState
}

const signInRequestAdapter = createRequestAdapter({ name: 'signIn' })
const signOutRequestAdapter = createRequestAdapter({ name: 'signOut' })
const loginPasswordRetrieveTokenCreateRequestAdapter = createRequestAdapter({
	name: 'loginPasswordRetrieveTokenCreate',
})

const intitialAuthState: AuthState = {
	agentId: null,
	refreshLoading: false,
	refreshError: null,
	signIn: signInRequestAdapter.getInitialState(),
	signOut: signOutRequestAdapter.getInitialState(),
	loginPasswordRetrieveTokenCreate: signOutRequestAdapter.getInitialState(),
}

export const auth = createSlice({
	name: 'auth',
	initialState: intitialAuthState,
	reducers: {
		authTokenRefreshRequest(state) {
			state.refreshLoading = true
			state.refreshError = null
		},
		authTokenRefreshSuccess(
			state,
			action: PayloadAction<{ accessToken: string }>
		) {
			state.refreshLoading = false

			if (!action.payload.accessToken) {
				state.accessToken = null
				state.accessTokenExpiresAt = undefined
				state.agentId = null
				return
			}

			state.accessToken = action.payload.accessToken

			state.accessTokenExpiresAt =
				getExpiresAtMsFromJWT(action.payload.accessToken) ?? undefined

			const newAgentId = getPropertyFromJWT(
				action.payload.accessToken,
				'agent_id'
			)

			const failedToExtractAgentId = Boolean(
				action.payload.accessToken && typeof newAgentId !== 'string'
			)
			if (failedToExtractAgentId) {
				state.agentId = null
				throw new Error('Could not extract agentId from accessToken')
			}

			if (typeof newAgentId === 'string') {
				state.agentId = newAgentId
			}
		},
		authTokenRefreshFailure(state, action: PayloadAction<{ error: string }>) {
			state.refreshLoading = false
			state.refreshError = action.payload.error

			state.accessToken = null
			state.accessTokenExpiresAt = undefined
			state.agentId = null
		},
		signInRequest: signInRequestAdapter.request,
		signInFailure: {
			reducer(
				state,
				action: PayloadAction<{ status?: number }, string, never, string>
			) {
				signInRequestAdapter.failure(state, action)
			},
			prepare({ error, status }: { error: string; status?: number }) {
				return { payload: { status }, error }
			},
		},
		signInSuccess(state, action: PayloadAction<{ response: any }>) {
			signInRequestAdapter.success(state, action)
		},
		signOutRequest: signOutRequestAdapter.request,
		signOutFailure: signOutRequestAdapter.failure,
		signOutSuccess(state, action: PayloadAction) {
			// the request adapter succes can't be used here, as an immer producer needs to either modify
			// the draft or return a result, but not both
			// signOutRequestAdapter.success(state, action)
			return intitialAuthState
		},
		loginPasswordRetrieveTokenCreateRequest:
			loginPasswordRetrieveTokenCreateRequestAdapter.request,
		loginPasswordRetrieveTokenCreateFailure: {
			reducer(
				state,
				action: PayloadAction<{ status?: number }, string, never, string>
			) {
				loginPasswordRetrieveTokenCreateRequestAdapter.failure(state, action)
			},
			prepare({ error, status }: { error: string; status?: number }) {
				return { payload: { status }, error }
			},
		},
		loginPasswordRetrieveTokenCreateSuccess(
			state,
			action: PayloadAction<{ response: any }>
		) {
			loginPasswordRetrieveTokenCreateRequestAdapter.success(state, action)
		},
	},
})

export default auth
