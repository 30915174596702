import { compact } from 'lodash'

import { BUNDLE_UPDATE_INTERVAL } from '../constants/config'
import bundle from '../slices/bundle'
import { ApplicationThunk } from '../store'

const extractBundleVersionFromDoc = (doc: Document) =>
	compact(
		[].slice
			.call(doc.getElementsByTagName('script'))
			.map((script: HTMLScriptElement) => script.outerHTML)
			.map(fileName => fileName.match(/.*\/main\.(.*)\.chunk\.js/))
	)
		.map(match => match[1])
		.find(bundleVersion => !!bundleVersion)

// Checks if the response status is a succesful one. If so
// it returns the response, otherwise it throws an error
const checkStatus = (response: Response) => {
	if (response.status >= 200 && response.status < 300) {
		return response
	}
	const error = new Error(response.statusText)
	throw error
}

const parseText = (response: Response) => response.text()

const parseHtml = (htmlString: string) => {
	const parser = new DOMParser()
	return parser.parseFromString(htmlString, 'text/html')
}

const fetchBundleVersion = () =>
	fetch('/index.html')
		.then(checkStatus)
		.then(parseText)
		.then(parseHtml)
		.then(extractBundleVersionFromDoc)

export function initBundle(): ApplicationThunk {
	return dispatch => {
		const version = extractBundleVersionFromDoc(window.document)

		if (version) {
			dispatch(bundle.actions.bundleVersionInit({ version }))
		}

		window.setInterval(() => {
			dispatch(bundle.actions.bundleVersionRequest())

			fetchBundleVersion().then(
				newVersion => {
					if (newVersion) {
						dispatch(
							bundle.actions.bundleVersionSuccess({
								version: newVersion,
							})
						)
					} else {
						dispatch(
							bundle.actions.bundleVersionFailure({
								error: "Couldn't extract version from document.",
							})
						)
					}
				},
				(error: Error) =>
					dispatch(
						bundle.actions.bundleVersionFailure({
							error: error.message,
						})
					)
			)
		}, BUNDLE_UPDATE_INTERVAL)
	}
}

export default initBundle
