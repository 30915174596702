import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import message from './message'

import { StoreState } from '.'

export enum AnnouncementPersonalResponseState {
	HasToIgnore,
	CanAcknowledge,
	HasAcknowledged,
	HasDismissed,
}

export type AnnouncementPersonalReponseStateCached =
	| AnnouncementPersonalResponseState.CanAcknowledge
	| AnnouncementPersonalResponseState.HasAcknowledged
	| AnnouncementPersonalResponseState.HasDismissed

export type AnnouncementPersonalResponse = {
	messageId: string
	response: AnnouncementPersonalReponseStateCached
}

export const responseAdapter =
	createEntityAdapter<AnnouncementPersonalResponse>({
		selectId: response => response.messageId,
	})

export type AnnouncementPersonalResponsesCacheState = ReturnType<
	typeof responseAdapter.getInitialState
>

export const announcementPersonalResponsesCache = createSlice({
	name: 'announcementPersonalResponsesCache',
	initialState: responseAdapter.getInitialState(),
	reducers: {
		updateResponse: responseAdapter.upsertOne,
	},
	extraReducers: builder =>
		builder.addCase(
			message.actions.conversationMessageSuccess,
			(state, action) => {
				responseAdapter.removeOne(state, action.payload.messageId)
			}
		),
})

export default announcementPersonalResponsesCache

const announcementPersonalResponsesCacheSelectors =
	responseAdapter.getSelectors<StoreState>(
		state => state.announcementPersonalResponsesCache
	)

export { announcementPersonalResponsesCacheSelectors }
