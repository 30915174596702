import {
	forwardRef,
	MouseEventHandler,
	PropsWithChildren,
	ReactNode,
} from 'react'

import {
	EndEnhancerContainer,
	IconContainer,
	StyledListItem,
	StyledListItemContent,
} from './styled-components'

export type ListItemT = {
	as?: keyof JSX.IntrinsicElements
	icon?: ReactNode
	noSeparator?: boolean
	endEnhancer?: ReactNode
	className?: string
	'data-testid'?: string
	onClick?: MouseEventHandler<HTMLElement>
}

export const ListItem = forwardRef<HTMLLIElement, PropsWithChildren<ListItemT>>(
	(
		{
			as,
			icon,
			noSeparator,
			endEnhancer,
			children,
			className,
			'data-testid': dataTestId,
			onClick,
		},
		ref
	) => (
		<StyledListItem
			as={as}
			className={className}
			ref={ref}
			data-testid={dataTestId}
			onClick={onClick}
		>
			{icon && <IconContainer>{icon}</IconContainer>}
			<StyledListItemContent
				$withMarginLeft={!!icon}
				$withBorder={!noSeparator}
			>
				{children}
				{endEnhancer && (
					<EndEnhancerContainer>{endEnhancer}</EndEnhancerContainer>
				)}
			</StyledListItemContent>
		</StyledListItem>
	)
)

export default ListItem
