import { noop } from 'lodash'

import { FetchOptions } from '../network/firestore'
import {
	fetchMessages,
	fetchMessage,
	observeLatestConversationMessages,
} from '../network/firestore/messages'
import conversationMessagesPagination from '../slices/conversationMessagesPagination'
import { message } from '../slices/message'
import messagesByConversation from '../slices/messagesByConversation'
import { ApplicationThunk } from '../store'
import { toSafeError } from '../utils/error'
import { purifyTimestamps } from '../utils/firebase'

export function subscribeToLatestConversationMessages(
	conversationId: string
): ApplicationThunk<() => void> {
	return (dispatch, getState) => {
		dispatch(
			messagesByConversation.actions.conversationMessagesUpdatesSubscribe({
				conversationId,
			})
		)
		const { firebase: firebaseState } = getState()

		if (!firebaseState.appName) {
			dispatch(
				messagesByConversation.actions.conversationMessagesUpdateError({
					error: 'Firebase not initialized',
					conversationId,
				})
			)
			return noop
		}

		try {
			const unobserveLatestConversationMessages =
				observeLatestConversationMessages(conversationId, {
					next: ({ data }) => {
						dispatch(
							messagesByConversation.actions.conversationMessagesUpdate({
								conversationId,
								messages: data.map(purifyTimestamps),
							})
						)
					},
					error: error => {
						dispatch(
							messagesByConversation.actions.conversationMessagesUpdateError({
								conversationId,
								error: error.message,
							})
						)
					},
				})

			return () => {
				dispatch(
					messagesByConversation.actions.conversationMessagesUpdatesUnsubscribe(
						{
							conversationId,
						}
					)
				)
				unobserveLatestConversationMessages()
			}
		} catch (unknownError) {
			const error = toSafeError(unknownError)
			dispatch(
				messagesByConversation.actions.conversationMessagesUpdateError({
					error: error.message ?? 'Failed to observe conversation messages',
					conversationId,
				})
			)
			return noop
		}
	}
}

export enum ConversationMessagesContext {
	Conversation = 'Conversation',
	ImportantInbox = 'ImportantInbox',
	OutstandingImportantInbox = 'OutstandingImportantInbox',
}

export function fetchConversationMessages(
	conversationId: string,
	context: ConversationMessagesContext,
	options: FetchOptions,
	resetPagination?: boolean
): ApplicationThunk {
	return async (dispatch, getState) => {
		dispatch(
			conversationMessagesPagination.actions.conversationMessagesRequest({
				conversationId,
				resetPagination,
				context,
			})
		)
		const { firebase: firebaseState } = getState()

		if (!firebaseState.appName) {
			dispatch(
				conversationMessagesPagination.actions.conversationMessagesFailure({
					error: 'Firebase not initialized',
					conversationId,
					context,
				})
			)
			return
		}

		try {
			const { pagination, data } = await fetchMessages(conversationId, options)

			dispatch(
				conversationMessagesPagination.actions.conversationMessagesSuccess({
					conversationId,
					messages: data.map(purifyTimestamps),
					pagination,
					context,
				})
			)
		} catch (unknownError) {
			const error = toSafeError(unknownError)
			dispatch(
				conversationMessagesPagination.actions.conversationMessagesFailure({
					conversationId,
					error: error.message,
					context,
				})
			)
		}
	}
}

export function fetchConversationMessage(
	conversationId: string,
	messageId: string
): ApplicationThunk<Promise<void>> {
	return async dispatch => {
		dispatch(
			message.actions.conversationMessageRequest({
				conversationId,
				messageId,
			})
		)

		try {
			const { data } = await fetchMessage({ messageId, conversationId })
			dispatch(
				message.actions.conversationMessageSuccess({
					conversationId,
					messageId,
					messages: data.map(purifyTimestamps),
				})
			)
		} catch (unknownError) {
			const error = toSafeError(unknownError)
			dispatch(
				message.actions.conversationMessageFailure({
					conversationId,
					messageId,
					error: error.message,
				})
			)
		}
	}
}
