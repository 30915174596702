import { FC } from 'react'
import { useHistory } from 'react-router'

import Page from '../AKPage'
import { PageProps } from '../AKPage/Page'

export type StatefulPageProps = Omit<PageProps, 'onNavigateBack'>

const StatefulPage: FC<PageProps> = ({ children, ...props }) => {
	const history = useHistory()

	return (
		<Page onNavigateBack={history.goBack} {...props}>
			{children}
		</Page>
	)
}

export default StatefulPage
