import styled from 'styled-components'

import { DisplayRegular } from '../../components/AKTypography'

export const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	text-align: center;
`

export const ErrorText = styled(DisplayRegular)`
	margin-bottom: 12px;
`
