import {
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit'

import { Attendant } from '../network/firestore'

import { StoreState } from '.'

interface BaseActionPayload {
	conversationId: string
	attendantId: string
}

interface SubscribeRequestPayload extends BaseActionPayload {}

interface SubscribeSuccessPayload extends BaseActionPayload {}

interface SubscribeFailurePayload extends BaseActionPayload {
	error: string
}

interface SubscriptionErrorPayload extends BaseActionPayload {
	error: string
}

interface UnsubscribedPayload extends BaseActionPayload {}

export function getUniqueId(attendantId: string, conversationId: string) {
	return `${attendantId}_${conversationId}`
}

export function selectAttendantId(attendant: Attendant): string {
	return getUniqueId(attendant.id, attendant.conversationId)
}

const adapter = createEntityAdapter<Attendant>({
	selectId: selectAttendantId,
})

const initialState = adapter.getInitialState()

const firestoreAttendantsSlice = createSlice({
	name: 'firestoreAttendants',
	initialState,
	reducers: {
		subscribeRequest: (_, action: PayloadAction<SubscribeRequestPayload>) => {},
		subscribeSuccess: (_, action: PayloadAction<SubscribeSuccessPayload>) => {},
		subscribeFailure: (_, action: PayloadAction<SubscribeFailurePayload>) => {},

		attendantUpdated: adapter.upsertOne,

		subscriptionError: (
			_,
			action: PayloadAction<SubscriptionErrorPayload>
		) => {},

		unsubscribed: (_, action: PayloadAction<UnsubscribedPayload>) => {},
	},
})

export type FirestoreAttendantState = typeof initialState

export const actions = firestoreAttendantsSlice.actions
export const selectById = adapter.getSelectors<StoreState>(
	state => state.firestoreAttendants
).selectById

export default firestoreAttendantsSlice
