import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react'

export enum ButtonKind {
	Full = 'Full',
	Inverted = 'Inverted',
	Minimal = 'Minimal',
}

export enum ButtonVariant {
	Primary = 'Primary',
	Secondary = 'Secondary',
	SecondaryAlt = 'SecondaryAlt',
	Danger = 'Danger',
	Poll = 'Poll',
	Event = 'Event',
	EventAlt = 'EventAlt',
}

export enum ButtonSize {
	Mini = 'Mini',
	Default = 'Default',
	Large = 'Large',
	Compact = 'Compact',
}

export enum ButtonShape {
	Default = 'Default',
	Pill = 'Pill',
	Round = 'Round',
}

export type ButtonProps = PropsWithChildren<{
	onClick?: MouseEventHandler<HTMLButtonElement>
	kind?: ButtonKind
	variant?: ButtonVariant
	size?: ButtonSize
	shape?: ButtonShape
	disabled?: boolean
	isLoading?: boolean
	type?: 'submit' | 'reset' | 'button'
	title?: string
	className?: string
	startEnhancer?: ReactNode
	endEnhancer?: ReactNode
	fluid?: boolean
	noWrap?: boolean
}>
