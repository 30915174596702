import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { SegmentClient } from '../utils/segmentProxy'

export function usePageTracker() {
	const { pathname, search } = useLocation()

	useEffect(() => {
		SegmentClient.page('', {
			routerProps: {
				pathname,
				search,
			},
		})
	}, [pathname, search])
}

export default usePageTracker
