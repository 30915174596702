import { MouseEventHandler } from 'react'

export enum CheckboxMarkStyle {
	Checkmark,
	Dot,
}

export interface Props {
	onClick?: MouseEventHandler
	checked: boolean
	disabled?: boolean
	circleColor?: string
	markColor?: string
	markStyle?: CheckboxMarkStyle
}
