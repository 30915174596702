import styled from 'styled-components'

const Well = styled.div`
	position: relative;
	width: 100%;
	border-radius: ${({ theme }) => theme.borders.surfaceBorderRadius};
	background: ${({ theme }) => theme.colors.backgroundSecondary};

	color: ${({ theme }) => theme.colors.contentPrimary};

	padding-left: ${({ theme }) => theme.grid.margin}px;
	padding-right: ${({ theme }) => theme.grid.margin}px;
	padding-top: ${({ theme }) => theme.grid.margin}px;
	padding-bottom: ${({ theme }) => theme.grid.margin}px;

	border-width: ${({ theme }) => theme.borders.border100.borderWidth};
	border-color: ${({ theme }) => theme.borders.border100.borderColor};
	border-style: ${({ theme }) => theme.borders.border100.borderStyle};

	${({ theme }) => theme.typography.ParagraphRegular};
`

export const FluidWell = styled(Well)`
	padding-right: 0;
	padding-left: 0;
`

export default Well
