import {
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'

import { FeedItem } from '../domain/ContentFeed'

import auth from './auth'

import { StoreState } from '.'

interface ContentFeedState {
	sphereId: string
	items: FeedItem[]
	isOpen: boolean
	error?: string
	updatedOnce: boolean
}

const contentFeedEntityAdapter = createEntityAdapter<ContentFeedState>({
	selectId(model) {
		return model.sphereId
	},
})

export const contentFeedSelectors =
	contentFeedEntityAdapter.getSelectors<StoreState>(
		state => state.contentFeed.feeds
	)

function getInitialContentFeedState(sphereId: string): ContentFeedState {
	return {
		sphereId,
		isOpen: false,
		items: [],
		error: undefined,
		updatedOnce: false,
	}
}

interface InputState {
	conversationId: string
	text: string
	isSending: boolean
	error?: string
}

function getInitialInputState(conversationId: string): InputState {
	return {
		conversationId,
		text: '',
		isSending: false,
		error: undefined,
	}
}

const inputStateAdapter = createEntityAdapter<InputState>({
	selectId(model) {
		return model.conversationId
	},
})

export const contentFeedInputSelectors =
	inputStateAdapter.getSelectors<StoreState>(state => state.contentFeed.inputs)

export interface ContentFeedsState {
	feeds: ReturnType<typeof contentFeedEntityAdapter.getInitialState>
	inputs: ReturnType<typeof inputStateAdapter.getInitialState>
}

const initialState: ContentFeedsState = {
	feeds: contentFeedEntityAdapter.getInitialState(),
	inputs: inputStateAdapter.getInitialState(),
}

function ensureInitialFeedState(
	state: WritableDraft<ContentFeedsState>,
	action: { payload: { sphereId: string } }
): string {
	const sphereId = action.payload.sphereId
	if (
		!contentFeedEntityAdapter.getSelectors().selectById(state.feeds, sphereId)
	) {
		contentFeedEntityAdapter.addOne(
			state.feeds,
			getInitialContentFeedState(sphereId)
		)
	}
	return sphereId
}

function ensureInitialInputState(
	state: WritableDraft<ContentFeedsState>,
	action: { payload: { conversationId: string } }
): string {
	const { conversationId } = action.payload
	if (
		!inputStateAdapter.getSelectors().selectById(state.inputs, conversationId)
	) {
		inputStateAdapter.addOne(state.inputs, getInitialInputState(conversationId))
	}
	return conversationId
}

export const contentFeed = createSlice({
	name: 'contentFeed',
	initialState,
	reducers: {
		feedOpened(state, action: PayloadAction<{ sphereId: string }>) {
			const sphereId = ensureInitialFeedState(state, action)
			contentFeedEntityAdapter.updateOne(state.feeds, {
				id: sphereId,
				changes: {
					isOpen: true,
				},
			})
		},
		feedClosed(state, action: PayloadAction<{ sphereId: string }>) {
			const sphereId = ensureInitialFeedState(state, action)
			contentFeedEntityAdapter.updateOne(state.feeds, {
				id: sphereId,
				changes: {
					isOpen: false,
				},
			})
		},
		subscriptionUpdate(
			state,
			action: PayloadAction<{ sphereId: string; items: FeedItem[] }>
		) {
			const sphereId = ensureInitialFeedState(state, action)
			contentFeedEntityAdapter.updateOne(state.feeds, {
				id: sphereId,
				changes: {
					items: action.payload.items,
					updatedOnce: true,
					error: undefined,
				},
			})
		},
		subscriptionError: {
			reducer(
				state,
				action: PayloadAction<{ sphereId: string }, string, never, string>
			) {
				const sphereId = ensureInitialFeedState(state, action)
				contentFeedEntityAdapter.updateOne(state.feeds, {
					id: sphereId,
					changes: {
						error: action.error,
					},
				})
			},
			prepare(args: { error: Error; sphereId: string }) {
				return {
					error: args.error.message,
					payload: { sphereId: args.sphereId },
				}
			},
		},
		inputChanged(
			state,
			action: PayloadAction<{
				conversationId: string
				text: string
			}>
		) {
			const conversationId = ensureInitialInputState(state, action)
			inputStateAdapter.updateOne(state.inputs, {
				id: conversationId,
				changes: action.payload,
			})
		},
		// TODO: Make request adapter work on top of an entity adapter and use here
		sendMessageRequest(
			state,
			action: PayloadAction<{
				conversationId: string
			}>
		) {
			const conversationId = ensureInitialInputState(state, action)
			inputStateAdapter.updateOne(state.inputs, {
				id: conversationId,
				changes: {
					isSending: true,
					error: undefined,
				},
			})
		},
		sendMessageSuccess(
			state,
			action: PayloadAction<{
				conversationId: string
			}>
		) {
			const conversationId = ensureInitialInputState(state, action)
			inputStateAdapter.updateOne(state.inputs, {
				id: conversationId,
				changes: {
					isSending: false,
					text: '',
					error: undefined,
				},
			})
		},
		sendMessageFailure: {
			reducer(
				state,
				action: PayloadAction<
					{
						conversationId: string
					},
					string,
					never,
					string
				>
			) {
				const conversationId = ensureInitialInputState(state, action)
				inputStateAdapter.updateOne(state.inputs, {
					id: conversationId,
					changes: {
						isSending: false,
						error: action.error,
					},
				})
			},
			prepare(args: { error: Error; conversationId: string }) {
				return {
					error: args.error.message,
					payload: { conversationId: args.conversationId },
				}
			},
		},
		sendMessageErrorDismissed(
			state,
			action: PayloadAction<{ conversationId: string }>
		) {
			const conversationId = ensureInitialInputState(state, action)
			inputStateAdapter.updateOne(state.inputs, {
				id: conversationId,
				changes: { error: undefined },
			})
		},
	},
	extraReducers(builder) {
		builder.addCase(auth.actions.signOutSuccess, state => ({
			...initialState,
		}))
	},
})

export default contentFeed
