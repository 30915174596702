import { SetRequired } from 'type-fest'

import { SphereEventModel as BaseSphereEventModel } from '../../SphereEvent'

export const RequiredSphereEventFields = [
	'name',
	'displayImageFilename',
	'description',
	'location',
	'link',
	'startDate',
	'endDate',
] as const

export type SphereEventModel = SetRequired<
	BaseSphereEventModel,
	typeof RequiredSphereEventFields[number]
>

export interface SphereEventInput {
	title: string
	imageFilename: string
	startDay: Date
	startTime: Date
	description?: string
	locationName?: string
	link?: string
	endDay?: Date
	endTime?: Date
}
