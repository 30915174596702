import { reduce } from 'lodash'

import { DeepPartial } from '../../types'

export type BaseKeys<T> = readonly (keyof T)[]

export type BaseKeysOrNested<T> =
	| BaseKeys<T>
	| Partial<
			{
				// required primitives are marked with true
				[K in keyof T]: T[K] extends number | string
					? true
					: // keyable entries can either be marked in their specifics recursively or with a true for mere presence, arguably the | true could be removed here for slightly more type safety
					  BaseKeysOrNested<T[K]> | true
			}
	  >

// This function recursively checks the presence of subkeys of a given object
export function validateExistance<T extends object>(
	object: T,
	keys: BaseKeysOrNested<T>
): boolean {
	if (!object) {
		return false
	}
	if (keys instanceof Array) {
		return keys.every(key => object[key] !== undefined)
	}
	for (const key in keys) {
		const value = keys[key]
		if (value === true) {
			if (object[key] === undefined) {
				return false
			}
		} else if (value !== undefined) {
			if (object[key] === null) {
				continue
			}

			if (!validateExistance(object[key] as any, value as any)) {
				return false
			}
		}
	}
	return true
}

// factory for domain typeguards
export function modelGuard<
	BaseModel extends object,
	TargetModel extends BaseModel
>(
	requiredFields: BaseKeysOrNested<BaseModel>
): // reverse check if the basemodel would be assignable to a deep optional target model
// this ensures that the subtypes match and the target model is e.g. not changing a string | null to a string, which does extend
// but wouldn't be guaranteed by the validate existance check
BaseModel extends DeepPartial<TargetModel>
	? (possibleModel: BaseModel) => possibleModel is TargetModel
	: // if this isn't possible return never on type level, the guard will still be a function in js land as the check can't influence that, but it won't be invokable
	  never {
	return function (possibleModel: BaseModel) {
		return validateExistance(possibleModel, requiredFields)
	} as any
}

export default modelGuard

// helper to makes the creation of the required key maps for validateExistance easier
export function spliceRequiredFields<K extends readonly string[]>(
	keys: K
): Record<K[number], true> {
	return reduce(
		keys,
		(acc, cur: K[number]) => {
			acc[cur] = true
			return acc
		},
		{} as Record<K[number], true>
	)
}
