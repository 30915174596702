import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { assignFromAction } from '../utils/slices'

import auth from './auth'

const userSpecificInitialState = {
	initializingPush: false,
	initializingPushError: null,
	pushSubscription: null,
	subscribing: false,
	subscribingError: null,
	unsubscribing: false,
	unsubscribingError: null,
	registering: false,
	registerError: null,
}

const sharedInitialState = {
	initialized: false,
	swSupported: null,
	pushSupported: null,
	swNotificationsSupported: null,
	pushManager: null,
	serviceWorker: null,
}

const initialState: ServiceWorkerState = {
	...sharedInitialState,
	...userSpecificInitialState,
}

export interface ServiceWorkerState {
	initialized: boolean
	swSupported: boolean | null
	pushSupported: boolean | null
	swNotificationsSupported: boolean | null
	registering: boolean
	registerError: string | null
	initializingPush: boolean
	initializingPushError: string | null
	pushSubscription: PushSubscription | null
	subscribing: boolean
	subscribingError: string | null
	unsubscribing: boolean
	unsubscribingError: string | null
	serviceWorker: ServiceWorker | null
	pushManager: PushManager | null
}

const serviceWorker = createSlice({
	name: 'serviceWorker',
	initialState,
	reducers: {
		serviceWorkerInit(
			state,
			action: PayloadAction<{
				swSupported: boolean
				pushSupported: boolean
				swNotificationsSupported: boolean
			}>
		) {
			state.initialized = true
			assignFromAction(state, action)
		},
		serviceWorkerRegisterRequest(state, action: PayloadAction) {
			state.registering = true
			state.registerError = null
		},
		serviceWorkerRegisterSuccess(
			state,
			action: PayloadAction<{
				serviceWorker: ServiceWorker | null
				pushManager: PushManager
			}>
		) {
			state.registering = false
			assignFromAction(state, action)
		},
		serviceWorkerRegisterFailure: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {
				state.registering = false
				state.registerError = action.error
			},
			prepare({ error }) {
				return { error, payload: {} }
			},
		},
		pushSubscriptionInitRequest(state, action: PayloadAction) {
			state.initializingPush = true
			state.initializingPushError = null
		},
		pushSubscriptionInitSuccess(
			state,
			action: PayloadAction<{
				subscription: PushSubscription | null
			}>
		) {
			state.initializingPush = false
			state.pushSubscription = action.payload.subscription
		},
		pushSubscriptionInitFailure: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {
				state.initializingPush = false
				state.initializingPushError = action.error
			},
			prepare({ error }) {
				return { error, payload: {} }
			},
		},
		pushSubscribeRequest(state, action: PayloadAction) {
			state.subscribing = true
			state.subscribingError = null
		},
		pushSubscribeSuccess(
			state,
			action: PayloadAction<{
				subscription: PushSubscription
			}>
		) {
			state.subscribing = false
			state.pushSubscription = action.payload.subscription
		},
		pushSubscribeFailure: {
			reducer(
				state,
				action: PayloadAction<
					{
						permission: NotificationPermission | null
					},
					string,
					never,
					string | undefined
				>
			) {
				state.subscribing = false
				if (action.error) {
					state.subscribingError = action.error
				}
			},
			prepare({
				permission,
				error,
			}: {
				error?: string
				permission?: NotificationPermission
			}) {
				return { error, payload: { permission: permission ?? null } }
			},
		},
		pushUnsubscribeRequest(state, action: PayloadAction) {
			state.unsubscribing = true
			state.unsubscribingError = null
		},
		pushUnsubscribeSuccess(state, action: PayloadAction) {
			state.unsubscribing = false
			state.pushSubscription = null
		},
		pushUnsubscribeFailure: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {
				state.unsubscribing = false
				state.unsubscribingError = action.error
			},
			prepare({ error }) {
				return { error, payload: {} }
			},
		},
	},
	extraReducers(builder) {
		builder.addCase(auth.actions.signOutSuccess, state =>
			Object.assign(state, userSpecificInitialState)
		)
	},
})

export default serviceWorker
