import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import auth from './auth'

type SubscribtionState =
	| 'subscribing'
	| 'subscribed'
	| 'subscribingFailed'
	| 'unsubscribing'
	| 'unsubscribed'
	| 'unsubscribingFailed'

interface ChannelState {
	type: 'conversation'
	conversationId: string
	state: SubscribtionState
}

interface SocketState {
	connected: boolean
}

export interface PubsubState {
	initialized: boolean
	socket: SocketState
	channels: { [channelName: string]: ChannelState }
}

const initialPubsubState: PubsubState = {
	initialized: false,
	socket: {
		connected: false,
	},
	channels: {},
}

export interface PubsubMessageWrapper {
	channelName: string
	event: string
	message: object
}

// this can't be in state as the prepare function doesn't have access to state
// this is not the jsonRPC id
let messageCounter = 1

export const pubsub = createSlice({
	name: 'pubsub',
	initialState: initialPubsubState,
	reducers: {
		subscribeToConversationChannelRequest(
			stateDraft,
			action: PayloadAction<{
				channelName: string
				conversationId: string
			}>
		) {
			stateDraft.channels[action.payload.channelName] = {
				type: 'conversation',
				conversationId: action.payload.conversationId,
				state: 'subscribing',
			}
		},
		subscribeToConversationChannelSuccess(
			stateDraft,
			action: PayloadAction<{
				channelName: string
			}>
		) {
			stateDraft.channels[action.payload.channelName].state = 'subscribed'
		},
		subscribeToConversationChannelFailure(
			stateDraft,
			action: PayloadAction<{
				channelName: string
			}>
		) {
			stateDraft.channels[action.payload.channelName].state =
				'subscribingFailed'
		},
		unsubscribeFromConversationChannelRequest(
			stateDraft,
			action: PayloadAction<{
				channelName: string
			}>
		) {
			stateDraft.channels[action.payload.channelName].state = 'unsubscribing'
		},
		unsubscribeFromConversationChannelSuccess(
			stateDraft,
			action: PayloadAction<{
				channelName: string
			}>
		) {
			stateDraft.channels[action.payload.channelName].state = 'unsubscribed'
		},
		unsubscribeFromConversationChannelFailure(
			stateDraft,
			action: PayloadAction<{
				channelName: string
			}>
		) {
			stateDraft.channels[action.payload.channelName].state =
				'unsubscribingFailed'
		},
		publishMessageRequest: {
			prepare(wrapper: PubsubMessageWrapper) {
				return {
					payload: {
						id: messageCounter++,
						channelName: wrapper.channelName,
						event: wrapper.event,
						message: JSON.stringify(wrapper.message),
					},
				}
			},
			reducer(
				stateDraft,
				action: PayloadAction<{
					channelName: string
					event: string
					message: string
					id: number
				}>
			) {},
		},
		publishMessageSuccess(
			stateDraft,
			action: PayloadAction<{
				id: number
				message: string
			}>
		) {},
		publishMessageFailure(
			stateDraft,
			action: PayloadAction<{
				id: number
			}>
		) {},
		messageReceived(stateDraft, action: PayloadAction<PubsubMessageWrapper>) {},
		websocketOpened(stateDraft) {
			stateDraft.socket.connected = true
		},
		websocketClosed(stateDraft) {
			stateDraft.socket.connected = false
		},
		websocketErrorOccured(
			stateDraft,
			action: PayloadAction<{ error: Error }>
		) {},
		clientErrorOccured(stateDraft, action: PayloadAction<{ error: Error }>) {},
		clientInitialized(stateDraft) {
			stateDraft.initialized = true
		},
		clientClosed(stateDraft) {
			stateDraft.initialized = false
		},
	},
	extraReducers(builder) {
		builder.addCase(auth.actions.signOutSuccess, () => initialPubsubState)
	},
})

export default pubsub

export function channelNameForConversation(conversationId: string) {
	return `conversation_events@${conversationId}`
}
