import { MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

import { ParagraphMedium } from '../AKTypography'

export const StyledListItemContent = styled.div<{
	$withMarginLeft?: boolean
	$withBorder: boolean
}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1 1 0;
	min-width: 0;
	min-height: ${p => p.theme.list.minHeight}px;
	margin-left: ${({ $withMarginLeft, theme }) =>
		$withMarginLeft ? theme.sizing.scale400 : '0px'};

	${p => p.$withBorder && p.theme.borders.border100};
	border-top: none;
	border-right: none;
	border-left: none;
`

export const StyledListItem = styled.li<{ onClick?: MouseEventHandler }>`
	width: 100%;
	list-style-type: none;
	display: flex;
	align-items: center;
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};

	:last-child {
		${StyledListItemContent} {
			border-bottom: none;
		}
	}
`

export const IconContainer = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
`

export const EndEnhancerContainer = styled.div`
	display: flex;
	align-items: center;
`

export const ListItemLabel = styled(ParagraphMedium)(
	({ theme }) => css`
		flex: 1 1 0;
		min-width: 0;

		color: ${theme.colors.contentPrimary};

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	`
)
