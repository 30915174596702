import styled from 'styled-components'

export const Spinner = styled.div`
	position: relative;
	flex: 0 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;
`

export const Message = styled.div`
	position: absolute;
	top: ${({ theme }) => theme.sizing.scale1000};
	left: -55px;
	width: 140px;

	text-align: center;
	${({ theme }) => theme.typography.LabelBold}
	color: ${({ theme }) => theme.colors.contentSecondary};
`

export const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background: ${({ theme }) => theme.colors.backgroundSecondary};
`
