import { FC } from 'react'

import Icon, { IconProps } from './Icon'

const ImageUploadAdd: FC<IconProps> = props => (
	<Icon {...props}>
		<g fill="none">
			<path
				d="M13.9375 22.1875H24.6375C24.8032 22.1875 24.9375 22.0532 24.9375 21.8875V9.425C24.9375 9.25931 24.8032 9.125 24.6375 9.125H8.7375C8.57181 9.125 8.4375 9.25931 8.4375 9.425V16.6875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5625 14.9688C13.5117 14.9688 14.2813 14.1992 14.2813 13.25C14.2813 12.3008 13.5117 11.5312 12.5625 11.5312C11.6133 11.5312 10.8438 12.3008 10.8438 13.25C10.8438 14.1992 11.6133 14.9688 12.5625 14.9688Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.2585 19.9164L18.0252 14.1496C18.1423 14.0325 18.3323 14.0325 18.4494 14.1496L24.931 20.6312"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.9078 16.758L22.5744 14.4246C22.4573 14.3075 22.2673 14.3075 22.1502 14.4246L20.4441 16.1307"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M8.4375 19.4375L8.4375 24.9375" strokeLinecap="round" />
			<path d="M5.6875 22.1875L11.1875 22.1875" strokeLinecap="round" />
		</g>
	</Icon>
)

export default ImageUploadAdd
