import { FS_MESSAGES_MAX_PAGE_SIZE } from '../../constants/config'

import {
	getCollection,
	getFirestoreDoc,
	observeCollection,
	FirestoreResult,
} from './firestore'
import { FetchOptions, Message } from './types'
import {
	makePaginationObject,
	convertFirestoreDocumentToFSEntity,
	getSafeLimit,
	extractOrderByItem,
} from './utils'

interface MessageResult extends FirestoreResult<Message> {}

function getConversationMessagesCollectionName(conversationId: string) {
	return `conversations/${conversationId}/messages`
}

export function fetchMessages(
	conversationId: string,
	options: FetchOptions
): Promise<MessageResult> {
	if (options.orderBy && options.orderBy.length > 1) {
		throw new Error(
			'fetchMessages is currently only supported for one orderBy entry'
		)
	}
	const [, order] = extractOrderByItem(options.orderBy?.[0])

	const safeLimits = getSafeLimit(options, FS_MESSAGES_MAX_PAGE_SIZE)
	const safeOptions: FetchOptions = {
		...options,
		...safeLimits,
	}

	return getCollection(
		getConversationMessagesCollectionName(conversationId),
		safeOptions
	).then(snapshot => {
		const data: Message[] = []
		snapshot.forEach(messageDocument =>
			data.push(convertFirestoreDocumentToFSEntity<Message>(messageDocument))
		)
		const dataInAscendingOrder = order === 'desc' ? [...data].reverse() : data

		const pagination = makePaginationObject({
			entities: data,
			options: safeOptions,
			size: snapshot.size,
		})
		return { pagination, data: dataInAscendingOrder }
	})
}

export function fetchMessage(ids: {
	messageId: string
	conversationId: string
}): Promise<MessageResult> {
	return getFirestoreDoc(
		getConversationMessagesCollectionName(ids.conversationId),
		ids.messageId
	).then(messageDocument => {
		const message = convertFirestoreDocumentToFSEntity<Message>(messageDocument)
		return { data: [message] }
	})
}

export function observeLatestConversationMessages(
	conversationId: string,
	callbacks: {
		next: (result: MessageResult) => void
		error: (error: Error) => void
		complete?: () => void
	}
) {
	const unobserverLatestConversationMessages = observeCollection(
		getConversationMessagesCollectionName(conversationId),
		{
			fetchOptions: { orderBy: [['createdAt', 'desc']], limit: 1 },
			callbacks: {
				next: snapshot => {
					const messages: Message[] = []
					snapshot.forEach(messageDocument =>
						messages.push(
							convertFirestoreDocumentToFSEntity<Message>(messageDocument)
						)
					)

					callbacks.next({ data: messages.reverse() })
				},
				error: callbacks.error,
				complete: callbacks.complete,
			},
		}
	)
	return unobserverLatestConversationMessages
}
