import { compact } from 'lodash'
import { createSelector } from 'reselect'

import {
	Mentionables as FSMentionables,
	Message as FSMessage,
} from '../network/firestore'
import { conversationMessagesBidirectionalPaginationSelectors } from '../slices/conversationMessagesPagination'
import { conversationsSelectors } from '../slices/conversations'
import { LocalMessage, localMessagesSelectors } from '../slices/localMessages'
import { messagesByConversationSelectors } from '../slices/messagesByConversation'
import { StoreState } from '../store'
import { mergeSorted } from '../utils/entitiesHelper'

import { getFirestoreMessages } from './firestoreMessages'
import { makeGetExtraParam } from './misc'
import UserInfo from './user'

export interface ConversationSelectorProps {
	conversationId: string
	createdAtSortingBehaviour?: 'asc' | 'desc'
	filterDeletedMessages?: boolean
}

export function getLocalMessageUniqueIdsForConversationFromProps(
	state: StoreState,
	props: ConversationSelectorProps
) {
	return (
		messagesByConversationSelectors.selectById(state, props.conversationId)
			?.localMessageUniqueIds ?? []
	)
}

function getLocalMessages(state: StoreState) {
	return localMessagesSelectors.selectEntities(state)
}

function makeGetLocalMessagesForConversationFromProps() {
	return createSelector(
		getLocalMessageUniqueIdsForConversationFromProps,
		getLocalMessages,
		(localMessageUniqueIds, localMessages) =>
			localMessageUniqueIds.map(uniqueId => localMessages[uniqueId])
	)
}

export function getMessageIdsForConversation(
	state: StoreState,
	props: ConversationSelectorProps
) {
	return (
		messagesByConversationSelectors.selectById(state, props.conversationId)
			?.messageIds ?? []
	)
}

function getSocketedMessageIdsForConversation(
	state: StoreState,
	props: ConversationSelectorProps
) {
	return (
		messagesByConversationSelectors.selectById(state, props.conversationId)
			?.socketedMessageIds ?? []
	)
}

export function getFetchedAllAscendingForConversation(
	state: StoreState,
	props: ConversationSelectorProps
) {
	return conversationMessagesBidirectionalPaginationSelectors.selectById(
		state,
		props.conversationId
	)?.fetchedAllAscending
}

export function makeGetPersistedMessagesOfConversationFromProps() {
	return createSelector(
		getMessageIdsForConversation,
		getFirestoreMessages,
		(conversationMessageIds, firestoreMessages) =>
			conversationMessageIds.map(messageId => firestoreMessages[messageId])
	)
}

export function makeGetSocketedMessagesForConversationFromProps() {
	return createSelector(
		getSocketedMessageIdsForConversation,
		getFirestoreMessages,
		(conversationSocketedMessageIds, firestoreMessages) =>
			conversationSocketedMessageIds.map(
				messageId => firestoreMessages[messageId]
			)
	)
}

function compareMessages(
	messageLeft: FSMessage | LocalMessage,
	messageRight: FSMessage | LocalMessage
): number {
	if (messageLeft.createdAt < messageRight.createdAt) {
		return -1
	}
	if (messageLeft.createdAt > messageRight.createdAt) {
		return 1
	}

	return 0
}

export function makeGetSortedMessagesOfConversationFromProps() {
	const getPersistedMessagesOfConversationFromProps =
		makeGetPersistedMessagesOfConversationFromProps()
	const getLocalMessagesForConversationFromProps =
		makeGetLocalMessagesForConversationFromProps()
	const getSocketedMessagesForConversationFromProps =
		makeGetSocketedMessagesForConversationFromProps()
	return createSelector(
		getPersistedMessagesOfConversationFromProps,
		getLocalMessagesForConversationFromProps,
		getSocketedMessagesForConversationFromProps,
		getFetchedAllAscendingForConversation,
		makeGetExtraParam<ConversationSelectorProps>(),
		(
			unsafeConversationPersistedMessages,
			unsafeConversationLocalMessages,
			usafeSocketedMessagesForConversationFromProps,
			conversationFetchedAllAscending,
			{
				createdAtSortingBehaviour,
				filterDeletedMessages: shouldFilterDeletedMessages,
			}
		) => {
			const conversationPersistedMessages = compact(
				unsafeConversationPersistedMessages
			)
			const conversationLocalMessages = compact(unsafeConversationLocalMessages)
			const conversationSocketedMessages = compact(
				usafeSocketedMessagesForConversationFromProps
			)
			let mergedMessages = mergeSorted<FSMessage | LocalMessage>(
				conversationPersistedMessages,
				conversationLocalMessages,
				compareMessages
			)

			if (conversationFetchedAllAscending) {
				mergedMessages = mergeSorted<FSMessage | LocalMessage>(
					mergedMessages,
					conversationSocketedMessages,
					compareMessages
				)
			}

			if (shouldFilterDeletedMessages) {
				mergedMessages = mergedMessages.filter(message => !message.removed)
			}

			if (createdAtSortingBehaviour === 'desc') {
				return mergedMessages.reverse()
			}

			return mergedMessages
		}
	)
}

export function getMentionablesOfConversationByConversationId(
	state: StoreState,
	props: ConversationSelectorProps
) {
	return conversationsSelectors.selectById(state, props.conversationId)
		?.mentionables
}

export function makeGetSortedMentionables() {
	return createSelector<
		StoreState,
		ConversationSelectorProps,
		FSMentionables | undefined,
		UserInfo[]
	>(getMentionablesOfConversationByConversationId, mentionables =>
		Object.entries(mentionables ?? {})
			.map(([id, mentionable]) => ({
				id,
				name: mentionable.name,
				color: mentionable.color,
				imageFilename: mentionable.image,
			}))
			.filter(mentionable => mentionable.name)
			.sort((userA, userB) => (userA.name < userB.name ? -1 : 1))
	)
}

export function getLatestLocalMessageForConversationId(
	state: StoreState,
	{ conversationId }: { conversationId?: string }
): LocalMessage | undefined {
	if (!conversationId) {
		return undefined
	}

	const localMessageUniqueIdsForConversation =
		getLocalMessageUniqueIdsForConversationFromProps(state, { conversationId })

	const latestLocalMessageUniqueIdForConversation =
		localMessageUniqueIdsForConversation.length
			? localMessageUniqueIdsForConversation[
					localMessageUniqueIdsForConversation.length - 1
			  ]
			: undefined
	if (!latestLocalMessageUniqueIdForConversation) {
		return undefined
	}

	const latestLocalMessage =
		getLocalMessages(state)[latestLocalMessageUniqueIdForConversation]
	return latestLocalMessage
}

export function getLatestFSMessageForConversationId(
	state: StoreState,
	{ conversationId }: { conversationId?: string }
): FSMessage | undefined {
	if (!conversationId) {
		return undefined
	}

	const messageIdsForConversation = getMessageIdsForConversation(state, {
		conversationId,
	})
	const latestMessageIdForConversation = messageIdsForConversation.length
		? messageIdsForConversation[messageIdsForConversation.length - 1]
		: undefined
	if (!latestMessageIdForConversation) {
		return undefined
	}

	const latestFSMessage =
		getFirestoreMessages(state)[latestMessageIdForConversation]
	return latestFSMessage
}

export function makeGetIsMessageIdInPagination() {
	return createSelector(
		(_, input: { messageId?: string }) => input.messageId,
		getMessageIdsForConversation,
		getLocalMessageUniqueIdsForConversationFromProps,
		getSocketedMessageIdsForConversation,
		getFetchedAllAscendingForConversation,
		(
			messageId,
			messageIds,
			localMessageUniqueIds,
			socketedMessageIds,
			fetchedAllAscending
		) => {
			if (!messageId) {
				return false
			}
			return Boolean(
				messageIds.find(id => id && id === messageId) ||
					localMessageUniqueIds.find(id => id && id === messageId) ||
					(fetchedAllAscending &&
						socketedMessageIds.find(id => id && id === messageId))
			)
		}
	)
}
