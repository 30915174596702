import { memoize, throttle } from 'lodash'
import type { ThrottleSettings } from 'lodash'
import { shallowEqual } from 'react-redux'
import { createSelectorCreator, defaultMemoize } from 'reselect'

interface Options {
	throttleSettings?: ThrottleSettings
	memoizeHashFunction?: Parameters<typeof memoize>[1]
}

export function memoizedThrottleFunctionFactory<
	Factory extends (...args: any[]) => (...args: any[]) => any,
	OuterArgs extends Parameters<Factory>,
	InnerFunc extends ReturnType<Factory>
>(functionFactory: Factory, throttleWaitTimeMs: number = 0, options?: Options) {
	return memoize(
		(...outerArgs: OuterArgs) =>
			throttle(
				functionFactory(...outerArgs) as InnerFunc,
				throttleWaitTimeMs,
				options?.throttleSettings
			),
		options?.memoizeHashFunction
	)
}

export const createShallowEqualSelector = createSelectorCreator(
	defaultMemoize,
	shallowEqual
)
