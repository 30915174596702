import styled from 'styled-components'

import { Props } from './types'

export const ToggleContainer = styled.div<Pick<Props, 'onClick'>>`
	position: relative;
	display: inline-block;
	width: 24px;
	height: 24px;
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

	input:checked + div > svg {
		opacity: 1;
	}
	input:checked + div {
		border-width: 0px;
	}
	input + div > svg {
		opacity: 0;
	}
`
export const HiddenInput = styled.input`
	opacity: 0;
	width: 0;
	height: 0;
`

export const HiddenSvg = styled.svg`
	display: flex;
`

export const CheckMark = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: ${({ theme }) => theme.borders.checkBoxRadius};
	border: ${p => p.theme.utils.stubToShorthand(p.theme.borders.border400)};
`
