import { createDarkTheme, darkThemePrimitives } from 'baseui'

import { borders } from './themes/darkTheme/borders'
import darkColorTokens from './themes/darkTheme/colorTokens'
import { lighting } from './themes/darkTheme/lighting'
import darkSemanticColorTokens from './themes/darkTheme/semanticTokens'
import { ThemeColorTokens } from './themes/types'

import createSphereTheme, { SphereTheme } from '.'

const colors: ThemeColorTokens = {
	...darkColorTokens,
	...darkSemanticColorTokens,
}

const baseTheme = createSphereTheme(colors, { elevateByShadows: false })
export const darkSphereTheme: SphereTheme = {
	...baseTheme,
	borders: {
		...baseTheme.borders,
		...borders,
	},
	lighting: {
		...baseTheme.lighting,
		...lighting,
	},
}

const SphereDarkTheme = createDarkTheme(darkThemePrimitives, darkSphereTheme)
// This doesn't get applied for some reason in createDarkTheme
SphereDarkTheme.lighting = {
	...SphereDarkTheme.lighting,
	...lighting,
}

export default SphereDarkTheme
