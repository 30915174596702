import { call, fork, put, spawn, take } from 'typed-redux-saga'

import { WebCoreKickstart } from '../slices/WebCoreKickstart'

async function getWebcoreSaga() {
	const WebCore = await import('~WebCore')
	return WebCore.initializeWebcore
}

function* initializeWebcore() {
	try {
		const mainSaga = yield* call(getWebcoreSaga)

		yield spawn(mainSaga)

		yield put(WebCoreKickstart.actions.initializeSuccess())
	} catch (e) {
		yield put(
			WebCoreKickstart.actions.initializeFailure({
				error: (e as Error).message,
			})
		)
	}
}

export default function* WebCoreSaga() {
	while (true) {
		yield* take(WebCoreKickstart.actions.initializeRequest.match)
		// webcore itself is reponsible for signout
		yield* fork(initializeWebcore)
	}
}
