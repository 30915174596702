import { FC, MouseEventHandler } from 'react'

import Checkbox from '../AKCheckbox'

import { Root, CheckboxRoot } from './styled-components'

export interface Props {
	selected?: boolean
	disabled?: boolean
	onClick?: MouseEventHandler<HTMLDivElement>
	label: string
}

export const SelectableCell: FC<Props> = ({
	selected,
	disabled,
	onClick,
	label,
}) => (
	<Root $selected={selected} $disabled={disabled} onClick={onClick}>
		<CheckboxRoot>
			<Checkbox
				onClick={onClick}
				checked={Boolean(selected)}
				disabled={disabled}
			/>
		</CheckboxRoot>
		{label}
	</Root>
)

export default SelectableCell
