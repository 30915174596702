import tokens from '../../colors/tokens'
import utils from '../../utils'
// shouldn't be used really
import { SemanticColorTokens } from '../types'

import colorTokens from './colorTokens'

const lightSemanticColorTokens: SemanticColorTokens = {
	contentPrimary: colorTokens.black,
	contentSecondary: colorTokens.mono500,

	backgroundPrimary: colorTokens.mono200,
	backgroundSecondary: colorTokens.white,

	buttonDisabledText: colorTokens.mono500,
	buttonDisabledFill: colorTokens.mono300,
	buttonInvertedDisabledBorder: colorTokens.mono500,

	buttonPrimaryBase: colorTokens.primary,
	buttonPrimaryContrast: colorTokens.white,
	buttonPrimaryHover: colorTokens.primary300,
	buttonPrimaryActive: colorTokens.primary600,

	buttonSecondaryBase: colorTokens.mono300,
	buttonSecondaryContrast: colorTokens.black,
	buttonSecondaryHover: colorTokens.mono400,
	buttonSecondaryActive: colorTokens.mono200,

	buttonSecondaryAltBase: colorTokens.mono500,
	buttonSecondaryAltContrast: colorTokens.white,
	buttonSecondaryAltHover: colorTokens.mono300,
	buttonSecondaryAltActive: colorTokens.mono600,

	buttonDangerBase: colorTokens.negative,
	buttonDangerContrast: colorTokens.white,
	buttonDangerHover: colorTokens.negative300,
	buttonDangerActive: colorTokens.negative600,

	// needs color from design
	buttonPollBase: colorTokens.magnificent,
	buttonPollContrast: colorTokens.white,
	buttonPollHover: colorTokens.magnificent300,
	buttonPollActive: colorTokens.magnificent600,

	buttonEventBase: tokens.autumn200,
	buttonEventContrast: colorTokens.white,
	buttonEventHover: tokens.autumn300,
	buttonEventActive: tokens.autumn400,

	buttonEventAltBase: colorTokens.white,
	buttonEventAltContrast: tokens.autumn200,
	buttonEventAltHover: colorTokens.mono200,
	buttonEventAltActive: colorTokens.mono300,

	feedItemActiveBackground: colorTokens.mono300,
	feedItemHoverBackground: colorTokens.mono200,

	mentionText: colorTokens.primary,
	mentionFill: utils.convertHexColorToRGBA(colorTokens.primary, 0.1),
	mentionHover: utils.convertHexColorToRGBA(colorTokens.primary, 0.2),
	mentionHighlightedFill: utils.convertHexColorToRGBA(
		colorTokens.positive,
		0.3
	),
	mentionHighlightedHover: utils.convertHexColorToRGBA(
		colorTokens.positive,
		0.4
	),

	menuTriggerHover: colorTokens.mono300,

	modalDarkBackdrop: 'rgba(0, 0, 0, 0.5)',

	badgePrimary: colorTokens.primary,
	badgeSecondary: colorTokens.mono500,
	badgeSuccess: colorTokens.positive,
	badgeTextPrimary: colorTokens.white,
	badgeDanger: colorTokens.negative,

	tagPrimaryBackground: colorTokens.primary,
	tagPrimaryTextLight: colorTokens.white,
	tagPrimaryTextDark: colorTokens.black,

	toggleUncheckedBackground: colorTokens.mono300,
	toggleCheckedBackground: colorTokens.primary,

	iconFillLight: colorTokens.white,
	iconFillGrey: colorTokens.mono300,
	iconFill: colorTokens.mono500,
	iconFillDark: colorTokens.black,

	contentNegative: colorTokens.negative,

	pollMessagePrimaryContent: colorTokens.magnificent,
	pollMessageBackground: colorTokens.magnificent100,
	pollMessageProgressBarBackground: colorTokens.magnificent100,
	pollMessageContent: colorTokens.black,
	announcementMessagePrimaryContent: colorTokens.primary,
	announcementMessageBackground: colorTokens.primary100,
	announcementMessageContent: colorTokens.black,

	fileIconBackground: tokens.grass500,
	messageAttachementBackground: tokens.snow600,

	tooltipText: colorTokens.mono100,
	tooltipBackground: colorTokens.mono500,

	reactionCounterFill: colorTokens.mono300,
	reactionCounterText: colorTokens.black,
	reactionCounterHighlighted: colorTokens.primary100,
	reactionCounterHover: colorTokens.mono400,
	reactionCounterActive: tokens.charcoal100,

	datePickerCalendarHeaderBackground: colorTokens.white,
	datePickerCalendarRangeBackground: colorTokens.mono300,
	datePickerCalendarSelectedBackground: colorTokens.primary,
	datePickerCalendarSelectedText: colorTokens.white,

	hyperlinkText: colorTokens.primary500,

	calendarIconFill: tokens.autumn500,
	appreciationsIconBackground: tokens.sun200,
	dmIconFill: tokens.slate400,

	searchBarBackground: colorTokens.mono300,

	addToListFill: colorTokens.primary100,

	lightDivider: tokens.silver200,
}

export default lightSemanticColorTokens
