import {
	useEffect,
	useRef,
	useCallback,
	PointerEventHandler,
	ChangeEventHandler,
	FormEventHandler,
	FC,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useAsyncSafeState from '../../hooks/useAsyncSafeState'
import { useUpdateUserMutation } from '../../hooks/useUpdateUserMutation'
import { GetOwnProfileQuery } from '../../network/graphql'

import Profile from '../../components/LegacyProfile'

import * as events from '../../constants/EventNames'
import { getUserAgentId } from '../../selectors/userContext'
import { fileUploadsSelectors } from '../../slices/files'
import profile from '../../slices/profile'
import { SegmentClient } from '../../utils/segmentProxy'
import SuperSegment from '../../utils/segmentTypes'

export interface Props {
	data?: GetOwnProfileQuery
	onSuccess(): void
}

const EditProfileContainer: FC<Props> = ({ data, onSuccess }) => {
	const [name, setName] = useAsyncSafeState('')
	const [description, setDescription] = useAsyncSafeState('')
	const [submitError, setSubmitError] = useAsyncSafeState<string | false>(false)

	const dispatch = useDispatch()
	const agentId = useSelector(getUserAgentId)
	const imageFileState = useSelector(state => {
		const objectURL = state.profile.imageUploadObjectURL
		return objectURL
			? fileUploadsSelectors.selectById(state, objectURL)
			: undefined
	})

	const persistedName = data?.viewer?.agent.name
	const persistedDescription = data?.viewer?.person?.about
	const persistedImageFilename = data?.viewer?.agent.image_filename
	useEffect(() => {
		if (persistedName) {
			setName(persistedName)
		}
	}, [persistedName, setName])
	useEffect(() => {
		if (persistedDescription) {
			setDescription(persistedDescription)
		}
	}, [persistedDescription, setDescription])

	const [updateUser, { loading: updateLoading }] = useUpdateUserMutation()

	const descriptionRef = useRef<HTMLTextAreaElement | null>(null)
	useEffect(() => {
		descriptionRef.current?.focus()
	}, [])
	const hasTrackedDetailsChange = useRef(false)

	const hasTrackedProfileView = useRef(false)
	useEffect(() => {
		if (agentId && !hasTrackedProfileView.current) {
			SuperSegment.PROFILE_VIEWED({
				source: 'settings',
				profile_agent_id: agentId,
			})
			hasTrackedProfileView.current = true
		}
	}, [agentId, name, description, data?.viewer])

	const handleNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
		e => {
			setName(e.target.value)
		},
		[setName]
	)

	const handleDescriptionChange = useCallback<
		ChangeEventHandler<HTMLTextAreaElement>
	>(
		e => {
			if (!hasTrackedDetailsChange.current) {
				SegmentClient.track(events.EDIT_PROFILE_DESCRIPTION)
				hasTrackedDetailsChange.current = true
			}
			setDescription(e.target.value.replace(/[\n\r\v]+/g, ''))
		},
		[setDescription]
	)

	const handleCancelClick = useCallback<PointerEventHandler<HTMLButtonElement>>(
		e => {
			SegmentClient.track(events.PROFILE_EDIT_CANCEL_CLICK)
			onSuccess()
		},
		[onSuccess]
	)

	const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
		async e => {
			e.preventDefault()

			SegmentClient.track(events.SAVED_PROFILE, {
				name,
				description,
			})

			if (agentId) {
				setSubmitError(false)
				try {
					const { errors } = await updateUser({ agentId, name, description })
					if (errors) {
						const mainError = errors[0]
						if (mainError.message === '"about" is not allowed to be empty') {
							setSubmitError('Please enter a description')
						} else if (
							mainError.message === '"name" is not allowed to be empty'
						) {
							setSubmitError('Please enter a name')
						} else {
							setSubmitError('Failed to update details')
						}
					} else {
						onSuccess()
					}
				} catch (e) {
					setSubmitError('Failed to update details')
				}
			}
		},
		[agentId, description, name, onSuccess, setSubmitError, updateUser]
	)

	const handleImageInputOnClick = () => {
		SegmentClient.track(events.EDIT_PROFILE_PHOTO)
	}

	const handleImageInputOnChange = useCallback<
		ChangeEventHandler<HTMLInputElement>
	>(
		e => {
			const updateProfileImage = (imageFilename: string) => {
				if (!agentId) {
					throw new Error('Missing Agent Id')
				}
				return updateUser({ agentId, imageFilename })
			}

			const clearPreviouslyUploadedProfileImage = () => {
				if (imageFileState) {
					dispatch(
						profile.actions.profileImageUploadClear({
							objectURL: imageFileState.objectURL,
						})
					)
				}
			}

			SegmentClient.track(events.PROFILE_IMAGE_UPLOAD_START)

			const fileList = e.target.files
			const file = fileList?.item(0)

			if (file) {
				const objectURL = URL.createObjectURL(file)

				clearPreviouslyUploadedProfileImage()
				dispatch(
					profile.actions.profileImageUploadInitiate({
						objectURL,
						file,
						updateProfileImage,
					})
				)
			}
		},
		[agentId, dispatch, imageFileState, updateUser]
	)

	return (
		<Profile
			name={name}
			description={description}
			filename={persistedImageFilename ?? undefined}
			attendantColor={data?.viewer?.agent.color ?? 'white'}
			descriptionRef={descriptionRef}
			onNameChange={handleNameChange}
			onDescriptionChange={handleDescriptionChange}
			imageFileState={imageFileState}
			submitError={submitError}
			updateLoading={updateLoading}
			onCancelClick={handleCancelClick}
			onSubmit={handleSubmit}
			onImageUploadChange={handleImageInputOnChange}
			onImageUploadClick={handleImageInputOnClick}
		/>
	)
}

export default EditProfileContainer
