import Schemas from '../constants/Schemas'
import entities from '../slices/entities'
import webPushSubscription from '../slices/webPushSubscription'
import { ApplicationThunk } from '../store'
import { makeApiCall } from '../utils/apiHelper'

function fetchWebPushSubscriptionsAction() {
	return makeApiCall({
		types: [
			webPushSubscription.actions.subscriptionsRequest.type,
			webPushSubscription.actions.subscriptionsSuccess.type,
			webPushSubscription.actions.subscriptionsFailure.type,
		],
		endpoint: '/me/webPushSubscriptions?order=descending',
		schema: Schemas.WEB_PUSH_SUBSCRIPTION_ARRAY,
	})
}

export function fetchWebPushSubscriptions(): ApplicationThunk {
	return dispatch => dispatch(fetchWebPushSubscriptionsAction())
}

function createWebPushSubscriptionAction(data: object) {
	return makeApiCall({
		types: [
			webPushSubscription.actions.createSubscriptionRequest.type,
			webPushSubscription.actions.createSubscriptionSuccess.type,
			webPushSubscription.actions.createSubscriptionFailure.type,
		],
		endpoint: '/me/webPushSubscriptions',
		schema: Schemas.WEB_PUSH_SUBSCRIPTION,
		options: {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		},
	})
}

export function createWebPushSubscription(data: object): ApplicationThunk {
	return dispatch => dispatch(createWebPushSubscriptionAction(data))
}

function deleteWebPushSubscriptionAction(webPushSubscriptionId: string) {
	return makeApiCall({
		types: [
			webPushSubscription.actions.deleteSubscriptionRequest.type,
			webPushSubscription.actions.deleteSubscriptionSuccess.type,
			webPushSubscription.actions.deleteSubscriptionFailure.type,
		],
		endpoint: `/me/webPushSubscriptions/${webPushSubscriptionId}`,
		schema: false,
		options: {
			method: 'delete',
		},
	})
}

export function deleteWebPushSubscription(
	webPushSubscriptionId: string
): ApplicationThunk {
	return dispatch => {
		dispatch(
			entities.actions.entityDeleteLocal({
				entityType: 'webPushSubscriptions',
				entityId: webPushSubscriptionId,
			})
		)
		return dispatch(deleteWebPushSubscriptionAction(webPushSubscriptionId))
	}
}
