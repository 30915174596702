import { FC } from 'react'
import styled, { css } from 'styled-components'

export interface RoundIconProps {
	size: number
	className?: string
	backgroundColor?: string
}

const Root = styled.div<{ $size: number; $backgroundColor?: string }>(
	({ $size, $backgroundColor }) => css`
		position: relative;
		border-radius: 50%;
		overflow: hidden;
		margin: 0;

		height: ${$size}px;
		width: ${$size}px;
		${$backgroundColor &&
		`
			background-color: ${$backgroundColor};
		`}
	`
)

const RoundIcon: FC<RoundIconProps> = ({ size, backgroundColor, ...rest }) => (
	<Root $size={size} $backgroundColor={backgroundColor} {...rest} />
)

export default RoundIcon
