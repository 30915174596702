import { FC } from 'react'
import { useTheme } from 'styled-components'

import Icon, { IconProps } from './Icon'

const Cross: FC<IconProps> = props => {
	const theme = useTheme()
	return (
		<Icon
			viewBox="9 9 10 10"
			size={28}
			color={theme.colors.contentPrimary}
			{...props}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M14 10v8M18 14h-8"
			/>
		</Icon>
	)
}

export default Cross
