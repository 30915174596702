import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'
import { createSelector } from 'reselect'
import { RequireExactlyOne } from 'type-fest'

import { COPY } from '../constants/config'
import SphereEventForm from '../domain/usecases/SphereEventForm'

import files from './files'

import { StoreState } from '.'

export interface SphereEventFormState {
	title?: string
	description?: string
	locationName?: string
	link?: string
	startDay?: string
	endDay?: string
	startTime?: string
	endTime?: string

	imageFilename?: string
	imageSource?: string
	imageSourceLoading?: boolean
	imageSourceError?: string

	errorMessage?: string
}

function getNewInitialState(): SphereEventFormState {
	const nowDate = new Date()
	const initialStartDate = SphereEventForm.getInitialStartDate({ nowDate })
	return {
		title: undefined,
		description: undefined,
		locationName: undefined,
		link: undefined,
		startDay: initialStartDate.toISOString(),
		endDay: undefined,
		startTime: initialStartDate.toISOString(),
		endTime: undefined,

		imageFilename: undefined,
		imageSource: undefined,
		imageSourceLoading: false,
		imageSourceError: undefined,

		errorMessage: undefined,
	}
}

const emptyState: SphereEventFormState = {}

export const sphereEventForm = createSlice({
	name: 'sphereEventForm',
	initialState: emptyState,
	reducers: {
		enter(
			state,
			action: PayloadAction<
				| {
						initialState?: SphereEventFormState
				  }
				| undefined
			>
		) {
			// Don't validate dates if we're setting from existing data
			Object.assign(state, action.payload?.initialState ?? getNewInitialState())
		},
		clear() {
			return emptyState
		},
		setTextInputState(
			state,
			action: PayloadAction<
				RequireExactlyOne<
					SphereEventFormState,
					'title' | 'description' | 'locationName' | 'link'
				>
			>
		) {
			Object.assign(state, action.payload)
		},
		setStartDay: {
			reducer(state, action: PayloadAction<{ newStartDay?: string }>) {
				const { newStartDay } = action.payload
				state.startDay = newStartDay
				state.errorMessage = undefined

				enforceValidStartAndEndDates(state)
			},
			prepare(input: Date | undefined) {
				return {
					payload: {
						newStartDay: input?.toISOString(),
					},
				}
			},
		},
		setEndDay: {
			reducer(state, action: PayloadAction<{ newEndDay?: string }>) {
				state.endDay = action.payload.newEndDay
				state.errorMessage = undefined

				enforceValidStartAndEndDates(state)
			},
			prepare(input: Date | undefined) {
				return {
					payload: {
						newEndDay: input?.toISOString(),
					},
				}
			},
		},
		setStartTime: {
			reducer(state, action: PayloadAction<{ newStartTime?: string }>) {
				state.startTime = action.payload.newStartTime
				state.errorMessage = undefined

				enforceValidStartAndEndDates(state)
			},
			prepare(input: Date | undefined) {
				return {
					payload: {
						newStartTime: input?.toISOString(),
					},
				}
			},
		},
		setEndTime: {
			reducer(state, action: PayloadAction<{ newEndTime?: string }>) {
				state.endTime = action.payload.newEndTime
				state.errorMessage = undefined

				enforceValidStartAndEndDates(state)
			},
			prepare(input: Date | undefined) {
				return {
					payload: {
						newEndTime: input?.toISOString(),
					},
				}
			},
		},
		imageSourceRequest(state) {
			state.imageSourceLoading = true
			state.imageSourceError = undefined
			state.imageSource = undefined
		},
		imageSourceSuccess: {
			reducer(
				state,
				action: PayloadAction<{ imageSource: string | undefined }>
			) {
				state.imageSource = action.payload.imageSource
				state.imageSourceLoading = false
			},
			prepare(input: string | undefined) {
				return {
					payload: {
						imageSource: input,
					},
				}
			},
		},
		imageSourceFailure: {
			reducer(
				state,
				action: PayloadAction<{ imageSourceError: string | undefined }>
			) {
				state.imageSourceError = action.payload.imageSourceError
				state.imageSourceLoading = false
			},
			prepare(error: Error) {
				return {
					payload: {
						imageSourceError: error.message,
					},
				}
			},
		},
	},
	extraReducers: builder => {
		builder.addCase(files.actions.uploadSuccess, (state, action) => {
			if (action.payload.objectURL === state.imageSource) {
				const { public_id: publicId, format } = action.payload.response || {}
				const imageFilename =
					publicId && format ? `${publicId}.${format}` : undefined
				state.imageFilename = imageFilename
			}
		})
	},
})

const toDate = (isoString?: string): Date | undefined =>
	isoString ? new Date(isoString) : undefined

export function enforceValidStartAndEndDates(
	state: WritableDraft<SphereEventFormState>,
	getCurrentDayDate = () => new Date()
) {
	const currentDayDate = getCurrentDayDate()

	const isStartDayBeforeToday = SphereEventForm.isStartDayBeforeToday({
		startDay: toDate(state.startDay),
		currentDayDate,
	})

	if (isStartDayBeforeToday) {
		state.startDay = currentDayDate.toISOString()
		state.errorMessage = COPY.EVENT_FORM_FLOW.START_DAY_BEFORE_TODAY_ERROR_TEXT
	}

	const isEndDayBeforeToday = SphereEventForm.isEndDayBeforeToday({
		endDay: toDate(state.endDay),
		currentDayDate,
	})

	if (isEndDayBeforeToday) {
		state.endDay = currentDayDate.toISOString()
		state.errorMessage = COPY.EVENT_FORM_FLOW.END_DAY_BEFORE_TODAY_ERROR_TEXT
	}

	const isStartDateAfterEndDateOnSameDay =
		SphereEventForm.isStartDateAfterEndDateOnSameDay({
			startDay: toDate(state.startDay),
			endDay: toDate(state.endDay),
			startTime: toDate(state.startTime),
			endTime: toDate(state.endTime),
		})
	const isStartDayAfterEndDay = SphereEventForm.isStartDayAfterEndDay({
		startDay: toDate(state.startDay),
		endDay: toDate(state.endDay),
	})

	if (isStartDateAfterEndDateOnSameDay) {
		state.endTime = SphereEventForm.getDefaultEndTime({
			startTime: toDate(state.startTime),
		})?.toISOString()
		state.errorMessage = COPY.EVENT_FORM_FLOW.END_TIME_TOO_EARLY_ERROR_TEXT
	} else if (isStartDayAfterEndDay) {
		state.endDay = SphereEventForm.getDefaultEndDay({
			startDay: toDate(state.startDay),
		})?.toISOString()
		state.errorMessage =
			COPY.EVENT_FORM_FLOW.END_DAY_BEFORE_START_DAY_ERROR_TEXT
	}
}

function getSphereEventFormState(state: StoreState) {
	return state.sphereEventForm
}

export function makeGetSphereEventFormStateWithDates() {
	return createSelector(getSphereEventFormState, sphereEventForm => ({
		...sphereEventForm,
		startDay: sphereEventForm.startDay
			? new Date(sphereEventForm.startDay)
			: undefined,
		startTime: sphereEventForm.startTime
			? new Date(sphereEventForm.startTime)
			: undefined,
		endDay: sphereEventForm.endDay
			? new Date(sphereEventForm.endDay)
			: undefined,
		endTime: sphereEventForm.endTime
			? new Date(sphereEventForm.endTime)
			: undefined,
	}))
}

export default sphereEventForm
