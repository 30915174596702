import jsonrpc, { RpcParams } from 'jsonrpc-lite'

import PubsubChannelMaintainer, {
	PubsubChannelTokenFetcher,
} from './PubsubChannelMaintainer'
import { streamWebSocketMessageEventJsonRPC } from './streamRPC'
import {
	PubsubMessage,
	PubsubMessageAcknowledgement,
	PubsubMessageParams,
} from './types'

/**
 * Pubsub Client for https://www.notion.so/heysphere/Generic-websocket-Pub-Sub-system-3144d260c4a348c9b85fc09f40998adf
 */
export class PubsubClient {
	protected websocket: WebSocket

	// Running ids for RPC requests
	// Needs to start with 1, as 0 is an invalid id
	protected rpcId: number = 1

	protected channelAuthenticationMaintainers: Record<
		string,
		PubsubChannelMaintainer
	> = {}

	/**
	 * Creates an instance of PubsubClient which in turn creates a WebSocket to connect to the given endpoint
	 */
	constructor(endpoint: string, accessToken: string) {
		this.websocket = new WebSocket(`${endpoint}?token=${accessToken}`)
	}

	private listenersWereRegistered = false

	protected waitingForAcknowledgement: {
		[id: string]: {
			resolve(value: unknown): void
			reject(reason?: any): void
		}
	} = {}

	/**
	 * Register listeners on Pubsub events
	 * Returning true if succesful, false if listeners couldn't be registered as listeners already existed
	 */
	registerListeners(listeners: {
		onEvent(this: PubsubClient, event: 'open' | 'close'): void
		onMessage(this: PubsubClient, message: PubsubMessage): void
		onMessageNotParseable(this: PubsubClient, params: PubsubMessageParams): void
		onError(this: PubsubClient): void
	}): boolean {
		if (this.listenersWereRegistered) {
			return false
		}

		this.listenersWereRegistered = true

		this.websocket.onopen = listeners.onEvent.bind(this, 'open')
		this.websocket.onclose = listeners.onEvent.bind(this, 'close')
		this.websocket.onmessage = streamWebSocketMessageEventJsonRPC({
			message: listeners.onMessage.bind(this),
			messageNotParseable: listeners.onMessageNotParseable.bind(this),
			sendAcknowledged: (id, payload) => {
				this.waitingForAcknowledgement[id]?.resolve(payload)
				delete this.waitingForAcknowledgement[id]
			},
			sendErrored: (id, error) => {
				this.waitingForAcknowledgement[id]?.reject(error)
				delete this.waitingForAcknowledgement[id]
			},
		})

		this.websocket.onerror = listeners.onError.bind(this)
		return true
	}

	/**
	 * Serialize Json RPC request
	 */
	protected serializeRequest(
		method: string,
		params: RpcParams
	): [message: string, id: string] {
		const id = `${this.rpcId++}`
		return [jsonrpc.request(id, method, params).serialize(), id]
	}

	/**
	 * Send RPC request on a internal socket
	 */
	protected async sendRPC(opts: {
		channelName: string
		method: 'subscribe' | 'unsubscribe' | 'publish'
		params?: RpcParams
		includeChannelToken?: boolean
	}): Promise<PubsubMessageAcknowledgement> {
		const { channelName, method, params, includeChannelToken } = opts

		let channelToken: {} | { token: string } = {}

		if (
			includeChannelToken &&
			channelName in this.channelAuthenticationMaintainers
		) {
			channelToken = {
				token: await this.channelAuthenticationMaintainers[
					channelName
				].getAccessToken(),
			}
		}

		const [message, id] = this.serializeRequest(method, {
			channel: channelName,
			...channelToken,
			...params,
		})

		const sendSuccesful = new Promise((resolve, reject) => {
			this.waitingForAcknowledgement[id] = { resolve, reject }
		})

		this.websocket.send(message)

		await sendSuccesful

		return { message, id }
	}

	/**
	 * Subscribe to a Pubsub Channel
	 * All subscribed channels will have their message intermixed on the passed in message callback
	 */
	async subscribeToChannel(
		channelName: string,
		channelTokenFetcher: PubsubChannelTokenFetcher
	): Promise<unknown> {
		if (channelName in this.channelAuthenticationMaintainers) {
			return
		}

		this.channelAuthenticationMaintainers[channelName] =
			new PubsubChannelMaintainer(channelTokenFetcher)

		return this.sendRPC({
			channelName,
			includeChannelToken: true,
			method: 'subscribe',
		})
	}

	async unsubscribeFromChannel(channelName: string): Promise<unknown> {
		return this.sendRPC({
			channelName,
			method: 'unsubscribe',
		}).then(() => {
			this.channelAuthenticationMaintainers[channelName].stop()
			delete this.channelAuthenticationMaintainers[channelName]
		})
	}

	publish(
		channelName: string,
		params: { event: string; message: string }
	): Promise<PubsubMessageAcknowledgement> {
		return this.sendRPC({
			channelName,
			includeChannelToken: true,
			method: 'publish',
			params,
		})
	}

	close() {
		this.websocket?.close()

		for (const channelMaintainerId in this.channelAuthenticationMaintainers) {
			this.channelAuthenticationMaintainers[channelMaintainerId].stop()
		}

		for (const message of Object.values(this.waitingForAcknowledgement)) {
			message.reject('socket closed')
		}
	}
}

export default PubsubClient
