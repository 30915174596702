import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit'

import { CloudinaryResourceType } from '../network/firestore'
import { createRequestAdapter, RequestAdapterState } from '../utils/slices'

import { StoreState } from '.'

export interface FileState {
	objectURL: string
	fileName: string | null
	fileType: string | null
	fileSize: number | null
	isUploading: boolean
	progress: number
	resourceType?: CloudinaryResourceType
	response?: any
	error?: string
}

export type FilesState = {
	uploads: EntityState<FileState>
	fileSignature: RequestAdapterState
}

const fileUploadsEntityAdapter = createEntityAdapter<FileState>({
	selectId(entry) {
		return entry.objectURL
	},
})

const fileSignatureRequestAdapter = createRequestAdapter({
	name: 'fileSignature',
})

const initialState: FilesState = {
	uploads: fileUploadsEntityAdapter.getInitialState(),
	fileSignature: fileSignatureRequestAdapter.getInitialState(),
}

export const files = createSlice({
	name: 'files',
	initialState,
	reducers: {
		uploadRequest(
			state,
			action: PayloadAction<{
				objectURL: string
				resource: File | Blob | string
				options?: {
					transformation?: string
				}
			}>
		) {
			const resource = action.payload.resource
			fileUploadsEntityAdapter.addOne(state.uploads, {
				objectURL: action.payload.objectURL,
				isUploading: true,
				progress: 0,
				fileName: resource instanceof File ? resource.name : null,
				fileType: resource instanceof File ? resource.type : null,
				fileSize: resource instanceof File ? resource.size : null,
				resourceType: undefined,
				response: undefined,
				error: undefined,
			})
		},

		uploadProgress(
			state,
			action: PayloadAction<{ objectURL: string; progress: number }>
		) {
			fileUploadsEntityAdapter.updateOne(state.uploads, {
				id: action.payload.objectURL,
				changes: {
					isUploading: true,
					progress: action.payload.progress,
				},
			})
		},

		uploadSuccess(
			state,
			action: PayloadAction<{ objectURL: string; response: any }>
		) {
			fileUploadsEntityAdapter.updateOne(state.uploads, {
				id: action.payload.objectURL,
				changes: {
					isUploading: false,
					progress: 1,
					response: action.payload.response,
					resourceType: action.payload.response.resource_type,
				},
			})
		},

		uploadFailure: {
			reducer(
				state,
				action: PayloadAction<{ objectURL: string }, string, never, string>
			) {
				fileUploadsEntityAdapter.updateOne(state.uploads, {
					id: action.payload.objectURL,
					changes: {
						isUploading: false,
						progress: 0,
						error: action.error,
					},
				})
			},
			prepare({ objectURL, error }: { objectURL: string; error: string }) {
				return { payload: { objectURL }, error }
			},
		},

		uploadClearState(state, action: PayloadAction<{ objectURL: string }>) {
			fileUploadsEntityAdapter.removeOne(
				state.uploads,
				action.payload.objectURL
			)
		},
		...fileSignatureRequestAdapter.caseReducers,
	},
})

export default files

export const fileUploadsSelectors =
	fileUploadsEntityAdapter.getSelectors<StoreState>(
		state => state.files.uploads
	)
