import { put, call, spawn } from 'typed-redux-saga/macro'

import { AttachmentInput } from '../network/graphql'

import {
	Attachment,
	Message,
	MessageContent,
	MessageSenderInfo,
	PollOption,
} from '../network/firestore'
import localMessages, { LocalMessage } from '../slices/localMessages'
import message from '../slices/message'
import { toSafeError } from '../utils/error'
import { now } from '../utils/time'

import combineMessageInputState from './combineMessageInputState'
import tryCreatePersistedMessage from './persistMessage'

function buildLocalMessageFromMessageInputState(
	messageInputState: ComposedMessageState,
	uniqueId: string
): LocalMessage {
	const createAtDate = new Date(now())
	return {
		uniqueId,
		createdAt: createAtDate.toJSON(),
		conversationId: messageInputState.conversationId,
		content: {
			text: messageInputState.text,
			...messageInputState.imageContent,
			pollOptions: messageInputState.pollOptions,
			attachments: messageInputState.attachments?.map(
				(attachment: AttachmentInput) =>
					({
						...attachment,
						attachmentType: attachment.attachmentType?.toLowerCase(),
					} as Attachment)
			),
		},
		senderInfo: messageInputState.senderInfo,
		type: 'client_message' as 'client_message',
		replyToMessageInfo: messageInputState.replyToMessageInfo ?? undefined,
		mentions: messageInputState.mentions,
		important: Boolean(messageInputState.isImportant),
		removed: false as false,
		permitsMultiplePollVotes: messageInputState.permitsMultiplePollVotes,
	}
}

export interface LocalMessageMention {
	mentionableId: string
	displayName?: string
	offset: number
	length: number
}

export interface ComposedMessageState {
	conversationId: string
	isImportant?: boolean
	text?: string
	mentions?: LocalMessageMention[]
	imageContent?: Omit<MessageContent, 'text'> | null
	replyToMessageInfo?: Message['replyToMessageInfo'] | null
	senderInfo: MessageSenderInfo
	permitsMultiplePollVotes?: boolean
	pollOptions?: PollOption[]
	attachments?: AttachmentInput[]
}

export default function* sendMessageSaga(
	sendMessageAction: ReturnType<typeof message.actions.sendMessageInitiate>
) {
	const { uniqueId } = sendMessageAction.payload

	let messageInputState: ComposedMessageState
	try {
		messageInputState = yield* call(combineMessageInputState, sendMessageAction)
	} catch (unknownError) {
		const error = toSafeError(unknownError)
		yield* put(
			message.actions.sendMessageFailure({
				error,
				uniqueId,
			})
		)
		return
	}

	// TODO: Should this function collect errors from below and streamline them?

	yield* put(
		localMessages.actions.localMessageCreate(
			buildLocalMessageFromMessageInputState(messageInputState, uniqueId)
		)
	)
	yield* spawn(tryCreatePersistedMessage, messageInputState, uniqueId)
}
