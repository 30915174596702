export enum ReactionType {
	EmojiReaction = 'EmojiReaction',
	AppreciationReaction = 'AppreciationReaction',
}

export interface EmojiReaction {
	type: ReactionType.EmojiReaction
	emojiId: string
	emojiImageId: string
	name?: string
	unicode?: string
}

export interface AppreciationReaction {
	type: ReactionType.AppreciationReaction
	appreciationId: string
	emojiImageId: string
	color: string
	appreciationName: string
	active?: boolean
}

export type ReactionModel = EmojiReaction | AppreciationReaction

export interface MessageReactionSenderInfo {
	attendantId: string
	displayName: string
	displayImageId?: string | null
	displayColor?: string | null
}

export type EmojiMessageReaction = EmojiReaction & MessageReactionSenderInfo
export type AppreciationMessageReaction = AppreciationReaction &
	MessageReactionSenderInfo
export type MessageReactionModel = ReactionModel & MessageReactionSenderInfo

export default class Reaction {
	static selectReactionId(reaction: ReactionModel): string {
		switch (reaction.type) {
			case ReactionType.AppreciationReaction:
				return reaction.appreciationId
			case ReactionType.EmojiReaction:
				return reaction.emojiId
		}
	}
	static reactionIsAppreciationReaction(
		reaction: ReactionModel | MessageReactionModel
	): reaction is AppreciationReaction {
		return reaction.type === ReactionType.AppreciationReaction
	}
}
