import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit'

import { channelNameForConversation, PubsubMessageWrapper } from './pubsub'

import { StoreState } from '.'

export type AttendantTypingState = {
	attendantId: string
	typing: boolean
	createdAt: string
}

const attendantsAdapter = createEntityAdapter<AttendantTypingState>({
	selectId: attendant => attendant.attendantId,
})

type ConversationTypingIndicatorsState = {
	conversationId: string
	pubsubChannelName: string
	subscribedToUpdates: boolean
	attendantsTyping: EntityState<AttendantTypingState>
}

const conversationsAdapter =
	createEntityAdapter<ConversationTypingIndicatorsState>({
		selectId: conversation => conversation.conversationId,
	})

export type AttendantTypingPubsubEventPayload = {
	attendant_id: string
	action: 'set' | 'clear'
	created_at: string
}

export type AttendantTypingEvent = {
	conversationId: string
	attendantId: string
	action: 'set' | 'clear'
	createdAt: string
}

export type TypingIndicatorsState =
	EntityState<ConversationTypingIndicatorsState>

export const typingIndicators = createSlice({
	name: 'typingIndicators',
	initialState: conversationsAdapter.getInitialState(),
	reducers: {
		subscribe: {
			reducer(
				stateDraft,
				action: PayloadAction<{
					conversationId: string
					channelName: string
				}>
			) {
				conversationsAdapter.upsertOne(stateDraft, {
					conversationId: action.payload.conversationId,
					pubsubChannelName: action.payload.channelName,
					subscribedToUpdates: true,
					attendantsTyping: attendantsAdapter.getInitialState(),
				})
			},
			prepare(props: { conversationId: string }) {
				return {
					payload: {
						...props,
						channelName: channelNameForConversation(props.conversationId),
					},
				}
			},
		},
		unsubscribe(
			stateDraft,
			action: PayloadAction<{
				conversationId: string
			}>
		) {
			conversationsAdapter.updateOne(stateDraft, {
				id: action.payload.conversationId,
				changes: { subscribedToUpdates: false },
			})
		},
		// we don't store the local attendant, just formalize it for the saga to deliver to the other end
		ownAttendantIsTyping: {
			prepare(props: Omit<AttendantTypingEvent, 'createdAt'>) {
				return { payload: { ...props, createdAt: new Date().toISOString() } }
			},
			reducer(stateDraft, action: PayloadAction<AttendantTypingEvent>) {},
		},
		attendantTypingStateUpdate(
			stateDraft,
			action: PayloadAction<AttendantTypingEvent>
		) {
			const {
				conversationId,
				attendantId,
				action: typingAction,
				createdAt,
			} = action.payload
			const conversation = stateDraft.entities[conversationId]
			// not subscribed (anymore), thus we skip updates
			if (!conversation || !conversation.subscribedToUpdates) {
				return
			}

			attendantsAdapter.upsertOne(conversation.attendantsTyping, {
				attendantId,
				typing: typingAction === 'set',
				createdAt,
			})
		},
		error(stateDraft, action: PayloadAction<{ error: any }>) {},
	},
})

export interface TypingMessageWrapper extends PubsubMessageWrapper {
	event: 'client-typing'
	message: AttendantTypingPubsubEventPayload
}

export function isTypingMessageWrapper(
	messageWrapper: PubsubMessageWrapper
): messageWrapper is TypingMessageWrapper {
	return (
		messageWrapper.event === 'client-typing' &&
		'attendant_id' in messageWrapper.message &&
		'created_at' in messageWrapper.message &&
		'action' in messageWrapper.message
	)
}

export function encodeAttendantTypingEvent(
	event: AttendantTypingEvent
): AttendantTypingPubsubEventPayload {
	return {
		attendant_id: event.attendantId,
		action: event.action,
		created_at: event.createdAt,
	}
}

export function decodeAttendantTypingPubsubEventPayload(
	event: AttendantTypingPubsubEventPayload
): {
	attendantId: string
	action: 'set' | 'clear'
	createdAt: string
} {
	return {
		attendantId: event.attendant_id,
		action: event.action,
		createdAt: event.created_at,
	}
}

export const selectConversationById =
	conversationsAdapter.getSelectors<StoreState>(
		state => state.typingIndicators
	).selectById

export const selectAllAttendantsTypingForConversationState =
	attendantsAdapter.getSelectors().selectAll

export const selectAllAttendantsTypingForConversationId =
	(conversationId: string) => (state: StoreState) => {
		const conversationState = selectConversationById(state, conversationId)
		if (!conversationState) {
			return
		}
		return selectAllAttendantsTypingForConversationState(
			conversationState.attendantsTyping
		)
	}

export default typingIndicators
