import { datadogRum } from '@datadog/browser-rum'
import { select, take } from 'typed-redux-saga/macro'

import auth from '../slices/auth'

export default function* datadogSaga() {
	while (true) {
		const config = yield* select(state => state.config)

		if (
			config.datadogApplicationId &&
			config.datadogClientToken &&
			config.datadogEnv &&
			!config.datadogEnv.startsWith('mock')
		) {
			datadogRum.init({
				applicationId: config.datadogApplicationId,
				clientToken: config.datadogClientToken,
				site: 'datadoghq.com',
				service: 'webapp',
				sampleRate: 100,
				trackInteractions: true,
				env: config.datadogEnv,
				allowedTracingOrigins: [
					config.apiRoot,
					`https://${config.statsEndpoint}`,
				],
			})
		}

		yield* take(auth.actions.signOutSuccess.match)
	}
}
