import './augmentations'
import 'react-app-polyfill/stable'
import { ApolloProvider } from '@apollo/client'
import { CloudinaryContext } from 'cloudinary-react'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { createBrowserHistory } from 'history'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Client as Styletron } from 'styletron-engine-atomic'

import configureClient from './network/graphql/configureClient'

import Config from './contexts/config'
import { ApiClient } from './network/api/apiClient'
import { FetchManager } from './network/api/fetchManager'
import { AuthClient } from './network/auth/authClient'
import Routes from './routes'
import sagaContext from './slices/sagaContext'
import configureStore from './store/configureStore'
import StyledApp from './styles/StyledApp'

// Config is set as a global in the HTML template by Caddy when deployed
// or hardcoded locally
const config = window.config

const authClient = new AuthClient(
	new FetchManager(),
	`${config.apiRoot}/api/auth`
)
const apiClient = new ApiClient(new FetchManager(), authClient, config.apiRoot)

const gqlClient = configureClient(
	config,
	authClient.getUserAccessToken.bind(authClient)
)

const history = createBrowserHistory()

const store = configureStore(
	{
		config,
	},
	{ apiClient, authClient, gqlClient },
	history
)

store.dispatch(
	sagaContext.actions.setSagaContext({ gqlClient, apiClient, authClient })
)

const engine = new Styletron()

ReactDOM.render(
	<StrictMode>
		<Config.Provider value={config}>
			<CloudinaryContext cloudName={config.cloudinaryCloudName} includeOwnBody>
				<ApolloProvider client={gqlClient}>
					<Provider store={store}>
						<StyledApp styletronEngine={engine}>
							<ConnectedRouter history={history}>
								<Routes />
							</ConnectedRouter>
						</StyledApp>
					</Provider>
				</ApolloProvider>
			</CloudinaryContext>
		</Config.Provider>
	</StrictMode>,
	document.getElementById('app-root')
)
