import { FC } from 'react'

import Icon, { IconProps } from '../SVGs/Icon'

const ArrowLeft: FC<IconProps> = props => (
	<Icon viewBox="0 0 12 20" size="20" {...props}>
		<g fill="none" fillRule="evenodd">
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
				d="M10 2l-8 8 8 8"
			/>
		</g>
	</Icon>
)

export default ArrowLeft
