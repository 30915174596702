import styled from 'styled-components'

import Button from '../AKButton'
import { FootnoteRegular } from '../AKTypography'

import { withEllipsisTextOverflow } from '../utils'

export const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
`

export const ErrorMessage = styled(FootnoteRegular)`
	flex: 1 1 100%;

	display: flex;

	color: ${({ theme }) => theme.colors.contentNegative};

	${withEllipsisTextOverflow}
`

export const StyledButton = styled(Button)`
	margin: ${({ theme }) => theme.sizing.scale100};
`
