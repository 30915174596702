import { AnyAction } from 'redux'
import { buffers, eventChannel, END } from 'redux-saga'
import { take, call, put, takeEvery, putResolve } from 'typed-redux-saga/macro'

import { getFileSignature } from '../actions/files'

import files from '../slices/files'
import { getCloudinaryUploadUrl } from '../utils/cloudinary'

type CloudinaryChannelPayload =
	| { err: Error }
	| { progress: number }
	| { success: { response: any } }

// Upload the specified file
// TODO types for cloudinary options
export function* uploadFileSaga(
	resource: File | Blob | string,
	objectURL: string,
	options?: { transformation?: string }
) {
	const { type, response } = yield* putResolve<{
		type: string
		response?: any
	}>(getFileSignature(options))

	if (type !== files.actions.fileSignatureSuccess.type) {
		yield* put(
			files.actions.uploadFailure({
				objectURL,
				error: new Error(response).message,
			})
		)
		return
	}

	const cloudinaryOptions = { ...options, ...response }

	const cloudinaryChannel = yield* call(
		createUploadToCloudinaryChannel,
		getCloudinaryUploadUrl(),
		resource,
		cloudinaryOptions
	)
	while (true) {
		const event = yield* take(cloudinaryChannel)

		if ('err' in event) {
			yield* put(
				files.actions.uploadFailure({
					objectURL,
					error: event.err.message,
				})
			)
		} else if ('success' in event) {
			yield* put(
				files.actions.uploadSuccess({
					objectURL,
					response: event.success.response,
				})
			)
			return
		} else {
			yield* put(
				files.actions.uploadProgress({ objectURL, progress: event.progress })
			)
		}
	}
}

// Watch for an upload request and then
// defer to another saga to perform the actual upload
export function* uploadRequestWatcherSaga() {
	yield* takeEvery(
		files.actions.uploadRequest.type,
		function* (action: AnyAction) {
			if (files.actions.uploadRequest.match(action)) {
				const { options, resource, objectURL } = action.payload
				yield* call(uploadFileSaga, resource, objectURL, options)
			}
		}
	)
}

export function createUploadToCloudinaryChannel(
	endpoint: string,
	file: File | Blob | string,
	options?: any
) {
	// Creates channel that will subscribe to an event source
	// using the subscribe method. Incoming events from the event source
	// will be queued in the channel buffer until
	// interested takers are registered.
	return eventChannel<CloudinaryChannelPayload>(emitter => {
		const xhr = new XMLHttpRequest()

		const onProgress = (e: ProgressEvent) => {
			if (e.lengthComputable) {
				const progress = e.loaded / e.total
				emitter({ progress })
			}
		}
		const onFailure = (e: ProgressEvent) => {
			emitter({ err: new Error('Upload failed') })
			emitter(END)
		}

		xhr.upload.addEventListener('progress', onProgress)
		xhr.upload.addEventListener('error', onFailure)
		xhr.upload.addEventListener('abort', onFailure)

		xhr.onreadystatechange = () => {
			const { readyState, status } = xhr
			if (readyState === 4) {
				const response = JSON.parse(xhr.response)
				if (status === 200) {
					emitter({ success: { response } })
					emitter(END)
				} else {
					emitter({ err: new Error(response?.error?.message) })
					emitter(END)
				}
			}
		}
		xhr.open('POST', endpoint, true)

		const data = new FormData()
		data.append('file', file)

		// add cloudinary signature options
		if (options) {
			data.append('upload_preset', options.upload_preset)
			data.append('signature', options.signature)
			data.append('api_key', options.api_key)
			data.append('timestamp', options.timestamp)
			if (options.format) {
				data.append('format', options.format)
			}
			if (options.transformation) {
				data.append('transformation', options.transformation)
			}
		}

		xhr.send(data)

		// this is clean-up callback that will be executed,
		// after END instruction gets emitted
		return () => {
			xhr.upload.removeEventListener('progress', onProgress)
			xhr.upload.removeEventListener('error', onFailure)
			xhr.upload.removeEventListener('abort', onFailure)
			xhr.onreadystatechange = null
			xhr.abort()
		}

		// defines channel buffer size and strategy
		// sliding buffer will insert the new message at the end
		// and drop the oldest message in the buffer.
	}, buffers.sliding(2))
}
