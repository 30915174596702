export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
	{ [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
	{ [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	/** Date custom scalar type */
	Date: Maybe<string>
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSON: any
}

export type AcceptInvitePayload = {
	__typename: 'AcceptInvitePayload'
	invite: Invite
	sphere?: Maybe<Sphere>
}

export type Actions = {
	__typename: 'Actions'
	dismissals: Array<Maybe<Scalars['String']>>
	acknowledgements?: Maybe<Array<Maybe<Scalars['String']>>>
	poll_votes?: Maybe<Array<Maybe<PollVote>>>
}

export type AddAgentSettingsPayload = {
	__typename: 'AddAgentSettingsPayload'
	agentFeatureSettings: Array<Maybe<FeatureSetting>>
}

export type Admin = {
	__typename: 'Admin'
	/** Get all spheres */
	spheres: SphereList
	/** Get all cards */
	cards: ChatCardList
	/** Get card by id */
	card?: Maybe<ChatCard>
	/** Get all users */
	users: UserList
	/** Get user by id */
	user?: Maybe<User>
}

export type AdminSpheresArgs = {
	paging?: Maybe<PagingInput>
	search?: Maybe<SphereSearchInput>
}

export type AdminCardsArgs = {
	paging?: Maybe<PagingInput>
}

export type AdminCardArgs = {
	card_id: Scalars['ID']
}

export type AdminUsersArgs = {
	paging?: Maybe<PagingInput>
	search?: Maybe<UserSearchInput>
}

export type AdminUserArgs = {
	userId: Scalars['ID']
}

export type AdminPinboard = {
	__typename: 'AdminPinboard'
	cardId: Scalars['ID']
	conversationId: Scalars['ID']
}

export type Agent = {
	__typename: 'Agent'
	id: Scalars['ID']
	type?: Maybe<Scalars['String']>
	name?: Maybe<Scalars['String']>
	color?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
	person?: Maybe<Person>
	created_at: Scalars['Date']
}

export type AgentsPushInput = {
	agents: Array<Maybe<Scalars['ID']>>
	text: Scalars['String']
	title?: Maybe<Scalars['String']>
	customPayload?: Maybe<Scalars['JSON']>
}

export type AnonymousQuestion = {
	__typename: 'AnonymousQuestion'
	id: Scalars['ID']
	createdAt: Scalars['Date']
	updatedAt?: Maybe<Scalars['Date']>
	sphereId: Scalars['ID']
	questionTitle: Scalars['String']
	questionContent: Scalars['String']
	chatType: AnonymousQuestionChatType
	invitedAgentIds: Array<Maybe<Scalars['String']>>
	action?: Maybe<AnonymousQuestionAction>
	actionedByAgentId?: Maybe<Scalars['String']>
}

export enum AnonymousQuestionAction {
	Approved = 'APPROVED',
	Rejected = 'REJECTED',
}

export enum AnonymousQuestionChatType {
	Public = 'PUBLIC',
	Private = 'PRIVATE',
}

export type AppInvite = Invite & {
	__typename: 'AppInvite'
	id: Scalars['ID']
	firebaseShortLink?: Maybe<Scalars['String']>
	firebaseShortLinkToStore?: Maybe<Scalars['String']>
	firebaseDeepLink?: Maybe<Scalars['String']>
	inviteCode: Scalars['String']
	email: Scalars['String']
	inviter?: Maybe<Agent>
	sphere?: Maybe<Sphere>
	expiresAt?: Maybe<Scalars['Date']>
}

export type Appreciation = {
	__typename: 'Appreciation'
	id: Scalars['ID']
	sphereId: Scalars['ID']
	name: Scalars['String']
	emoji: Emoji
	color: Scalars['String']
	active: Scalars['Boolean']
	description: Scalars['String']
	creatorInfo?: Maybe<AppreciationCreatorInfo>
}

export type AppreciationCreatorInfo = {
	__typename: 'AppreciationCreatorInfo'
	name: Scalars['String']
}

export type AppreciationInput = {
	name: Scalars['String']
	emojiId: Scalars['String']
	description?: Maybe<Scalars['String']>
}

export type Attachment = {
	__typename: 'Attachment'
	attachmentType: AttachmentType
	/** Unique identifier used to download the file */
	fileId: Scalars['ID']
	/** Original file name */
	fileName: Scalars['String']
	/** File type (see https://en.wikipedia.org/wiki/Media_type) */
	mimeType: Scalars['String']
	/** File size in bytes */
	fileSize: Scalars['Int']
	/** Resource type returned from cloudinary */
	resourceType: Scalars['String']
	dimensions?: Maybe<VideoDimensions>
}

export type AttachmentInput = {
	attachmentType?: Maybe<AttachmentType>
	/** Unique identifier used to download the file */
	fileId: Scalars['ID']
	/** Original file name */
	fileName: Scalars['String']
	/** File type (see https://en.wikipedia.org/wiki/Media_type) */
	mimeType: Scalars['String']
	/** File size in bytes */
	fileSize: Scalars['Int']
	/** Resource type returned from cloudinary */
	resourceType: Scalars['String']
}

export enum AttachmentType {
	Image = 'IMAGE',
	Video = 'VIDEO',
	Raw = 'RAW',
	AnimatedImage = 'ANIMATED_IMAGE',
}

export type Attendant = {
	__typename: 'Attendant'
	id: Scalars['ID']
	conversation_id: Scalars['ID']
	follows_conversation: Scalars['Boolean']
	agent_id: Scalars['ID']
	agent: Agent
	display_name: Scalars['String']
	display_color: Scalars['String']
	display_image_filename?: Maybe<Scalars['String']>
	/** @deprecated latest_seen_message_id is deprecated */
	latest_seen_message_id?: Maybe<Scalars['ID']>
	muted: Scalars['Boolean']
	muted_until?: Maybe<Scalars['Date']>
	role: ConversationRole
	/** @deprecated use unread_message_count instead */
	has_new_activity: Scalars['Boolean']
	unread_message_count: Scalars['Int']
	last_seen_message_id?: Maybe<Scalars['ID']>
	outstanding_important_messages: Array<Maybe<Scalars['String']>>
	capabilities: ChatCapabilities
}

export type AttendantInfo = {
	__typename: 'AttendantInfo'
	attendant_id: Scalars['String']
	display_name: Scalars['String']
	display_color: Scalars['String']
	display_image_filename?: Maybe<Scalars['String']>
	conversationId: Scalars['String']
	role: ConversationRole
	bio?: Maybe<Scalars['String']>
	last_seen?: Maybe<Scalars['Date']>
	online: Scalars['Boolean']
	canBeDirectMessagedByViewer: Scalars['Boolean']
	canBeRemovedByViewer: Scalars['Boolean']
}

export type AttendantPresence = {
	__typename: 'AttendantPresence'
	attendant_id: Scalars['ID']
	last_seen?: Maybe<Scalars['Date']>
	online: Scalars['Boolean']
}

export type Capability = {
	__typename: 'Capability'
	permitted: Scalars['Boolean']
}

export type ChatCapabilities = {
	__typename: 'ChatCapabilities'
	addMembersToChat: Capability
	removeAttendantsFromChat: Capability
	unfollowChat: Capability
	setChatTitle: Capability
	setChatImage: Capability
	endChat: Capability
	sendMessage: Capability
	reactToMessage: Capability
	actionImportantMessage: Capability
	pinMessageToAdminPinboard: Capability
	removeOwnMessage: Capability
	removeAnyMessage: Capability
	muteConversation: Capability
	replyToMessage: Capability
	addAppreciationReactionToMessage: Capability
	removeAppreciationReactionFromMessage: Capability
	sendPollMessage: SendPollMessageCapability
	sendAnnouncementMessage: Capability
}

/** Conversation representation */
export type ChatCard = {
	__typename: 'ChatCard'
	/** (currently query id) */
	id: Scalars['ID']
	chat_type: ChatType
	chat_state: ChatState
	private: Scalars['Boolean']
	/** @deprecated use conversationType instead */
	dm: Scalars['Boolean']
	/** When chat card was created */
	created_at: Scalars['Date']
	is_new: Scalars['Boolean']
	/**
	 * When the conversation was started
	 * @deprecated Field no longer supported
	 */
	started_at?: Maybe<Scalars['Date']>
	/**
	 * Expiration time for ephemeral chats
	 * @deprecated Field no longer supported
	 */
	expires_at?: Maybe<Scalars['Date']>
	title: Scalars['String']
	description: Scalars['String']
	image_filename?: Maybe<Scalars['String']>
	image_color?: Maybe<Scalars['String']>
	/** Viewer's attendant (can be null) */
	attendant?: Maybe<Attendant>
	/** Card conversation id */
	conversation_id?: Maybe<Scalars['ID']>
	/** Card conversation */
	conversation?: Maybe<Conversation>
	/** User who created this card */
	creator?: Maybe<Agent>
	/** Spheres this card belongs to */
	spheres?: Maybe<Array<Sphere>>
	/** @deprecated use conversationType instead */
	isEventConversation: Scalars['Boolean']
	sphereEventId?: Maybe<Scalars['String']>
	conversationType: ConversationType
	/** @deprecated Field no longer supported */
	public: Scalars['Boolean']
	/** @deprecated Field no longer supported */
	publish_at?: Maybe<Scalars['Date']>
	creator_id?: Maybe<Scalars['ID']>
	/**
	 * Number of users subscribed to this card
	 * @deprecated Field no longer supported
	 */
	subscriber_count?: Maybe<Scalars['Int']>
	/**
	 * Is viewer subscribed to this card
	 * @deprecated Field no longer supported
	 */
	viewer_is_subscribed?: Maybe<Scalars['Boolean']>
	/**
	 * Intro prompt users see when they join conversation
	 * @deprecated Field no longer supported
	 */
	user_intro_encouragement?: Maybe<Scalars['String']>
	/** @deprecated Field no longer supported */
	vetted_state?: Maybe<VettedState>
}

export type ChatCardList = {
	__typename: 'ChatCardList'
	/** array of chat cards */
	data?: Maybe<Array<ChatCard>>
	/** pagination info (currently half baked) */
	paging: Paging
}

export enum ChatState {
	/** Chat is created but not started */
	Upcoming = 'UPCOMING',
	/** Chat is scheduled for start */
	Scheduled = 'SCHEDULED',
	/** Chat is started */
	Live = 'LIVE',
	/** Chat is ended */
	Ended = 'ENDED',
}

export enum ChatType {
	/** Normal persistent chat */
	Persistent = 'PERSISTENT',
	/** Chat that has expiration time (aka 24h chat) */
	Ephimeral = 'EPHIMERAL',
}

export type ClientMessageInput = {
	unique_id: Scalars['String']
	content: MessageContentInput
	reply_to_message_id?: Maybe<Scalars['String']>
	mentions?: Maybe<Array<Maybe<UserMentionInput>>>
	important?: Maybe<Scalars['Boolean']>
	actionable_until?: Maybe<Scalars['Date']>
	permits_multiple_poll_votes?: Maybe<Scalars['Boolean']>
}

export type Conversation = {
	__typename: 'Conversation'
	id: Scalars['ID']
	/** Number of users participating in this conversation */
	attendant_count: Scalars['Int']
	/** Last message in this conversation */
	last_message?: Maybe<Message>
	activity_info?: Maybe<ConversationActivityInfo>
	/** The jwt token to access the socket channel of the conversation */
	socket_channel_token: Scalars['String']
	attendant_info: AttendantInfo
	attendants_info: Array<AttendantInfo>
	stats: ConversationStats
	sticky_notes: Array<Maybe<StickyNote>>
}

export type ConversationAttendantInfoArgs = {
	attendant_id: Scalars['ID']
}

export type ConversationStickyNotesArgs = {
	attendant_id: Scalars['ID']
}

export type ConversationActivityInfo = {
	__typename: 'ConversationActivityInfo'
	recently_active: Scalars['Int']
}

export enum ConversationRole {
	ConversationCreator = 'CONVERSATION_CREATOR',
	ConversationParticipant = 'CONVERSATION_PARTICIPANT',
}

export type ConversationStats = {
	__typename: 'ConversationStats'
	reactions: ReactionStats
	words: Array<Maybe<WordStats>>
}

export enum ConversationType {
	Channel = 'CHANNEL',
	Dm = 'DM',
	Event = 'EVENT',
	Pinboard = 'PINBOARD',
}

/** Chat card creation from admin */
export type CreateCardInput = {
	chat_type: ChatType
	chat_state: ChatState
	public: Scalars['Boolean']
	publish_at?: Maybe<Scalars['Date']>
	vetted_state?: Maybe<VettedState>
	title: Scalars['String']
	description: Scalars['String']
	image_filename?: Maybe<Scalars['String']>
	creator_id: Scalars['ID']
}

/** Chat card creation for users */
export type CreateChatInput = {
	chat_type?: Maybe<ChatType>
	title: Scalars['String']
	description?: Maybe<Scalars['String']>
	image_filename: Scalars['String']
	sphere_id?: Maybe<Scalars['ID']>
	private?: Maybe<Scalars['Boolean']>
	members?: Maybe<Array<Scalars['ID']>>
	firstMessageTextContent?: Maybe<Scalars['String']>
}

export type CreateSphereInput = {
	title: Scalars['String']
	description?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
	public: Scalars['Boolean']
}

export type DeleteSphereEventCapability = {
	__typename: 'DeleteSphereEventCapability'
	permitted: Scalars['Boolean']
}

export type Device = {
	__typename: 'Device'
	id: Scalars['ID']
	type: DeviceType
	token: Scalars['ID']
}

export enum DeviceType {
	Ios = 'IOS',
	Android = 'ANDROID',
	Test = 'TEST',
}

export type EditSphereEventCapability = {
	__typename: 'EditSphereEventCapability'
	permitted: Scalars['Boolean']
}

export type EditSphereEventInput = {
	name: Scalars['String']
	startDate: Scalars['Date']
	displayImageId?: Maybe<Scalars['String']>
	endDate?: Maybe<Scalars['Date']>
	location?: Maybe<SphereEventLocationInput>
	description?: Maybe<Scalars['String']>
	link?: Maybe<Scalars['String']>
}

export type Emoji = {
	__typename: 'Emoji'
	id: Scalars['ID']
	unicode?: Maybe<Scalars['String']>
	name: Scalars['String']
	category: Scalars['String']
	imageId: Scalars['String']
}

export type EmojiList = {
	__typename: 'EmojiList'
	data: Array<Maybe<Emoji>>
	paging?: Maybe<EmojiPaging>
}

export type EmojiPaging = {
	__typename: 'EmojiPaging'
	start?: Maybe<Scalars['ID']>
	end?: Maybe<Scalars['ID']>
	hasNextPage: Scalars['Boolean']
}

export type EmojisFilterInput = {
	searchTerm?: Maybe<Scalars['String']>
}

export type FeatureSetting = {
	__typename: 'FeatureSetting'
	name: Scalars['String']
	value: Scalars['String']
}

export type FilterInput = {
	canBeDirectMessagedByViewer?: Maybe<Scalars['Boolean']>
	canBeAddedToConversation?: Maybe<Scalars['ID']>
}

export type Gate = {
	__typename: 'Gate'
	id: Scalars['ID']
}

export type ImageDimensions = {
	__typename: 'ImageDimensions'
	width: Scalars['Int']
	height: Scalars['Int']
}

export type ImagePreview = {
	__typename: 'ImagePreview'
	url?: Maybe<Scalars['String']>
}

export type ImageResource = {
	__typename: 'ImageResource'
	url: Scalars['String']
	width: Scalars['Int']
	height: Scalars['Int']
}

export type ImageSearchResult = {
	__typename: 'ImageSearchResult'
	source: ImageResource
	thumbnail: ImageResource
}

export type ImageSearchResults = {
	__typename: 'ImageSearchResults'
	data: Array<Maybe<ImageSearchResult>>
	paging: Paging
}

export type Invite = {
	id: Scalars['ID']
	firebaseShortLink?: Maybe<Scalars['String']>
	firebaseShortLinkToStore?: Maybe<Scalars['String']>
	firebaseDeepLink?: Maybe<Scalars['String']>
	inviteCode: Scalars['String']
	/** @deprecated moved to SphereInvite */
	sphere?: Maybe<Sphere>
	inviter?: Maybe<Agent>
	expiresAt?: Maybe<Scalars['Date']>
}

export type LinkPreview = {
	__typename: 'LinkPreview'
	url: Scalars['String']
	originalURL: Scalars['String']
	image?: Maybe<Array<Maybe<ImagePreview>>>
	publisher: Scalars['String']
	title?: Maybe<Scalars['String']>
	description?: Maybe<Scalars['String']>
	logo?: Maybe<Scalars['String']>
}

export type LoginPassword = {
	__typename: 'LoginPassword'
	id: Scalars['ID']
	personId: Scalars['ID']
	email?: Maybe<Scalars['String']>
	createdAt: Scalars['Date']
	updatedAt?: Maybe<Scalars['Date']>
}

/**
 * Message representation
 * only minimal amount of fields is exposed for now
 */
export type Message = {
	__typename: 'Message'
	id: Scalars['ID']
	created_at: Scalars['Date']
	updated_at?: Maybe<Scalars['Date']>
	unique_id: Scalars['ID']
	/** Message type */
	type: MessageType
	/** Service message type */
	service_message_type?: Maybe<ServiceMessageType>
	/** Message sender */
	agent: Agent
	/** Message content */
	content: MessageContent
	sender_info?: Maybe<SenderInfo>
	/** Array of reactions to this message */
	reactions?: Maybe<Array<Maybe<MessageReaction>>>
	/** Message this was reply to */
	reply_to_message?: Maybe<Message>
	/** Array of user mentions in this message */
	user_mentions?: Maybe<Array<Maybe<UserMention>>>
	important?: Maybe<Scalars['Boolean']>
	actionable_until?: Maybe<Scalars['Date']>
	permits_multiple_poll_votes?: Maybe<Scalars['Boolean']>
	actions?: Maybe<Actions>
	permits_action_from_sender?: Maybe<Scalars['Boolean']>
	linkPreviews?: Maybe<Array<LinkPreview>>
	syncedShareInfo: SyncedShareInfo
}

export type MessageContent = {
	__typename: 'MessageContent'
	type: MessageContentType
	text?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
	image_dimensions?: Maybe<ImageDimensions>
	poll_options?: Maybe<Array<Maybe<PollOption>>>
	attachments?: Maybe<Array<Attachment>>
}

export type MessageContentInput = {
	text?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
	poll_options?: Maybe<Array<Maybe<Scalars['String']>>>
	attachments?: Maybe<Array<AttachmentInput>>
}

export enum MessageContentType {
	Text = 'TEXT',
	Image = 'IMAGE',
	Poll = 'POLL',
}

export type MessageReaction = {
	__typename: 'MessageReaction'
	attendant_id?: Maybe<Scalars['String']>
	display_name?: Maybe<Scalars['String']>
	type: MessageReactionType
	agent_id: Scalars['ID']
	agent: Agent
	emojiId?: Maybe<Scalars['String']>
	emojiImageId?: Maybe<Scalars['String']>
	emojiUnicode?: Maybe<Scalars['String']>
	color?: Maybe<Scalars['String']>
	appreciationId?: Maybe<Scalars['String']>
	appreciationName?: Maybe<Scalars['String']>
}

export enum MessageReactionType {
	Anger = 'ANGER',
	Joy = 'JOY',
	Adoration = 'ADORATION',
	Excitement = 'EXCITEMENT',
	Like = 'LIKE',
	Sadness = 'SADNESS',
	Emoji = 'EMOJI',
	Appreciation = 'APPRECIATION',
}

export enum MessageType {
	ServiceMessage = 'SERVICE_MESSAGE',
	ClientMessage = 'CLIENT_MESSAGE',
}

export enum Mood {
	Sad = 'SAD',
	Eating = 'EATING',
	Happy = 'HAPPY',
	WhatsappFan = 'WHATSAPP_FAN',
	Pumped = 'PUMPED',
	Annoyed = 'ANNOYED',
	NeedInspiration = 'NEED_INSPIRATION',
	Mindful = 'MINDFUL',
}

export type Mutation = {
	__typename: 'Mutation'
	/**
	 * Create a new user with email and name, send verification email.
	 * returns a (deprecated) string that should be ignored. It used to be a
	 * verification token that is not used anymore.
	 * inviteId is optional, but if provided, it must be a valid inviteId,
	 * otherwise an error is thrown. If provided, the invite will be accepted by
	 * the created agent.
	 */
	signUpWithEmailAndName: Scalars['String']
	/** Set email and name of a shadow, send verification email */
	setLoginDetails: Scalars['Boolean']
	/** Update user (can update both agent and person under the hood) */
	updateUser: Agent
	createUser: User
	removeUser: Scalars['Boolean']
	setAgentFeatureSetting: AddAgentSettingsPayload
	sendMessage: Message
	removeMessage: Scalars['Boolean']
	addEmojiReactionToMessage: Message
	removeEmojiReactionFromMessage: Message
	addAppreciationReactionToMessage: Message
	removeAppreciationReactionFromMessage: Message
	addAppreciationToSphere: Appreciation
	disableAppreciationOnSphere: Scalars['Boolean']
	reactToMessage: Message
	removeMessageReaction: Scalars['Boolean']
	markLatestMessageAsSeen: Scalars['Boolean']
	/** A mutation to perform an action on an announcement message */
	performActionOnAnnouncementMessage: Scalars['Boolean']
	/** A mutation to perform an action on a poll message */
	performActionOnPollMessage: Scalars['Boolean']
	/** A mutation to trigger a reminder for all attendants who have not actioned the announcement message */
	remindAttendantsToPerformActionOnMessage: Scalars['Boolean']
	/** A mutation to trigger a reminder for a single attendant who has not actioned the announcement message */
	remindAttendantToPerformActionOnMessage: Scalars['Boolean']
	/** A mutation to create a sticky note */
	createStickyNote: Scalars['Boolean']
	/** A mutation to remove a sticky note */
	removeStickyNote: Scalars['Boolean']
	/** join chat */
	join?: Maybe<ChatCard>
	/** join a conversation without following it */
	joinConversation: Attendant
	followConversation?: Maybe<Scalars['Boolean']>
	unfollowConversation?: Maybe<Scalars['Boolean']>
	/**
	 * leave chat
	 * @deprecated Field no longer supported
	 */
	leave?: Maybe<ChatCard>
	/** @deprecated Field no longer supported */
	subscribeToCard?: Maybe<ChatCard>
	/** @deprecated Field no longer supported */
	unsubscribeFromCard?: Maybe<ChatCard>
	mute?: Maybe<ChatCard>
	unmute?: Maybe<ChatCard>
	createChat: ChatCard
	updateChat: ChatCard
	endChat: ChatCard
	addAgentToChat: Scalars['Boolean']
	removeAgentFromChat: Scalars['Boolean']
	archiveSphereChat?: Maybe<Attendant>
	archiveSphereEvent?: Maybe<Attendant>
	archiveSphereDm: Attendant
	joinSphere: Sphere
	leaveSphere: Sphere
	createSphere: Sphere
	updateSphere: Sphere
	/** @deprecated Field no longer supported */
	markSphereAsSeen: Sphere
	updateSphereMember: SphereMember
	removeSphereMember: Scalars['Boolean']
	addMembersToSphereConversation: Array<AttendantInfo>
	removeAttendantsFromSphereConversation: Array<AttendantInfo>
	adminUpdateSphereMember: SphereMember
	adminRemoveSphereMember: Scalars['Boolean']
	setSphereFeatureSetting?: Maybe<SetSphereFeatureSettingPayload>
	createSphereEvent: SphereEvent
	editSphereEvent: SphereEvent
	remindInviteesToRSVPSphereEvent?: Maybe<Scalars['Boolean']>
	remindInviteeToRSVPSphereEvent?: Maybe<Scalars['Boolean']>
	rsvpSphereEvent: SphereEvent
	deleteSphereEvent: Scalars['Boolean']
	pinMessageToPinboard: PinMessageToPinboardOutput
	createAnonymousQuestion?: Maybe<AnonymousQuestion>
	actionAnonymousQuestion?: Maybe<AnonymousQuestion>
	/** @deprecated use acceptInvite */
	acceptSphereInvite: Sphere
	/** @deprecated use acceptInviteByCode */
	acceptSphereInviteByCode: Sphere
	acceptInvite: AcceptInvitePayload
	acceptInviteByCode: AcceptInvitePayload
	createSphereInvite: SphereInvite
	sendPushToAgents: Scalars['Boolean']
	/**
	 * Create chat card from admin
	 * @deprecated Field no longer supported
	 */
	createCard: ChatCard
	/** Update chat card from admin */
	updateCard: ChatCard
	/** Archive (delete) chat card */
	archiveCard: ChatCard
	/**
	 * Add multipe agents to chat card
	 * @deprecated Field no longer supported
	 */
	addAgentsToCard: Scalars['Boolean']
	/** Add multiple agents to sphere */
	addAgentsToSphere: Scalars['Boolean']
	/** Turn user into a god user */
	makeGod: Scalars['Boolean']
	createLoginTokenURL: Scalars['String']
	setLoginPassword: LoginPassword
}

export type MutationSignUpWithEmailAndNameArgs = {
	email: Scalars['String']
	name: Scalars['String']
	inviteId?: Maybe<Scalars['String']>
}

export type MutationSetLoginDetailsArgs = {
	email: Scalars['String']
	name: Scalars['String']
}

export type MutationUpdateUserArgs = {
	agent_id: Scalars['ID']
	input: UpdateUserInput
}

export type MutationCreateUserArgs = {
	name: Scalars['String']
	email: Scalars['String']
}

export type MutationRemoveUserArgs = {
	userId: Scalars['ID']
}

export type MutationSetAgentFeatureSettingArgs = {
	name: Scalars['String']
	value: Scalars['String']
}

export type MutationSendMessageArgs = {
	conversation_id: Scalars['ID']
	input: ClientMessageInput
}

export type MutationRemoveMessageArgs = {
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
}

export type MutationAddEmojiReactionToMessageArgs = {
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	emojiId: Scalars['ID']
}

export type MutationRemoveEmojiReactionFromMessageArgs = {
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	emojiId: Scalars['ID']
}

export type MutationAddAppreciationReactionToMessageArgs = {
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	appreciationId: Scalars['ID']
}

export type MutationRemoveAppreciationReactionFromMessageArgs = {
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	appreciationId: Scalars['ID']
}

export type MutationAddAppreciationToSphereArgs = {
	sphereId: Scalars['ID']
	appreciationInput?: Maybe<AppreciationInput>
}

export type MutationDisableAppreciationOnSphereArgs = {
	sphereId: Scalars['ID']
	appreciationId: Scalars['ID']
}

export type MutationReactToMessageArgs = {
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
	reaction_type: MessageReactionType
}

export type MutationRemoveMessageReactionArgs = {
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
	reaction_type: MessageReactionType
}

export type MutationMarkLatestMessageAsSeenArgs = {
	conversation_id: Scalars['ID']
}

export type MutationPerformActionOnAnnouncementMessageArgs = {
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
	acknowledge?: Maybe<Scalars['Boolean']>
	dismiss?: Maybe<Scalars['Boolean']>
}

export type MutationPerformActionOnPollMessageArgs = {
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
	add_vote_for_options?: Maybe<Array<Maybe<Scalars['String']>>>
	remove_vote_for_options?: Maybe<Array<Maybe<Scalars['String']>>>
	dismiss?: Maybe<Scalars['Boolean']>
}

export type MutationRemindAttendantsToPerformActionOnMessageArgs = {
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
}

export type MutationRemindAttendantToPerformActionOnMessageArgs = {
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
	attendant_id: Scalars['ID']
}

export type MutationCreateStickyNoteArgs = {
	conversation_id: Scalars['ID']
	attendant_id: Scalars['ID']
	content: Scalars['String']
}

export type MutationRemoveStickyNoteArgs = {
	conversation_id: Scalars['ID']
	attendant_id: Scalars['ID']
	content: Scalars['String']
}

export type MutationJoinArgs = {
	card_id: Scalars['ID']
}

export type MutationJoinConversationArgs = {
	conversation_id: Scalars['ID']
}

export type MutationFollowConversationArgs = {
	conversation_id: Scalars['ID']
}

export type MutationUnfollowConversationArgs = {
	conversation_id: Scalars['ID']
}

export type MutationLeaveArgs = {
	card_id: Scalars['ID']
}

export type MutationSubscribeToCardArgs = {
	card_id: Scalars['ID']
}

export type MutationUnsubscribeFromCardArgs = {
	card_id: Scalars['ID']
}

export type MutationMuteArgs = {
	card_id: Scalars['ID']
	muted_until?: Maybe<Scalars['String']>
}

export type MutationUnmuteArgs = {
	card_id: Scalars['ID']
}

export type MutationCreateChatArgs = {
	input?: Maybe<CreateChatInput>
}

export type MutationUpdateChatArgs = {
	card_id: Scalars['ID']
	input?: Maybe<UpdateChatInput>
}

export type MutationEndChatArgs = {
	card_id: Scalars['ID']
}

export type MutationAddAgentToChatArgs = {
	agent_id: Scalars['ID']
	card_id: Scalars['ID']
}

export type MutationRemoveAgentFromChatArgs = {
	agent_id: Scalars['ID']
	card_id: Scalars['ID']
}

export type MutationArchiveSphereChatArgs = {
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
}

export type MutationArchiveSphereEventArgs = {
	sphereId: Scalars['ID']
	eventId: Scalars['ID']
}

export type MutationArchiveSphereDmArgs = {
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
}

export type MutationJoinSphereArgs = {
	sphere_id: Scalars['ID']
}

export type MutationLeaveSphereArgs = {
	sphere_id: Scalars['ID']
}

export type MutationCreateSphereArgs = {
	input: CreateSphereInput
}

export type MutationUpdateSphereArgs = {
	sphere_id: Scalars['ID']
	input: UpdateSphereInput
}

export type MutationMarkSphereAsSeenArgs = {
	sphere_id: Scalars['ID']
}

export type MutationUpdateSphereMemberArgs = {
	sphere_id: Scalars['ID']
	input: SphereMemberInput
}

export type MutationRemoveSphereMemberArgs = {
	sphere_id: Scalars['ID']
	agent_id: Scalars['ID']
}

export type MutationAddMembersToSphereConversationArgs = {
	agentIds: Array<Scalars['ID']>
	conversationId: Scalars['ID']
	sphereId?: Maybe<Scalars['ID']>
}

export type MutationRemoveAttendantsFromSphereConversationArgs = {
	attendantIds: Array<Scalars['ID']>
	conversationId: Scalars['ID']
	sphereId?: Maybe<Scalars['ID']>
}

export type MutationAdminUpdateSphereMemberArgs = {
	sphere_id: Scalars['ID']
	input: SphereMemberInput
}

export type MutationAdminRemoveSphereMemberArgs = {
	sphere_id: Scalars['ID']
	agent_id: Scalars['ID']
}

export type MutationSetSphereFeatureSettingArgs = {
	sphere_id: Scalars['ID']
	setting_name: Scalars['String']
	setting_value: Scalars['String']
}

export type MutationCreateSphereEventArgs = {
	sphereId: Scalars['ID']
	eventInput: SphereEventInput
}

export type MutationEditSphereEventArgs = {
	sphereEventId: Scalars['ID']
	editSphereEventInput: EditSphereEventInput
	notifyInvitees?: Maybe<Scalars['Boolean']>
}

export type MutationRemindInviteesToRsvpSphereEventArgs = {
	sphereEventId: Scalars['ID']
	sphereEventInviteeFilter: SphereEventInviteeFilter
}

export type MutationRemindInviteeToRsvpSphereEventArgs = {
	sphereEventId: Scalars['ID']
	inviteeId: Scalars['ID']
}

export type MutationRsvpSphereEventArgs = {
	sphereEventId: Scalars['ID']
	rsvp: SphereEventRsvp
}

export type MutationDeleteSphereEventArgs = {
	sphereEventId: Scalars['ID']
}

export type MutationPinMessageToPinboardArgs = {
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	pinboardConversationId: Scalars['ID']
}

export type MutationCreateAnonymousQuestionArgs = {
	sphereId: Scalars['ID']
	questionTitle: Scalars['String']
	questionContent: Scalars['String']
	chatType: AnonymousQuestionChatType
	invitedAgentIds: Array<Maybe<Scalars['String']>>
}

export type MutationActionAnonymousQuestionArgs = {
	sphereId: Scalars['ID']
	anonymousQuestionId: Scalars['ID']
	action: AnonymousQuestionAction
}

export type MutationAcceptSphereInviteArgs = {
	invite_id: Scalars['ID']
}

export type MutationAcceptSphereInviteByCodeArgs = {
	invite_code: Scalars['String']
}

export type MutationAcceptInviteArgs = {
	invite_id: Scalars['ID']
}

export type MutationAcceptInviteByCodeArgs = {
	invite_code: Scalars['String']
}

export type MutationCreateSphereInviteArgs = {
	sphere_id: Scalars['ID']
}

export type MutationSendPushToAgentsArgs = {
	input: AgentsPushInput
}

export type MutationCreateCardArgs = {
	input: CreateCardInput
}

export type MutationUpdateCardArgs = {
	card_id: Scalars['ID']
	input: UpdateCardInput
}

export type MutationArchiveCardArgs = {
	card_id: Scalars['ID']
}

export type MutationAddAgentsToCardArgs = {
	card_id: Scalars['ID']
	agents: Array<Scalars['ID']>
}

export type MutationAddAgentsToSphereArgs = {
	sphere_id: Scalars['ID']
	agents: Array<Scalars['ID']>
}

export type MutationMakeGodArgs = {
	userId: Scalars['ID']
}

export type MutationCreateLoginTokenUrlArgs = {
	userId: Scalars['ID']
}

export type MutationSetLoginPasswordArgs = {
	userId: Scalars['ID']
	password: Scalars['String']
}

/** Pagination info */
export type Paging = {
	__typename: 'Paging'
	cursors: PagingCursors
}

/** Pagination cursors */
export type PagingCursors = {
	__typename: 'PagingCursors'
	/** first id in array */
	before?: Maybe<Scalars['ID']>
	/** last id in array */
	after?: Maybe<Scalars['ID']>
}

/** Input for pagination */
export type PagingInput = {
	before?: Maybe<Scalars['ID']>
	after?: Maybe<Scalars['ID']>
	limit?: Maybe<Scalars['Int']>
	order?: Maybe<PagingOrder>
}

export enum PagingOrder {
	Ascending = 'ASCENDING',
	Descending = 'DESCENDING',
}

export type Person = {
	__typename: 'Person'
	id: Scalars['ID']
	agent_id?: Maybe<Scalars['ID']>
	email?: Maybe<Scalars['String']>
	phone?: Maybe<Scalars['String']>
	first_name?: Maybe<Scalars['String']>
	middle_name?: Maybe<Scalars['String']>
	last_name?: Maybe<Scalars['String']>
	about?: Maybe<Scalars['String']>
	occupation?: Maybe<Scalars['String']>
	location?: Maybe<Scalars['String']>
	date_of_birth?: Maybe<Scalars['String']>
	image_id?: Maybe<Scalars['String']>
	email_is_verified?: Maybe<Scalars['Boolean']>
	personMeta?: Maybe<PersonMeta>
}

export type PersonMeta = {
	__typename: 'PersonMeta'
	id: Scalars['ID']
	agent_id?: Maybe<Scalars['ID']>
	person_id?: Maybe<Scalars['ID']>
	joined_hard_waitlist_with_email?: Maybe<Scalars['String']>
	signed_up_with_email?: Maybe<Scalars['String']>
}

export type PinMessageToPinboardOutput = {
	__typename: 'PinMessageToPinboardOutput'
	sourceMessage: Message
	targetMessage: Message
}

export type PinShareTarget = {
	__typename: 'PinShareTarget'
	conversationId: Scalars['String']
	messageId: Scalars['String']
	messageCreatedAt: Scalars['Date']
}

export type PollOption = {
	__typename: 'PollOption'
	option_id: Scalars['String']
	option_title: Scalars['String']
}

export type PollVote = {
	__typename: 'PollVote'
	option_id: Scalars['String']
	attendant_id: Scalars['String']
}

export type Query = {
	__typename: 'Query'
	systemAgentFeatureSettings: Array<Maybe<FeatureSetting>>
	systemSphereFeatureSettings: Array<Maybe<FeatureSetting>>
	agent?: Maybe<Agent>
	agentFeatureSettings: Array<Maybe<FeatureSetting>>
	viewer?: Maybe<Viewer>
	requestId: Scalars['ID']
	invite?: Maybe<Invite>
	inviteByCode?: Maybe<Invite>
	admin?: Maybe<Admin>
}

export type QueryAgentArgs = {
	id: Scalars['ID']
}

export type QueryInviteArgs = {
	invite_id: Scalars['ID']
}

export type QueryInviteByCodeArgs = {
	code: Scalars['String']
}

export type ReactionStats = {
	__typename: 'ReactionStats'
	anger: Scalars['Int']
	joy: Scalars['Int']
	adoration: Scalars['Int']
	excitement: Scalars['Int']
	like: Scalars['Int']
	sadness: Scalars['Int']
}

export type RemindToRsvpSphereEventCapability = {
	__typename: 'RemindToRSVPSphereEventCapability'
	permitted: Scalars['Boolean']
}

export type RsvpSphereEventCapability = {
	__typename: 'RsvpSphereEventCapability'
	permitted: Scalars['Boolean']
}

export type SendPollMessageCapability = {
	__typename: 'SendPollMessageCapability'
	permitted: Scalars['Boolean']
	importantPermitted: Scalars['Boolean']
	importantDefault: Scalars['Boolean']
}

export type SenderInfo = {
	__typename: 'SenderInfo'
	attendant_id?: Maybe<Scalars['String']>
	display_name?: Maybe<Scalars['String']>
	display_color?: Maybe<Scalars['String']>
	display_image_filename?: Maybe<Scalars['String']>
}

export enum ServiceMessageType {
	UserJoined = 'USER_JOINED',
	UserLeft = 'USER_LEFT',
}

export type SetSphereFeatureSettingPayload = {
	__typename: 'SetSphereFeatureSettingPayload'
	sphereFeatureSettings: Array<Maybe<SphereFeatureSetting>>
}

export type Sphere = {
	__typename: 'Sphere'
	id: Scalars['ID']
	title: Scalars['String']
	description?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
	created_at: Scalars['Date']
	public: Scalars['Boolean']
	/**
	 * Returns an existing or creates a new dm conversation between the caller
	 * and the referenced sphere members
	 */
	dmConversationWithMembers: ChatCard
	/**
	 * Returns an existing or creates a new dm conversation between the caller
	 * and the sphere member referenced by the attendant
	 */
	dmConversationWithAttendantOfConversation: ChatCard
	/**
	 * Load all chats in this sphere.
	 * This query works in modern and compat modes:
	 * Modern - return all sphere chats with pagination and filtering
	 * Compat - used when paging os omitted, returns only live chats sorted by last message
	 */
	chats?: Maybe<ChatCardList>
	/** Chat count (visible even if you're not a member) */
	chat_count: Scalars['Int']
	/** Sphere members (can be null when sphere is private and you are not a member */
	members?: Maybe<SphereMemberList>
	/** Member count (visible even if you're not a member) */
	member_count: Scalars['Int']
	/** Viewer's membership in this sphere (null if not a member) */
	membership?: Maybe<SphereMember>
	/**
	 * Badge count
	 * For now, a number of unread chats but it might change in future,
	 * clients should simply display it
	 */
	badge_count?: Maybe<Scalars['Int']>
	/**
	 * Feature Settings
	 * Feature Settings of the Sphere
	 */
	feature_settings: Array<Maybe<SphereFeatureSetting>>
	event?: Maybe<SphereEvent>
	pendingUpcomingEvents: SphereEventList
	attendedUpcomingEvents: SphereEventList
	notAttendedUpcomingEvents: SphereEventList
	attendedPastEvents: SphereEventList
	notAttendedPastEvents: SphereEventList
	adminPinboard: AdminPinboard
	pendingAnonymousQuestions: Array<Maybe<AnonymousQuestion>>
	appreciations: Array<Maybe<Appreciation>>
	emojis: EmojiList
}

export type SphereDmConversationWithMembersArgs = {
	agentIds: Array<Scalars['String']>
}

export type SphereDmConversationWithAttendantOfConversationArgs = {
	conversationId: Scalars['String']
	attendantId: Scalars['String']
}

export type SphereChatsArgs = {
	paging?: Maybe<PagingInput>
	showAllPrivateChats?: Maybe<Scalars['Boolean']>
}

export type SphereMembersArgs = {
	paging?: Maybe<PagingInput>
	filter?: Maybe<FilterInput>
}

export type SphereEventArgs = {
	sphereEventId: Scalars['ID']
}

export type SpherePendingUpcomingEventsArgs = {
	limit?: Maybe<Scalars['Int']>
	startAfter?: Maybe<Scalars['Date']>
}

export type SphereAttendedUpcomingEventsArgs = {
	limit?: Maybe<Scalars['Int']>
	startAfter?: Maybe<Scalars['Date']>
}

export type SphereNotAttendedUpcomingEventsArgs = {
	limit?: Maybe<Scalars['Int']>
	startAfter?: Maybe<Scalars['Date']>
}

export type SphereAttendedPastEventsArgs = {
	limit?: Maybe<Scalars['Int']>
	startBefore?: Maybe<Scalars['Date']>
}

export type SphereNotAttendedPastEventsArgs = {
	limit?: Maybe<Scalars['Int']>
	startBefore?: Maybe<Scalars['Date']>
}

export type SphereEmojisArgs = {
	filter?: Maybe<EmojisFilterInput>
	startAfter?: Maybe<Scalars['ID']>
	limit: Scalars['Int']
}

export type SphereCapability = {
	__typename: 'SphereCapability'
	type: SphereCapabilityType
}

export enum SphereCapabilityType {
	InviteToSphere = 'INVITE_TO_SPHERE',
	AddAppreciationToSphere = 'ADD_APPRECIATION_TO_SPHERE',
	RemoveAppreciationFromSphere = 'REMOVE_APPRECIATION_FROM_SPHERE',
}

export type SphereEvent = {
	__typename: 'SphereEvent'
	id: Scalars['ID']
	createdAt: Scalars['Date']
	updatedAt?: Maybe<Scalars['Date']>
	sphereId: Scalars['ID']
	displayImageId?: Maybe<Scalars['ID']>
	name: Scalars['String']
	description?: Maybe<Scalars['String']>
	location?: Maybe<SphereEventLocation>
	link?: Maybe<Scalars['String']>
	startDate: Scalars['Date']
	endDate?: Maybe<Scalars['Date']>
	privacyLevel: SphereEventPrivacyLevel
	conversationId: Scalars['ID']
	cardId?: Maybe<Scalars['ID']>
	creator?: Maybe<SphereEventCreator>
	inviteeCounts: SphereEventInviteeCounts
	invitees: SphereEventInvitees
	viewerInvitee?: Maybe<SphereEventInvitee>
	viewerCapabilities: SphereEventCapabilities
}

export type SphereEventCapabilities = {
	__typename: 'SphereEventCapabilities'
	rsvp: RsvpSphereEventCapability
	delete: DeleteSphereEventCapability
	edit: EditSphereEventCapability
	remindToRsvp: RemindToRsvpSphereEventCapability
}

export type SphereEventCreator = {
	__typename: 'SphereEventCreator'
	displayName: Scalars['String']
	displayColor: Scalars['String']
	displayImageId?: Maybe<Scalars['ID']>
	agentId: Scalars['ID']
}

export type SphereEventInput = {
	displayImageId?: Maybe<Scalars['ID']>
	name: Scalars['String']
	description?: Maybe<Scalars['String']>
	location?: Maybe<SphereEventLocationInput>
	link?: Maybe<Scalars['String']>
	startDate: Scalars['Date']
	endDate?: Maybe<Scalars['Date']>
	privacyLevel: SphereEventPrivacyLevel
}

export type SphereEventInvitee = {
	__typename: 'SphereEventInvitee'
	id: Scalars['ID']
	agentId: Scalars['ID']
	rsvp?: Maybe<SphereEventRsvp>
	displayName: Scalars['String']
	displayColor: Scalars['String']
	displayImageId?: Maybe<Scalars['ID']>
}

export type SphereEventInviteeCounts = {
	__typename: 'SphereEventInviteeCounts'
	pending: Scalars['Int']
	going: Scalars['Int']
	thinking: Scalars['Int']
	notGoing: Scalars['Int']
}

export enum SphereEventInviteeFilter {
	ThinkingRsvp = 'THINKING_RSVP',
	PendingRsvp = 'PENDING_RSVP',
}

export type SphereEventInviteeList = {
	__typename: 'SphereEventInviteeList'
	data: Array<SphereEventInvitee>
	paging: Paging
}

export type SphereEventInvitees = {
	__typename: 'SphereEventInvitees'
	pending: SphereEventInviteeList
	going: SphereEventInviteeList
	thinking: SphereEventInviteeList
	notGoing: SphereEventInviteeList
}

export type SphereEventInviteesPendingArgs = {
	limit?: Maybe<Scalars['Int']>
	startAfter?: Maybe<Scalars['ID']>
}

export type SphereEventInviteesGoingArgs = {
	limit?: Maybe<Scalars['Int']>
	startAfter?: Maybe<Scalars['ID']>
}

export type SphereEventInviteesThinkingArgs = {
	limit?: Maybe<Scalars['Int']>
	startAfter?: Maybe<Scalars['ID']>
}

export type SphereEventInviteesNotGoingArgs = {
	limit?: Maybe<Scalars['Int']>
	startAfter?: Maybe<Scalars['ID']>
}

export type SphereEventList = {
	__typename: 'SphereEventList'
	/** array of sphere events */
	data: Array<SphereEvent>
	/** pagination info */
	paging: Paging
}

export type SphereEventLocation = {
	__typename: 'SphereEventLocation'
	name: Scalars['String']
}

export type SphereEventLocationInput = {
	name: Scalars['String']
}

export enum SphereEventPrivacyLevel {
	Sphere = 'SPHERE',
}

export enum SphereEventRsvp {
	Going = 'GOING',
	Thinking = 'THINKING',
	NotGoing = 'NOT_GOING',
}

export type SphereFeatureSetting = {
	__typename: 'SphereFeatureSetting'
	/** name of the feature */
	name: Scalars['String']
	/** value of the feature setting */
	value: Scalars['String']
}

export type SphereInvite = Invite & {
	__typename: 'SphereInvite'
	id: Scalars['ID']
	firebaseShortLink?: Maybe<Scalars['String']>
	firebaseShortLinkToStore?: Maybe<Scalars['String']>
	firebaseDeepLink?: Maybe<Scalars['String']>
	inviteCode: Scalars['String']
	sphere: Sphere
	inviter: Agent
	expiresAt?: Maybe<Scalars['Date']>
}

export type SphereList = {
	__typename: 'SphereList'
	/** array of spheres */
	data: Array<Maybe<Sphere>>
	/** pagination info */
	paging: Paging
}

export type SphereMember = {
	__typename: 'SphereMember'
	is_admin: Scalars['Boolean']
	agent_id: Scalars['ID']
	/** @deprecated use info instead */
	agent: Agent
	info: SphereMemberInfo
	capabilities: Array<SphereCapability>
	canBeDirectMessagedByViewer: Scalars['Boolean']
}

export type SphereMemberInfo = {
	__typename: 'SphereMemberInfo'
	display_name: Scalars['String']
	display_color: Scalars['String']
	display_image_filename?: Maybe<Scalars['String']>
	last_seen?: Maybe<Scalars['Date']>
	online: Scalars['Boolean']
}

export type SphereMemberInput = {
	agent_id: Scalars['ID']
	is_admin?: Maybe<Scalars['Boolean']>
}

export type SphereMemberList = {
	__typename: 'SphereMemberList'
	/** array of sphere members */
	data: Array<SphereMember>
	/** pagination info */
	paging: Paging
}

export type SphereSearchInput = {
	title?: Maybe<Scalars['String']>
}

export type StickyNote = {
	__typename: 'StickyNote'
	content: Scalars['String']
	author: Scalars['String']
}

export type Subscription = {
	__typename: 'Subscription'
	presenceOfAttendants: AttendantPresence
	sphereChatCreated: ChatCard
	sphereChatUpdated: ChatCard
	sphereUpdated: Sphere
}

export type SubscriptionPresenceOfAttendantsArgs = {
	conversation_id: Scalars['ID']
}

export type SubscriptionSphereChatCreatedArgs = {
	sphere_id: Scalars['ID']
}

export type SubscriptionSphereChatUpdatedArgs = {
	sphere_id: Scalars['ID']
}

export type SubscriptionSphereUpdatedArgs = {
	sphere_id: Scalars['ID']
}

export type SyncedShareInfo = {
	__typename: 'SyncedShareInfo'
	pinShareTargets: Array<PinShareTarget>
	syncedShareSource?: Maybe<SyncedShareSource>
}

export type SyncedShareSource = {
	__typename: 'SyncedShareSource'
	syncedShareType: SyncedShareType
	conversationId: Scalars['String']
	conversationName: Scalars['String']
	messageId: Scalars['String']
	messageCreatedAt: Scalars['Date']
}

export enum SyncedShareType {
	PinShare = 'PIN_SHARE',
}

/** Chat card update from admin */
export type UpdateCardInput = {
	public?: Maybe<Scalars['Boolean']>
	publish_at?: Maybe<Scalars['Date']>
	vetted_state?: Maybe<VettedState>
	title?: Maybe<Scalars['String']>
	description?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
}

/** Chat card update for users */
export type UpdateChatInput = {
	title?: Maybe<Scalars['String']>
	description?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
}

export type UpdateSphereInput = {
	title?: Maybe<Scalars['String']>
	description?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
	public?: Maybe<Scalars['Boolean']>
}

export type UpdateUserInput = {
	name?: Maybe<Scalars['String']>
	about?: Maybe<Scalars['String']>
	image_filename?: Maybe<Scalars['String']>
	email?: Maybe<Scalars['String']>
	mood?: Maybe<Mood>
}

export type User = {
	__typename: 'User'
	/** user id (equals to agent id) */
	id: Scalars['ID']
	/** user name in one string */
	name: Scalars['String']
	email: Scalars['String']
	color: Scalars['String']
	about?: Maybe<Scalars['String']>
	imageFilename?: Maybe<Scalars['String']>
	createdAt: Scalars['Date']
	devices: Array<Device>
	gates: Array<Gate>
}

export type UserList = {
	__typename: 'UserList'
	data: Array<User>
	paging: Paging
}

export type UserMention = {
	__typename: 'UserMention'
	attendant_id?: Maybe<Scalars['String']>
	offset: Scalars['Int']
	length: Scalars['Int']
	agent: Agent
}

export type UserMentionInput = {
	mentionable_id: Scalars['String']
	offset: Scalars['Int']
	length: Scalars['Int']
}

export type UserSearchInput = {
	name?: Maybe<Scalars['String']>
	email?: Maybe<Scalars['String']>
}

export enum VettedState {
	Vetted = 'VETTED',
	Unvetted = 'UNVETTED',
}

export type VideoDimensions = {
	__typename: 'VideoDimensions'
	width?: Maybe<Scalars['Int']>
	height?: Maybe<Scalars['Int']>
}

export type Viewer = {
	__typename: 'Viewer'
	agent_id: Scalars['String']
	agent: Agent
	person?: Maybe<Person>
	emojis: EmojiList
	/**
	 * All user's active chats
	 * @deprecated Field no longer supported
	 */
	activeChats?: Maybe<ChatCardList>
	/**
	 * Public live chats that user is not member of
	 * @deprecated Field no longer supported
	 */
	recommendedChats?: Maybe<ChatCardList>
	/**
	 * Upcoming chats
	 * @deprecated Field no longer supported
	 */
	upcomingChats?: Maybe<ChatCardList>
	/** Get card by id */
	card?: Maybe<ChatCard>
	/** Get card by conversation id */
	chatCard?: Maybe<ChatCard>
	/** Spheres (user's first) */
	spheres: SphereList
	/** Get sphere by id */
	sphere?: Maybe<Sphere>
	/**
	 * Get all cards (admin only)
	 * @deprecated use Admin.cards instead
	 */
	cards?: Maybe<ChatCardList>
	imageSearch: ImageSearchResults
}

export type ViewerEmojisArgs = {
	searchTerm?: Maybe<Scalars['String']>
	startAfter?: Maybe<Scalars['ID']>
	limit: Scalars['Int']
}

export type ViewerCardArgs = {
	card_id: Scalars['ID']
}

export type ViewerChatCardArgs = {
	conversationId: Scalars['ID']
}

export type ViewerSphereArgs = {
	sphere_id: Scalars['ID']
}

export type ViewerCardsArgs = {
	paging?: Maybe<PagingInput>
}

export type ViewerImageSearchArgs = {
	term: Scalars['String']
	paging?: Maybe<PagingInput>
}

export type WordStats = {
	__typename: 'WordStats'
	text: Scalars['String']
	count: Scalars['Int']
}

export type LastMessageSenderInfoFragment = {
	__typename: 'SenderInfo'
	attendant_id?: Maybe<string>
	display_name?: Maybe<string>
	display_color?: Maybe<string>
	display_image_filename?: Maybe<string>
}

export type LastMessageFragment = {
	__typename: 'Message'
	id: string
	created_at: Maybe<string>
	sender_info?: Maybe<
		{ __typename: 'SenderInfo' } & LastMessageSenderInfoFragment
	>
	content: {
		__typename: 'MessageContent'
		text?: Maybe<string>
		image_filename?: Maybe<string>
		attachments?: Maybe<
			Array<{ __typename: 'Attachment'; fileId: string; mimeType: string }>
		>
	}
}

export type OwnAttendantFragment = {
	__typename: 'Attendant'
	id: string
	agent_id: string
	conversation_id: string
	display_name: string
	display_color: string
	display_image_filename?: Maybe<string>
	unread_message_count: number
	role: ConversationRole
	muted: boolean
	follows_conversation: boolean
	outstanding_important_messages: Array<Maybe<string>>
	last_seen_message_id?: Maybe<string>
}

export type TypingIndicatorAttendantInfoFragment = {
	__typename: 'AttendantInfo'
	attendant_id: string
	conversationId: string
	display_name: string
}

export type PresenceAttendantInfoFragment = {
	__typename: 'AttendantInfo'
	attendant_id: string
	conversationId: string
	online: boolean
	last_seen?: Maybe<Maybe<string>>
}

export type FeedItemFragment = {
	__typename: 'ChatCard'
	id: string
	creator_id?: Maybe<string>
	title: string
	description: string
	image_filename?: Maybe<string>
	image_color?: Maybe<string>
	created_at: Maybe<string>
	conversation_id?: Maybe<string>
	chat_state: ChatState
	chat_type: ChatType
	private: boolean
	conversationType: ConversationType
	attendant?: Maybe<{ __typename: 'Attendant' } & OwnAttendantFragment>
	conversation?: Maybe<{
		__typename: 'Conversation'
		id: string
		attendant_count: number
		last_message?: Maybe<{ __typename: 'Message' } & LastMessageFragment>
	}>
}

export type UnreadCountChatCardFragment = {
	__typename: 'ChatCard'
	id: string
	attendant?: Maybe<{
		__typename: 'Attendant'
		id: string
		conversation_id: string
		unread_message_count: number
	}>
}

export type SphereSummaryFragment = {
	__typename: 'Sphere'
	id: string
	title: string
	description?: Maybe<string>
	image_filename?: Maybe<string>
	public: boolean
	badge_count?: Maybe<number>
	membership?: Maybe<{ __typename: 'SphereMember'; agent_id: string }>
}

export type SphereFeedInfoFragment = {
	__typename: 'Sphere'
	id: string
	title: string
	image_filename?: Maybe<string>
	public: boolean
	badge_count?: Maybe<number>
}

export type SphereMemberInfoFragment = {
	__typename: 'Sphere'
	member_count: number
	members?: Maybe<{
		__typename: 'SphereMemberList'
		data: Array<{
			__typename: 'SphereMember'
			is_admin: boolean
			agent_id: string
			info: {
				__typename: 'SphereMemberInfo'
				display_name: string
				display_color: string
				display_image_filename?: Maybe<string>
			}
			capabilities: Array<{
				__typename: 'SphereCapability'
				type: SphereCapabilityType
			}>
		}>
	}>
	membership?: Maybe<{
		__typename: 'SphereMember'
		is_admin: boolean
		agent_id: string
		info: {
			__typename: 'SphereMemberInfo'
			display_name: string
			display_color: string
			display_image_filename?: Maybe<string>
		}
		capabilities: Array<{
			__typename: 'SphereCapability'
			type: SphereCapabilityType
		}>
	}>
}

export type MessageReactionFragment = {
	__typename: 'MessageReaction'
	attendant_id?: Maybe<string>
	display_name?: Maybe<string>
	type: MessageReactionType
	agent_id: string
}

export type SphereMemberFragment = {
	__typename: 'SphereMember'
	is_admin: boolean
	agent_id: string
	canBeDirectMessagedByViewer: boolean
	info: {
		__typename: 'SphereMemberInfo'
		display_name: string
		display_color: string
		display_image_filename?: Maybe<string>
		online: boolean
		last_seen?: Maybe<Maybe<string>>
	}
	agent: {
		__typename: 'Agent'
		person?: Maybe<{ __typename: 'Person'; about?: Maybe<string> }>
	}
	capabilities: Array<{
		__typename: 'SphereCapability'
		type: SphereCapabilityType
	}>
}

export type FeedEventFragment = {
	__typename: 'SphereEvent'
	id: string
	displayImageId?: Maybe<string>
	name: string
	startDate: Maybe<string>
	endDate?: Maybe<Maybe<string>>
	sphereId: string
	createdAt: Maybe<string>
	viewerInvitee?: Maybe<{
		__typename: 'SphereEventInvitee'
		id: string
		rsvp?: Maybe<SphereEventRsvp>
	}>
	viewerCapabilities: {
		__typename: 'SphereEventCapabilities'
		rsvp: { __typename: 'RsvpSphereEventCapability'; permitted: boolean }
	}
}

export type FullSphereFragment = {
	__typename: 'Sphere'
	id: string
	title: string
	description?: Maybe<string>
	image_filename?: Maybe<string>
	created_at: Maybe<string>
	public: boolean
	chat_count: number
	member_count: number
	badge_count?: Maybe<number>
	chats?: Maybe<{
		__typename: 'ChatCardList'
		data?: Maybe<Array<{ __typename: 'ChatCard' } & FeedItemFragment>>
	}>
	members?: Maybe<{
		__typename: 'SphereMemberList'
		data: Array<{ __typename: 'SphereMember' } & SphereMemberFragment>
	}>
	membership?: Maybe<{ __typename: 'SphereMember' } & SphereMemberFragment>
	pendingUpcomingEvents: {
		__typename: 'SphereEventList'
		data: Array<{ __typename: 'SphereEvent' } & FeedEventFragment>
	}
}

export type AttendantInfoFragment = {
	__typename: 'AttendantInfo'
	attendant_id: string
	conversationId: string
	role: ConversationRole
	bio?: Maybe<string>
	display_color: string
	display_image_filename?: Maybe<string>
	display_name: string
}

export type ProfileAttendantInfoFragment = {
	__typename: 'AttendantInfo'
	attendant_id: string
	conversationId: string
	display_name: string
	display_image_filename?: Maybe<string>
	display_color: string
	bio?: Maybe<string>
	online: boolean
	last_seen?: Maybe<Maybe<string>>
	role: ConversationRole
	canBeDirectMessagedByViewer: boolean
}

export type ChatCardFragment = {
	__typename: 'ChatCard'
	id: string
	conversation_id?: Maybe<string>
	conversationType: ConversationType
	title: string
	description: string
	image_filename?: Maybe<string>
	image_color?: Maybe<string>
	chat_state: ChatState
	private: boolean
	attendant?: Maybe<{ __typename: 'Attendant' } & OwnAttendantFragment>
	conversation?: Maybe<{
		__typename: 'Conversation'
		id: string
		activity_info?: Maybe<{
			__typename: 'ConversationActivityInfo'
			recently_active: number
		}>
	}>
	spheres?: Maybe<Array<{ __typename: 'Sphere'; id: string }>>
}

export type CapabilityFragment = {
	__typename: 'Capability'
	permitted: boolean
}

export type ChatViewCapabilitiesFragment = {
	__typename: 'Attendant'
	id: string
	capabilities: {
		__typename: 'ChatCapabilities'
		sendMessage: { __typename: 'Capability' } & CapabilityFragment
		reactToMessage: { __typename: 'Capability' } & CapabilityFragment
		addAppreciationReactionToMessage: {
			__typename: 'Capability'
		} & CapabilityFragment
		removeAppreciationReactionFromMessage: {
			__typename: 'Capability'
		} & CapabilityFragment
		pinMessageToAdminPinboard: { __typename: 'Capability' } & CapabilityFragment
		removeOwnMessage: { __typename: 'Capability' } & CapabilityFragment
		removeAnyMessage: { __typename: 'Capability' } & CapabilityFragment
		replyToMessage: { __typename: 'Capability' } & CapabilityFragment
		actionImportantMessage: { __typename: 'Capability' } & CapabilityFragment
		sendPollMessage: {
			__typename: 'SendPollMessageCapability'
			permitted: boolean
			importantPermitted: boolean
			importantDefault: boolean
		}
		sendAnnouncementMessage: { __typename: 'Capability' } & CapabilityFragment
	}
}

export type ChatViewFragment = {
	__typename: 'ChatCard'
	attendant?: Maybe<
		{ __typename: 'Attendant'; id: string } & ChatViewCapabilitiesFragment
	>
} & ChatCardFragment

export type EventInfoInviteeFragment = {
	__typename: 'SphereEventInvitee'
	id: string
	rsvp?: Maybe<SphereEventRsvp>
	displayName: string
	displayColor: string
	displayImageId?: Maybe<string>
	agentId: string
}

export type RsvpEventFragment = {
	__typename: 'SphereEvent'
	id: string
	sphereId: string
	inviteeCounts: {
		__typename: 'SphereEventInviteeCounts'
		pending: number
		going: number
		thinking: number
		notGoing: number
	}
	viewerInvitee?: Maybe<
		{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
	>
}

export type ChatCardChatHeaderFragment = {
	__typename: 'ChatCard'
	id: string
	title: string
	image_filename?: Maybe<string>
	image_color?: Maybe<string>
	private: boolean
	conversationType: ConversationType
	sphereEventId?: Maybe<string>
	attendant?: Maybe<{
		__typename: 'Attendant'
		id: string
		conversation_id: string
		follows_conversation: boolean
	}>
	conversation?: Maybe<{
		__typename: 'Conversation'
		id: string
		attendant_count: number
		activity_info?: Maybe<{
			__typename: 'ConversationActivityInfo'
			recently_active: number
		}>
	}>
}

export type AttendantPresenceFragment = {
	__typename: 'AttendantPresence'
	attendant_id: string
	last_seen?: Maybe<Maybe<string>>
	online: boolean
}

export type ChatInfoViewerAttendantFragment = {
	__typename: 'Attendant'
	id: string
	conversation_id: string
	display_name: string
	display_color: string
	display_image_filename?: Maybe<string>
	role: ConversationRole
	muted: boolean
	follows_conversation: boolean
	capabilities: {
		__typename: 'ChatCapabilities'
		addMembersToChat: { __typename: 'Capability'; permitted: boolean }
		removeAttendantsFromChat: { __typename: 'Capability'; permitted: boolean }
		unfollowChat: { __typename: 'Capability'; permitted: boolean }
		setChatTitle: { __typename: 'Capability'; permitted: boolean }
		setChatImage: { __typename: 'Capability'; permitted: boolean }
		endChat: { __typename: 'Capability'; permitted: boolean }
	}
}

export type ChatInfoAttendantInfoFragment = {
	__typename: 'AttendantInfo'
	attendant_id: string
	conversationId: string
	display_name: string
	display_image_filename?: Maybe<string>
	display_color: string
	role: ConversationRole
	online: boolean
	last_seen?: Maybe<Maybe<string>>
	canBeRemovedByViewer: boolean
}

export type ChatCardChatInfoFragment = {
	__typename: 'ChatCard'
	id: string
	title: string
	description: string
	image_filename?: Maybe<string>
	image_color?: Maybe<string>
	chat_state: ChatState
	private: boolean
	conversation_id?: Maybe<string>
	conversationType: ConversationType
	attendant?: Maybe<
		{ __typename: 'Attendant' } & ChatInfoViewerAttendantFragment
	>
	conversation?: Maybe<{
		__typename: 'Conversation'
		id: string
		attendants_info: Array<
			{ __typename: 'AttendantInfo' } & ChatInfoAttendantInfoFragment
		>
	}>
}

export type ChatHeaderPresenceAttendantInfoFragmentFragment = {
	__typename: 'AttendantInfo'
	attendant_id: string
	conversationId: string
	online: boolean
	last_seen?: Maybe<Maybe<string>>
}

export type AddMemberListAddableMemberFragmentFragment = {
	__typename: 'SphereMember'
	agent_id: string
	info: {
		__typename: 'SphereMemberInfo'
		display_name: string
		display_color: string
		display_image_filename?: Maybe<string>
		online: boolean
		last_seen?: Maybe<Maybe<string>>
	}
}

export type DmMemberListSphereMemberFragmentFragment = {
	__typename: 'SphereMember'
	agent_id: string
	info: {
		__typename: 'SphereMemberInfo'
		display_name: string
		display_color: string
		display_image_filename?: Maybe<string>
		online: boolean
		last_seen?: Maybe<Maybe<string>>
	}
}

export type SphereEventListEventFragment = {
	__typename: 'SphereEvent'
	id: string
	sphereId: string
	displayImageId?: Maybe<string>
	name: string
	createdAt: Maybe<string>
	startDate: Maybe<string>
	endDate?: Maybe<Maybe<string>>
	viewerInvitee?: Maybe<{
		__typename: 'SphereEventInvitee'
		id: string
		agentId: string
		rsvp?: Maybe<SphereEventRsvp>
	}>
	viewerCapabilities: {
		__typename: 'SphereEventCapabilities'
		rsvp: { __typename: 'RsvpSphereEventCapability'; permitted: boolean }
	}
}

export type SphereViewFragment = {
	__typename: 'Sphere'
	adminPinboard: {
		__typename: 'AdminPinboard'
		cardId: string
		conversationId: string
	}
}

export type AppreciationFragmentFragment = {
	__typename: 'Appreciation'
	id: string
	sphereId: string
	name: string
	color: string
	active: boolean
	emoji: { __typename: 'Emoji'; id: string; imageId: string }
}

export type EmojiFragmentFragment = {
	__typename: 'Emoji'
	id: string
	unicode?: Maybe<string>
	name: string
	category: string
	imageId: string
}

export type MuteChatMutationVariables = Exact<{
	cardId: Scalars['ID']
}>

export type MuteChatMutation = {
	__typename: 'Mutation'
	mute?: Maybe<{
		__typename: 'ChatCard'
		id: string
		attendant?: Maybe<{
			__typename: 'Attendant'
			id: string
			conversation_id: string
			muted: boolean
		}>
	}>
}

export type UnmuteChatMutationVariables = Exact<{
	cardId: Scalars['ID']
}>

export type UnmuteChatMutation = {
	__typename: 'Mutation'
	unmute?: Maybe<{
		__typename: 'ChatCard'
		id: string
		attendant?: Maybe<{
			__typename: 'Attendant'
			id: string
			conversation_id: string
			muted: boolean
		}>
	}>
}

export type JoinConversationMutationVariables = Exact<{
	conversationId: Scalars['ID']
}>

export type JoinConversationMutation = {
	__typename: 'Mutation'
	joinConversation: { __typename: 'Attendant' } & OwnAttendantFragment &
		ChatViewCapabilitiesFragment
}

export type FollowConversationMutationVariables = Exact<{
	conversationId: Scalars['ID']
}>

export type FollowConversationMutation = {
	__typename: 'Mutation'
	followConversation?: Maybe<boolean>
}

export type UnfollowConversationMutationVariables = Exact<{
	conversationId: Scalars['ID']
}>

export type UnfollowConversationMutation = {
	__typename: 'Mutation'
	unfollowConversation?: Maybe<boolean>
}

export type CreateChatMutationVariables = Exact<{
	input: CreateChatInput
}>

export type CreateChatMutation = {
	__typename: 'Mutation'
	createChat: {
		__typename: 'ChatCard'
		id: string
		chat_type: ChatType
		title: string
		description: string
		image_filename?: Maybe<string>
		conversation_id?: Maybe<string>
		private: boolean
	}
}

export type EndChatMutationVariables = Exact<{
	id: Scalars['ID']
}>

export type EndChatMutation = {
	__typename: 'Mutation'
	endChat: {
		__typename: 'ChatCard'
		id: string
		chat_state: ChatState
		attendant?: Maybe<{
			__typename: 'Attendant'
			id: string
			conversation_id: string
			role: ConversationRole
			has_new_activity: boolean
		}>
	}
}

export type CreateSphereMutationVariables = Exact<{
	input: CreateSphereInput
}>

export type CreateSphereMutation = {
	__typename: 'Mutation'
	createSphere: { __typename: 'Sphere' } & SphereSummaryFragment
}

export type UpdateChatMutationVariables = Exact<{
	card_id: Scalars['ID']
	input?: Maybe<UpdateChatInput>
}>

export type UpdateChatMutation = {
	__typename: 'Mutation'
	updateChat: {
		__typename: 'ChatCard'
		id: string
		chat_type: ChatType
		title: string
		description: string
		image_filename?: Maybe<string>
		conversation_id?: Maybe<string>
		private: boolean
	}
}

export type UpdateSphereMutationVariables = Exact<{
	sphere_id: Scalars['ID']
	input: UpdateSphereInput
}>

export type UpdateSphereMutation = {
	__typename: 'Mutation'
	updateSphere: {
		__typename: 'Sphere'
		id: string
		title: string
		description?: Maybe<string>
		image_filename?: Maybe<string>
	}
}

export type JoinSphereMutationVariables = Exact<{
	id: Scalars['ID']
}>

export type JoinSphereMutation = {
	__typename: 'Mutation'
	joinSphere: { __typename: 'Sphere'; id: string } & SphereMemberInfoFragment
}

export type LeaveSphereMutationVariables = Exact<{
	id: Scalars['ID']
}>

export type LeaveSphereMutation = {
	__typename: 'Mutation'
	leaveSphere: { __typename: 'Sphere'; id: string } & SphereMemberInfoFragment
}

export type UpdateSphereMemberMutationVariables = Exact<{
	sphereId: Scalars['ID']
	input: SphereMemberInput
}>

export type UpdateSphereMemberMutation = {
	__typename: 'Mutation'
	updateSphereMember: {
		__typename: 'SphereMember'
		agent_id: string
		is_admin: boolean
	}
}

export type RemoveSphereMemberMutationVariables = Exact<{
	sphereId: Scalars['ID']
	agentId: Scalars['ID']
}>

export type RemoveSphereMemberMutation = {
	__typename: 'Mutation'
	removeSphereMember: boolean
}

export type AddAppreciationReactionToMessageMutationVariables = Exact<{
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	appreciationId: Scalars['ID']
}>

export type AddAppreciationReactionToMessageMutation = {
	__typename: 'Mutation'
	addAppreciationReactionToMessage: {
		__typename: 'Message'
		id: string
		updated_at?: Maybe<Maybe<string>>
		reactions?: Maybe<
			Array<Maybe<{ __typename: 'MessageReaction' } & MessageReactionFragment>>
		>
	}
}

export type RemoveAppreciationReactionFromMessageMutationVariables = Exact<{
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	appreciationId: Scalars['ID']
}>

export type RemoveAppreciationReactionFromMessageMutation = {
	__typename: 'Mutation'
	removeAppreciationReactionFromMessage: {
		__typename: 'Message'
		id: string
		updated_at?: Maybe<Maybe<string>>
		reactions?: Maybe<
			Array<Maybe<{ __typename: 'MessageReaction' } & MessageReactionFragment>>
		>
	}
}

export type AddAppreciationToSphereMutationVariables = Exact<{
	sphereId: Scalars['ID']
	name: Scalars['String']
	emojiId: Scalars['String']
	description?: Maybe<Scalars['String']>
}>

export type AddAppreciationToSphereMutation = {
	__typename: 'Mutation'
	addAppreciationToSphere: {
		__typename: 'Appreciation'
	} & AppreciationFragmentFragment
}

export type DisableAppreciationOnSphereMutationVariables = Exact<{
	sphereId: Scalars['ID']
	appreciationId: Scalars['ID']
}>

export type DisableAppreciationOnSphereMutation = {
	__typename: 'Mutation'
	disableAppreciationOnSphere: boolean
}

export type AddEmojiReactionToMessageMutationVariables = Exact<{
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	emojiId: Scalars['ID']
}>

export type AddEmojiReactionToMessageMutation = {
	__typename: 'Mutation'
	addEmojiReactionToMessage: {
		__typename: 'Message'
		id: string
		updated_at?: Maybe<Maybe<string>>
		reactions?: Maybe<
			Array<Maybe<{ __typename: 'MessageReaction' } & MessageReactionFragment>>
		>
	}
}

export type RemoveEmojiReactionFromMessageMutationVariables = Exact<{
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	emojiId: Scalars['ID']
}>

export type RemoveEmojiReactionFromMessageMutation = {
	__typename: 'Mutation'
	removeEmojiReactionFromMessage: {
		__typename: 'Message'
		id: string
		updated_at?: Maybe<Maybe<string>>
		reactions?: Maybe<
			Array<Maybe<{ __typename: 'MessageReaction' } & MessageReactionFragment>>
		>
	}
}

export type RemoveMessageMutationVariables = Exact<{
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
}>

export type RemoveMessageMutation = {
	__typename: 'Mutation'
	removeMessage: boolean
}

export type SendMessageMutationVariables = Exact<{
	conversation_id: Scalars['ID']
	input: ClientMessageInput
}>

export type SendMessageMutation = {
	__typename: 'Mutation'
	sendMessage: {
		__typename: 'Message'
		id: string
		created_at: Maybe<string>
		updated_at?: Maybe<Maybe<string>>
		unique_id: string
		content: {
			__typename: 'MessageContent'
			text?: Maybe<string>
			image_filename?: Maybe<string>
			image_dimensions?: Maybe<{
				__typename: 'ImageDimensions'
				width: number
				height: number
			}>
		}
	}
}

export type MarkLatestMessageAsSeenMutationVariables = Exact<{
	conversation_id: Scalars['ID']
}>

export type MarkLatestMessageAsSeenMutation = {
	__typename: 'Mutation'
	markLatestMessageAsSeen: boolean
}

export type CreateInviteMutationVariables = Exact<{
	id: Scalars['ID']
}>

export type CreateInviteMutation = {
	__typename: 'Mutation'
	createSphereInvite: {
		__typename: 'SphereInvite'
		id: string
		firebaseShortLink?: Maybe<string>
		firebaseDeepLink?: Maybe<string>
		sphere: { __typename: 'Sphere'; id: string; title: string }
		inviter: { __typename: 'Agent'; id: string; name?: Maybe<string> }
	}
}

export type UpdateUserMutationVariables = Exact<{
	agent_id: Scalars['ID']
	input: UpdateUserInput
}>

export type UpdateUserMutation = {
	__typename: 'Mutation'
	updateUser: {
		__typename: 'Agent'
		id: string
		type?: Maybe<string>
		name?: Maybe<string>
		color?: Maybe<string>
		image_filename?: Maybe<string>
		person?: Maybe<{
			__typename: 'Person'
			id: string
			email?: Maybe<string>
			about?: Maybe<string>
		}>
	}
}

export type PerformActionOnAnnouncementMessageMutationVariables = Exact<{
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
	acknowledge?: Maybe<Scalars['Boolean']>
	dismiss?: Maybe<Scalars['Boolean']>
}>

export type PerformActionOnAnnouncementMessageMutation = {
	__typename: 'Mutation'
	performActionOnAnnouncementMessage: boolean
}

export type RemindAttendantToPerformActionOnMessageMutationVariables = Exact<{
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
	attendant_id: Scalars['ID']
}>

export type RemindAttendantToPerformActionOnMessageMutation = {
	__typename: 'Mutation'
	remindAttendantToPerformActionOnMessage: boolean
}

export type RemindAttendantsToPerformActionOnMessageMutationVariables = Exact<{
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
}>

export type RemindAttendantsToPerformActionOnMessageMutation = {
	__typename: 'Mutation'
	remindAttendantsToPerformActionOnMessage: boolean
}

export type PerformActionOnPollMessageMutationVariables = Exact<{
	conversation_id: Scalars['ID']
	message_id: Scalars['ID']
	add_vote_for_options?: Maybe<
		Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
	>
	remove_vote_for_options?: Maybe<
		Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
	>
	dismiss?: Maybe<Scalars['Boolean']>
}>

export type PerformActionOnPollMessageMutation = {
	__typename: 'Mutation'
	performActionOnPollMessage: boolean
}

export type RsvpSphereEventMutationVariables = Exact<{
	sphereEventId: Scalars['ID']
	rsvp: SphereEventRsvp
}>

export type RsvpSphereEventMutation = {
	__typename: 'Mutation'
	rsvpSphereEvent: { __typename: 'SphereEvent' } & RsvpEventFragment
}

export type AddMembersToSphereConversationMutationVariables = Exact<{
	conversationId: Scalars['ID']
	sphereId: Scalars['ID']
	agentIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type AddMembersToSphereConversationMutation = {
	__typename: 'Mutation'
	addMembersToSphereConversation: Array<
		{
			__typename: 'AttendantInfo'
			conversationId: string
		} & ChatInfoAttendantInfoFragment
	>
}

export type RemoveAttendantsFromSphereConversationMutationVariables = Exact<{
	attendantIds: Array<Scalars['ID']> | Scalars['ID']
	conversationId: Scalars['ID']
	sphereId: Scalars['ID']
}>

export type RemoveAttendantsFromSphereConversationMutation = {
	__typename: 'Mutation'
	removeAttendantsFromSphereConversation: Array<{
		__typename: 'AttendantInfo'
		conversationId: string
		attendant_id: string
	}>
}

export type CreateSphereEventMutationVariables = Exact<{
	sphereId: Scalars['ID']
	eventInput: SphereEventInput
}>

export type CreateSphereEventMutation = {
	__typename: 'Mutation'
	createSphereEvent: { __typename: 'SphereEvent'; id: string }
}

export type DeleteSphereEventMutationVariables = Exact<{
	sphereEventId: Scalars['ID']
}>

export type DeleteSphereEventMutation = {
	__typename: 'Mutation'
	deleteSphereEvent: boolean
}

export type EditSphereEventMutationVariables = Exact<{
	sphereEventId: Scalars['ID']
	input: EditSphereEventInput
	notifyInvitees?: Maybe<Scalars['Boolean']>
}>

export type EditSphereEventMutation = {
	__typename: 'Mutation'
	editSphereEvent: {
		__typename: 'SphereEvent'
		id: string
		sphereId: string
		name: string
		startDate: Maybe<string>
		displayImageId?: Maybe<string>
		endDate?: Maybe<Maybe<string>>
		description?: Maybe<string>
		link?: Maybe<string>
		location?: Maybe<{ __typename: 'SphereEventLocation'; name: string }>
	}
}

export type RemindEventInviteesMutationVariables = Exact<{
	sphereEventId: Scalars['ID']
	sphereEventInviteeFilter: SphereEventInviteeFilter
}>

export type RemindEventInviteesMutation = {
	__typename: 'Mutation'
	remindInviteesToRSVPSphereEvent?: Maybe<boolean>
}

export type RemindAboutEventMutationVariables = Exact<{
	sphereEventId: Scalars['ID']
	inviteeId: Scalars['ID']
}>

export type RemindAboutEventMutation = {
	__typename: 'Mutation'
	remindInviteeToRSVPSphereEvent?: Maybe<boolean>
}

export type PinMessageToPinboardMutationVariables = Exact<{
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
	messageId: Scalars['ID']
	pinboardConversationId: Scalars['ID']
}>

export type PinMessageToPinboardMutation = {
	__typename: 'Mutation'
	pinMessageToPinboard: {
		__typename: 'PinMessageToPinboardOutput'
		sourceMessage: { __typename: 'Message'; id: string }
		targetMessage: { __typename: 'Message'; id: string }
	}
}

export type ArchiveChatMutationVariables = Exact<{
	sphereId: Scalars['ID']
	conversationId: Scalars['ID']
}>

export type ArchiveChatMutation = {
	__typename: 'Mutation'
	archiveSphereChat?: Maybe<{
		__typename: 'Attendant'
		id: string
		conversation_id: string
	}>
}

export type GetChatCardByConversationIdQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetChatCardByConversationIdQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{ __typename: 'ChatCard' } & ChatCardFragment>
	}>
}

export type GetChatCardForChatViewQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetChatCardForChatViewQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{ __typename: 'ChatCard' } & ChatViewFragment>
	}>
}

export type GetSocketChannelTokenForChatCardByIdQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetSocketChannelTokenForChatCardByIdQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{
			__typename: 'ChatCard'
			id: string
			conversation?: Maybe<{
				__typename: 'Conversation'
				id: string
				socket_channel_token: string
			}>
		}>
	}>
}

export type GetViewerAttendantIdForChatCardByIdQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetViewerAttendantIdForChatCardByIdQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{
			__typename: 'ChatCard'
			id: string
			attendant?: Maybe<{ __typename: 'Attendant' } & OwnAttendantFragment>
		}>
	}>
}

export type ImageSearchQueryVariables = Exact<{
	searchTerm: Scalars['String']
	paging?: Maybe<PagingInput>
}>

export type ImageSearchQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		imageSearch: {
			__typename: 'ImageSearchResults'
			data: Array<
				Maybe<{
					__typename: 'ImageSearchResult'
					source: {
						__typename: 'ImageResource'
						url: string
						width: number
						height: number
					}
					thumbnail: {
						__typename: 'ImageResource'
						url: string
						width: number
						height: number
					}
				}>
			>
			paging: {
				__typename: 'Paging'
				cursors: {
					__typename: 'PagingCursors'
					after?: Maybe<string>
					before?: Maybe<string>
				}
			}
		}
	}>
}

export type GetSphereSummaryInfosQueryVariables = Exact<{
	[key: string]: never
}>

export type GetSphereSummaryInfosQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		spheres: {
			__typename: 'SphereList'
			data: Array<Maybe<{ __typename: 'Sphere' } & SphereSummaryFragment>>
		}
	}>
}

export type GetSphereQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetSphereQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{ __typename: 'Sphere' } & FullSphereFragment>
	}>
}

export type GetFeedSphereQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetFeedSphereQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{ __typename: 'Sphere' } & SphereFeedInfoFragment>
	}>
}

export type GetSpheresFeedInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetSpheresFeedInfoQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		spheres: {
			__typename: 'SphereList'
			data: Array<Maybe<{ __typename: 'Sphere' } & SphereFeedInfoFragment>>
		}
	}>
}

export type GetSpheresAndSphereQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetSpheresAndSphereQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{ __typename: 'Sphere' } & SphereSummaryFragment>
		spheres: {
			__typename: 'SphereList'
			data: Array<Maybe<{ __typename: 'Sphere' } & SphereSummaryFragment>>
		}
	}>
}

export type GetSphereSummaryQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetSphereSummaryQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{ __typename: 'Sphere' } & SphereSummaryFragment>
	}>
}

export type GetAttendantInfoForProfileQueryVariables = Exact<{
	conversationId: Scalars['ID']
	attendantId: Scalars['ID']
}>

export type GetAttendantInfoForProfileQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{
			__typename: 'ChatCard'
			id: string
			conversation?: Maybe<{
				__typename: 'Conversation'
				id: string
				attendant_info: {
					__typename: 'AttendantInfo'
				} & ProfileAttendantInfoFragment
			}>
		}>
	}>
}

export type GetAttendantInfoForTypingIndicatorByIdQueryVariables = Exact<{
	conversationId: Scalars['ID']
	attendantId: Scalars['ID']
}>

export type GetAttendantInfoForTypingIndicatorByIdQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{
			__typename: 'ChatCard'
			id: string
			conversation?: Maybe<{
				__typename: 'Conversation'
				id: string
				attendant_info: {
					__typename: 'AttendantInfo'
				} & TypingIndicatorAttendantInfoFragment
			}>
		}>
	}>
}

export type GetOwnProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetOwnProfileQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		agent: {
			__typename: 'Agent'
			id: string
			name?: Maybe<string>
			image_filename?: Maybe<string>
			color?: Maybe<string>
		}
		person?: Maybe<{
			__typename: 'Person'
			id: string
			about?: Maybe<string>
			email?: Maybe<string>
		}>
	}>
}

export type GetChatCardAttendantsByCardIdQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetChatCardAttendantsByCardIdQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{
			__typename: 'ChatCard'
			id: string
			conversation?: Maybe<{
				__typename: 'Conversation'
				id: string
				attendants_info: Array<
					{
						__typename: 'AttendantInfo'
						attendant_id: string
					} & AttendantInfoFragment
				>
			}>
		}>
	}>
}

export type GetOutstandingImportantMessagesPerSphereQueryVariables = Exact<{
	[key: string]: never
}>

export type GetOutstandingImportantMessagesPerSphereQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		spheres: {
			__typename: 'SphereList'
			data: Array<
				Maybe<{
					__typename: 'Sphere'
					id: string
					chats?: Maybe<{
						__typename: 'ChatCardList'
						data?: Maybe<
							Array<{
								__typename: 'ChatCard'
								id: string
								attendant?: Maybe<{
									__typename: 'Attendant'
									id: string
									conversation_id: string
									outstanding_important_messages: Array<Maybe<string>>
								}>
							}>
						>
					}>
				}>
			>
		}
	}>
}

export type GetEventInfoQueryVariables = Exact<{
	sphereId: Scalars['ID']
	sphereEventId: Scalars['ID']
	inviteeLimit?: Maybe<Scalars['Int']>
}>

export type GetEventInfoQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			event?: Maybe<{
				__typename: 'SphereEvent'
				id: string
				sphereId: string
				conversationId: string
				displayImageId?: Maybe<string>
				name: string
				description?: Maybe<string>
				link?: Maybe<string>
				startDate: Maybe<string>
				endDate?: Maybe<Maybe<string>>
				createdAt: Maybe<string>
				location?: Maybe<{ __typename: 'SphereEventLocation'; name: string }>
				inviteeCounts: {
					__typename: 'SphereEventInviteeCounts'
					pending: number
					going: number
					thinking: number
					notGoing: number
				}
				viewerInvitee?: Maybe<
					{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
				>
				invitees: {
					__typename: 'SphereEventInvitees'
					pending: {
						__typename: 'SphereEventInviteeList'
						data: Array<
							{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
						>
					}
					going: {
						__typename: 'SphereEventInviteeList'
						data: Array<
							{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
						>
					}
					thinking: {
						__typename: 'SphereEventInviteeList'
						data: Array<
							{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
						>
					}
					notGoing: {
						__typename: 'SphereEventInviteeList'
						data: Array<
							{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
						>
					}
				}
				creator?: Maybe<{
					__typename: 'SphereEventCreator'
					displayName: string
					displayColor: string
					displayImageId?: Maybe<string>
					agentId: string
				}>
				viewerCapabilities: {
					__typename: 'SphereEventCapabilities'
					rsvp: { __typename: 'RsvpSphereEventCapability'; permitted: boolean }
					delete: {
						__typename: 'DeleteSphereEventCapability'
						permitted: boolean
					}
					remindToRsvp: {
						__typename: 'RemindToRSVPSphereEventCapability'
						permitted: boolean
					}
					edit: { __typename: 'EditSphereEventCapability'; permitted: boolean }
				}
			}>
		}>
	}>
}

export type GetPendingEventInviteesQueryVariables = Exact<{
	sphereId: Scalars['ID']
	eventId: Scalars['ID']
}>

export type GetPendingEventInviteesQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			event?: Maybe<{
				__typename: 'SphereEvent'
				id: string
				sphereId: string
				inviteeCounts: {
					__typename: 'SphereEventInviteeCounts'
					pending: number
				}
				invitees: {
					__typename: 'SphereEventInvitees'
					pending: {
						__typename: 'SphereEventInviteeList'
						data: Array<
							{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
						>
					}
				}
			}>
		}>
	}>
}

export type GetGoingEventInviteesQueryVariables = Exact<{
	sphereId: Scalars['ID']
	eventId: Scalars['ID']
}>

export type GetGoingEventInviteesQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			event?: Maybe<{
				__typename: 'SphereEvent'
				id: string
				sphereId: string
				inviteeCounts: { __typename: 'SphereEventInviteeCounts'; going: number }
				invitees: {
					__typename: 'SphereEventInvitees'
					going: {
						__typename: 'SphereEventInviteeList'
						data: Array<
							{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
						>
					}
				}
			}>
		}>
	}>
}

export type GetNotGoingEventInviteesQueryVariables = Exact<{
	sphereId: Scalars['ID']
	eventId: Scalars['ID']
}>

export type GetNotGoingEventInviteesQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			event?: Maybe<{
				__typename: 'SphereEvent'
				id: string
				sphereId: string
				inviteeCounts: {
					__typename: 'SphereEventInviteeCounts'
					notGoing: number
				}
				invitees: {
					__typename: 'SphereEventInvitees'
					notGoing: {
						__typename: 'SphereEventInviteeList'
						data: Array<
							{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
						>
					}
				}
			}>
		}>
	}>
}

export type GetThinkingEventInviteesQueryVariables = Exact<{
	sphereId: Scalars['ID']
	eventId: Scalars['ID']
}>

export type GetThinkingEventInviteesQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			event?: Maybe<{
				__typename: 'SphereEvent'
				id: string
				sphereId: string
				inviteeCounts: {
					__typename: 'SphereEventInviteeCounts'
					thinking: number
				}
				invitees: {
					__typename: 'SphereEventInvitees'
					thinking: {
						__typename: 'SphereEventInviteeList'
						data: Array<
							{ __typename: 'SphereEventInvitee' } & EventInfoInviteeFragment
						>
					}
				}
			}>
		}>
	}>
}

export type GetChatCardForChatHeaderQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetChatCardForChatHeaderQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{ __typename: 'ChatCard' } & ChatCardChatHeaderFragment>
	}>
}

export type GetChatCardForChatInfoQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetChatCardForChatInfoQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{ __typename: 'ChatCard' } & ChatCardChatInfoFragment>
	}>
}

export type GetViewerAttendantForChatInfoQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetViewerAttendantForChatInfoQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{
			__typename: 'ChatCard'
			id: string
			attendant?: Maybe<
				{ __typename: 'Attendant' } & ChatInfoViewerAttendantFragment
			>
		}>
	}>
}

export type GetChatCardAttendantInfoPresenceForChatHeaderQueryVariables =
	Exact<{
		id: Scalars['ID']
	}>

export type GetChatCardAttendantInfoPresenceForChatHeaderQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		chatCard?: Maybe<{
			__typename: 'ChatCard'
			id: string
			conversation?: Maybe<{
				__typename: 'Conversation'
				id: string
				attendants_info: Array<
					{
						__typename: 'AttendantInfo'
					} & ChatHeaderPresenceAttendantInfoFragmentFragment
				>
			}>
		}>
	}>
}

export type GetSphereMembersAddableForConversationQueryVariables = Exact<{
	sphereId: Scalars['ID']
	conversationId?: Maybe<Scalars['ID']>
}>

export type GetSphereMembersAddableForConversationQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			members?: Maybe<{
				__typename: 'SphereMemberList'
				data: Array<
					{
						__typename: 'SphereMember'
					} & AddMemberListAddableMemberFragmentFragment
				>
			}>
		}>
	}>
}

export type GetDmChatCardWithSphereMembersQueryVariables = Exact<{
	sphereId: Scalars['ID']
	agentIds: Array<Scalars['String']> | Scalars['String']
}>

export type GetDmChatCardWithSphereMembersQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			dmConversationWithMembers: {
				__typename: 'ChatCard'
				id: string
				conversation_id?: Maybe<string>
			}
		}>
	}>
}

export type GetDmChatCardWithConversationAttendantQueryVariables = Exact<{
	sphereId: Scalars['ID']
	conversationId: Scalars['String']
	attendantId: Scalars['String']
}>

export type GetDmChatCardWithConversationAttendantQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			dmConversationWithAttendantOfConversation: {
				__typename: 'ChatCard'
				id: string
				conversation_id?: Maybe<string>
			}
		}>
	}>
}

export type GetSphereMembersViewerCanDirectMessageQueryVariables = Exact<{
	sphereId: Scalars['ID']
}>

export type GetSphereMembersViewerCanDirectMessageQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			members?: Maybe<{
				__typename: 'SphereMemberList'
				data: Array<
					{
						__typename: 'SphereMember'
					} & DmMemberListSphereMemberFragmentFragment
				>
			}>
		}>
	}>
}

export type GetAllEventsOfSphereQueryVariables = Exact<{
	sphereId: Scalars['ID']
}>

export type GetAllEventsOfSphereQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			attendedUpcomingEvents: {
				__typename: 'SphereEventList'
				data: Array<
					{ __typename: 'SphereEvent' } & SphereEventListEventFragment
				>
			}
			notAttendedUpcomingEvents: {
				__typename: 'SphereEventList'
				data: Array<
					{ __typename: 'SphereEvent' } & SphereEventListEventFragment
				>
			}
			attendedPastEvents: {
				__typename: 'SphereEventList'
				data: Array<
					{ __typename: 'SphereEvent' } & SphereEventListEventFragment
				>
			}
			notAttendedPastEvents: {
				__typename: 'SphereEventList'
				data: Array<
					{ __typename: 'SphereEvent' } & SphereEventListEventFragment
				>
			}
		}>
	}>
}

export type GetSphereViewQueryVariables = Exact<{
	sphereId: Scalars['ID']
}>

export type GetSphereViewQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{ __typename: 'Sphere'; id: string } & SphereViewFragment>
	}>
}

export type GetSphereIdsQueryVariables = Exact<{ [key: string]: never }>

export type GetSphereIdsQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		spheres: {
			__typename: 'SphereList'
			data: Array<Maybe<{ __typename: 'Sphere'; id: string }>>
		}
	}>
}

export type GetAppreciationsForSphereQueryVariables = Exact<{
	sphereId: Scalars['ID']
}>

export type GetAppreciationsForSphereQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			membership?: Maybe<{
				__typename: 'SphereMember'
				agent_id: string
				capabilities: Array<{
					__typename: 'SphereCapability'
					type: SphereCapabilityType
				}>
			}>
			appreciations: Array<
				Maybe<{ __typename: 'Appreciation' } & AppreciationFragmentFragment>
			>
		}>
	}>
}

export type GetEmojiIdsUsedInAppreciationsQueryVariables = Exact<{
	sphereId: Scalars['ID']
}>

export type GetEmojiIdsUsedInAppreciationsQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			appreciations: Array<
				Maybe<{
					__typename: 'Appreciation'
					id: string
					active: boolean
					emoji: { __typename: 'Emoji'; id: string }
				}>
			>
		}>
	}>
}

export type GetEmojisForSphereQueryVariables = Exact<{
	sphereId: Scalars['ID']
	searchTerm?: Maybe<Scalars['String']>
	startAfter?: Maybe<Scalars['ID']>
	limit: Scalars['Int']
}>

export type GetEmojisForSphereQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{
			__typename: 'Sphere'
			id: string
			emojis: {
				__typename: 'EmojiList'
				data: Array<Maybe<{ __typename: 'Emoji' } & EmojiFragmentFragment>>
				paging?: Maybe<{
					__typename: 'EmojiPaging'
					start?: Maybe<string>
					end?: Maybe<string>
					hasNextPage: boolean
				}>
			}
		}>
	}>
}

export type GetSphereHeaderSphereQueryVariables = Exact<{
	id: Scalars['ID']
}>

export type GetSphereHeaderSphereQuery = {
	__typename: 'Query'
	viewer?: Maybe<{
		__typename: 'Viewer'
		sphere?: Maybe<{ __typename: 'Sphere'; id: string; title: string }>
	}>
}

export type OnConversationPresenceOfAttendantsSubscriptionVariables = Exact<{
	conversationId: Scalars['ID']
}>

export type OnConversationPresenceOfAttendantsSubscription = {
	__typename: 'Subscription'
	presenceOfAttendants: {
		__typename: 'AttendantPresence'
	} & AttendantPresenceFragment
}
