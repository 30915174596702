import { Capability, Entity } from '../shared/types'

export enum AttendantConversationRole {
	Creator = 'Creator',
	Participant = 'Participant',
}

interface SendPollCapability extends Capability {
	importantDefault?: boolean
	importantPermitted?: boolean
}

export interface AttendantCapabilities {
	addMembersToChat?: Capability
	removeAttendantsFromChat?: Capability
	unfollowChat?: Capability
	setChatTitle?: Capability
	setChatImage?: Capability
	endChat?: Capability
	sendMessage?: Capability
	reactToMessage?: Capability
	addAppreciationReactionToMessage?: Capability
	removeAppreciationReactionFromMessage?: Capability
	pinMessageToAdminPinboard?: Capability
	removeOwnMessage?: Capability
	removeAnyMessage?: Capability
	replyToMessage?: Capability
	sendAnnouncementMessage?: Capability
	sendPollMessage?: SendPollCapability
}

export interface AttendantInformation {
	conversationId?: string
	displayColor?: string | null
	displayImageFilename?: string | null
	displayName?: string | null
	role?: AttendantConversationRole
}

export interface AttendantModel extends Entity, AttendantInformation {
	lastSeenAt?: string | null
	online?: boolean
	canBeRemovedByViewer?: boolean
}

export interface ViewerAttendantModel extends Entity, AttendantInformation {
	agentId?: string
	followsConversation?: boolean
	muted?: boolean
	mutedUntil?: string | null
	unreadMessageCount?: number
	lastSeenMessageId?: string | null
	outstandingImportantMessageIds?: string[]
	capabilities?: AttendantCapabilities
}
