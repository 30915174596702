import { FC, memo, PropsWithChildren, ReactNode } from 'react'
import { Helmet } from 'react-helmet'

import { Content, Main, Root, Sidebar } from './styled-components'

type Props = PropsWithChildren<{
	sidebarContent?: ReactNode
	headerMenu?: ReactNode
}>

const Layout: FC<Props> = ({ children, sidebarContent, headerMenu }) => (
	<Root>
		<Helmet title="Sphere" />
		<Content>
			{sidebarContent && <Sidebar>{sidebarContent}</Sidebar>}
			<Main>{children}</Main>
		</Content>
	</Root>
)

export default memo(Layout)
