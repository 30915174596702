import { FC } from 'react'
import styled, { useTheme } from 'styled-components'

const Circle = styled.circle`
	transition: stroke-dashoffset 0.35s;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
`

interface ProgressCircleProps {
	size: number
	progress?: number
	strokeWidth?: number
	underline?: boolean
}

export const ProgressCircle: FC<ProgressCircleProps> = ({
	size,
	progress = 0,
	strokeWidth = 2,
	underline = false,
}) => {
	const theme = useTheme()
	const radius = size / 2 - strokeWidth * 2
	const circumference = radius * 2 * Math.PI
	return (
		<svg
			style={{ width: size, height: size }}
			strokeWidth={strokeWidth}
			fill="transparent"
		>
			{underline && (
				<Circle
					stroke={theme.colors.mono400}
					r={radius}
					cx={size / 2}
					cy={size / 2}
				/>
			)}
			<Circle
				stroke={theme.colors.iconFillLight}
				strokeDasharray={`${circumference} ${circumference}`}
				strokeDashoffset={circumference - progress * circumference}
				r={radius}
				cx={size / 2}
				cy={size / 2}
			/>
		</svg>
	)
}

export default ProgressCircle
