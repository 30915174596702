import {
	Agent,
	AttendantInfo,
	AttendantInfoFragment,
	SphereMember,
	Maybe,
} from '../network/graphql'

import {
	Message as FSMessage,
	MessageSenderInfo as FSMessageSenderInfo,
} from '../network/firestore'
import { LocalMessage } from '../slices/localMessages'

// here we can add type-guards to use around project
// for example

// notUndefined
// helps typescript understand that
// we succesfully weeded out undefines in a
// chain of array transforms
// .filter(notUndefined)

// returned type is assertion if x is of type T

export function notUndefined<T>(x: T | undefined): x is T {
	return x !== undefined
}

export function notNull<T>(x: T | null): x is T {
	return x !== null
}

export function notMaybe<T>(x: Maybe<T>): x is T {
	return x !== null && x !== undefined
}

export function notFalse<T>(x: T | false): x is T {
	return x !== false
}

export function isPersistedMessage(
	x?: FSMessage | LocalMessage
): x is FSMessage {
	return !!x && (x as FSMessage).id !== undefined
}

export function isGQLAgent(input: any): input is Agent {
	return (input as any)['__typename'] === 'Agent' && input['id']
}

export function isGQLAttendantInfo(input: any): input is AttendantInfo {
	return input['__typename'] === 'AttendantInfo' && input['attendant_id']
}

export function isGQLAttendantInfoFragment(
	input: any
): input is AttendantInfoFragment {
	return input['__typename'] === 'AttendantInfo' && input['attendant_id']
}

export function isFSMessageSenderInfo(
	input: any
): input is FSMessageSenderInfo {
	return input['attendantId'] && input['displayName'] && input['displayColor']
}

export function isGQLSphereMember(input: any): input is SphereMember {
	return input['__typename'] === 'SphereMember'
}

export function includes<T, U extends T>(
	arr: readonly U[],
	elem: T
): elem is U {
	return arr.includes(elem as any)
}
