import { makeAuthorizationHeader } from '../../utils/apiHelper'
import { constructUrl } from '../../utils/constructUrl'
import {
	getPropertyFromJWT,
	checkIfTokenHasExpired,
} from '../../utils/jwtHelper'
import { FetchManager } from '../api/fetchManager'
import { FetchResult } from '../api/types'

import {
	AccessTokenRefreshCallbacks,
	AccessTokenRefreshManager,
} from './accessTokenRefreshManager'

interface AuthenticatedSessionCallbacks extends AccessTokenRefreshCallbacks {}

export class AuthClient {
	static withCookiesRequestInit = {
		credentials: 'include' as const,
	}

	private accessToken: string | null = null
	private accessTokenRefreshManager: AccessTokenRefreshManager
	private listeners: AuthenticatedSessionCallbacks[] = []
	private mergedCallbacks: AuthenticatedSessionCallbacks = {
		onRefreshSuccess: (newAccessToken: string) => {
			this.setAccessToken(newAccessToken)
			this.listeners.forEach(listener =>
				listener.onRefreshSuccess?.(newAccessToken)
			)
		},
		onRefreshFailure: (errorMessage: string) => {
			this.listeners.forEach(listener =>
				listener.onRefreshFailure?.(errorMessage)
			)
		},
		onRefreshRequest: () => {
			this.listeners.forEach(listener => listener.onRefreshRequest?.())
		},
		onError: (errorMessage: string) => {
			this.listeners.forEach(listener => listener.onError?.(errorMessage))
		},
	}

	constructor(
		private fetchManager: FetchManager,
		private authServiceBase: string
	) {
		this.accessTokenRefreshManager = new AccessTokenRefreshManager(
			this.refreshAccessToken.bind(this)
		)
	}

	private async refreshAccessToken(): Promise<string> {
		const { data, error } = await this.fetchManager.get(
			constructUrl(this.authServiceBase, '/accessToken'),
			AuthClient.withCookiesRequestInit
		)

		if (error) {
			// TODO differentiate between errors due to the current user not being
			// authenticated (401), and network errors / internal server errors, etc
			throw new Error(`Error fetching accessToken: ${error}`)
		}

		const accessToken =
			(!!data && (data as { accessToken: string }).accessToken) || null
		if (!accessToken || typeof accessToken !== 'string') {
			throw new Error('No AccessToken in response')
		}
		return accessToken
	}

	private deleteRefreshToken(): Promise<FetchResult> {
		return this.fetchManager.delete(
			constructUrl(this.authServiceBase, ''),
			AuthClient.withCookiesRequestInit
		)
	}

	private setAccessToken(newAccessToken: string) {
		this.accessToken = newAccessToken
	}

	public subscribe(callbacks: AuthenticatedSessionCallbacks) {
		this.listeners.push(callbacks)
		return () => {
			const index = this.listeners.indexOf(callbacks)
			if (index !== -1) {
				this.listeners.splice(index, 1)
			}
		}
	}

	public async beginAuthenticatedSession(
		callbacks?: AuthenticatedSessionCallbacks
	): Promise<void> {
		if (callbacks) {
			this.subscribe(callbacks)
		}

		this.accessTokenRefreshManager.maintainFreshAccessToken(
			this.mergedCallbacks
		)
	}

	public async endAuthenticatedSession(): Promise<FetchResult> {
		const deleteResult = await this.deleteRefreshToken()
		if (!deleteResult.error) {
			this.accessTokenRefreshManager.stopRefreshingAccessToken?.()
			this.accessTokenRefreshManager = new AccessTokenRefreshManager(
				this.refreshAccessToken.bind(this)
			)
			this.accessToken = null
		}
		return deleteResult
	}

	public isAuthenticated(): boolean {
		return !!this.accessToken && !checkIfTokenHasExpired(this.accessToken)
	}

	public getUserAccessToken(): string | null {
		return this.accessToken
	}

	public getAuthorizationHeader(): undefined | { Authorization: string } {
		const accessToken = this.getUserAccessToken()
		if (!this.isAuthenticated() || !accessToken) {
			return undefined
		}
		return makeAuthorizationHeader(accessToken)
	}

	// TODO: This assumes that the accessToken hasn't been invalidated server-side
	public getAuthenticatedAgentId(): string | null {
		if (!this.accessToken || !this.isAuthenticated()) {
			return null
		}
		const agentId = getPropertyFromJWT(this.accessToken, 'agent_id')
		if (typeof agentId !== 'string') {
			return null
		}
		return agentId
	}

	public async logInWithEmailAndPassword({
		email,
		password,
	}: {
		email: string
		password: string
	}): Promise<FetchResult<any>> {
		const fetchResult = await this.fetchManager.post(
			constructUrl(this.authServiceBase, '/password/login'),
			{ email, password },
			AuthClient.withCookiesRequestInit
		)

		const potentialErrorMessage =
			fetchResult.data && (fetchResult.data as any).message
		if (fetchResult.error && typeof potentialErrorMessage === 'string') {
			return { ...fetchResult, error: potentialErrorMessage }
		}

		return fetchResult
	}
}
