import { COPY } from '../../constants/config'

import {
	AttendantConversationRole,
	AttendantModel,
	ViewerAttendantModel,
} from './types'

export class Attendant {
	static defaultDisplayName: string = COPY.ATTENDANT.DEFAULT_DISPLAY_NAME

	static getDisplayName({
		attendant,
		isOwnAttendant,
	}: {
		attendant: Pick<AttendantModel, 'displayName'>
		isOwnAttendant?: boolean
	}): string | undefined {
		if (!attendant.displayName) {
			return
		}

		return `${attendant.displayName}${isOwnAttendant ? ' (you)' : ''}`
	}

	static compareAttendantPrecedence(
		attendant: Pick<AttendantModel, 'role'>,
		otherAttendant: Pick<AttendantModel, 'role'>
	): -1 | 0 | 1 {
		switch (true) {
			case attendant.role === AttendantConversationRole.Creator &&
				otherAttendant.role === AttendantConversationRole.Creator:
				return 0
			case attendant.role === AttendantConversationRole.Creator:
				return -1
			case otherAttendant.role === AttendantConversationRole.Creator:
				return 1
			default:
				return 0
		}
	}
}

export class ViewerAttendant extends Attendant {
	static personalPronoun: string = COPY.ATTENDANT.VIEWER_SUBJECT_PRONOUN

	static getAgentId(
		attendant: Pick<ViewerAttendantModel, 'agentId'>
	): string | undefined {
		return attendant.agentId
	}

	static getConversationId(
		attendant: Pick<ViewerAttendantModel, 'conversationId'>
	): string | undefined {
		return attendant.conversationId
	}

	static isFollowing(
		attendant: Pick<ViewerAttendantModel, 'followsConversation'>
	): boolean | undefined {
		return attendant.followsConversation
	}

	static hasMuted(
		attendant: Pick<ViewerAttendantModel, 'muted'>
	): boolean | undefined {
		return attendant.muted
	}

	static getUnreadMessageCount(
		attendant: Pick<ViewerAttendantModel, 'unreadMessageCount'>
	): number | undefined {
		return attendant.unreadMessageCount
	}
}

export default Attendant
