import { TransitionStatus } from 'react-transition-group/Transition'
import styled, { CSSObject } from 'styled-components'

import { PLACEHOLDER_IMAGE_URL } from '../../constants/config'

export const TRANSITION_DURATION_MS = 300

const defaultTransitionStyle: CSSObject = {
	opacity: 0,
}
const transitionStyles: Record<TransitionStatus, CSSObject> = {
	entering: { opacity: 1 },
	entered: { opacity: 1 },
	exiting: { opacity: 0 },
	exited: { opacity: 0 },
	unmounted: { opacity: 0 },
}
const animation = `transition: opacity ${TRANSITION_DURATION_MS}ms linear;`

export const StyledImage = styled.img<{
	$transitionStatus: TransitionStatus
	$withAnimation?: boolean
}>`
	width: 100%;
	height: 100%;
	object-fit: cover;

	${defaultTransitionStyle}
	${p => p.$withAnimation && animation}
	${p => transitionStyles[p.$transitionStatus]}
`

export const Placeholder = styled.div<{
	$transitionStatus: TransitionStatus
	$withAnimation?: boolean
}>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	${defaultTransitionStyle}
	${p => p.$withAnimation && animation}
	${p => transitionStyles[p.$transitionStatus]}
`

export const SVGPlaceholder = styled(Placeholder)`
	background-color: ${({ theme }) => theme.colors.backgroundPrimary};
	background-size: 50px 50px;
	background-image: url('${PLACEHOLDER_IMAGE_URL}');
`
