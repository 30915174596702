import { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { signOut } from '../../actions/auth'
import useAsyncSafeState from '../../hooks/useAsyncSafeState'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import { useGetOwnProfileQuery } from '../../hooks/useGetOwnProfileQuery'

import Modal, { SIZE } from '../../components/AKModal'
import AppLoadingScreen from '../../components/AppLoadingScreen'
import Settings from '../../components/Settings/Settings'

import EditProfileContainer from '../EditProfile'

import { actions, ThemeState } from '../../slices/theme'
import SuperSegment from '../../utils/segmentTypes'

import useDebugSettings from './useDebugSettings'

export const SettingsContainer: FC = () => {
	const { data, loading } = useGetOwnProfileQuery()
	const viewerAgent = data?.viewer?.agent
	const viewerPerson = data?.viewer?.person

	const darkModeFeatureEnabled = useFeatureFlags('dark-mode', false)

	const dispatch = useDispatch()
	const themePreference = useSelector(state => state.theme)
	const systemPrefersDarkColorScheme = useSelector(
		state => !!state.environment.prefersDarkColorScheme
	)
	const handleSetThemePreference = useCallback(
		(preference: ThemeState) => {
			dispatch(actions.setTheme(preference))
		},
		[dispatch]
	)

	const [debugSettings, setDebugValue] = useDebugSettings()

	const handleSignout = useCallback(() => {
		SuperSegment.SIGN_OUT_CLICKED()

		dispatch(signOut())
	}, [dispatch])

	const [editingProfile, setEditingProfile] = useAsyncSafeState(false)

	const handleProfileEditClick = useCallback(() => {
		SuperSegment.EDIT_PROFILE_CLICKED()
		setEditingProfile(true)
	}, [setEditingProfile])

	const handleProfileEditSuccess = useCallback(() => {
		setEditingProfile(false)
	}, [setEditingProfile])

	if (!viewerAgent || !viewerPerson || loading) {
		return <AppLoadingScreen />
	}

	return (
		<>
			<Settings
				displayName={viewerAgent.name}
				imageFilename={viewerAgent.image_filename}
				imageColor={viewerAgent.color}
				bio={viewerPerson.about}
				email={viewerPerson.email}
				onEditProfile={handleProfileEditClick}
				darkModeFeatureEnabled={darkModeFeatureEnabled}
				themePreference={themePreference}
				systemPrefersDarkColorScheme={systemPrefersDarkColorScheme}
				onSetThemePreference={handleSetThemePreference}
				onSignout={handleSignout}
				debugSettings={debugSettings}
				onDebugSettingToggle={setDebugValue}
			/>
			<Modal
				isOpen={editingProfile}
				size={SIZE.auto}
				closeable
				onClose={handleProfileEditSuccess}
			>
				<EditProfileContainer
					data={data}
					onSuccess={handleProfileEditSuccess}
				/>
			</Modal>
		</>
	)
}

export default SettingsContainer
