import { ConversationRole } from '../network/graphql'

export const DEFAULT_API_VERSION = 'api'

export const CONFIG_PATH = '/config.json'

const SECOND = 1000
const MINUTE = 60 * SECOND
export const MAX_TIMESPAN_BETWEEN_MESSAGES_WITH_ONE_TIMESTAMP = 5 * MINUTE
export const TIMER_UPDATE_INTERVAL = 0.5 * SECOND
export const NOTIFICATION_TIMEOUT_INTERVAL = 5 * SECOND
export const POLLING_INVERVAL = 30 * SECOND
export const BUNDLE_UPDATE_INTERVAL = 5 * MINUTE

export const LOGO_PATH = '/img/logo.png'

export const MESSAGE_REPLY_TYPES = {
	TEXT: 'text',
	NO_REPLY: 'no_reply',
	BUTTONS: 'buttons',
	EMAIL: 'email',
	PASSWORD: 'password',
	CODE: 'code',
	CONTEXT: 'context',
	PHONE: 'phone',
}

export const HIDE_INPUT_ON_REPLY_TYPES = [
	MESSAGE_REPLY_TYPES.NO_REPLY,
	MESSAGE_REPLY_TYPES.BUTTONS,
]

export const COPY = {
	SETTINGS: {
		TITLE: 'Settings',
		EDIT_BUTTON_LABEL: 'Edit',
		DARK_MODE_LABEL: 'Dark mode',
		EXPERIMENTS_LABEL: 'Experimental features',
		DESCRIPTION_SECTION_TITLE: 'Your bio',
		APPEARANCE_SECTION_TITLE: 'Appearance',
		APPEARANCE_AUTO_LABEL: 'System preference',
		APPEARANCE_LIGHT_LABEL: 'Light theme',
		APPEARANCE_DARK_LABEL: 'Dark theme',
		EXPERIMENTS_SECTION_TITLE: 'Experiments',
		SIGNOUT_BUTTON_LABEL: 'Sign out',
	},
	USER_PROFILE: {
		ADMIN_SUBTITLE: 'Admin',
		ONLINE_SUBTITLE: 'Online',
		EMPTY_DESCRIPTION_PLACEHOLDER: "User hasn't entered a description.",
		DIRECT_MESSAGE_BUTTON_LABEL: 'Send a message',
	},
	DM_CREATE_FLOW: {
		TITLE: 'Direct message',
		NO_DMABLE_MEMBERS:
			'You can start direct messaging once you invite more people to your Sphere.',
		ERROR_TEXT: 'Failed to create direct message chat',
	},
	EVENT_FORM_FLOW: {
		CREATE_TITLE: 'New event',
		EDIT_TITLE: 'Update event',
		SUBMIT_LABEL: 'Create',
		SAVE_LABEL: 'Save',
		IMAGE_UPLOAD: 'Upload image',
		IMAGE_SEARCH: 'Search web',
		TITLE_PLACEHOLDER: 'Title',
		DESCRIPTION_PLACEHOLDER: 'Description',
		LOCATION_PLACEHOLDER: 'Location',
		LINK_PLACEHOLDER: 'Link',
		NOTIFICATION_DISCLAIMER:
			'Creating an event will notify all members of a sphere and require them to RSVP',
		START_DATE_LABEL: 'Start date',
		END_DATE_LABEL: 'End date',
		START_DATE_PLACEHOLDER: 'DD/MM/YYYY',
		END_DATE_PLACEHOLDER: 'DD/MM/YYYY',
		START_TIME_PLACEHOLDER: 'Start time',
		END_TIME_PLACEHOLDER: 'End time',
		SUBMIT_ERROR_TEXT: 'Something went wrong',
		END_TIME_TOO_EARLY_ERROR_TEXT: 'End time must be after start time',
		START_DAY_BEFORE_TODAY_ERROR_TEXT:
			'Start date and time cannot be in the past',
		END_DAY_BEFORE_TODAY_ERROR_TEXT: 'End date and time cannot be in the past',
		END_DAY_BEFORE_START_DAY_ERROR_TEXT: 'End day must be after start day',
		MISSING_TITLE_ERROR_TEXT: 'Title required',
		MISSING_START_DATE_ERROR_TEXT: 'Start date required',
		MISSING_START_TIME_ERROR_TEXT: 'Start time required',
		NOTIFY_SELECTION_HEADER: 'Update everyone?',
		NOTIFY_SELECTION_BODY:
			'Attendees will be notified about your changes to this event.',
		NOTIFY_SELECTION_CONFIRM: 'Update everyone',
		NOTIFY_SELECTION_DENY: 'Do not update',
		NOTIFY_SELECTION_CANCEL: 'Cancel',
	},
	CHAT_INFO: {
		CREATOR_LABEL: 'Chat creator',
		ONLINE_LABEL: 'online',
		LABEL_SEPARATOR: '•',
		PAGE_TITLE: 'Chat info',
		EDIT_BUTTON_LABEL: 'Edit',
		DESCRIPTION_SECTION_TITLE: 'Description',
		ATTENDANTS_SECTION_TITLE_SUFFIX: 'person',
		ADD_USERS: 'Add people',
		ADD_USERS_FAILED: 'Failed to add users',
		REMOVE_PERSON: 'Remove from chat',
		REMOVE_PERSON_CONFIRMATION_HEADER: 'Are you sure?',
		REMOVE_PERSON_CONFIRMATION_BODY:
			'If a person is removed from a secret chat, they will no longer be able to see any messages. You can re-invite them from the chat information screen.',
		REMOVE_PERSON_CONFIRMATION_REMOVE: 'Remove',
		REMOVE_PERSON_CONFIRMATION_CANCEL: 'Cancel',
		REMOVE_PERSON_FAILED: 'Failed to remove user',
		NO_USERS_TO_ADD:
			'All members of this sphere are already part of this chat.',
		FOLLOW_CONVERSATION_ERROR: 'Failed to follow this chat',
	},
	FEED: {
		EVENTS_SECTION_TITLE: 'New Events',
		EVENTS_SECTION_EMPTY_STATE_LABEL: 'You have no new events',
		EMPTY_STATE_TITLE: 'No Chats',
		EMPTY_STATE_SUBTITLE: 'Be the pioneer, create a first chat.',
		EMPTY_STATE_BUTTON_LABEL: 'Create a new chat',
		SPHERE_INFO_MENU_ITEM_LABEL: 'Sphere info',
		MY_CHATS_SECTION_TITLE: 'My chats',
		MY_CHATS_SECTION_EMPTY_STATE_LABEL: 'You are not following any chats',
		OTHER_CHATS_SECTION_TITLE: 'Other chats',
		OTHER_CHATS_SECTION_EMPTY_STATE_LABEL: 'No other chats to follow',
		SEARCH_PLACEHOLDER: 'Search chats by title',
	},
	FEED_MENU: {
		EVENTS: 'Events',
		PINNED_MESSAGES: 'Pinned messages',
		SPHERE_INFO: 'Sphere info',
	},
	FEED_ITEM: {
		FOLLOWER_COUNT_SUFFIX: 'follower',
	},
	IMAGE_INPUT: {
		BUTTON_TITLE: 'Upload an image',
	},
	IMAGES: {
		PLACEHOLDER_ALT_TEXT: 'Image with no description',
	},
	NOTIFICATION_PERMISSION_USER:
		'Get notifications when Sphere Experts answer your query or send you a ' +
		'message.',
	NOTIFICATION_PERMISSION_EXPERT:
		'Notifications help you keep track of queries and messages sent to you ' +
		'via Sphere.',
	SPHEREBOT: {
		NAME: 'Sphere',
		DESCRIPTION: "I'm an expert on all things Sphere",
	},
	INVITE_FRIENDS_TO_SPHERE:
		'Invite a friend to Sphere and we will give them free credit ' +
		'and you will receive a bonus as well.',
	DEFAULT_AGENT_NAME: 'Sphere user',
	ATTENDANT: {
		DEFAULT_DISPLAY_NAME: 'Member',
		VIEWER_SUBJECT_PRONOUN: 'You',
	},
	MESSAGE_DELETED_LABEL: 'Message was deleted.',
	MESSAGE_NOT_FOUND: 'Message not found',
	MESSAGE_TYPE_NOT_SUPPORTED_ON_WEB:
		'Message type currently not supported on web. Please download our mobile apps to see this message.',
	SERVICE_MESSAGE: {
		USER_JOINED: 'followed the chat',
		USER_LEFT_PRIVATE_CHAT: 'unfollowed the chat',
		USER_LEFT: 'unfollowed the chat, @mention to bring them back',
	},
	NOTIFICATION_PERMISSION: {
		DIALOG_TITLE: 'Enable notifications',
		DIALOG_BODY:
			"Enable notifications so you don't miss any activity on Sphere.",
		CONFIRM_BUTTON_LABEL: 'Enable',
		CANCEL_BUTTON_LABEL: 'Not now',
	},
	VERIFY_EMAIL_DIALOG_TITLE: 'Check your email',
	VERIFY_EMAIL_DIALOG_BODY:
		'Open the link in the email we sent you to verify your account. You can also do this later.',
	WHAT_IS_A_SPHERE_DISCLAIMER:
		'A sphere is a group of people that can have lots of different chats together.',
	SPHERE_MEMBER_LIST_EMPTY_TITLE: 'No one here',
	EMPTY_SPHERE_MEMBER_LIST_MESSAGE:
		'There are no members in this sphere yet. Invite people to your sphere to start chatting.',
	PRIVATE_CHAT_DISCLAIMER:
		'Messages can only be seen by the people added to this chat.',
	SPHERE_MISSING_DESCRIPTION: 'No description provided.',
	POLLS: {
		TITLE: 'Poll',
	},
	POLL_RESPONSES: {
		LOADING: 'Loading poll responses',
	},
	POLL_COMPOSER: {
		MESSAGE_INPUT_PLACEHOLDER: 'Ask a question',
		OPTION_PLACEHOLDER: 'Option',
		MULTIPLE_CHOICE_LABEL: 'Multiple Choice',
		SEND_AS_IMPORTANT_LABEL: 'Important poll',
		SENDING_AS_IMPORTANT_DISCLAIMER:
			'Sending a Poll as an important message will notify all members of the chat and require them to acknowledge it.',
	},
	ANNOUNCEMENTS: {
		TITLE: 'Announcement',
		SENDING_DISCLAIMER:
			'Sending an Announcement will notify all members of the chat and require them to acknowledge it.',
		SENDING_PLACEHOLDER: 'Type an announcement...',
	},
	IMPORTANT_MESSAGES_LIST: {
		PAGE_TITLE: 'Important Messages',
		ALL_MESSAGES_TAB_TITLE: 'All',
		OUTSTANDING_MESSAGES_TAB_TITLE: 'Active',
		LOAD_MORE_BUTTON_LABEL: 'Load More',
		DEFAULT_ERROR_MESSAGE:
			'Something went wrong when fetching messages, please try again',
		NO_MESSAGES_FOUND_TITLE: 'No messages',
		NO_MESSAGES_FOUND_MESSAGE: 'All your Important Messages will appear here',
	},
	EVENT_LISTS: {
		LINK_NAME: 'Events',
		PAGE_TITLE: 'Events',
		UPCOMING_TAB_TITLE: 'Active',
		PAST_TAB_TITLE: 'Past',
		ATTENDING_SECTION_LABEL: 'My events',
		ATTENDING_EMPTY_LABEL: "You aren't going to any upcoming events",
		NON_ATTENDING_SECTION_LABEL: 'Other events',
		NON_ATTENDING_EMPTY_LABEL: 'There are no other upcoming events',
		ATTENDED_SECTION_LABEL: 'My events',
		ATTENDED_EMPTY_LABEL: "You haven't gone to any past events",
		UNATTENDED_SECTION_LABEL: 'Other events',
		UNATTENDED_EMPTY_LABEL: 'There are no other past events',
		EMPTY_PLACEHOLDER_TITLE: 'No events',
		EMPTY_UPCOMING_PLACEHOLDER_CAPTION:
			'There are no active events in the sphere',
		EMPTY_PAST_PLACEHOLDER_CAPTION: 'There are no past events in the sphere',
		EMPTY_PLACEHOLDER_CREATE_LABEL: 'Create an Event',
		ERROR_TITLE: 'Something went wrong',
		ERROR_CAPTION: 'That didn’t seem to work',
		ERROR_REFRESH_ACTION_LABEL: 'Refresh',
	},
	EVENT_INFO: {
		LOAD_MORE_ERROR_MESSAGE: 'Failed to load invitees',
		LOAD_MORE_INVITEES_BUTTON_LABEL: 'Show all',
		PAGE_TITLE: 'Event',
		CHAT_LINK_TEXT: 'Go to discussion',
		GOING_TAB_LABEL: 'Going',
		NOT_GOING_TAB_LABEL: 'Not going',
		THINKING_TAB_LABEL: 'Thinking',
		PENDING_TAB_LABEL: "Didn't respond",
		INVITEES_SECTION_LABEL: 'Participants',
		EVENT_DETAILS_SECTION_LABEL: 'Event Details',
		INVITEES_EMPTY_LABEL: 'No one chose this option',
		CREATOR_PLACEHOLDER_NAME: "Member doesn't exist",
		EDIT_BUTTON_LABEL: 'Edit',
	},
	EVENT_DELETE: {
		BUTTON_LABEL: 'Delete',
		DIALOG_TITLE: 'Delete the event?',
		DIALOG_BODY: 'Deleting this event will not delete the associated chat',
		CANCEL_LABEL: 'Cancel',
		CONFIRM_LABEL: 'Confirm',
		ERROR_MESSAGE: 'Failed to delete event',
	},
	EVENT_RSVP: {
		ERROR_MESSAGE: 'Failed to RSVP to event',
		GOING_OPTION: 'Going',
		NOT_GOING_OPTION: 'Not going',
		THINKING_OPTION: 'Thinking',
		BUTTON_LABEL: 'RSVP',
		ENDED_GOING: 'Attended',
	},
	GENERIC_ERROR_DIALOG: {
		TITLE: 'Something went wrong',
		DISMISS_BUTTON_LABEL: 'Dismiss',
	},
	FILE_ATTACHMENTS: {
		UI_ELEMENT_HINT: 'Download file',
		FILE_TYPE_NOT_ALLOWED_ERROR:
			'We currently only support image file attachments.',
		TOO_MANY_FILES_ERROR:
			"We currently don't support multiple file attachements. Please send one file at a time.",
	},
	FILE_ATTACHMENTS_ERROR_DIALOG: {
		HEADER: 'Oops',
		BODY: 'Unfortunatley that file is too large. Size limit is:',
		CONFIRM: 'Ok',
	},
	MESSAGE_FEED_CAPTION: {
		VIDEO: 'sent a video',
		IMAGE: 'sent an image',
		FILE: 'sent a file',
		ANIMATED_IMAGE: 'sent a gif',
		DEFAULT: 'sent a message',
	},
	MESSAGE_REPLY_CAPTION: {
		VIDEO: 'Video',
		IMAGE: 'Sent an image',
		ANIMATED_IMAGE: 'Gif',
	},
	MESSAGE_INPUT_MENU: {
		MEDIA: 'Photo or Video',
		FILE: 'File',
		ANNOUNCEMENT: 'Announcement',
		POLL: 'Poll',
		ANIMATED_IMAGE: 'GIF',
	},
	CHAT_HEADER_MENU: {
		CHAT_INFO_OPTION_LABEL: 'Chat info',
	},
	CHAT_HEADER_SUBTITLE: {
		RECENTLY_ACTIVE_COUNT_SUFFIX: 'recently online',
		ONLINE_COUNT_SUFFIX: 'online',
		MULTIPLE_PEOPLE_TYPING_SUFFIX: 'people typing...',
		TWO_PEOPLE_TYPING_SUFFIX: 'are typing...',
		SINGLE_PERSON_TYPING_SUFFIX: 'is typing...',
	},
	ONE_TO_ONE_DM_CHAT_HEADER_SUBTITLE: {
		OTHER_PERSON_TYPING: 'typing...',
		OTHER_PERSON_ONLINE: 'online',
		OTHER_PERSON_LAST_SEEN_PREFIX: 'last seen',
	},
	SPHERE_TRAY: {
		CREATE_ACTION_TOOLTIP: 'Create a new sphere',
	},
	REACTIONS: {
		TOOLTIP_VERB: 'reacted',
	},
	MESSAGE_ACTIONS: {
		REACT_TOOLTIP: 'React',
		APPRECIATION_TOOLTIP: 'Appreciate',
		REPLY_TOOLTIP: 'Reply',
		REMOVE_TOOLTIP: 'Remove',
		PIN_TOOLTIP: 'Pin',
		UNPIN_TOOLTIP: 'Unpin',
	},
	MESSAGE_OVERFLOW_ACTIONS: {
		COPY_LINK_LABEL: 'Copy link',
	},
	ERROR_DIALOG: {
		BODY: 'Do you want to try again?',
		CANCEL: 'Cancel',
		TRY_AGAIN: 'Try again',
	},
	TIME: {
		MONTH_NAMES: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		],
		MONTH_NAME_ABBREVIATIONS: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		],
		DAY_NAMES: [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday',
		],
		DAY_NAME_ABBREVIATIONS: ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'],
	},
	ANIMATED_IMAGES: {
		SEARCH_GIPHY: 'Search GIPHY',
		PREPARING_GIPHY: 'Preparing gif',
		NO_RESULTS: 'No gif found',
		PREPARING_FAILED: 'Preparing gif failed',
	},
	REMINDERS: {
		REMIND_ALL_BUTTON_LABEL: 'Remind everyone',
		REMIND_INDIVIDUAL_BUTTON_LABEL: 'Remind',
		REMINDER_SENT_BUTTON_LABEL: 'Sent',
		REMINDER_FAILED: 'Reminder failed',
	},
	EVENT_REMINDERS: {
		GENERIC_ERROR_MESSAGE: 'Something went wrong',
		OPEN_REMINDER_LIST_BUTTON_LABEL: 'Remind',
		THINKING_SECTION_LABEL: 'Still Thinking',
		PENDING_SECTION_LABEL: "Didn't respond",
	},
	ADMIN_PINBOARD: {
		TITLE: 'Pinned messages',
		LOADING: 'Preparing pinned messages',
		NO_PINNED: 'There are no pinned messages yet.',
		PINNING_ERROR: 'Pinning failed',
		UNPINNING_ERROR: 'Unpinning failed',
		PINNING_CONFIRMATION_HEADER: 'Message Pinned',
		PINNING_CONFIRMATION_BODY: 'All your messages will appear on the pinboard',
		PINNING_CONFIRMATION_DISMISS: 'Got it',
		PINNING_CONFIRMATION_CONFIRM: 'View',
		PINNED_IN_CHAT: 'Pinned',
	},
	DELETE_MESSAGE_DIALOG: {
		HEADER: 'Remove message?',
		BODY: 'This will delete your message forever.',
		DISMISS: 'Cancel',
		DELETE: 'Remove',
		ERROR: 'Failed to remove message.',
	},
	DELETE_MESSAGE_DIALOG_ON_PINBOARD: {
		HEADER: 'Unpin message?',
		BODY: 'This will unpin the message but keep the original.',
		DISMISS: 'Cancel',
		DELETE: 'Unpin',
		ERROR: 'Failed to unpin message.',
	},
	NO_SPHERES: {
		TITLE: 'You’re not in a sphere',
		DESCRIPTION: 'Ask for an invite link to join one.',
		CREATE_ACTION_TOOLTIP: 'Create a new sphere',
		CREATE_ACTION_LABEL: 'Create a new sphere',
	},
	APPRECIATIONS: {
		TITLE: 'Appreciations',
		EDIT: 'Edit',
		REMOVE: 'Remove',
		CREATE_LABEL: 'Create',
		ERROR_BUTTON_LABEL: 'Retry',
		ERROR_TITLE: 'Loading error',
		ERROR_CAPTION: 'Please retry to fetch the data',
		TOOLTIP_VERB: 'appreciated',
		MESSAGE_ACTION_TOOLTIP: 'Appreciate',
		PICKER_TITLE: 'Send an appreciation',
	},
	EMOJI_PICKER: {
		HEADER_LABEL: 'Choose Emoji',
		NEXT_PAGE_LABEL: 'Load more',
		ERROR_MESSAGE: 'Something went wrong',
	},
	APPRECIATION_FORM_FLOW: {
		CREATE_TRIGGER_BUTTON_TITLE: 'Create a new appreciation for this sphere',
		CREATE_PROCEED_LABEL: 'Create',
		EDIT_PROCEED_LABEL: 'Save',
		CREATE_TITLE: 'New Appreciation',
		EDIT_TITLE: 'Edit Appreciation',
		NAME_PLACEHOLDER: 'Name',
		DESCRIPTION_PLACEHOLDER: 'Description (Optional)',
		DISCLAIMER:
			'Creating an Appreciation will allow everyone in your sphere to see and use it',
		DISABLED_EMOJI_WARNING_HEADER: 'Seems like this emoji is already in use',
		DISABLED_EMOJI_WARNING_BODY: 'Choose another one please',
		DISABLED_EMOJI_WARNING_CONFIRM: 'Got It',
		ERROR_MESSAGE_FALLBACK: 'Something went wrong',
		EMPTY_NAME_ERROR_MESSAGE: 'Name must contain at least one character',
	},
	APPRECIATION_REMOVE_FLOW: {
		HEADER: 'Are you sure?',
		BODY: 'This Appreciation will no longer be available to your group, but will remain on messages it has already been applied to.',
		DISMISS_ACTION: 'Cancel',
		CONFIRM_ACTION: 'Remove',
		ERROR_MESSAGE: 'Failed to remove appreciation',
	},
	CONTENT_FEED: {
		ICONS_STARTED_A_CHAT: 'Started a chat',
		EMPTY_TITLE: 'No active conversations',
		EMPTY_BODY: 'Conversations moved to archive after 24 hours of inactivity.',
		FETCHING_FEED: 'Fetching Zen Flow',
		TAG_EXPIRING: 'Expiring',
		TAG_NEW: 'New',
		INPUT_PLACEHOLDER: 'Reply',
		SENDING_FAILED: "Message couldn't be sent",
		HIDE_FROM_FEED: 'Hide from Zen Flow',
		HIDE_FROM_FEED_FAILED: 'Hiding failed',
	},
	CREATE_CONTENT: {
		CREATE_BUTTON_LABEL: '+ Start a chat',
		MENU_HEADER_LABEL: 'chat type',
		NEW_CHAT_CARD_OPTION_LABEL: 'Group',
		NEW_DM_OPTION_LABEL: 'Direct message',
		NEW_EVENT_OPTION_LABEL: 'Event',
		CHAT_TITLE_PLACEHOLDER_IN_FLOW: 'Title (optional)',
		CHAT_TITLE_PLACEHOLDER: 'Chat name',
		CHAT_DESCRIPTION_PLACEHOLDER: 'What would you like to discuss?',
	},
} as const

export const SPHERE_BOT_AGENT_ID = '00000000000000000000000000000001'

export const ROOT_TAG_ID = '00000000000000000000000000000010'

export const DEFAULT_TAG_TREE_VERSION = '1'

export const DEFAULT_LOADING_STATUS_TIMEOUT = 3 * SECOND

// Auction duration + 20 seconds for client to read
export const SECONDS_FOR_ANSWER = 3 + 20

export const HASHTAG_REGEX = /\B(#[a-zA-Z0-9]+\b)/g
export const DRAFTJS_MENTIONS_HASHTAG_REGEX = /\B(#([a-zA-Z0-9])*)/g
export const HASHTAG_TRIGGER = '#'

export const QUERY_MIN_CHARACTER_COUNT = 10

export const SEND_IMAGES_DISABLED = true

export const PLAY_STORE_URL =
	'https://play.google.com/store/apps/details' +
	'?id=com.sphere.androidapp.prod&utm_source=webapp'
export const APP_STORE_URL =
	'https://apps.apple.com/gb/app/sphere-group-chat/id1488310384'

export const SUGGEST_CHAT_INTERCOM_PLACEHOLDER = 'I want to talk about '

export const MAX_CHAT_TITLE_CHARACTER_LIMIT = 25
export const CHAT_IMAGE_PAGINATION_LIMIT = 100
export const CHAT_BING_IMAGE_SEARCH_DEFAULT_IMAGES_SEARCH_VALUE =
	'beautiful places'

export const MAX_SPHERE_TITLE_CHARACTER_LIMIT = 25
export const MAX_SPHERE_DESCRIPTION_CHARACTER_LIMIT = 250

export const MAX_SPHERE_EVENT_TITLE_CHARACTER_LIMIT = 64
export const MAX_SPHERE_EVENT_DESCRIPTION_CHARACTER_LIMIT = 500
export const MAX_SPHERE_EVENT_LOCATION_CHARACTER_LIMIT = 200
export const MAX_SPHERE_EVENT_LINK_CHARACTER_LIMIT = 200

export const MAX_APPRECIATION_NAME_LENGTH = 25
export const MAX_APPRECIATION_DESCRIPTION_LENGTH = 250

export const CHATS_POLL_INTERVAL = 10000
export const SPHERES_POLL_INTERVAL = CHATS_POLL_INTERVAL

export const MAX_FEED_WIDTH = 600

export const SHOW_PROFILE_POPOVER_AFTER_MILLIS = 300

// This is a hard max limit set by the backend. Check with
// a backend engineer before changing it.
export const MESSAGES_PAGINATION_SIZE = 50

export const REFETCH_UPDATED_MESSAGE_THROTTLE_INTERVAL = 300
export const SEE_LATEST_CONVERSATION_MESSAGE_THROTTLE_INTERVAL = 300

export const GQL_CLIENT_SAGA_CONTEXT_KEY = 'gqlClient'
export type GqlClientSagaContextKey = typeof GQL_CLIENT_SAGA_CONTEXT_KEY
export const AUTH_CLIENT_SAGA_CONTEXT_KEY = 'authClient'
export type AuthClientSagaContextKey = typeof AUTH_CLIENT_SAGA_CONTEXT_KEY
export const API_CLIENT_SAGA_CONTEXT_KEY = 'apiClient'
export type ApiClientSagaContextKey = typeof API_CLIENT_SAGA_CONTEXT_KEY
export const INJECTABLE_STORE_SAGA_CONTEXT_KEY = 'injectableStore'
export type InjectableStoreSagaContextKey =
	typeof INJECTABLE_STORE_SAGA_CONTEXT_KEY

export const REFRESH_TOKEN_BUFFER_MS = 5 * MINUTE
export const REFRESH_TOKEN_RETRIES = 10

export const BASEWEB_PROVIDER_Z = 100
export const BASEWEB_PROVIDER_Z_PLUS_1 = BASEWEB_PROVIDER_Z + 1

export const POLL_MAX_OPTION_LENGTH = 150

export const PLACEHOLDER_IMAGE_URL = '/img/card_placeholder_tile.svg'

export const FS_IMPORTANT_MESSAGES_MAX_PAGE_SIZE = 10
export const FS_MESSAGES_MAX_PAGE_SIZE = 50

export const FIREBASE_APP_NAME = 'SPHERE_WEB'
export const FIREBASE_CONTENT_FEED_APP_NAME = 'SPHERE_WEB_CONTENT_FEED'

export const ATTACHMENT_BOX_MAX_HEIGHT = 320
export const MESSAGE_ATTACHMENTS_CONTAINER_MAX_WIDTH = 480

export const TYPING_INDICATOR_LIFETIME_FALLBACK = 3000

export const REACTIONS_NAMES_MAX_LENGTH = 1000

export const CONVERSATION_ROLE_ORDER_IN_LISTS: ConversationRole[] = [
	ConversationRole.ConversationCreator,
	ConversationRole.ConversationParticipant,
]

export const PINNED_BEFORE_KEY = 'pinned before'

export const TRAY_SHOW_SPHERE_LOGO = false

// Local store
export const LOCAL_STORE_PERSIST_THROTTLE_WAIT = 1000
export const LOCAL_STORE_PREFIX = 'state-persist'

export const EMOJI_PICKER_PAGE_SIZE = 200
