import { take, put, select } from 'typed-redux-saga/macro'

import { fetchConversationMessage } from '../actions/messages'

import { REFETCH_UPDATED_MESSAGE_THROTTLE_INTERVAL } from '../constants/config'
import conversations from '../slices/conversations'
import { firestoreMessagesSelectors } from '../slices/firestoreMessages'
import { memoizedThrottleFunctionFactory } from '../utils/functionsHelper'
import { timestampToMilliseconds } from '../utils/time'

const memoizeHashFunction = (conversationId: string, messageId: string) =>
	`${conversationId}_${messageId}`

const throttledFetch = memoizedThrottleFunctionFactory(
	fetchConversationMessage,
	REFETCH_UPDATED_MESSAGE_THROTTLE_INTERVAL,
	{
		memoizeHashFunction,
	}
)

export default function* observeMessageUpdates(
	fetchMessageThunk: (
		conversationId: string,
		messageId: string
	) => any = throttledFetch
) {
	while (true) {
		const action = yield* take(
			conversations.actions.conversationStateUpdate.match
		)
		if (conversations.actions.conversationStateUpdate.match(action)) {
			if (
				!action.payload.conversationId ||
				!action.payload.lastUpdatedMessageInfo?.messageId
			) {
				continue
			}

			const messageId = action.payload.lastUpdatedMessageInfo?.messageId
			const conversationId = action.payload.conversationId

			const updatedAt = action.payload.lastUpdatedMessageInfo?.updatedAt
			const updateAtMillis = updatedAt
				? timestampToMilliseconds(updatedAt.seconds, updatedAt.nanoseconds)
				: undefined

			const cachedMessage = yield* select(store =>
				typeof messageId === 'string'
					? firestoreMessagesSelectors.selectById(store, messageId)
					: undefined
			)
			const cachedMessageUpdatedAtMillis = cachedMessage?.updatedAt
				? timestampToMilliseconds(
						cachedMessage.updatedAt.seconds,
						cachedMessage.updatedAt.nanoseconds
				  )
				: undefined

			const isUpdatedMessageUpdatedMoreRecentlyThanCachedMessage =
				cachedMessageUpdatedAtMillis === undefined ||
				(updateAtMillis !== undefined &&
					updateAtMillis > cachedMessageUpdatedAtMillis)

			const shouldFetchUpdatedMessage =
				!!cachedMessage && isUpdatedMessageUpdatedMoreRecentlyThanCachedMessage

			if (messageId && conversationId && shouldFetchUpdatedMessage) {
				yield* put(fetchMessageThunk(conversationId, messageId))
			}
		}
	}
}
