import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit'

import { MessageReaction } from '../network/firestore'

import { LegacyEmojiReactionTable } from './config'

export type LoadingReactionPayload = {
	messageId: string
	conversationId: string
	reaction: MessageReaction
	legacyEmojiReactionTable: LegacyEmojiReactionTable
}

export type LoadingReaction = LoadingReactionPayload & {
	direction: 'adding' | 'deleting'
}

export function selectUniqueMessageReactorIdentifier(payload: {
	messageId: string
	attendantId: string
	conversationId: string
}) {
	return `${payload.messageId}_${payload.attendantId}_${payload.conversationId}`
}

export function selectId(payload: LoadingReactionPayload) {
	return `${selectUniqueMessageReactorIdentifier({
		messageId: payload.messageId,
		conversationId: payload.conversationId,
		attendantId: payload.reaction.attendantId,
	})}_${payload.reaction.type}`
}

export const loadingReactionsAdapter = createEntityAdapter<LoadingReaction>({
	selectId,
})

export type MessageReactionsState = {
	loading: EntityState<LoadingReaction>
}

const initialState: MessageReactionsState = {
	loading: loadingReactionsAdapter.getInitialState(),
}

export const messageReactions = createSlice({
	name: 'messageReactions',
	initialState,
	reducers: {
		createRequest(stateDraft, action: PayloadAction<LoadingReactionPayload>) {
			loadingReactionsAdapter.addOne(stateDraft.loading, {
				...action.payload,
				direction: 'adding',
			})
		},
		createSuccess(stateDraft, action: PayloadAction<LoadingReactionPayload>) {
			loadingReactionsAdapter.removeOne(
				stateDraft.loading,
				selectId(action.payload)
			)
		},
		createFailure(stateDraft, action: PayloadAction<LoadingReactionPayload>) {
			loadingReactionsAdapter.removeOne(
				stateDraft.loading,
				selectId(action.payload)
			)
		},
		deleteRequest(stateDraft, action: PayloadAction<LoadingReactionPayload>) {
			loadingReactionsAdapter.addOne(stateDraft.loading, {
				...action.payload,
				direction: 'adding',
			})
		},
		deleteSuccess(stateDraft, action: PayloadAction<LoadingReactionPayload>) {
			loadingReactionsAdapter.removeOne(
				stateDraft.loading,
				selectId(action.payload)
			)
		},
		deleteFailure(stateDraft, action: PayloadAction<LoadingReactionPayload>) {
			loadingReactionsAdapter.removeOne(
				stateDraft.loading,
				selectId(action.payload)
			)
		},
	},
})

export default messageReactions
