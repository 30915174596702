import { useEffect, lazy, Suspense, useCallback, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router'

import { initBrowser } from '../actions/browser'
import { initBundle } from '../actions/bundle'
import { initEnvironment } from '../actions/environment'
import useIntercom from '../hooks/useIntercom'
import useIsMobileWidth from '../hooks/useIsMobileWidth'
import useLogRocket from '../hooks/useLogRocket'
import usePageTracker from '../hooks/usePageTracker'
import useSegment from '../hooks/useSegment'
import useViewerAuthInfo from '../hooks/useViewerAuthInfo'
import useWebPushSubscription from '../hooks/useWebPushSubscription'

import AppLoadingScreen from '../components/AppLoadingScreen'
import Layout from '../components/Layout'
import Favicon from '../containers/Favicon'
import SettingsContainer from '../containers/Settings'

import getProps from '../selectors/containers/routes'
import api from '../slices/api'
import sphereSidebar from '../slices/sphereSidebar'
import SuperSegment from '../utils/segmentTypes'

import ProtectedRoute from './ProtectedRoute'
import { Redirect, RoutePath } from './routeDefinitions'

const SignInContainer = lazy(() => import('../containers/SignIn'))
const NoSpheresContainer = lazy(() => import('../containers/NoSpheres'))
const SphereRoute = lazy(() => import('./Sphere'))
const SphereSidebarContainer = lazy(() => import('../containers/SphereSidebar'))

interface RoutePropsWithKey {
	key: string
	public?: boolean
	hasSidebar?: boolean
	props: RouteProps
}

const routes: RoutePropsWithKey[] = [
	{
		key: 'sign-in',
		public: true,
		props: {
			path: RoutePath.Signin,
			component: SignInContainer,
		},
	},
	{
		key: 'chats',
		hasSidebar: true,
		props: {
			path: RoutePath.Sphere,
			component: SphereRoute,
		},
	},
	{
		key: 'settings',
		props: {
			path: RoutePath.Settings,
			component: SettingsContainer,
		},
	},
	{
		key: 'empty-state',
		hasSidebar: true,
		props: {
			path: RoutePath.Default,
			component: NoSpheresContainer,
		},
	},
	{
		key: 'default',
		props: {
			render: () => <Redirect.Default />,
		},
	},
]

const sideBarPaths = routes.reduce<string[]>((sideBarRoutes, currentRoute) => {
	const currentRoutePath = currentRoute.props.path
	if (!currentRoutePath || !currentRoute.hasSidebar) {
		return sideBarRoutes
	}
	if (typeof currentRoutePath === 'string') {
		return [...sideBarRoutes, currentRoutePath]
	}
	// currentRoutePath is a string[]
	return [...sideBarRoutes, ...currentRoutePath]
}, [])

const sideBarMobileDenyPaths = [
	RoutePath.ChatInfo,
	RoutePath.Chat,
	RoutePath.EventInfo,
	RoutePath.AdminPinboard,
]

const Routes: FC = () => {
	const { auth } = useSelector(getProps)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(initEnvironment())
		dispatch(initBrowser())
		dispatch(initBundle())
		dispatch(api.actions.configurationInitiate())
	}, [dispatch])

	const handleRetryUserConfigurationClick = useCallback(() => {
		SuperSegment.USER_CONFIGURATION_RETRY_CLICKED()
		dispatch(api.actions.configurationInitiate())
	}, [dispatch])

	const viewerAuthInfo = useViewerAuthInfo()

	// We use a Segment proxy for tracking
	useSegment(viewerAuthInfo)
	usePageTracker()

	// We use Intercom for customer support and feedback
	useIntercom(viewerAuthInfo)

	// We use LogRocket for bug and usability tracking
	useLogRocket(viewerAuthInfo)

	useWebPushSubscription(viewerAuthInfo)

	const hasSidebar = Boolean(useRouteMatch(sideBarPaths))
	const shouldHideSidebarOnMobile = Boolean(
		useRouteMatch(sideBarMobileDenyPaths)
	)
	const isMobileWidth = useIsMobileWidth()

	const routeMatchedSphereId = useRouteMatch<{ sphereId?: string }>(
		RoutePath.Sphere
	)?.params.sphereId

	useEffect(() => {
		dispatch(
			sphereSidebar.actions.switchedToSphere({
				sphereId: routeMatchedSphereId,
			})
		)
	}, [dispatch, routeMatchedSphereId])

	return (
		// Suspense for loading the layout
		<Suspense fallback={<AppLoadingScreen />}>
			<Layout
				sidebarContent={
					auth.authenticated &&
					hasSidebar &&
					(!isMobileWidth || (isMobileWidth && !shouldHideSidebarOnMobile)) && (
						<SphereSidebarContainer selectedSphereId={routeMatchedSphereId} />
					)
				}
			>
				{/* // Suspense for loading a component on the right, so preserve the layout and sidebar */}
				<Suspense fallback={<AppLoadingScreen />}>
					<Switch>
						{routes.map(route =>
							route.public ? (
								<Route key={route.key} {...route.props} />
							) : (
								<ProtectedRoute
									key={route.key}
									auth={auth}
									onRetryClick={handleRetryUserConfigurationClick}
									{...route.props}
								/>
							)
						)}
					</Switch>
				</Suspense>
			</Layout>
			<Favicon />
		</Suspense>
	)
}

export default Routes
