import { FC, useCallback } from 'react'
import { useTheme } from 'styled-components'

import SurfaceHeader from '../AKSurfaceHeader'

import { CloseButton, CloseIcon, ProceedButton } from './styled-components'
import { ModalHeaderPropsT } from './types'

export const ModalHeader: FC<ModalHeaderPropsT> = ({
	children,
	closeable,
	onClose,
	proceed,
}) => {
	const theme = useTheme()

	const onClick = useCallback(() => {
		onClose?.({ closeSource: 'closeButton' })
	}, [onClose])

	return (
		<SurfaceHeader
			leftEnhancer={
				closeable && onClose ? (
					<CloseButton onClick={onClick}>
						<CloseIcon
							size={theme.sizing.scale500}
							color={theme.colors.iconFillDark}
						/>
					</CloseButton>
				) : undefined
			}
			rightEnhancer={
				proceed ? (
					<ProceedButton
						isLoading={proceed.isLoading}
						disabled={proceed.disabled}
						onClick={proceed.onClick}
					>
						{proceed.label}
					</ProceedButton>
				) : undefined
			}
		>
			{children}
		</SurfaceHeader>
	)
}

export default ModalHeader
