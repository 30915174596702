import { USER_UNSUBSCRIBED_PUSH_NOTIFICATIONS } from '../constants/CookieKeys'
import browser from '../slices/browser'
import serviceWorker from '../slices/serviceWorker'
import { ApplicationThunk } from '../store'
import { getCookie } from '../utils/cookies'

import {
	createWebPushSubscription,
	deleteWebPushSubscription,
} from './webPushSubscriptions'

export function requestNotificationPersmission(): ApplicationThunk {
	return (dispatch, getState) => {
		const { serviceWorker } = getState()
		dispatch(browser.actions.notificationPermissionRequest())

		// Safari doesn't support .requestPermission().then() yet...
		return Notification.requestPermission(permission => {
			dispatch(browser.actions.notificationPermissionChanged({ permission }))
			if (permission === 'granted') {
				if (serviceWorker.pushManager) {
					dispatch(pushSubscribe(serviceWorker.pushManager))
				}
			}
		})
	}
}

function urlBase64ToUint8Array(base64String: string) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

	const rawData = window.atob(base64)
	const outputArray = new Uint8Array(rawData.length)

	for (let i = 0; i < rawData.length; i += 1) {
		outputArray[i] = rawData.charCodeAt(i)
	}
	return outputArray
}

export function pushSubscribe(pushManager: PushManager): ApplicationThunk {
	return dispatch => {
		dispatch(serviceWorker.actions.pushSubscribeRequest())

		if (!pushManager) {
			return dispatch(
				serviceWorker.actions.pushSubscribeFailure({
					error: 'No Push Manager',
				})
			)
		}

		const vapidPublicKey =
			'BGNA6snZSoe3WVN44_vsaeRlMa1qg4PWTOgMCXRgsV5a0cJUT' +
			'dw__v5xyH75ERBbVBa22InK8vO6qXdv1lRHojY'
		const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)

		return pushManager
			.subscribe({
				userVisibleOnly: true,
				applicationServerKey: convertedVapidKey,
			})
			.then(
				subscription => {
					dispatch(
						serviceWorker.actions.pushSubscribeSuccess({
							subscription,
						})
					)

					const subscriptionData = subscription.toJSON()
					return dispatch(
						createWebPushSubscription({
							endpoint: subscriptionData.endpoint,
							keys: subscriptionData.keys,
						})
					)
				},
				(error: Error) =>
					dispatch(
						serviceWorker.actions.pushSubscribeFailure({
							permission: Notification.permission,
							error:
								Notification.permission === 'denied'
									? 'Permission for Notifications was denied'
									: error.message,
						})
					)
			)
	}
}

export function pushUnsubscribe(): ApplicationThunk {
	return (dispatch, getState) => {
		dispatch(serviceWorker.actions.pushUnsubscribeRequest())

		// Delete the web push subscription from server
		const {
			entities: { webPushSubscriptions },
			serviceWorker: serviceWorkerInstance,
		} = getState()

		if (!serviceWorkerInstance.pushSubscription) {
			return dispatch(
				serviceWorker.actions.pushUnsubscribeFailure({
					error: 'No push subscription',
				})
			)
		}

		const { endpoint } = serviceWorkerInstance.pushSubscription.toJSON()
		const webPushSubscription = Object.values(webPushSubscriptions).find(
			webPushSub => webPushSub?.endpoint === endpoint
		)

		if (webPushSubscription) {
			dispatch(deleteWebPushSubscription(webPushSubscription.id))
		}

		return serviceWorkerInstance.pushSubscription.unsubscribe().then(
			() => dispatch(serviceWorker.actions.pushUnsubscribeSuccess()),
			error =>
				dispatch(
					serviceWorker.actions.pushUnsubscribeFailure({
						error: error.message,
					})
				)
		)
	}
}

export function initPushSubscription(
	pushManager?: PushManager
): ApplicationThunk {
	return (dispatch, getState) => {
		dispatch(serviceWorker.actions.pushSubscriptionInitRequest())

		if (!pushManager) {
			dispatch(
				serviceWorker.actions.pushSubscriptionInitFailure({
					error: 'No push manager',
				})
			)
			return
		}

		pushManager.getSubscription().then(
			subscription => {
				// Sync subscription on server
				if (subscription) {
					const subscriptionData = subscription.toJSON()

					dispatch(
						createWebPushSubscription({
							endpoint: subscriptionData.endpoint,
							keys: subscriptionData.keys,
						})
					)
				} else {
					const hasUnsubscribed = getCookie(
						USER_UNSUBSCRIBED_PUSH_NOTIFICATIONS
					)
					const { supported, permission } = getState().browser.notifications
					if (!hasUnsubscribed && supported && permission === 'granted') {
						dispatch(pushSubscribe(pushManager))
					}
				}

				return dispatch(
					serviceWorker.actions.pushSubscriptionInitSuccess({
						subscription,
					})
				)
			},
			error =>
				dispatch(
					serviceWorker.actions.pushSubscriptionInitFailure({
						error: error.message,
					})
				)
		)
	}
}

function registerServiceWorker(): ApplicationThunk<
	Promise<ServiceWorkerRegistration>
> {
	return dispatch => {
		dispatch(serviceWorker.actions.serviceWorkerRegisterRequest())

		return navigator.serviceWorker.register('/sw.js').then(
			serviceWorkerRegistration => {
				dispatch(
					serviceWorker.actions.serviceWorkerRegisterSuccess({
						serviceWorker: serviceWorkerRegistration.active,
						pushManager: serviceWorkerRegistration.pushManager,
					})
				)
				return serviceWorkerRegistration
			},
			error => {
				dispatch(
					serviceWorker.actions.serviceWorkerRegisterFailure({
						error: error.message,
					})
				)
				throw error
			}
		)
	}
}

function uninstallLegacyServiceWorkers() {
	navigator.serviceWorker
		.getRegistrations()
		.then(registrations =>
			registrations
				.filter(
					registration =>
						registration.active &&
						registration.active.scriptURL.endsWith('js/sw.js')
				)
				.forEach(registration => registration && registration.unregister())
		)
}

function addServiceWorkerMessageEventListener(): ApplicationThunk {
	return (dispatch, getState) => {
		navigator.serviceWorker.addEventListener('message', event => {
			const { data = {} } = event

			if (typeof data.type === 'string') {
				switch (data.type) {
					default:
						dispatch({ ...data })
				}
			}
		})
	}
}

export function initServiceWorker(): ApplicationThunk {
	return (dispatch, getState) => {
		const { registering, initializingPush, pushSubscription, subscribing } =
			getState().serviceWorker
		const swSupported =
			'serviceWorker' in navigator && 'ServiceWorkerRegistration' in window
		if (swSupported) {
			uninstallLegacyServiceWorkers()
		}

		if (initializingPush || pushSubscription || registering || subscribing) {
			return
		}

		const pushSupported = 'PushManager' in window
		const swNotificationsSupported =
			swSupported && 'showNotification' in ServiceWorkerRegistration.prototype

		dispatch(
			serviceWorker.actions.serviceWorkerInit({
				swSupported,
				pushSupported,
				swNotificationsSupported,
			})
		)

		if (swSupported) {
			dispatch(registerServiceWorker()).then(serviceWorkerRegistration => {
				if (swSupported && pushSupported && swNotificationsSupported) {
					dispatch(initPushSubscription(serviceWorkerRegistration.pushManager))
				}
			})

			dispatch(addServiceWorkerMessageEventListener())
		}
	}
}

// TODO: WEB-153

// types from https://stackoverflow.com/questions/51503754/typescript-type-beforeinstallpromptevent
/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
	/**
	 * Returns an array of DOMString items containing the platforms on which the event was dispatched.
	 * This is provided for user agents that want to present a choice of versions to the user such as,
	 * for example, "web" or "play" which would allow the user to chose between a web version or
	 * an Android version.
	 */
	readonly platforms: Array<string>

	/**
	 * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
	 */
	readonly userChoice: Promise<{
		outcome: 'accepted' | 'dismissed'
		platform: string
	}>

	/**
	 * Allows a developer to show the install prompt at a time of their own choosing.
	 * This method returns a Promise.
	 */
	prompt(): Promise<void>
}

export function denyWebappInstall(): ApplicationThunk {
	return dispatch => dispatch(browser.actions.webappInstallPromptDeny())
}

export function requestWebappInstall(
	deferredEvent: BeforeInstallPromptEvent
): ApplicationThunk {
	return dispatch => {
		dispatch(browser.actions.webappInstallPromptRequest())

		deferredEvent.prompt()

		return deferredEvent.userChoice.then(
			choiceResult =>
				dispatch(
					browser.actions.webappInstallPromptSuccess({
						outcome: choiceResult.outcome,
					})
				),
			(error: Error) =>
				dispatch(
					browser.actions.webappInstallPromptFailure({
						error: error.message,
					})
				)
		)
	}
}

function initWebappInstall(): ApplicationThunk {
	return dispatch => {
		window.addEventListener('beforeinstallprompt', event => {
			event.preventDefault()

			dispatch(
				browser.actions.webappInstallEventDeferred({
					event,
				})
			)

			return false
		})
	}
}

export function initBrowser(): ApplicationThunk {
	return dispatch => {
		dispatch(browser.actions.notificationInit())
		dispatch(browser.actions.shareApiInit())
		dispatch(initWebappInstall())
	}
}
