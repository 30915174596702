import styled from 'styled-components'

export const ToggleContainer = styled.div`
	position: relative;
	display: inline-block;
	width: 52px;
	height: 32px;

	input:checked + div {
		background-color: ${({ theme }) => theme.colors.toggleCheckedBackground};
	}
`
export const HiddenInput = styled.input`
	opacity: 0;
	width: 0;
	height: 0;
`
export const Slider = styled.div`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 34px;
	background-color: ${({ theme }) => theme.colors.toggleUncheckedBackground};
	transition: ${({ theme }) => theme.animation.timing400};

	&::before {
		position: absolute;
		content: '';
		height: 26px;
		width: 26px;
		left: 3px;
		bottom: 3px;
		background-color: white;
		transition: ${({ theme }) => theme.animation.timing400};
		border-radius: 50%;
		box-shadow: ${({ theme }) => theme.lighting.shadow100};
	}
	input:checked + &::before {
		transform: translateX(20px);
	}
`
