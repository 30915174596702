import {
	EditSphereEventInput,
	SphereEventInput as GqlSphereEventInput,
	SphereEventPrivacyLevel,
} from '../../../network/graphql'

import { SphereEventFormState } from '../../../slices/sphereEventForm'
import { SphereEventModel } from '../../usecases/SphereEventForm'
import SphereEventForm from '../../usecases/SphereEventForm'

import { SphereEventInput } from './types'

export function toGqlSphereEventInput(
	input: SphereEventInput
): GqlSphereEventInput {
	return {
		name: input.title,
		description: input.description || undefined,
		displayImageId: input.imageFilename || undefined,
		location: input.locationName ? { name: input.locationName } : undefined,
		link: input.link || undefined,
		startDate: SphereEventForm.getStartDateFromFormInput(input),
		endDate: SphereEventForm.getEndDateFromFormInput(input),
		privacyLevel: SphereEventPrivacyLevel.Sphere,
	}
}

// On the backend, passing the current value on any of the input fields will be
// ignored (i.e updates will not take place).
// Name and startDate are mandatory, the non-mandatory fields can also be set to
// null (the property can be unset)
export function toEditSphereEventInput({
	initialValues,
	editedValues,
}: {
	initialValues: SphereEventModel
	editedValues: SphereEventInput
}): EditSphereEventInput {
	const editedStartDate =
		SphereEventForm.getStartDateFromFormInput(editedValues)
	const hasStartDateBeenEdited = SphereEventForm.hasDateBeenEdited({
		initialStartOrEndDate: initialValues.startDate,
		startOrEndDate: editedStartDate,
	})

	const editedEndDate = SphereEventForm.getEndDateFromFormInput(editedValues)
	const hasEndDateBeenEdited = SphereEventForm.hasDateBeenEdited({
		initialStartOrEndDate: initialValues.endDate ?? undefined,
		startOrEndDate: editedEndDate ?? undefined,
	})

	const locationNameInput = SphereEventForm.getStringFieldEditFormInput({
		existing: initialValues.location?.name,
		edited: editedValues.locationName,
	})

	return {
		// Mandatory fields:
		name: editedValues.title,
		displayImageId: editedValues.imageFilename,
		startDate: hasStartDateBeenEdited
			? editedStartDate
			: initialValues.startDate,

		// Optional Fields:
		description: SphereEventForm.getStringFieldEditFormInput({
			existing: initialValues.description,
			edited: editedValues.description,
		}),
		location:
			typeof locationNameInput === 'string'
				? { name: locationNameInput }
				: locationNameInput,
		link: SphereEventForm.getStringFieldEditFormInput({
			existing: initialValues.link,
			edited: editedValues.link,
		}),
		endDate: hasEndDateBeenEdited
			? SphereEventForm.getEndDateFromFormInput(editedValues)
			: undefined,
	}
}

export function toSphereEventFormState(
	sphereEventModel: SphereEventModel
): SphereEventFormState {
	return {
		title: sphereEventModel.name,
		description: sphereEventModel.description ?? undefined,
		locationName: sphereEventModel.location?.name,
		link: sphereEventModel.link ?? undefined,
		startDay: sphereEventModel.startDate
			? new Date(sphereEventModel.startDate).toISOString()
			: undefined,
		endDay: sphereEventModel.endDate
			? new Date(sphereEventModel.endDate).toISOString()
			: undefined,
		// the dates contain both the day & time information
		startTime: sphereEventModel.startDate
			? new Date(sphereEventModel.startDate).toISOString()
			: undefined,
		endTime: sphereEventModel.endDate
			? new Date(sphereEventModel.endDate).toISOString()
			: undefined,

		imageFilename: sphereEventModel.displayImageFilename ?? undefined,
		imageSource: undefined,
		imageSourceLoading: false,
		imageSourceError: undefined,

		errorMessage: undefined,
	}
}
