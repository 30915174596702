import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { assignFromAction } from '../utils/slices'

export interface BundleState {
	version: string | null
	updating: boolean
	updatingError: any
}

const initialState: BundleState = {
	version: null,
	updating: false,
	updatingError: false,
}

const bundle = createSlice({
	name: 'bundle',
	initialState,
	reducers: {
		bundleVersionInit(state, action: PayloadAction<{ version: string }>) {
			assignFromAction(state, action)
		},
		bundleVersionRequest(state, action: PayloadAction) {
			state.updating = true
			state.updatingError = null
		},
		bundleVersionSuccess(state, action: PayloadAction<{ version: string }>) {
			assignFromAction(state, action)
			state.updating = false
		},
		bundleVersionFailure: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {
				state.updating = false
				state.updatingError = action.error
			},
			prepare({ error }: { error: string }) {
				return { payload: {}, error }
			},
		},
	},
})

export default bundle
