export class IntercomClient {
	static instance: IntercomClient

	Intercom: Intercom_.IntercomStatic | null = null

	initialized = false
	booted = false
	shutdown = false

	constructor(appId: string) {
		if (IntercomClient.instance) {
			return IntercomClient.instance
		}

		IntercomClient.instance = this

		IntercomClient.instance.initialize(appId)

		return IntercomClient.instance
	}

	initialize(appId: string) {
		window.intercomSettings = {
			app_id: appId,
			custom_launcher_selector: '.intercom_launcher',
			hide_default_launcher: true,
		}

		const intercom = window.Intercom
		this.Intercom = intercom

		if (typeof intercom === 'function') {
			// not provided in intercom types but used in official documentation: https://developers.intercom.com/installing-intercom/docs/basic-javascript
			// @ts-ignore
			intercom('reattach_activator')
			intercom('update', window.intercomSettings)
		} else {
			// intercom shim
			// TODO: better ways to type this?
			const i = (...args: any[]) => {
				i.c(args)
			}
			i.q = [] as any[]
			i.c = (args: any) => {
				i.q.push(args)
			}
			window.Intercom = i as unknown as Intercom_.IntercomStatic
			const loadScript = () => {
				const script = document.createElement('script')
				script.type = 'text/javascript'
				script.async = true
				script.src = `https://widget.intercom.io/widget/${appId}`
				const x = document.getElementsByTagName('script')[0]
				x?.parentNode?.insertBefore(script, x)
			}
			loadScript()
			window.addEventListener('load', loadScript, false)
		}
	}

	static boot(
		userTraits: Intercom_.IntercomSettings = {},
		{
			onBoot,
			onUnreadCountChange,
		}: { onBoot(): void; onUnreadCountChange(unreadCount: number): void }
	) {
		window.intercomSettings = Object.assign(
			{},
			window.intercomSettings,
			userTraits
		)

		if (typeof window.Intercom === 'function') {
			onBoot()

			window.Intercom('onUnreadCountChange', onUnreadCountChange)
			window.Intercom('boot', window.intercomSettings)
		}
	}

	static shutdown() {
		if (typeof window.Intercom === 'function') {
			window.Intercom('shutdown')
		}
	}

	static update(userTraits: Intercom_.IntercomSettings) {
		if (typeof window.Intercom === 'function') {
			window.Intercom('update', userTraits)
		}
	}

	static showNewMessage(messageContent: string) {
		if (typeof window.Intercom === 'function') {
			window.Intercom('showNewMessage', messageContent)
		}
	}
}

export default IntercomClient
