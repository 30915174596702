import { useQuery, QueryFunctionOptions } from '@apollo/client'

import {
	GetOwnProfileQuery,
	GetOwnProfileQueryVariables,
} from '../network/graphql'
import { GET_OWN_PROFILE } from '../network/graphql/queries'

import UserInfo from '../selectors/user'

export function useGetOwnProfileQuery(
	options?: QueryFunctionOptions<
		GetOwnProfileQuery,
		GetOwnProfileQueryVariables
	>
) {
	return useQuery<GetOwnProfileQuery, GetOwnProfileQueryVariables>(
		GET_OWN_PROFILE,
		options
	)
}

export function useGetOwnProfileQueryAsUserInfo(): UserInfo | undefined {
	const query = useGetOwnProfileQuery()

	return (
		(query.data?.viewer && {
			id: query.data.viewer.agent.id,
			name: query.data.viewer.agent.name ?? undefined,
			color: query.data.viewer.agent.color ?? undefined,
			imageFilename: query.data.viewer.agent.image_filename ?? undefined,
			email: query.data.viewer.person?.email ?? undefined,
		}) ??
		undefined
	)
}
