import { ConversationMessagesContext } from '../actions/messages'

export function uuid() {
	// @ts-ignore
	const crypto = window.crypto || window.msCrypto
	function randomDigit() {
		if (crypto && crypto.getRandomValues) {
			const rands = new Uint8Array(1)
			crypto.getRandomValues(rands)
			return (rands[0] % 16).toString(16)
		}
		return ((Math.random() * 16) | 0).toString(16)
	}
	return 'xxxxxxxxxxxx4xxx8xxxxxxxxxxxxxxx'.replace(/x/g, randomDigit)
}

// in case of id duplicates, collection2 takes precedence
// function mergeCombine(dictionary1 = {}, dictionary2 = {}) {
// 	return Object.assign({}, dictionary1, dictionary2)
// }

// Returns a copy of the latest entity based on the updated_at field
interface UpdatedAt {
	updated_at: number
}

function keepLatest<A extends UpdatedAt, B extends UpdatedAt>(
	entity1: A,
	entity2: B
): A | B {
	if (!entity1) {
		return entity2
	}
	if (!entity2) {
		return entity1
	}

	if (!entity1.updated_at) {
		return entity2
	}
	if (!entity2.updated_at) {
		return entity1
	}

	if (entity2.updated_at > entity1.updated_at) {
		return entity2
	}
	return entity1
}

// Merge two collections based on the given strategy
export function mergeDictionaryCollections<T extends Record<string, any>>(
	collection1: T,
	collection2: T,
	strategy: <K extends keyof T>(a: T[K], b: T[K]) => T[K]
): T {
	const mergedCollection: any = {}
	const collection1Keys = Object.keys(collection1)
	const collection2Keys = Object.keys(collection2)
	const combinedKeys = [...collection1Keys, ...collection2Keys]
	const uniqueKeysSet = new Set(combinedKeys)
	const keys = [...uniqueKeysSet]
	keys.forEach(key => {
		mergedCollection[key] = strategy(collection1[key], collection2[key])
	})
	return mergedCollection as T
}

// in case of id duplicates, keep the lates value based on the updated_at field
export function mergeKeepLatest<T extends Record<string, UpdatedAt>>(
	dictionary1: T,
	dictionary2: T
) {
	if (!dictionary1 && !dictionary2) {
		throw new Error('Expected at least one object')
	} else if (!dictionary1) {
		return dictionary2
	} else if (!dictionary2) {
		return dictionary1
	}
	return mergeDictionaryCollections(dictionary1, dictionary2, keepLatest)
}

export function newestFirstBy<
	K extends string,
	O extends { [k in K]: string | null }
>(dateField: K) {
	return (entity1: O, entity2: O) => {
		const date2 = entity2[dateField] ?? ''
		const date1 = entity1[dateField] ?? ''
		return new Date(date2).getTime() - new Date(date1).getTime()
	}
}

export function getConversationMessagesPaginationKey({
	conversationId,
	context,
}: {
	conversationId: string
	context: ConversationMessagesContext
}) {
	return `${conversationId}_${context}`
}

/**
 * Takes two **already sorted** arrays and merges them while preserving sort order.
 * Unlike the more concise [...left,...right].sort(compareFunction),
 * mergeSorted should be O(n).
 * @param left The prioritized sorted array
 * @param right The deprioritized sorted array
 * @param compareFunction Determines the sort order while merging the two arrays.
 * If compareFunction returns 0, left is preferred
 * @returns The two arrays merged and sorted by compareFunction
 */
export function mergeSorted<T>(
	left: T[],
	right: T[],
	compareFunction: (left: T, right: T) => number
): T[] {
	const merged: T[] = []
	let mergedIndex = 0
	let leftIndex = 0
	let rightIndex = 0

	// JS returns `undefined` in the case of an out of bounds array access attempt
	// But `left` and `right` may intentionally include `undefined`s.
	// So we'll use this flag instead
	const OutOfBounds = Symbol('Out of bounds')

	while (mergedIndex < left.length + right.length) {
		const nextLeft = leftIndex < left.length ? left[leftIndex] : OutOfBounds
		const nextRight =
			rightIndex < right.length ? right[rightIndex] : OutOfBounds

		if (
			nextLeft !== OutOfBounds &&
			(nextRight === OutOfBounds || compareFunction(nextLeft, nextRight) <= 0)
		) {
			merged[mergedIndex] = nextLeft
			leftIndex++
		} else if (nextRight !== OutOfBounds) {
			merged[mergedIndex] = nextRight
			rightIndex++
		}
		mergedIndex++
	}
	return merged
}
