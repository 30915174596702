import { ReactionType } from '../domain/Reactions'

import * as eventNames from '../constants/EventNames'
import {
	AttachmentType as FSAttachmentType,
	Message as FSMessage,
} from '../network/firestore'
import { LocalMessage } from '../slices/localMessages'

import segmentClient from './segmentProxy'

export type EventKeys = keyof typeof eventNames
type EventsTyped = {
	MESSAGE_ATTACHMENT_SELECTED: {
		conversationId: string
		type: 'image' | 'file' | 'announcement' | 'poll' | 'image-video' | 'gif'
	}
	FILE_UPLOAD_ATTEMPTED: {
		conversationId: string
		// TODO: we don't have this id on web at this point
		// messageUniqueId: string
		fileName?: string
		mimeType?: string
		fileSize?: number
	}
	FILE_UPLOAD_CANCEL: {
		conversationId: string
		// TODO: we don't have this id on web at this point
		// messageUniqueId: string
	}
	FILE_UPLOAD_FAILED: {
		conversationId: string
		errorMessage: string
		fileName?: string
		mimeType?: string
		fileSize?: number
		uniformTypeIdentifier?: number
	}
	FILE_OPENED: {
		conversationId: string
		messageId: string
		fileId: string
		attachmentType: FSAttachmentType
	}
	DISABLED_APPRECIATION_CLICK: {
		conversationId: string
		messageId: string
		appreciationId: string
		source: 'message_menu' | 'message_reaction'
	}
	REACTION_TRAY_OPEN_CLICK: {
		messageId?: string
		conversationId?: string
	}
	REACTION_TRAY_CLOSE_CLICK: {
		messageId?: string
		conversationId?: string
	}
	CHAT_ADD_PEOPLE_CLICKED: {
		source: 'chat_creation' | 'chat_info'
		conversationId?: string
	}
	CHAT_ADD_MEMBER_TOGGLED: {
		toggledState: 'selected' | 'unselected'
		targetAgentId: string
		source: 'chat_creation' | 'chat_info'
		conversationId?: string
	}
	CHAT_ADD_PEOPLE_CONFIRMED: {
		membersCount: number
		source: 'chat_creation' | 'chat_info'
		conversationId?: string
	}
	CHAT_MEMBER_ACTION_MENU_EXPANDED: {
		conversationId: string
		targetAttendantId: string
	}
	REMOVE_CHAT_MEMBER_INITIATED: {
		conversationId: string
		targetAttendantId: string
	}
	REMOVE_CHAT_MEMBER_CONFIRMED: {
		conversationId: string
		targetAttendantId: string
	}
	REMOVE_CHAT_MEMBER_CANCELLED: {
		conversationId: string
		targetAttendantId: string
	}
	REMOVE_CHAT_MEMBER_FAILED: {
		conversationId: string
		targetAttendantId: string
	}
	FAILED_REMOVE_CHAT_MEMBER_RETRY: {
		conversationId: string
		targetAttendantId: string
	}
	FAILED_REMOVE_CHAT_MEMBER_CANCEL: {
		conversationId: string
		targetAttendantId: string
	}
	CHAT_UNAVAILABLE_VIEWED: {
		conversationId: string
	}
	CHAT_INFO_OPENED: {
		conversationId: string
	}
	ADD_CHAT_MEMBER_FAILED: {
		conversationId: string
	}
	FAILED_ADD_CHAT_MEMBER_RETRY: {
		conversationId: string
	}
	FAILED_ADD_CHAT_MEMBER_CANCEL: {
		conversationId: string
	}
	DIRECT_MESSAGE_ACTIONED: {
		source: 'create_chat' | 'member_profile'
		target_agent_id?: string
		target_attendant_id?: string
		conversation_id?: string
	}
	SPHERE_VIEWED: {
		sphere_id: string
	}
	SPHERE_INFO_CLICKED: {
		sphereId: string
	}
	CREATE_SPHERE_CONTENT_CLICKED: {
		sphereId: string
	}
	CREATE_SPHERE_CONTENT_TYPE_SELECTED: {
		sphere_id: string
		chat_type: 'group_chat' | 'direct_message' | 'event'
		source: 'feed' | 'event_board'
	}
	PROFILE_VIEWED: {
		source: 'sphere_info' | 'chat' | 'chat_info' | 'settings'
		profile_agent_id?: string
		profile_attendant_id?: string
		conversation_id?: string
	}
	GIF_KEYBOARD_EXPANDED: {
		conversationId: string
		source: 'keyboard' | 'message_attachment'
	}
	GIF_SEARCH_STARTED: {
		conversationId: string
		searchTerm: string
		searchTrigger: 'auto' | 'manual'
	}
	GIF_SUGGESTIONS_SCROLLED: {
		conversationId: string
		searchTerm: string
	}
	GIF_SELECTED: {
		conversationId: string
		searchTerm: string
	}
	GIF_SEARCH_NO_RESULTS_FOUND: {
		conversationId: string
		searchTerm: string
	}
	GIF_SEARCH_FAILED: {
		conversationId: string
		searchTerm: string
	}
	GIF_KEYBOARD_CLOSED: {
		conversationId: string
	}
	EVENT_NAME_TYPED: {
		event_id?: string
	}
	EVENT_DESCRIPTION_TYPED: {
		event_id?: string
	}
	EVENT_LOCATION_TYPED: {
		event_id?: string
	}
	EVENT_LINK_TYPED: {
		event_id?: string
	}
	EVENT_START_TIME_ACTIONED: {
		event_id?: string
	}
	EVENT_END_TIME_ACTIONED: {
		event_id?: string
	}
	EVENT_START_DATE_SELECTED: {
		event_id?: string
		startDay?: string
		endDay?: string
	}
	EVENT_END_DATE_SELECTED: {
		event_id?: string
		startDay?: string
		endDay?: string
	}
	CREATE_EVENT_CONFIRMED: {}
	DELETE_EVENT_INITIATED: {
		event_id: string
	}
	DELETE_EVENT_CONFIRMED: {
		event_id: string
	}
	DELETE_EVENT_CANCELED: {
		event_id: string
	}
	EVENT_RSVP_INITIATED: {
		event_id: string
		source: 'feed' | 'event' | 'event_board' | 'feed_card' | 'outstanding_event'
	}
	EVENT_RSVP_SUBMITTED: {
		event_id: string
		source: 'feed' | 'event' | 'event_board' | 'feed_card' | 'outstanding_event'
		rsvp?: 'going' | 'not_going' | 'thinking'
	}
	EVENT_RSVP_FAILED: {
		event_id: string
		source: 'feed' | 'event' | 'event_board' | 'feed_card' | 'outstanding_event'
	}
	EVENT_CREATOR_PROFILE_CLICKED: {
		event_id: string
		agent_id?: string
	}
	EVENT_RSVP_RETRY_CLICK: {
		event_id: string
		source: 'feed' | 'event' | 'event_board' | 'feed_card' | 'outstanding_event'
	}
	EVENT_SCREEN_VIEWED: {
		event_id: string
	}
	EVENT_LINK_CLICKED: {
		event_id: string
	}
	EVENT_CHAT_CLICKED: {
		event_id: string
	}
	EVENT_ATTENDEE_SECTION_CLICKED: {
		event_id: string
		section?: 'going' | 'not_going' | 'thinking' | 'pending'
	}
	FEED_EVENT_CLICKED: {
		eventId: string
		source: 'feed' | 'event_board' | 'feed_card' | 'outstanding_event'
	}
	FEED_EVENT_BANNER_CLICKED: {
		eventId: string
		source: 'feed' | 'event_board' | 'feed_card' | 'outstanding_event'
	}
	EVENT_ATTENDEE_PROFILE_CLICKED: {
		event_id: string
		agent_id: string
		section?: 'going' | 'not_going' | 'thinking' | 'pending'
	}
	LOAD_ALL_EVENT_INVITEES_CLICK: {
		event_id: string
		section?: 'going' | 'not_going' | 'thinking' | 'pending'
	}
	EVENT_EDIT_INITIATED: {
		event_id: string
	}
	EVENT_EDIT_SAVE_INITIATED: {
		event_id?: string
	}
	EVENT_EDIT_SAVE_CONFIRMED: {
		event_id?: string
		notify: boolean
	}
	EVENT_EDIT_CANCELED: {
		event_id?: string
		source: 'edit_event' | 'notify_everyone'
	}
	EVENT_BOARD_OPENED: {
		sphere_id: string
		view: 'active' | 'past'
	}
	EVENT_BOARD_EMPTY_VIEWED: {
		sphere_id: string
		view: 'active' | 'past'
	}
	EVENT_LISTS_LINK_CLICKED: {
		sphere_id: string
		source: 'sphere_info'
	}
	EVENT_REMINDER_INITIATED: {
		event_id: string
		section: 'thinking' | 'pending' | 'going' | 'notGoing'
	}
	EVENT_REMIND_EVERYONE_ACTIONED: {
		event_id: string
		section?: 'thinking' | 'pending'
	}
	EVENT_REMIND_INVITEE_ACTIONED: {
		event_id: string
		agent_id?: string
		section?: 'thinking' | 'pending'
	}
	MESSAGE_PINNED: {
		conversationId: string
		messageId: string
	}
	FIRST_MESSAGE_PINNED_MENU_VIEWED: {}
	FIRST_MESSAGE_PINNED_MENU_ACTIONED: {
		action: 'go_to_pinboard' | 'dismiss'
	}
	MESSAGE_UNPINNED: {
		conversationId: string
		messageId: string
	}
	PINBOARD_OPENED: {
		sphereId: string
	}
	PINNED_MESSAGE_CLICKED: {
		conversationId: string
		messageId: string
	}
	COPY_LINK_TO_MESSAGE_CLICKED: {
		conversationId: string
		messageId: string
	}
	MESSAGE_ACTIONS_OVERFLOW_MENU_OPENED: {
		conversationId: string
		messageId: string
	}
	// Content Feed
	SPHERE_FEED_VIEWED: {
		sphereId: string
		outstandingEventsCount: number
		chatCardsCount: number
		eventCardsCount: number
	}
	SPHERE_CHAT_LIST_VIEWED: { sphereId: string }
	CARD_ACTION_EXPANDED: {
		conversationId: string
	}
	HIDE_FROM_FEED_ACTIONED: {
		conversationId: string
	}
	CHAT_SCREEN_VIEWED: {
		conversationId: string
	}
	OPENED_CHAT: {
		conversationId: string
		source: 'feed_card' | 'chat_list'
		badgeCount?: number
		importantInfo?: number
		position?: number
	}
	CHAT_FIRST_MESSAGE_TYPED: {}
	// Appreciations
	CREATE_APPRECIATION_INITIATED: {
		source: 'appreciation_board' | 'send_appreciation'
		sphereId: string
		conversationId?: string
	}
	CREATE_APPRECIATION_CANCELED: {
		sphereId: string
		conversationId?: string
	}
	CREATE_APPRECIATION_CONFIRMED: {
		sphereId: string
		conversationId?: string
	}
	SPHERE_APPRECIATION_MENU_EXPANDED: {
		sphereId: string
		conversationId?: string
		appreciationId: string
	}
	SPHERE_APPRECIATION_EDIT_INITIATED: {
		sphereId: string
		conversationId?: string
		appreciationId: string
	}
	SPHERE_APPRECIATION_EDIT_CONFIRMED: {
		sphereId: string
		conversationId?: string
		appreciationId: string
	}
	SPHERE_APPRECIATION_DELETE_INITIATED: {
		sphereId: string
		conversationId?: string
		appreciationId: string
	}
	SPHERE_APPRECIATION_DELETE_CONFIRMED: {
		sphereId: string
		conversationId?: string
		appreciationId: string | null
	}
	SPHERE_APPRECIATION_DELETE_DISMISSED: {
		sphereId: string
		conversationId?: string
		appreciationId: string | null
	}
	MESSAGE_REACTION_ADDED: {
		conversationId?: string
		messageId: string
		source: 'message_menu' | 'message_reaction' | 'add_reaction'
		reactionType: 'emoji' | 'appreciation'
		emojiId?: string
		appreciationId?: string
	}
	MESSAGE_REACTION_REMOVED: {
		conversationId?: string
		messageId: string
		source: 'message_menu' | 'message_reaction'
		reactionType: 'emoji' | 'appreciation'
		emojiId?: string
		appreciationId?: string
	}
	SPHERE_APPRECIATION_BOARD_OPENED: {
		sphereId?: string
	}
	APPRECIATION_PICKER_CLOSE_CLICK: {
		messageId?: string
	}
	APPRECIATION_PICKER_OPEN_CLICK: {
		messageId: string
	}
}

type EventKeysTyped = keyof EventsTyped

// Check that EventsTyped only contains keys from the EventNames.ts file
type TypeChecker<T extends EventKeys> = T
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Check = TypeChecker<EventKeysTyped>

type EventKeysUntyped = Exclude<EventKeys, EventKeysTyped>

export type EventTypes = {
	// TODO actually get types from prototracks here
	[K in EventKeysUntyped]: Record<string, number | string | undefined>
} &
	EventsTyped

export type EventTracker<K extends EventKeys> = (
	properties?: EventTypes[K],
	options?: any
) => void

export type SuperSegmentType = { [K in EventKeys]: EventTracker<K> }

export const SuperSegment: SuperSegmentType = new Proxy(segmentClient, {
	get: function (target: typeof segmentClient, key: EventKeys, receiver) {
		if (key in target) {
			return Reflect.get(target, key, receiver)
		}
		if (!(key in eventNames)) {
			throw new Error('Invalid track name:' + key)
		}
		return ((properties, options) => {
			target.track(eventNames[key], properties, options)
		}) as EventTracker<typeof key>
	},
}) as unknown as SuperSegmentType

export default SuperSegment

export function trackingTypeForMessage(
	message: FSMessage | LocalMessage
): string | undefined {
	if (!message.important) {
		return undefined
	}
	return message.content?.pollOptions ? 'poll' : 'announcement'
}

export function reactionTypeToTrackReactionType(
	reactionType: ReactionType
): EventsTyped['MESSAGE_REACTION_ADDED']['reactionType'] {
	switch (reactionType) {
		case ReactionType.AppreciationReaction:
			return 'appreciation'
		case ReactionType.EmojiReaction:
			return 'emoji'
	}
}
