import { FC } from 'react'

import LoadingSpinner from '../LoadingSpinner'

import { Container, Message, Spinner } from './styled-components'

interface Props {
	message?: string
}

const AppLoadingScreen: FC<Props> = ({ message }) => (
	<Container>
		<Spinner>
			<LoadingSpinner />
			{!!message && <Message>{message}</Message>}
		</Spinner>
	</Container>
)

export default AppLoadingScreen
