import { useSelector } from 'react-redux'

// We want a string union type here in order for the code references
// integration to work:
// https://docs.launchdarkly.com/integrations/code-references
export type FlaggedFeatures =
	| 'dark-mode'
	| 'give-appreciations'
	| 'non-important-poll'

type FeatureFlagValue = boolean

export function useFeatureFlags(
	flagKey: FlaggedFeatures
): FeatureFlagValue | undefined
export function useFeatureFlags(
	flagKey: string,
	defaultValue: FeatureFlagValue
): FeatureFlagValue
export function useFeatureFlags(
	flagKey: string,
	defaultValue?: FeatureFlagValue
): FeatureFlagValue | undefined {
	const flagValue = useSelector(state => state.launchDarkly.flags[flagKey])

	if (defaultValue === undefined) {
		return typeof flagValue === 'boolean' ? flagValue : undefined
	}
	return typeof flagValue === 'boolean' ? flagValue : defaultValue
}

export default useFeatureFlags
