import { createTheme, lightThemePrimitives } from 'baseui'

import lightColorTokens from './themes/lightTheme/colorTokens'
import lightSemanticColorTokens from './themes/lightTheme/semanticTokens'
import { ThemeColorTokens } from './themes/types'

import createSphereTheme, { SphereTheme } from '.'

const colors: ThemeColorTokens = {
	...lightColorTokens,
	...lightSemanticColorTokens,
}

export const lightSphereTheme: SphereTheme = createSphereTheme(colors)

export const SphereLightTheme = createTheme(
	lightThemePrimitives,
	lightSphereTheme
)

export default SphereLightTheme
