import { useContext } from 'react'

import Config from '../contexts/config'

function useConfig() {
	const config = useContext(Config)
	return config
}

export default useConfig
