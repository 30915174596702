import { select, getContext, call } from 'typed-redux-saga/macro'

import {
	GetViewerAttendantIdForChatCardByIdQuery,
	GetViewerAttendantIdForChatCardByIdQueryVariables,
} from '../network/graphql'
import { GQLClient } from '../network/graphql/configureClient'
import { GET_VIEWER_ATTENDANT_FOR_CHAT_CARD_BY_ID } from '../network/graphql/queries'

import { GQL_CLIENT_SAGA_CONTEXT_KEY } from '../constants/config'
import { getFileForNameFromProps } from '../selectors/files'
import { firestoreMessagesSelectors } from '../slices/firestoreMessages'
import message from '../slices/message'
import { getImageContentFromCloudinaryResponse } from '../utils/cloudinary'

import { ComposedMessageState } from './sendMessage'

async function getSenderInfoFromCache(
	client: GQLClient,
	conversationId: string
) {
	const result = await client.query<
		GetViewerAttendantIdForChatCardByIdQuery,
		GetViewerAttendantIdForChatCardByIdQueryVariables
	>({
		query: GET_VIEWER_ATTENDANT_FOR_CHAT_CARD_BY_ID,
		variables: {
			id: conversationId,
		},
		fetchPolicy: 'cache-first',
	})

	const attendant = result?.data?.viewer?.chatCard?.attendant

	if (!attendant || !attendant.id) {
		throw new Error('Failed to get own sender info')
	}

	const senderInfo = {
		attendantId: attendant.id,
		displayColor: attendant.display_color,
		displayName: attendant.display_name,
		displayImageFilename: attendant.display_image_filename || undefined,
	}

	return senderInfo
}

function* selectImageContent(imageObjectUrls: string[] | null) {
	// For now we only allow one image
	const imageObjectUrl = imageObjectUrls ? imageObjectUrls[0] : null
	const imageFileState = yield* select(getFileForNameFromProps, {
		fileName: imageObjectUrl ?? undefined,
	})
	const imageContent = getImageContentFromCloudinaryResponse(imageFileState)
	return imageContent
}

function* combineMessageInputState(
	action: ReturnType<typeof message.actions.sendMessageInitiate>
) {
	const {
		conversationId,
		text,
		mentions,
		imageObjectUrls,
		replyToMessageId,
		isImportant,
		permitsMultiplePollVotes,
		pollOptions,
		attachments,
	} = action.payload
	const imageContent = yield* call(selectImageContent, imageObjectUrls)

	const isMessageEmpty = !(
		text ||
		imageContent?.imageFilename ||
		attachments?.length
	)
	if (isMessageEmpty) {
		throw new Error('Expected message to have content')
	}

	const replyToMessage = yield* select(state =>
		replyToMessageId
			? firestoreMessagesSelectors.selectById(state, replyToMessageId)
			: null
	)

	const gqlClient = yield* getContext(GQL_CLIENT_SAGA_CONTEXT_KEY)

	const senderInfo = yield* call(
		getSenderInfoFromCache,
		gqlClient,
		conversationId
	)

	const messageInputState: ComposedMessageState = {
		conversationId,
		isImportant,
		text: text ?? undefined,
		mentions: mentions ?? undefined,
		imageContent,
		senderInfo,
		replyToMessageInfo:
			replyToMessage?.content && replyToMessage?.senderInfo
				? {
						messageId: replyToMessage.id,
						content: replyToMessage.content,
						senderInfo: replyToMessage.senderInfo,
						mentions: replyToMessage.mentions,
						removed: replyToMessage.removed,
						important: replyToMessage.important ?? false, // backend says this should default to false
				  }
				: undefined,
		permitsMultiplePollVotes,
		pollOptions,
		attachments,
	}
	return messageInputState
}

export default combineMessageInputState
