import firebase from 'firebase/app'
import { mapValues, isObject, isArray } from 'lodash'
import 'firebase/firestore'

type PurifyTimestampOutput<Input extends object> = {
	[Property in keyof Input]: Input[Property] extends firebase.firestore.Timestamp
		? {
				seconds: number
				nanoseconds: number
		  }
		: Input[Property] extends object
		? PurifyTimestampOutput<Input[Property]>
		: Input[Property]
}

export function purifyTimestamps<Input extends object>(
	input: Input
): PurifyTimestampOutput<Input> {
	return mapValues(input, value => {
		if (value instanceof firebase.firestore.Timestamp) {
			return {
				seconds: value.seconds,
				nanoseconds: value.nanoseconds,
			} as const
		}
		if (isObject(value) && !isArray(value)) {
			return purifyTimestamps(value as unknown as object)
		}
		return value
	}) as PurifyTimestampOutput<Input>
}
