import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// TODO: better places to define this types? Or duplicate?
import { AttachmentInput as GQLAttachmentInput } from '../network/graphql'
import { LocalMessageMention } from '../sagas/sendMessage'

import { Message as FSMessage } from '../network/firestore/types'
import { uuid } from '../utils/entitiesHelper'

export interface SendMessageParams {
	text: string | null
	mentions: LocalMessageMention[] | null
	imageObjectUrls: string[] | null
	replyToMessageId: string | null
	isImportant: boolean
	permitsMultiplePollVotes?: boolean
	pollOptions?: { optionId: string; optionTitle: string }[]
	attachments?: GQLAttachmentInput[]
}

export type MessageState = {}

const initialState: MessageState = {}

export const message = createSlice({
	name: 'message',
	initialState,
	reducers: {
		sendMessageInitiate: {
			reducer(
				state,
				action: PayloadAction<
					{
						conversationId: string
						uniqueId: string
					} & SendMessageParams
				>
			) {
				// TODO: use request adapter to track sending
			},
			// prepares the message with a uniqueMessageId to allow tracking of succes and failure
			prepare(args: { conversationId: string } & SendMessageParams) {
				return {
					payload: {
						...args,
						uniqueId: uuid(),
					},
				}
			},
		},
		sendMessageFailure: {
			reducer(state, action: PayloadAction<unknown, string, never, string>) {},
			prepare({ error, uniqueId }: { error: Error; uniqueId: string }) {
				return { error: error.message, payload: { uniqueId } }
			},
		},
		messageRetryQueueAdd(
			state,
			action: PayloadAction<{ uniqueId: string; conversationId: string }>
		) {},
		messageRetryQueueRemove(
			state,
			action: PayloadAction<{ uniqueId: string }>
		) {},
		optimisticUIRemoveMessage(
			state,
			action: PayloadAction<{ messageId: string }>
		) {},
		optimisticUIUndoRemoveMessage(
			state,
			action: PayloadAction<{ messageId: string }>
		) {},
		conversationMessageRequest(
			state,
			action: PayloadAction<{ conversationId: string; messageId: string }>
		) {
			// TODO: use request adapter to track sending
		},
		conversationMessageFailure: {
			reducer(
				state,
				action: PayloadAction<
					{
						conversationId: string
						messageId: string
					},
					string,
					never,
					string
				>
			) {
				// TODO: use request adapter to track sending
			},
			prepare({
				error,
				conversationId,
				messageId,
			}: {
				error: string
				conversationId: string
				messageId: string
			}) {
				return { error, payload: { conversationId, messageId } }
			},
		},
		conversationMessageSuccess(
			state,
			action: PayloadAction<{
				conversationId: string
				messageId: string
				messages: FSMessage[]
			}>
		) {
			// TODO: use request adapter to track sending
		},
	},
})

export default message
