import {
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit'

import { PinnedMessageLocalState } from '../domain/usecases/Message/MessageInConversation'

import message from './message'
import messagesByConversation from './messagesByConversation'

export const overlayAdapter = createEntityAdapter<PinnedMessageLocalState>({
	selectId: response => response.messageId,
})

export type PinnedMessagesState = {
	localOverlay: ReturnType<typeof overlayAdapter.getInitialState>
}

const initialState: PinnedMessagesState = {
	localOverlay: overlayAdapter.getInitialState(),
}

export const pinnedMessages = createSlice({
	name: 'pinnedMessages',
	initialState,
	reducers: {
		updateOverlay(state, action: PayloadAction<PinnedMessageLocalState>) {
			overlayAdapter.upsertOne(state.localOverlay, action)
		},
		clearOverlay(state, action: PayloadAction<{ messageId: string }>) {
			overlayAdapter.removeOne(state.localOverlay, action.payload.messageId)
		},
	},
	extraReducers: builder =>
		builder
			.addCase(message.actions.conversationMessageSuccess, (state, action) => {
				overlayAdapter.removeOne(state.localOverlay, action.payload.messageId)
			})
			.addCase(
				messagesByConversation.actions.conversationMessagesUpdate,
				(state, action) => {
					overlayAdapter.removeMany(
						state.localOverlay,
						action.payload.messages.map(message => message.id)
					)
				}
			),
})

export default pinnedMessages
