import firebase from 'firebase/app'
import 'firebase/firestore'
import { compact } from 'lodash'
import { AnyAction } from 'redux'
import { Task } from 'redux-saga'
import {
	take,
	getContext,
	put,
	select,
	call,
	fork,
	cancel,
	spawn,
} from 'typed-redux-saga/macro'

import {
	fetchConversationMessages,
	ConversationMessagesContext,
} from '../actions/messages'
import {
	GetChatCardByConversationIdQuery,
	GetChatCardByConversationIdQueryVariables,
} from '../network/graphql'
import { GQLClient } from '../network/graphql/configureClient'
import { GET_CHAT_CARD_BY_ID } from '../network/graphql/queries'

import {
	GQL_CLIENT_SAGA_CONTEXT_KEY,
	FS_IMPORTANT_MESSAGES_MAX_PAGE_SIZE,
} from '../constants/config'
import conversationMessagesPagination, {
	conversationMessagesPaginationSelectors,
} from '../slices/conversationMessagesPagination'
import firebaseSlice from '../slices/firebase'
import importantMessages from '../slices/importantMessages'
import { getConversationMessagesPaginationKey } from '../utils/entitiesHelper'
import { toSafeError } from '../utils/error'

export async function fetchOutstandingImportantMessageIds(
	conversationId: string,
	gqlClient: GQLClient
): Promise<string[]> {
	const result = await gqlClient.query<
		GetChatCardByConversationIdQuery,
		GetChatCardByConversationIdQueryVariables
	>({
		query: GET_CHAT_CARD_BY_ID,
		variables: { id: conversationId },
		// Messages are usually kept very tightly in-sync with the backend via firestore
		// But we don't have that level of liveness with graphql data
		// This query fetches messageIds, so any amount of staleness
		// can lead to a significantly poor UX.
		// network-only ensures that the data is as live as possible
		fetchPolicy: 'network-only',
	})
	const outstandingImportantMessageIds = compact(
		result.data?.viewer?.chatCard?.attendant?.outstanding_important_messages
	)
	return outstandingImportantMessageIds
}

export function makeFetchPageOfMessageIdsThunk({
	conversationId,
	resetPagination,
	messageIds,
}: {
	conversationId: string
	resetPagination: boolean
	messageIds: string[]
}) {
	return fetchConversationMessages(
		conversationId,
		ConversationMessagesContext.OutstandingImportantInbox,
		{
			where: [[firebase.firestore.FieldPath.documentId(), 'in', messageIds]],
			limit: FS_IMPORTANT_MESSAGES_MAX_PAGE_SIZE,
		},
		resetPagination
	)
}

function getNextPageOfDescendingIds(params: {
	idsDescending: string[]
	oldestFetchedId?: string
	pageSize: number
}) {
	const { idsDescending, oldestFetchedId, pageSize } = params
	const nextPageStartIndex = oldestFetchedId
		? idsDescending.findIndex(id => id === oldestFetchedId) + 1
		: 0
	return idsDescending.slice(nextPageStartIndex, nextPageStartIndex + pageSize)
}

function* fetchSubsequentPagesSaga({
	outstandingImportantMessageIdsDescendingByCreatedAt,
	conversationId,
}: {
	outstandingImportantMessageIdsDescendingByCreatedAt: string[]
	conversationId: string
}) {
	while (true) {
		const nextPageAction = yield* take(
			importantMessages.actions.nextOutstandingImportantMessagesPageClick.match
		)
		if (nextPageAction.payload.conversationId !== conversationId) {
			continue
		}

		const paginationState = yield* select(state =>
			conversationMessagesPaginationSelectors.selectById(
				state,
				getConversationMessagesPaginationKey({
					conversationId,
					context: ConversationMessagesContext.OutstandingImportantInbox,
				})
			)
		)
		const oldestFetchedMessageId = paginationState?.ids?.[0]
		const alreadyLoading = paginationState?.isFetching

		if (alreadyLoading) {
			continue
		}

		const nextPage = getNextPageOfDescendingIds({
			idsDescending: outstandingImportantMessageIdsDescendingByCreatedAt,
			oldestFetchedId: oldestFetchedMessageId,
			pageSize: FS_IMPORTANT_MESSAGES_MAX_PAGE_SIZE,
		})

		if (!nextPage.length) {
			continue
		}

		const fetchNextPageOfMessageIdsThunk = yield* call(
			makeFetchPageOfMessageIdsThunk,
			{
				conversationId,
				resetPagination: false,
				messageIds: nextPage,
			}
		)
		yield* put(fetchNextPageOfMessageIdsThunk as unknown as AnyAction)
	}
}

function* outstandingImportantMessagesSaga({
	payload: { conversationId },
}: ReturnType<
	typeof importantMessages.actions.outstandingImportantMessagesMount
>) {
	const gqlClient = yield* getContext(GQL_CLIENT_SAGA_CONTEXT_KEY)
	if (!gqlClient) {
		yield* put(
			importantMessages.actions.outstandingImportantMessagesError({
				conversationId,
				error: new Error('gqlClient not in context'),
			})
		)
		return
	}

	const isFirebaseInitialized = yield* select(
		state => state.firebase.initialized
	)
	if (!isFirebaseInitialized) {
		yield* take(firebaseSlice.actions.firebaseAppInitSuccess.type)
	}

	try {
		const outstandingImportantMessageIds = yield* call(
			fetchOutstandingImportantMessageIds,
			conversationId,
			gqlClient
		)

		const outstandingImportantMessageIdsDescendingByCreatedAt = [
			...outstandingImportantMessageIds,
		].reverse()

		const firstPage = outstandingImportantMessageIdsDescendingByCreatedAt.slice(
			0,
			FS_IMPORTANT_MESSAGES_MAX_PAGE_SIZE
		)

		if (!firstPage.length) {
			return
		}

		const fetchFirstPageOfMessageIdsThunk = yield* call(
			makeFetchPageOfMessageIdsThunk,
			{
				conversationId,
				resetPagination: true,
				messageIds: firstPage,
			}
		)
		// fetchFirstPageOfMessageIdsThunk is a thunk which the declaration for put doesn't support
		yield* put(fetchFirstPageOfMessageIdsThunk as unknown as AnyAction)

		yield* fork(fetchSubsequentPagesSaga, {
			conversationId,
			outstandingImportantMessageIdsDescendingByCreatedAt,
		})
	} catch (unknownError) {
		const error = toSafeError(unknownError)
		yield* put(
			importantMessages.actions.outstandingImportantMessagesError({
				conversationId,
				error,
			})
		)
		return
	}
}

function* cancelWatcherSaga(conversationId: string, task: Task) {
	while (true) {
		const cancelAction = yield* take(
			importantMessages.actions.outstandingImportantMessagesUnmount.match
		)
		if (cancelAction.payload.conversationId === conversationId) {
			yield* cancel(task)
			yield* put(
				conversationMessagesPagination.actions.conversationMessagesReset({
					conversationId,
					context: ConversationMessagesContext.OutstandingImportantInbox,
				})
			)
			return
		}
	}
}

export default function* outstandingImportantMessagesWatcherSaga() {
	while (true) {
		const action = yield* take(
			importantMessages.actions.outstandingImportantMessagesMount.match
		)
		const mainTask = yield* spawn(outstandingImportantMessagesSaga, action)
		yield* spawn(cancelWatcherSaga, action.payload.conversationId, mainTask)
	}
}
