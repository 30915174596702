import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { useEffect } from 'react'

import { SegmentClient } from '../utils/segmentProxy'

import useConfig from './useConfig'
import { ViewerAuthInfo } from './useViewerAuthInfo'

export function useLogRocket({
	userAgentId,
	userName,
	userEmail,
}: ViewerAuthInfo) {
	const config = useConfig()

	useEffect(() => {
		if (config?.logRocketKey) {
			try {
				LogRocket.init(config.logRocketKey)
				setupLogRocketReact(LogRocket)

				// Link to Segment
				LogRocket.getSessionURL(sessionURL => {
					SegmentClient.track('LogRocket', { sessionURL })
				})
			} catch (error) {
				// Logrocket failed
				SegmentClient.track('LogRocket init failed', { error })
			}
		}
	}, [config?.logRocketKey])

	useEffect(() => {
		if (userAgentId) {
			const userTraits = {
				...(userName ? { name: userName } : undefined),
				...(userEmail ? { email: userEmail } : undefined),
			}
			LogRocket.identify(userAgentId, userTraits)
		} else {
			// This is how you logout a user on LogRocket
			LogRocket.identify('')
		}
	}, [userAgentId, userName, userEmail])
}

export default useLogRocket
