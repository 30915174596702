import { createSlice } from '@reduxjs/toolkit'

import { LegacyMessageReactionType } from '../network/firestore'

interface EmojiIdsForLegacyReaction {
	emojiId: string
	emojiImageId: string
	unicode: string
	name: string
}

export type LegacyEmojiReactionTable = Record<
	LegacyMessageReactionType,
	EmojiIdsForLegacyReaction
>

type EnvShortKey = 'mock-dev' | 'dev' | 'local-dev' | 'staging' | 'prod'

export interface Config {
	apiRoot: string
	wsEndpoint: string
	pubsubEndpoint: string
	appEndpoint: string
	staticEndpoint: string
	domainName: string
	cloudinaryCloudName: string
	cloudinaryUploadLimit: number
	intercomAppId: string
	statsEndpoint: string
	segmentWriteKey: string
	logRocketKey: string
	firestoreSettings: import('firebase').default.firestore.Settings
	bypassFirebaseAuth: boolean
	launchDarklyClientId: string
	datadogApplicationId: string
	datadogClientToken: string
	datadogEnv: EnvShortKey
	legacyEmojiReactionTable: LegacyEmojiReactionTable
	AppCoreEnv: EnvShortKey
}

const initialState = {} as Config

const config = createSlice({
	name: 'config',
	initialState,
	reducers: {},
})

export default config
