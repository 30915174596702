export const CONTENT_FEED_VERSION_SUPPORTED = [1]

export interface Timestamp {
	seconds: number
	nanoseconds: number
}

export enum FeedItemType {
	SphereChat = 'SPHERE_CHAT',
	SphereEvent = 'SPHERE_EVENT',
}

type AttendantId = string

interface ChatCreatorInfo {
	attendantId: AttendantId
	displayName: string
	displayImageId: string | null
	displayColor: string
}

interface MessageInfo {
	messageId: string
	sentAt: Timestamp
	content: {
		text: string // for v1 the backend will set this to the correct placeholder in case the message is an attachment message or removed. We might make this more sophisticated later on
	}
	senderInfo: SenderInfo
}

interface SenderInfo {
	attendantId: string
	displayName: string
	displayImageId: string | null
	displayColor: string
}

interface AttendantInfo {
	displayName: string
	displayImageId: string | null
	displayColor: string
	followedAt: Timestamp
}

interface Capability {
	permitted: boolean
}

// The schemas of all feed items inherit from this base schema
export interface FeedItemBase {
	// id is actually not part of the firebase document data
	// id: string
	sphereId: string // required for collection group queries on the backend side
	createdAt: Timestamp
	updatedAt: Timestamp | null
	type: FeedItemType
	feedVersion: number // 1 for the first iteration
	prioritized: boolean
	lastActivityAt: Timestamp
	expiresAt: Timestamp | null // if null, the feed item does not expire
	archived: boolean
}

interface FeedChatInfo {
	conversationId: string
	title: string | null
	attendantId: AttendantId | null
	creatorInfo: ChatCreatorInfo
	firstDisplayedMessageInfo: MessageInfo | null
	lastDisplayedMessageInfo: MessageInfo | null
	sampleAttendantsInfo: Record<AttendantId, AttendantInfo> // for v1 this map will contain the info fo all the attendants, rendering a separate attendant count field unnecessary
	private: boolean
	capabilities: {
		archive: Capability
	}
	cardId: string // will be deprecated in the future
}

export interface SphereChatFeedItem extends FeedItemBase {
	type: FeedItemType.SphereChat
	chatInfo: FeedChatInfo
	capabilities: {
		archive: Capability
	}
}

interface FeedEventInfo {
	eventId: string
	name: string
	creatorInfo: EventCreatorInfo
	startDate: Timestamp
	endDate: Timestamp | null
	rsvp: SphereEventRsvp | null
}

export interface SphereEventFeedItem extends FeedItemBase {
	type: FeedItemType.SphereEvent
	status: SphereEventFeedItemStatus
	chatInfo: FeedChatInfo | null
	eventInfo: FeedEventInfo
	capabilities: {
		rsvp: Capability
		archive: Capability
	}
}

export type FeedItem = SphereChatFeedItem | SphereEventFeedItem

interface EventCreatorInfo {
	agentId: string
	displayName: string
	displayImageId: string | null
	displayColor: string
}

enum SphereEventFeedItemStatus {
	Default = 'DEFAULT',
	PendingRsvp = 'PENDING_RSVP',
}

export enum SphereEventRsvp {
	Going = 'GOING',
	Thinking = 'THINKING',
	NotGoing = 'NOT_GOING',
}
