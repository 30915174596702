// this file is the first file imported by our entrypoint and thus can still augment other modules
import React from 'react'
// https://github.com/welldone-software/why-did-you-render
// Use yarn start-wdyr and flags components to show why they rerendered in the console
// Component['whyDidYouRender'] = true
if (process.env.REACT_APP_WDYR === 'true') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render')
	const ReactRedux = require('react-redux')
	whyDidYouRender(React, {
		trackAllPureComponents: true,
		trackExtraHooks: [[ReactRedux, 'useSelector']],
	})
}
