import { ApolloCache, MutationUpdaterFn } from '@apollo/client'

import {
	OWN_ATTENDANT_FRAGMENT,
	FEED_ITEM_FRAGMENT,
	UNREAD_COUNT_CHAT_CARD_FRAGMENT,
} from './fragments'
import {
	GET_SPHERE,
	GET_APPRECIATIONS_FOR_SPHERE,
	GET_SPHERE_SUMMARY_INFOS,
} from './queries'
import {
	OwnAttendantFragment,
	FeedItemFragment,
	GetSphereQuery,
	GetSphereQueryVariables,
	RemoveSphereMemberMutation,
	FollowConversationMutation,
	MarkLatestMessageAsSeenMutation,
	UnreadCountChatCardFragment,
	AddAppreciationToSphereMutation,
	GetAppreciationsForSphereQuery,
	GetAppreciationsForSphereQueryVariables,
	DisableAppreciationOnSphereMutation,
} from './schema'

export const attendantFollowsConversationCacheUpdate =
	<T>(
		attendantId: string,
		conversationId: string,
		followsConversation: boolean
	) =>
	(cache: ApolloCache<T>) => {
		const attendantFragment = cache.readFragment<OwnAttendantFragment>({
			fragment: OWN_ATTENDANT_FRAGMENT,
			id: cache.identify({
				__typename: 'Attendant',
				id: attendantId,
				conversation_id: conversationId,
			}),
		})

		if (!attendantFragment) {
			return
		}
		cache.writeFragment<OwnAttendantFragment>({
			fragment: OWN_ATTENDANT_FRAGMENT,
			id: cache.identify({
				__typename: 'Attendant',
				id: attendantId,
				conversation_id: conversationId,
			}),
			data: {
				...attendantFragment,
				follows_conversation: followsConversation,
			},
		})
	}

const attendantCountOfConversationUpdateOptimisticUI =
	<T>(
		chatCardId: string,
		updateAttendantCount: (attendantCount: number) => number
	) =>
	(cache: ApolloCache<T>) => {
		const feedItemFragment = cache.readFragment<FeedItemFragment>({
			fragment: FEED_ITEM_FRAGMENT,
			fragmentName: 'feedItem',
			id: cache.identify({ __typename: 'ChatCard', id: chatCardId }),
		})
		if (!feedItemFragment || !feedItemFragment.conversation) {
			return
		}
		const attendantCount = feedItemFragment.conversation.attendant_count ?? 0
		cache.writeFragment<FeedItemFragment>({
			fragment: FEED_ITEM_FRAGMENT,
			fragmentName: 'feedItem',
			id: chatCardId,
			data: {
				...feedItemFragment,
				conversation: {
					...feedItemFragment.conversation,
					attendant_count: updateAttendantCount(attendantCount),
				},
			},
		})
	}

export const followConversationOptimisticUpdate =
	(attendantId: string, chatCardId: string, conversationId: string) =>
	(cache: ApolloCache<FollowConversationMutation>) => {
		attendantFollowsConversationCacheUpdate<FollowConversationMutation>(
			attendantId,
			conversationId,
			true
		)(cache)

		attendantCountOfConversationUpdateOptimisticUI<FollowConversationMutation>(
			chatCardId,
			count => count + 1
		)(cache)
	}

export const muteChatOptimisticUI = (
	chatCardId: string,
	attendantId: string,
	conversationId: string
) => ({
	__typename: 'Mutation' as 'Mutation',
	mute: {
		__typename: 'ChatCard' as 'ChatCard',
		id: chatCardId,
		attendant: {
			__typename: 'Attendant' as 'Attendant',
			id: attendantId,
			conversation_id: conversationId,
			muted: true,
		},
	},
})

export const unmuteChatOptimisticUI = (
	chatCardId: string,
	attendantId: string,
	conversationId: string
) => ({
	__typename: 'Mutation' as 'Mutation',
	unmute: {
		__typename: 'ChatCard' as 'ChatCard',
		id: chatCardId,
		attendant: {
			__typename: 'Attendant' as 'Attendant',
			id: attendantId,
			conversation_id: conversationId,
			muted: false,
		},
	},
})

export const sphereMembershipUpdateOptimisticUI = (sphereId: string) => [
	{
		query: GET_SPHERE_SUMMARY_INFOS,
		variables: { id: sphereId },
	},
	{
		query: GET_SPHERE,
		variables: { id: sphereId },
	},
]

export const removeSphereMemberCacheUpdate =
	(sphereId: string, agentId: string) =>
	(cache: ApolloCache<RemoveSphereMemberMutation>) => {
		const data = cache.readQuery<GetSphereQuery, GetSphereQueryVariables>({
			query: GET_SPHERE,
			variables: { id: sphereId },
		})
		if (!data?.viewer?.sphere?.members?.data) {
			return
		}
		const updatedMembers = data.viewer.sphere.members.data.filter(
			member => member.agent_id !== agentId
		)
		cache.writeQuery<GetSphereQuery, GetSphereQueryVariables>({
			query: GET_SPHERE,
			data: {
				...data,
				viewer: {
					...data.viewer,
					sphere: {
						...data.viewer.sphere,
						member_count: data.viewer.sphere.member_count - 1,
						members: {
							...data.viewer.sphere.members,
							data: updatedMembers,
						},
					},
				},
			},
		})
	}

export function seeConversationOptimisticUpdate(chatCardId?: string) {
	return function (cache: ApolloCache<MarkLatestMessageAsSeenMutation>) {
		if (!chatCardId) {
			return
		}
		try {
			const currentChatCardFragment =
				cache.readFragment<UnreadCountChatCardFragment>({
					id: cache.identify({
						__typename: 'ChatCard',
						id: chatCardId,
					}),
					fragment: UNREAD_COUNT_CHAT_CARD_FRAGMENT,
				})

			if (!currentChatCardFragment || !currentChatCardFragment.attendant) {
				return
			}

			cache.writeFragment<UnreadCountChatCardFragment>({
				id: cache.identify({
					__typename: 'ChatCard',
					id: chatCardId,
				}),
				fragment: UNREAD_COUNT_CHAT_CARD_FRAGMENT,
				data: {
					...currentChatCardFragment,
					attendant: {
						...currentChatCardFragment.attendant,
						unread_message_count: 0,
					},
				},
			})
		} catch (e) {
			// ignore errors
		}
	}
}

export const insertSphereAppreciationCacheUpdate =
	({
		sphereId,
	}: {
		sphereId: string
	}): MutationUpdaterFn<AddAppreciationToSphereMutation> =>
	(cache, result) => {
		const createdAppreciation = result.data?.addAppreciationToSphere

		if (!createdAppreciation) {
			return
		}

		const data = cache.readQuery<
			GetAppreciationsForSphereQuery,
			GetAppreciationsForSphereQueryVariables
		>({ query: GET_APPRECIATIONS_FOR_SPHERE, variables: { sphereId } })

		if (!data?.viewer?.sphere?.appreciations) {
			return
		}

		cache.writeQuery<
			GetAppreciationsForSphereQuery,
			GetAppreciationsForSphereQueryVariables
		>({
			query: GET_APPRECIATIONS_FOR_SPHERE,
			data: {
				...data,
				viewer: {
					...data?.viewer,
					sphere: {
						...data.viewer?.sphere,
						appreciations: [
							createdAppreciation,
							...(data.viewer.sphere?.appreciations ?? []),
						],
					},
				},
			},
		})
	}

export const disableSphereAppreciation =
	({
		appreciationId,
	}: {
		appreciationId: string
	}): MutationUpdaterFn<DisableAppreciationOnSphereMutation> =>
	cache => {
		if (!appreciationId) {
			return
		}

		cache.modify({
			id: cache.identify({
				__typename: 'Appreciation',
				id: appreciationId,
			}),
			fields: {
				active() {
					return false
				},
			},
		})
	}
