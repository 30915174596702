import { gql } from '@apollo/client'

import {
	SPHERE_MEMBER_INFO,
	MESSAGE_REACTION_FRAGMENT,
	OWN_ATTENDANT_FRAGMENT,
	SPHERE_SUMMARY_INFO_FRAGMENT,
	CHAT_INFO_ATTENDANT_INFO_FRAGMENT,
	RSVP_EVENT_FRAGMENT,
	CHAT_VIEW_CAPABILITIES_FRAGMENT,
	APPRECIATION_FRAGMENT,
} from './fragments'

export const MUTE_CHAT = gql`
	mutation muteChat($cardId: ID!) {
		mute(card_id: $cardId) {
			id
			attendant {
				id
				conversation_id
				muted
			}
		}
	}
`

export const UNMUTE_CHAT = gql`
	mutation unmuteChat($cardId: ID!) {
		unmute(card_id: $cardId) {
			id
			attendant {
				id
				conversation_id
				muted
			}
		}
	}
`

export const JOIN_CONVERSATION = gql`
	${OWN_ATTENDANT_FRAGMENT}
	${CHAT_VIEW_CAPABILITIES_FRAGMENT}
	mutation joinConversation($conversationId: ID!) {
		joinConversation(conversation_id: $conversationId) {
			...ownAttendant
			# chat view capabilites are included here as joining a chat will change them
			# fetching here will allow a direct update of a chatview query
			...chatViewCapabilities
		}
	}
`

export const FOLLOW_CONVERSATION = gql`
	mutation followConversation($conversationId: ID!) {
		followConversation(conversation_id: $conversationId)
	}
`

export const UNFOLLOW_CONVERSATION = gql`
	mutation unfollowConversation($conversationId: ID!) {
		unfollowConversation(conversation_id: $conversationId)
	}
`

export const CREATE_CHAT = gql`
	mutation createChat($input: CreateChatInput!) {
		createChat(input: $input) {
			id
			chat_type
			title
			description
			image_filename
			conversation_id
			private
		}
	}
`

export const END_CHAT = gql`
	mutation endChat($id: ID!) {
		endChat(card_id: $id) {
			id
			chat_state
			attendant {
				id
				conversation_id
				role
				has_new_activity
			}
		}
	}
`

export const CREATE_SPHERE = gql`
	${SPHERE_SUMMARY_INFO_FRAGMENT}
	mutation createSphere($input: CreateSphereInput!) {
		createSphere(input: $input) {
			...sphereSummary
		}
	}
`

export const EDIT_CHAT = gql`
	mutation updateChat($card_id: ID!, $input: UpdateChatInput) {
		updateChat(card_id: $card_id, input: $input) {
			id
			chat_type
			title
			description
			image_filename
			conversation_id
			private
		}
	}
`

export const UPDATE_SPHERE = gql`
	mutation updateSphere($sphere_id: ID!, $input: UpdateSphereInput!) {
		updateSphere(sphere_id: $sphere_id, input: $input) {
			id
			title
			description
			image_filename
		}
	}
`

export const JOIN_SPHERE = gql`
	${SPHERE_MEMBER_INFO}
	mutation joinSphere($id: ID!) {
		joinSphere(sphere_id: $id) {
			id
			...sphereMemberInfo
		}
	}
`

export const LEAVE_SPHERE = gql`
	${SPHERE_MEMBER_INFO}
	mutation leaveSphere($id: ID!) {
		leaveSphere(sphere_id: $id) {
			id
			...sphereMemberInfo
		}
	}
`

export const UPDATE_SPHERE_MEMBER = gql`
	mutation updateSphereMember($sphereId: ID!, $input: SphereMemberInput!) {
		updateSphereMember(sphere_id: $sphereId, input: $input) {
			agent_id
			is_admin
		}
	}
`

export const REMOVE_SPHERE_MEMBER = gql`
	mutation removeSphereMember($sphereId: ID!, $agentId: ID!) {
		removeSphereMember(sphere_id: $sphereId, agent_id: $agentId)
	}
`

export const ADD_APPRECIATION_TO_MESSAGE = gql`
	${MESSAGE_REACTION_FRAGMENT}
	mutation addAppreciationReactionToMessage(
		$sphereId: ID!
		$conversationId: ID!
		$messageId: ID!
		$appreciationId: ID!
	) {
		addAppreciationReactionToMessage(
			sphereId: $sphereId
			conversationId: $conversationId
			messageId: $messageId
			appreciationId: $appreciationId
		) {
			id
			updated_at
			reactions {
				...messageReaction
			}
		}
	}
`

export const REMOVE_MESSAGE_APPRECIATION = gql`
	${MESSAGE_REACTION_FRAGMENT}
	mutation removeAppreciationReactionFromMessage(
		$sphereId: ID!
		$conversationId: ID!
		$messageId: ID!
		$appreciationId: ID!
	) {
		removeAppreciationReactionFromMessage(
			sphereId: $sphereId
			conversationId: $conversationId
			messageId: $messageId
			appreciationId: $appreciationId
		) {
			id
			updated_at
			reactions {
				...messageReaction
			}
		}
	}
`

export const ADD_APPRECIATION_TO_SPHERE = gql`
	${APPRECIATION_FRAGMENT}
	mutation addAppreciationToSphere(
		$sphereId: ID!
		$name: String!
		$emojiId: String!
		$description: String
	) {
		addAppreciationToSphere(
			sphereId: $sphereId
			appreciationInput: {
				name: $name
				emojiId: $emojiId
				description: $description
			}
		) {
			...appreciationFragment
		}
	}
`

export const DISABLE_APPRECIATION_ON_SPHERE = gql`
	mutation disableAppreciationOnSphere($sphereId: ID!, $appreciationId: ID!) {
		disableAppreciationOnSphere(
			sphereId: $sphereId
			appreciationId: $appreciationId
		)
	}
`

export const ADD_EMOJI_REACTION_TO_MESSAGE = gql`
	${MESSAGE_REACTION_FRAGMENT}
	mutation addEmojiReactionToMessage(
		$sphereId: ID!
		$conversationId: ID!
		$messageId: ID!
		$emojiId: ID!
	) {
		addEmojiReactionToMessage(
			sphereId: $sphereId
			conversationId: $conversationId
			messageId: $messageId
			emojiId: $emojiId
		) {
			id
			updated_at
			reactions {
				...messageReaction
			}
		}
	}
`

export const REMOVE_MESSAGE_EMOJI_REACTION = gql`
	${MESSAGE_REACTION_FRAGMENT}
	mutation removeEmojiReactionFromMessage(
		$sphereId: ID!
		$conversationId: ID!
		$messageId: ID!
		$emojiId: ID!
	) {
		removeEmojiReactionFromMessage(
			sphereId: $sphereId
			conversationId: $conversationId
			messageId: $messageId
			emojiId: $emojiId
		) {
			id
			updated_at
			reactions {
				...messageReaction
			}
		}
	}
`

export const REMOVE_MESSAGE = gql`
	mutation removeMessage($conversationId: ID!, $messageId: ID!) {
		removeMessage(conversation_id: $conversationId, message_id: $messageId)
	}
`

export const SEND_MESSAGE = gql`
	mutation sendMessage($conversation_id: ID!, $input: ClientMessageInput!) {
		sendMessage(conversation_id: $conversation_id, input: $input) {
			id
			created_at
			updated_at
			unique_id
			content {
				text
				image_filename
				image_dimensions {
					width
					height
				}
			}
		}
	}
`

export const MARK_LATEST_MESSAGE_AS_SEEN = gql`
	mutation markLatestMessageAsSeen($conversation_id: ID!) {
		markLatestMessageAsSeen(conversation_id: $conversation_id)
	}
`

export const CREATE_SPHERE_INVITE = gql`
	mutation createInvite($id: ID!) {
		createSphereInvite(sphere_id: $id) {
			id
			firebaseShortLink
			firebaseDeepLink
			sphere {
				id
				title
			}
			inviter {
				id
				name
			}
		}
	}
`

export const UPDATE_USER = gql`
	mutation updateUser($agent_id: ID!, $input: UpdateUserInput!) {
		updateUser(agent_id: $agent_id, input: $input) {
			id
			type
			name
			color
			image_filename
			person {
				id
				email
				about
			}
		}
	}
`

export const PERFORM_ACTION_ON_ANNOUNCEMENT_MESSAGE = gql`
	mutation performActionOnAnnouncementMessage(
		$conversation_id: ID!
		$message_id: ID!
		$acknowledge: Boolean
		$dismiss: Boolean
	) {
		performActionOnAnnouncementMessage(
			conversation_id: $conversation_id
			message_id: $message_id
			acknowledge: $acknowledge
			dismiss: $dismiss
		)
	}
`

export const REMIND_ATTENDANT_TO_PERFORM_ACTION_ON_MESSAGE = gql`
	mutation remindAttendantToPerformActionOnMessage(
		$conversation_id: ID!
		$message_id: ID!
		$attendant_id: ID!
	) {
		remindAttendantToPerformActionOnMessage(
			conversation_id: $conversation_id
			message_id: $message_id
			attendant_id: $attendant_id
		)
	}
`

export const REMIND_ATTENDANTS_TO_PERFORM_ACTION_ON_MESSAGE = gql`
	mutation remindAttendantsToPerformActionOnMessage(
		$conversation_id: ID!
		$message_id: ID!
	) {
		remindAttendantsToPerformActionOnMessage(
			conversation_id: $conversation_id
			message_id: $message_id
		)
	}
`

export const PERFORM_ACTION_ON_POLL_MESSAGE = gql`
	mutation performActionOnPollMessage(
		$conversation_id: ID!
		$message_id: ID!
		$add_vote_for_options: [String]
		$remove_vote_for_options: [String]
		$dismiss: Boolean
	) {
		performActionOnPollMessage(
			conversation_id: $conversation_id
			message_id: $message_id
			add_vote_for_options: $add_vote_for_options
			remove_vote_for_options: $remove_vote_for_options
			dismiss: $dismiss
		)
	}
`

export const RSVP_TO_SPHERE_EVENT = gql`
	${RSVP_EVENT_FRAGMENT}
	mutation rsvpSphereEvent($sphereEventId: ID!, $rsvp: SphereEventRsvp!) {
		rsvpSphereEvent(sphereEventId: $sphereEventId, rsvp: $rsvp) {
			...rsvpEvent
		}
	}
`

export const ADD_MEMBERS_TO_SPHERE_CONVERSATION = gql`
	${CHAT_INFO_ATTENDANT_INFO_FRAGMENT}
	mutation addMembersToSphereConversation(
		$conversationId: ID!
		$sphereId: ID!
		$agentIds: [ID!]!
	) {
		addMembersToSphereConversation(
			conversationId: $conversationId
			agentIds: $agentIds
			sphereId: $sphereId
		) {
			conversationId
			...chatInfoAttendantInfo
		}
	}
`

export const REMOVE_ATTENDANTS_FROM_SPHERE_CONVERSATION = gql`
	mutation removeAttendantsFromSphereConversation(
		$attendantIds: [ID!]!
		$conversationId: ID!
		$sphereId: ID!
	) {
		removeAttendantsFromSphereConversation(
			conversationId: $conversationId
			attendantIds: $attendantIds
			sphereId: $sphereId
		) {
			conversationId
			attendant_id
		}
	}
`

export const CREATE_SPHERE_EVENT = gql`
	mutation createSphereEvent($sphereId: ID!, $eventInput: SphereEventInput!) {
		createSphereEvent(sphereId: $sphereId, eventInput: $eventInput) {
			id
		}
	}
`

export const DELETE_SPHERE_EVENT = gql`
	mutation deleteSphereEvent($sphereEventId: ID!) {
		deleteSphereEvent(sphereEventId: $sphereEventId)
	}
`

export const EDIT_SPHERE_EVENT = gql`
	mutation editSphereEvent(
		$sphereEventId: ID!
		$input: EditSphereEventInput!
		$notifyInvitees: Boolean
	) {
		editSphereEvent(
			sphereEventId: $sphereEventId
			editSphereEventInput: $input
			notifyInvitees: $notifyInvitees
		) {
			id
			sphereId
			name
			startDate
			displayImageId
			endDate
			location {
				name
			}
			description
			link
		}
	}
`

export const REMIND_EVENT_INVITEES = gql`
	mutation remindEventInvitees(
		$sphereEventId: ID!
		$sphereEventInviteeFilter: SphereEventInviteeFilter!
	) {
		remindInviteesToRSVPSphereEvent(
			sphereEventId: $sphereEventId
			sphereEventInviteeFilter: $sphereEventInviteeFilter
		)
	}
`

export const REMIND_EVENT_INVITEE = gql`
	mutation remindAboutEvent($sphereEventId: ID!, $inviteeId: ID!) {
		remindInviteeToRSVPSphereEvent(
			sphereEventId: $sphereEventId
			inviteeId: $inviteeId
		)
	}
`

export const PIN_MESSAGE_TO_ADMIN_PINBOARD = gql`
	mutation pinMessageToPinboard(
		$sphereId: ID!
		$conversationId: ID!
		$messageId: ID!
		$pinboardConversationId: ID!
	) {
		pinMessageToPinboard(
			sphereId: $sphereId
			conversationId: $conversationId
			messageId: $messageId
			pinboardConversationId: $pinboardConversationId
		) {
			sourceMessage {
				id
			}
			targetMessage {
				id
			}
		}
	}
`

export const ARCHIVE_CHAT = gql`
	mutation archiveChat($sphereId: ID!, $conversationId: ID!) {
		archiveSphereChat(sphereId: $sphereId, conversationId: $conversationId) {
			id
			conversation_id
		}
	}
`
