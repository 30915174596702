import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import auth from './auth'

export const DebugFlags = [
	'disableMarkMessagesAsSeen',
	'disableLoadChatsFromLastSeenMessage',
] as const
export type DebugFlag = typeof DebugFlags[number]

export type DebugState = Record<DebugFlag, boolean>

const initialState: DebugState = {
	disableMarkMessagesAsSeen: false,
	disableLoadChatsFromLastSeenMessage: false,
}

export const debug = createSlice({
	name: 'debug',
	initialState,
	reducers: {
		setValue(
			state,
			action: PayloadAction<{ flag: DebugFlag; value: boolean }>
		) {
			state[action.payload.flag] = action.payload.value
		},
	},
	extraReducers(builder) {
		builder.addCase(auth.actions.signOutSuccess, () => initialState)
	},
})

export default debug
