import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

export const withGutterLeft = css`
	padding-left: ${({ theme }) => theme.grid.gutter}px;
`

export const withGutterRight = css`
	padding-right: ${({ theme }) => theme.grid.gutter}px;
`

export const withGutter = css`
	${withGutterLeft}
	${withGutterRight}
`

export const Gutter = styled.div`
	${withGutter}
`

export const GutterLeft = styled.div`
	${withGutterLeft}
`

export const GutterRight = styled.div`
	${withGutterRight}
`

export const withEllipsisTextOverflow = css`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const withExperimentalHiddenScrollbarCSS = css`
	/* Hide scrollbar on chromium browsers */
	::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar on firefox */
	scrollbar-width: none;
`

export function withLineClamp(
	maxNumberOfLines: number
): FlattenSimpleInterpolation {
	return css`
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: ${maxNumberOfLines};
		overflow: hidden;
	`
}

export const withForcedPillShape = css(({ theme }) => ({
	borderRadius: theme.borders.forcPillShapeRadius,
}))

export const withEventGradientBackground = css`
	background-color: ${p => p.theme.colors.calendarIconFill};
	background-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0.5),
		rgba(255, 255, 255, 0.5)
	);
	background-blend-mode: soft-light;
`

export const withElevation = css(({ theme }) => {
	if (theme.behaviour.elevateByShadows) {
		return {
			boxShadow: theme.lighting.shadow100,
		}
	}
	return {
		backgroundColor: theme.colors.backgroundPrimary,
	}
})

export const withDisabledOverlay = css`
	filter: opacity(0.3);
`

export const withBrightenedAppreciationBackgroundColor = css`
	background-image: linear-gradient(
		rgba(255, 255, 255, 0.7),
		rgba(255, 255, 255, 0.7)
	);
`
