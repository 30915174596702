// this turned into time / id helper
// TODO: cleanup this file
import moment from 'moment'

export const A_SECOND = 1000
export const A_MINUTE = 60 * A_SECOND
export const AN_HOUR = 60 * A_MINUTE
export const A_DAY = 24 * AN_HOUR
export const A_WEEK = 7 * A_DAY
export const A_YEAR = 365 * A_DAY

export const now = () => Date.now()

export const adjustedDateNow = (offset: number) => now() - offset

export const adjustedServerDate = (dateString: string, offset = 0) =>
	new Date(dateString).getTime() + offset

// create a 6-byte hex string (12 characters) from the current timestamp
const dateHex = (date: Date) => `${date.getTime().toString(16)}0`

// smallest possible id created at the specified date
export const idCursorAtDate = (date: Date) =>
	`${dateHex(date)}00000000000000000000`

export const dateAtIdCursor = (cursor: string) =>
	new Date(parseInt(cursor.substr(0, 11), 16))
export const nHoursAgo = (hours: number) => {
	const date = new Date()
	// TODO make this helper ok
	date.setHours(date.getHours() - hours)
	return date
}

export const getDateString = (date: Date) => date.toLocaleDateString()

export const stringDatesDifference = (timeA: string, timeB: string) =>
	new Date(timeA).getTime() - new Date(timeB).getTime()

export const stringDatesDateDifference = (
	stringDateTimeA: string,
	stringDateTimeB: string,
	offset?: number
) => {
	const dateA = new Date(adjustedServerDate(stringDateTimeA, offset))
	const dateB = new Date(adjustedServerDate(stringDateTimeB, offset))

	return dateA.getDate() !== dateB.getDate()
}

const MAX_SET_TIMEOUT_MS = Math.pow(2, 31) - 1

type CancelTimeout = () => void
export function setLongTimeout<Callback extends Function>(
	callback: Callback,
	durationMS: number,
	maxSetTimeoutMS: number = MAX_SET_TIMEOUT_MS
): CancelTimeout {
	let currentTimeoutRef: number

	function recursivelySetTimeout(innerDurationMS: number) {
		if (innerDurationMS > maxSetTimeoutMS) {
			const nextDuration = innerDurationMS - maxSetTimeoutMS
			currentTimeoutRef = window.setTimeout(
				() => recursivelySetTimeout(nextDuration),
				maxSetTimeoutMS
			)
		} else {
			currentTimeoutRef = window.setTimeout(callback, innerDurationMS)
		}
	}

	recursivelySetTimeout(durationMS)

	return () => {
		window.clearTimeout(currentTimeoutRef)
	}
}

export function waitUntil(
	timeToWaitUntilMS: number,
	getCurrentTime: () => number = now
): Promise<void> {
	const nowMS = getCurrentTime()
	if (nowMS > timeToWaitUntilMS) {
		return Promise.resolve()
	}
	const durationToWaitMs = timeToWaitUntilMS - nowMS
	return new Promise(resolve => setLongTimeout(resolve, durationToWaitMs))
}

export function estimateServerTimeOffset(params: {
	requestSentAtMS: number
	serverTime: number
	responseReceivedAtMs: number
}): number {
	const estimatedTimeForRequestToReachServer =
		(params.responseReceivedAtMs - params.requestSentAtMS) / 2

	const estimatedClientTimeWhenServerTimeWasRecorded =
		params.requestSentAtMS + estimatedTimeForRequestToReachServer

	const timeOffset =
		estimatedClientTimeWhenServerTimeWasRecorded - params.serverTime

	return timeOffset
}

export function getMsUntilTomorrow(getCurrentTime: () => number = now): number {
	const current = moment(getCurrentTime())
	const midnight = moment(current).endOf('day')
	const duration = moment.duration(midnight.diff(current))
	// +1 due to end of day being 1 second before tomorrow
	const msUntilTomorrow = duration.asMilliseconds() + 1
	return msUntilTomorrow
}

/*
 * https://stackoverflow.com/questions/60740996/detect-if-users-locale-is-set-to-12-hour-or-24-hour-timeformat-using-javascript
 * Detects browser's locale 24h time preference
 * It works by checking whether hour output contains a space ('1 AM' or '01')
 */
export const getIsBrowserLocale24h = () =>
	!new Intl.DateTimeFormat(undefined, { hour: 'numeric' }).format(0).match(/\s/)

export const getDatesAreOnSameDay = (dateA: Date, dateB: Date): boolean =>
	dateA.getFullYear() === dateB.getFullYear() &&
	dateA.getMonth() === dateB.getMonth() &&
	dateA.getDate() === dateB.getDate()

export function compareDatesToDayPrecision(
	timeA: Date,
	timeB: Date
): 1 | 0 | -1 {
	const dayA = new Date(timeA)
	dayA.setUTCHours(0, 0, 0, 0)
	const dayB = new Date(timeB)
	dayB.setUTCHours(0, 0, 0, 0)

	if (dayA > dayB) {
		return 1
	}
	if (dayA < dayB) {
		return -1
	}

	return 0
}

export function compareDatesToPrecision(
	timeA: Date,
	timeB: Date,
	precision:
		| 'year'
		| 'month'
		| 'day'
		| 'hour'
		| 'minute'
		| 'second'
		| 'millisecond'
): 1 | 0 | -1 {
	const dayA = new Date(timeA)
	const dayB = new Date(timeB)

	switch (precision) {
		case 'year':
			dayA.setUTCMonth(0, 1)
			dayA.setUTCHours(0, 0, 0, 0)
			dayB.setUTCMonth(0, 1)
			dayB.setUTCHours(0, 0, 0, 0)
			break
		case 'month':
			dayA.setUTCDate(1)
			dayA.setUTCHours(0, 0, 0, 0)
			dayB.setUTCDate(1)
			dayB.setUTCHours(0, 0, 0, 0)
			break
		case 'day':
			dayA.setUTCHours(0, 0, 0, 0)
			dayB.setUTCHours(0, 0, 0, 0)
			break
		case 'hour':
			dayA.setUTCMinutes(0, 0, 0)
			dayB.setUTCMinutes(0, 0, 0)
			break
		case 'minute':
			dayA.setUTCSeconds(0, 0)
			dayB.setUTCSeconds(0, 0)
			break
		case 'second':
			dayA.setUTCMilliseconds(0)
			dayB.setUTCMilliseconds(0)
			break
		case 'millisecond':
			break
	}

	if (dayA > dayB) {
		return 1
	}
	if (dayA < dayB) {
		return -1
	}

	return 0
}

export function compareDatesByHoursAndMinutes(
	timeA: Date,
	timeB: Date
): 1 | 0 | -1 {
	const hourDifference = timeA.getUTCHours() - timeB.getUTCHours()

	if (hourDifference > 0) {
		return 1
	}
	if (hourDifference < 0) {
		return -1
	}

	const minuteDifference = timeA.getUTCMinutes() - timeB.getUTCMinutes()

	if (minuteDifference > 0) {
		return 1
	}
	if (minuteDifference < 0) {
		return -1
	}

	return 0
}

const A_NANO_SECOND = 0.000001
/**
 * Converts a firestore style timestamp to milliseconds from unix epoch
 *
 * @param timeStamp An object comprised of the seconds since the unix epoch
 * and the nanoseconds (0 to 999,999,999 inclusive) remainder
 * @returns
 */
export function timestampToMilliseconds(
	seconds: number,
	nanoseconds: number
): number {
	const millisecondsFromSeconds = seconds * A_SECOND
	const millisecondsFromNanoSeconds = Math.floor(nanoseconds * A_NANO_SECOND)
	const milliseconds = millisecondsFromSeconds + millisecondsFromNanoSeconds
	return milliseconds
}

export function momentFromFirebaseTimestamp(time: {
	seconds: number
	nanoseconds: number
}) {
	return moment(timestampToMilliseconds(time.seconds, time.nanoseconds))
}

export function hoursSinceMoment(date: moment.Moment) {
	const duration = moment.duration(moment().diff(date))
	const hours = duration.asHours()
	return hours
}
