import { merge } from 'lodash'
import {
	call,
	put,
	takeEvery,
	select,
	putResolve,
	fork,
} from 'typed-redux-saga/macro'

import { fetchConversationMessage } from '../actions/messages'

import { MESSAGES_PAGINATION_SIZE } from '../constants/config'
import { ConversationMessagesFilter, FetchOptions } from '../network/firestore'
import {
	conversationMessagesFilterToWhereClause,
	getNewestCreatedAtPageFetchOptions,
	getOldestCreatedAtPageFetchOptions,
	getPageAfterCreatedAtFetchOptions,
	getPageAfterCreatedAtInclusiveFetchOptions,
	getPageBeforeCreatedAtFetchOptions,
} from '../network/firestore/adapters'
import { fetchMessages } from '../network/firestore/messages'
import { UpdateType } from '../network/pagination'
import {
	makeGetIsMessageIdInPagination,
	getFetchedAllAscendingForConversation,
} from '../selectors/conversations'
import { getFirestoreInitialized } from '../selectors/firestore'
import conversationMessagesPagination from '../slices/conversationMessagesPagination'
import conversationMessagePagination, {
	conversationMessagesBidirectionalPaginationSelectors,
} from '../slices/conversationMessagesPagination'
import conversationsUI from '../slices/conversationsUI'
import { firestoreMessagesSelectors } from '../slices/firestoreMessages'
import { toSafeError } from '../utils/error'
import { purifyTimestamps } from '../utils/firebase'

function* fetchPage(options: {
	conversationId: string
	fetchOptions: FetchOptions
	updateType: UpdateType
	resetPagination: boolean
	startsFromEdge: boolean
}) {
	const {
		conversationId,
		fetchOptions,
		updateType,
		resetPagination,
		startsFromEdge,
	} = options

	yield* put(
		conversationMessagePagination.actions.paginationRequest({
			conversationId,
			resetPagination,
			updateType,
		})
	)

	const firebaseInitialized = yield* select(getFirestoreInitialized)
	if (!firebaseInitialized) {
		yield* put(
			conversationMessagePagination.actions.paginationFailure({
				error: new Error('Firebase not initialized'),
				conversationId,
				updateType,
			})
		)
		return
	}

	try {
		const { pagination, data } = yield* call(
			fetchMessages,
			conversationId,
			fetchOptions
		)
		yield* put(
			conversationMessagePagination.actions.paginationSuccess({
				conversationId,
				startsFromEdge,
				messages: data.map(purifyTimestamps),
				pagination,
				updateType,
			})
		)
	} catch (unknownError) {
		const error = toSafeError(unknownError)
		yield* put(
			conversationMessagePagination.actions.paginationFailure({
				conversationId,
				updateType,
				error,
			})
		)
	}
}

function* getCursorPaginationWhereClause(conversationId: string) {
	const filter = yield* select(
		state =>
			conversationMessagesBidirectionalPaginationSelectors.selectById(
				state,
				conversationId
			)?.filter
	)
	// CuratedMessageIds requries non-cursor pagination
	if (!filter || filter === ConversationMessagesFilter.CuratedMessageIds) {
		return undefined
	}
	const whereClause = conversationMessagesFilterToWhereClause(filter)
	if (typeof whereClause === 'function') {
		return undefined
	}
	return whereClause
}

// This will likely be more complex in the future
// eg the limit increases with each subsequent page requested
export function* getPageSize(conversationId: string) {
	const limit = yield* select(
		state =>
			conversationMessagesBidirectionalPaginationSelectors.selectById(
				state,
				conversationId
			)?.nextPageSize
	)
	return limit ?? MESSAGES_PAGINATION_SIZE
}

function* fetchFirstPage(
	action: ReturnType<typeof conversationMessagePagination.actions.getFirstPage>
) {
	const { conversationId } = action.payload
	const where = yield* call(getCursorPaginationWhereClause, conversationId)
	const limit = yield* call(getPageSize, conversationId)

	const fetchOptions: FetchOptions = merge(
		{
			where,
		},
		getOldestCreatedAtPageFetchOptions(limit)
	)
	yield* call(fetchPage, {
		conversationId,
		fetchOptions,
		updateType: UpdateType.Append,
		resetPagination: true,
		startsFromEdge: true,
	})
}

function* fetchLastPage(
	action: ReturnType<typeof conversationMessagePagination.actions.getLastPage>
) {
	const { conversationId } = action.payload
	const where = yield* call(getCursorPaginationWhereClause, conversationId)
	const limit = yield* call(getPageSize, conversationId)

	const fetchOptions: FetchOptions = merge(
		{
			where,
		},
		getNewestCreatedAtPageFetchOptions(limit)
	)
	yield* call(fetchPage, {
		conversationId,
		fetchOptions,
		// Conceptually, we're starting from the latest message "edge", and prepending
		// Practically, we want to prepend to any socketed messages which may have
		// made it into the pagination before the first page could be fetched
		updateType: UpdateType.Prepend,
		resetPagination: true,
		startsFromEdge: true,
	})
}

function* fetchNextPage(
	action: ReturnType<typeof conversationMessagePagination.actions.getNextPage>
) {
	const { conversationId } = action.payload
	const where = yield* call(getCursorPaginationWhereClause, conversationId)
	const limit = yield* call(getPageSize, conversationId)

	const afterCursor = yield* select(
		state =>
			conversationMessagesBidirectionalPaginationSelectors.selectById(
				state,
				conversationId
			)?.after
	)
	if (!afterCursor) {
		yield* put(
			conversationMessagePagination.actions.missingCursorError({
				error: 'After cursor not found',
				cursor: 'after',
				conversationId,
			})
		)
		return
	}

	const fetchOptions: FetchOptions = merge(
		{
			where,
		},
		getPageAfterCreatedAtFetchOptions(afterCursor, limit)
	)

	yield* call(fetchPage, {
		conversationId,
		fetchOptions,
		updateType: UpdateType.Append,
		resetPagination: false,
		startsFromEdge: false,
	})
}

function* fetchPreviousPage(
	action: ReturnType<
		typeof conversationMessagePagination.actions.getPreviousPage
	>
) {
	const { conversationId } = action.payload
	const where = yield* call(getCursorPaginationWhereClause, conversationId)
	const limit = yield* call(getPageSize, conversationId)

	const beforeCursor = yield* select(
		state =>
			conversationMessagesBidirectionalPaginationSelectors.selectById(
				state,
				conversationId
			)?.before
	)
	if (!beforeCursor) {
		yield* put(
			conversationMessagePagination.actions.missingCursorError({
				error: 'Before cursor not found',
				cursor: 'before',
				conversationId,
			})
		)
		return
	}

	const fetchOptions: FetchOptions = merge(
		{
			where,
		},
		getPageBeforeCreatedAtFetchOptions(beforeCursor, limit)
	)

	yield* call(fetchPage, {
		conversationId,
		fetchOptions,
		updateType: UpdateType.Prepend,
		resetPagination: false,
		startsFromEdge: false,
	})
}

export function* fetchPagesAroundAnchorMessage(
	action: ReturnType<
		typeof conversationMessagePagination.actions.getPagesAroundCursor
	>
) {
	const { conversationId, cursor } = action.payload
	const where = yield* call(getCursorPaginationWhereClause, conversationId)
	const limit = yield* call(getPageSize, conversationId)

	const fetchOptionsBefore: FetchOptions = merge(
		{
			where,
		},
		getPageBeforeCreatedAtFetchOptions(cursor, limit)
	)

	yield* fork(fetchPage, {
		conversationId,
		fetchOptions: fetchOptionsBefore,
		updateType: UpdateType.Prepend,
		// This only works because the pagination is reset in the request
		// If it happened in the success action, there would be a race condition
		// between this page fetch and the subsequent one below
		resetPagination: true,
		startsFromEdge: false,
	})

	const fetchOptionsAfter: FetchOptions = merge(
		{
			where,
		},
		getPageAfterCreatedAtInclusiveFetchOptions(cursor, limit)
	)

	yield* fork(fetchPage, {
		conversationId,
		fetchOptions: fetchOptionsAfter,
		updateType: UpdateType.Append,
		resetPagination: false,
		startsFromEdge: false,
	})
}

function* getMessageFromCacheOrNetwork({
	conversationId,
	messageId,
}: {
	conversationId: string
	messageId: string
}) {
	const message = yield* select(state =>
		firestoreMessagesSelectors.selectById(state, messageId)
	)
	if (message) {
		return message
	}
	yield* putResolve(fetchConversationMessage(conversationId, messageId))

	return yield* select(state =>
		firestoreMessagesSelectors.selectById(state, messageId)
	)
}

function* startPaginationFromMessageId(
	action: ReturnType<
		typeof conversationMessagePagination.actions.getPagesAroundMessage
	>
) {
	const { conversationId, messageId } = action.payload

	const message = yield* call(getMessageFromCacheOrNetwork, {
		conversationId,
		messageId,
	})

	if (!message) {
		yield* put(
			conversationMessagePagination.actions.missingCursorError({
				conversationId,
				cursor: 'anchor',
				error: 'Failed to find pagination anchor message',
			})
		)
		return
	}
	const cursor: string = message.createdAt

	yield* put(
		// This action triggers the saga fetchPagesAroundAnchorMessage
		conversationMessagePagination.actions.getPagesAroundCursor({
			conversationId,
			cursor,
		})
	)
}

function* jumpToMessageSaga(
	action: ReturnType<typeof conversationsUI.actions.scrollToMessage>
) {
	const { messageId, conversationId } = action.payload

	const isMessageIdInPaginationSelector = makeGetIsMessageIdInPagination()

	const isMessageIdInPagination = yield* select(state =>
		isMessageIdInPaginationSelector(state, { messageId, conversationId })
	)

	if (!isMessageIdInPagination) {
		yield* put(
			conversationMessagesPagination.actions.getPagesAroundMessage({
				conversationId,
				messageId,
			})
		)
	}

	yield* put(
		conversationsUI.actions.setHighlightMessage({ conversationId, messageId })
	)
}

function* scrollToLatestMessage(
	action: ReturnType<typeof conversationsUI.actions.scrollToLatestMessage>
) {
	const { conversationId } = action.payload
	const canJumpToBottomWithCurrentPagination = yield* select(state =>
		getFetchedAllAscendingForConversation(state, { conversationId })
	)

	if (!canJumpToBottomWithCurrentPagination) {
		yield* putResolve(
			conversationMessagePagination.actions.getLastPage({ conversationId })
		)
	}

	// TODO: Handle the case where the last page is not the bottom page
	// Either be determining which page is the bottom page
	// Or renaming this to ScrollToLatestRequest
	yield* put(conversationsUI.actions.scrollToBottomRequest({ conversationId }))
}

export default function* conversationMessagesSaga() {
	// Meta actions
	yield* takeEvery(conversationsUI.actions.scrollToMessage, jumpToMessageSaga)
	yield* takeEvery(
		conversationsUI.actions.scrollToLatestMessage,
		scrollToLatestMessage
	)

	// Initial page actions, these will reset the current
	yield* takeEvery(
		conversationMessagePagination.actions.getFirstPage,
		fetchFirstPage
	)
	yield* takeEvery(
		conversationMessagePagination.actions.getLastPage,
		fetchLastPage
	)
	yield* takeEvery(
		conversationMessagePagination.actions.getPagesAroundCursor,
		fetchPagesAroundAnchorMessage
	)
	yield* takeEvery(
		conversationMessagePagination.actions.getPagesAroundMessage,
		startPaginationFromMessageId
	)

	// Further pagination action, these accumulate into the current pagination
	yield* takeEvery(
		conversationMessagePagination.actions.getNextPage,
		fetchNextPage
	)
	yield* takeEvery(
		conversationMessagePagination.actions.getPreviousPage,
		fetchPreviousPage
	)
}
