import { put, takeEvery, call } from 'typed-redux-saga/macro'

import conversationsUI from '../slices/conversationsUI'
import message from '../slices/message'

import sendMessageSaga from './sendMessage'

function* submitMessageSaga(
	sendMessageAction: ReturnType<typeof message.actions.sendMessageInitiate>
) {
	yield* call(sendMessageSaga, sendMessageAction)

	yield* put(
		conversationsUI.actions.scrollToLatestMessage({
			conversationId: sendMessageAction.payload.conversationId,
		})
	)
}

export default function* sendMessageWatcherSaga() {
	yield* takeEvery(message.actions.sendMessageInitiate.type, submitMessageSaga)
}
