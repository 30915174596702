import firebase from 'firebase/app'
import 'firebase/firestore'

import {
	MessageReactionModel,
	ReactionType,
	MessageReactionSenderInfo,
	EmojiReaction,
} from '../../domain/Reactions'
import { reactionTypeIsLegacy } from './reactions'

import { AttendantModel } from '../../domain/Attendant'
import { ChatCardState } from '../../domain/ChatCard'
import {
	MessageAttachment,
	MessageContent,
	MessageModel,
} from '../../domain/Message'
import { Config } from '../../slices/config'
import { LocalMessage } from '../../slices/localMessages'

import {
	Attachment as FSMessageAttachment,
	Message as FSMessage,
	MessageSenderInfo,
	MessageContent as FSMessageContent,
	FetchOptions,
	ConversationState,
	ConversationMessagesFilter,
	MessageReaction as FSMessageReaction,
	BaseMessageReaction as FSBaseMessageReaction,
	LegacyReaction as FSLegacyReaction,
} from './types'

export const senderInfoToAttendantModel = (
	senderInfo: MessageSenderInfo
): AttendantModel => ({
	id: senderInfo.attendantId,
	displayName: senderInfo.displayName,
	displayImageFilename: senderInfo.displayImageFilename,
	displayColor: senderInfo.displayColor,
})

const toMessageAttachment = (
	attachment: FSMessageAttachment
): MessageAttachment => ({
	fileId: attachment.fileId,
	type: attachment.attachmentType,
	fileName: attachment.fileName,
	mimeType: attachment.mimeType,
	fileSize: attachment.fileSize,
	resourceType: attachment.resourceType,
	dimensions: attachment.dimensions,
})

export const toMessageContent = (
	content: FSMessageContent
): MessageContent => ({
	type:
		(!!content.imageFilename && 'image') ||
		(!!content.pollOptions && 'poll') ||
		'text',
	text: content.text,
	imageFilename: content.imageFilename,
	imageDimensions: content.imageDimensions,
	pollOptions: content.pollOptions?.map(option => ({
		id: option.optionId,
		title: option.optionTitle,
	})),
	attachments: content.attachments?.map(attachement =>
		toMessageAttachment(attachement)
	),
})

function localMessageToMessageModel(message: LocalMessage): MessageModel {
	return {
		uniqueId: message.uniqueId,
		conversationId: message.conversationId,
		createdAt: message.createdAt,
		type: message.type === 'client_message' ? 'client' : 'service',
		isImportant: message.important,
		sender: senderInfoToAttendantModel(message.senderInfo),
		content: toMessageContent(message.content),
		syncedShareInfo: message.syncedShareInfo ?? null,
		removed: message.removed,
	}
}

export function remoteMessageToMessageModel(message: FSMessage): MessageModel {
	return {
		id: message.id,
		conversationId: message.conversationId,
		uniqueId: message.uniqueId,
		createdAt: message.createdAt,
		type: message.type === 'client_message' ? 'client' : 'service',
		isImportant: message.important,
		sender:
			message.senderInfo && senderInfoToAttendantModel(message.senderInfo),
		content: message.content && toMessageContent(message.content),
		syncedShareInfo: message.syncedShareInfo ?? null,
		removed: message.removed,
	}
}

export function toMessageModel(
	message?: FSMessage | LocalMessage
): MessageModel | undefined {
	if (message === undefined) {
		return undefined
	}
	if ('id' in message) {
		return remoteMessageToMessageModel(message)
	}
	return localMessageToMessageModel(message)
}

export function fsConversationStateToChatCardState(
	state: ConversationState
): ChatCardState {
	switch (state) {
		case 'live':
			return ChatCardState.Live
		case 'ended':
			return ChatCardState.Ended
	}
}

export function getPageAfterCreatedAtFetchOptions(
	afterCursor: string,
	limit: number
): FetchOptions {
	return {
		orderBy: [['createdAt', 'desc']],
		endBefore: afterCursor,
		limitToLast: limit,
	}
}

export function getPageAfterCreatedAtInclusiveFetchOptions(
	afterCursor: string,
	limit: number
): FetchOptions {
	return {
		orderBy: [['createdAt', 'desc']],
		endAt: afterCursor,
		limitToLast: limit,
	}
}

export function getPageBeforeCreatedAtFetchOptions(
	beforeCursor: string,
	limit: number
): FetchOptions {
	return {
		orderBy: [['createdAt', 'desc']],
		startAfter: beforeCursor,
		limit: limit,
	}
}

export function getNewestCreatedAtPageFetchOptions(
	limit: number
): FetchOptions {
	return {
		orderBy: [['createdAt', 'desc']],
		limit,
	}
}

export function getOldestCreatedAtPageFetchOptions(
	limit: number
): FetchOptions {
	return {
		orderBy: [['createdAt', 'asc']],
		limit,
	}
}

export function conversationMessagesFilterToWhereClause(
	filter: ConversationMessagesFilter
): FetchOptions['where'] | ((ids: string[]) => FetchOptions['where']) {
	switch (filter) {
		case ConversationMessagesFilter.None:
			return undefined
		case ConversationMessagesFilter.NoDeleted:
			return [['removed', '==', false]]
		case ConversationMessagesFilter.ImportantOnly:
			return [['important', '==', true]]
		case ConversationMessagesFilter.CuratedMessageIds:
			return (messageIds: string[]) => [
				[firebase.firestore.FieldPath.documentId(), 'in', messageIds],
			]
	}
}

export function legacyMessageReactionToModelEmojiReaction(
	legacyReaction: FSLegacyReaction,
	config: Config
): EmojiReaction {
	const tableForLegacyReaction =
		config.legacyEmojiReactionTable[legacyReaction.type]
	return {
		type: ReactionType.EmojiReaction,
		emojiId: tableForLegacyReaction.emojiId,
		emojiImageId: tableForLegacyReaction.emojiImageId,
		name: tableForLegacyReaction.name,
		unicode: tableForLegacyReaction.unicode,
	}
}

export function remoteMessageReactionToModelMessageReaction(
	reaction: FSMessageReaction,
	config: Config
): MessageReactionModel | undefined {
	const senderInfo: MessageReactionSenderInfo = {
		attendantId: reaction.attendantId,
		displayName: reaction.displayName,
		displayImageId: reaction.displayImageId,
		displayColor: reaction.displayColor,
	}

	if (reaction.type === 'EMOJI') {
		return {
			...senderInfo,
			type: ReactionType.EmojiReaction,
			emojiId: reaction.emojiId,
			emojiImageId: reaction.emojiImageId,
			unicode: reaction.emojiUnicode ?? undefined,
		}
	}
	if (reaction.type === 'APPRECIATION') {
		return {
			...senderInfo,
			type: ReactionType.AppreciationReaction,
			color: reaction.color,
			emojiImageId: reaction.emojiImageId,
			appreciationId: reaction.appreciationId,
			appreciationName: reaction.appreciationName,
			// We do not have enough information to determine whether the appreciation
			// is still active (ie has not been "deleted")
			active: undefined,
		}
	}

	if (reactionTypeIsLegacy(reaction.type)) {
		// Convert the legacy type into an EMOJI
		return {
			...senderInfo,
			...legacyMessageReactionToModelEmojiReaction(reaction, config),
		}
	}

	// More reaction types may be added in the future
	return undefined
}

export function modelMessageReactionToFSMessageReaction(
	reaction: MessageReactionModel
): FSMessageReaction {
	const senderInfo: FSBaseMessageReaction = {
		attendantId: reaction.attendantId,
		displayName: reaction.displayName,
		displayImageId: reaction.displayImageId,
		displayColor: reaction.displayColor,
	}

	switch (reaction.type) {
		case ReactionType.AppreciationReaction:
			return {
				...senderInfo,
				type: 'APPRECIATION',
				color: reaction.color,
				emojiImageId: reaction.emojiImageId,
				appreciationId: reaction.appreciationId,
				appreciationName: reaction.appreciationName,
			}
		case ReactionType.EmojiReaction: {
			return {
				...senderInfo,
				type: 'EMOJI',
				emojiId: reaction.emojiId,
				emojiImageId: reaction.emojiImageId,
			}
		}
	}
}
