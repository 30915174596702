import { FC, forwardRef } from 'react'
import { useTheme } from 'styled-components'

import LoadingSpinner from '../LoadingSpinner'

import {
	StyledButton,
	StyledStartEnhancer,
	StyledEndEnhancer,
} from './styled-components'
import {
	ButtonProps,
	ButtonKind,
	ButtonSize,
	ButtonShape,
	ButtonVariant,
} from './types'

const ButtonInternals: FC<ButtonProps> = props => {
	const StartEnhancer = props.startEnhancer
	const EndEnhancer = props.endEnhancer
	return (
		<>
			{StartEnhancer && (
				<StyledStartEnhancer>{StartEnhancer}</StyledStartEnhancer>
			)}
			{props.children}
			{EndEnhancer && <StyledEndEnhancer>{EndEnhancer}</StyledEndEnhancer>}
		</>
	)
}

const LoadingSpinnerForButtonKind = ({
	variant,
}: {
	variant: ButtonVariant
}) => {
	const theme = useTheme()
	switch (variant) {
		case ButtonVariant.Secondary:
		case ButtonVariant.SecondaryAlt:
		case ButtonVariant.EventAlt:
			return <LoadingSpinner color={theme.colors.contentPrimary} />
		case ButtonVariant.Primary:
		case ButtonVariant.Danger:
		case ButtonVariant.Poll:
		default:
			return <LoadingSpinner color={theme.colors.primary} />
	}
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const {
			kind = ButtonKind.Full,
			variant = ButtonVariant.Primary,
			size = ButtonSize.Default,
			shape = ButtonShape.Default,
			disabled = false,
			type = 'button',
			isLoading = false,
			fluid = false,
			noWrap = false,
			children,
			...rest
		} = props
		return (
			<StyledButton
				type={type}
				$kind={kind}
				$variant={variant}
				$size={size}
				$shape={shape}
				$fluid={fluid}
				$noWrap={noWrap}
				disabled={disabled}
				ref={ref}
				{...rest}
			>
				{isLoading ? (
					<LoadingSpinnerForButtonKind variant={variant} />
				) : (
					<ButtonInternals {...props}>{children}</ButtonInternals>
				)}
			</StyledButton>
		)
	}
)

export default Button
