import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'
import { union } from 'lodash'

import { ConversationMessagesContext } from '../actions/messages'

import { MESSAGES_PAGINATION_SIZE } from '../constants/config'
import {
	Message as FSMessage,
	ConversationMessagesFilter,
} from '../network/firestore'
import { UpdateType } from '../network/pagination'

import auth from './auth'
import { PaginationAction, paginationAdapter } from './paginate'

import { StoreState } from '.'

interface BidirectionalPaginationState {
	loadingErrorDescending?: string
	loadingDescending: boolean
	fetchedAllDescending: boolean
	before?: string | null
	messageIds: string[]
	after?: string | null
	fetchedAllAscending: boolean
	loadingAscending: boolean
	loadingErrorAscending?: string
}

// Eventually there may be multiple BidirectionalPaginationState's
// per slice, which might be merged on paginationSuccess
// similar to AppCore
export interface ConversationMessages extends BidirectionalPaginationState {
	conversationId: string
	filter: ConversationMessagesFilter
	nextPageSize: number
}

export const conversationMessagesAdapter =
	createEntityAdapter<ConversationMessages>({
		selectId(entry) {
			// TODO: keying by conversationId means that we cannot have more than one
			// pagination per conversation
			return entry.conversationId
		},
	})

export type MessagesByConversationState = EntityState<ConversationMessages>

function getInitialConversationMessagesState(
	conversationId: string
): ConversationMessages {
	return {
		conversationId,

		filter: ConversationMessagesFilter.None,
		nextPageSize: MESSAGES_PAGINATION_SIZE,

		loadingDescending: false,
		fetchedAllDescending: false,
		before: null,
		messageIds: [],
		after: null,
		fetchedAllAscending: false,
		loadingAscending: false,
	}
}

export type ConversationMessagesAction<P = unknown, E = never> = PayloadAction<
	P & {
		conversationId: string
	},
	string,
	never,
	E
>

export type ConversationMessagesPaginationAction<
	P = unknown,
	E = never
> = PaginationAction<
	P & {
		conversationId: string
	},
	E
>

function ensureInitialState(
	state: WritableDraft<EntityState<ConversationMessages>>,
	action: { payload: { conversationId: string } }
): string {
	const conversationId = action.payload.conversationId
	if (
		!conversationMessagesAdapter
			.getSelectors()
			.selectById(state, conversationId)
	) {
		conversationMessagesAdapter.addOne(
			state,
			getInitialConversationMessagesState(conversationId)
		)
	}
	return conversationId
}

function resetPagination(
	state: WritableDraft<EntityState<ConversationMessages>>,
	action: { payload: { conversationId: string } }
) {
	conversationMessagesAdapter.updateOne(state, {
		id: action.payload.conversationId,
		changes: {
			fetchedAllDescending: false,
			before: null,
			messageIds: [],
			after: null,
			fetchedAllAscending: false,
		},
	})
}

const conversationMessagesPagination = createSlice({
	name: 'conversationMessagesPagination',
	initialState: conversationMessagesAdapter.getInitialState(),
	reducers: {
		// Saga actions
		getFirstPage(state, action: ConversationMessagesAction) {},
		getLastPage(state, action: ConversationMessagesAction) {},
		getNextPage(state, action: ConversationMessagesAction) {},
		getPreviousPage(state, action: ConversationMessagesAction) {},
		getPagesAroundCursor(
			state,
			action: ConversationMessagesAction<{
				cursor: string
			}>
		) {},
		getPagesAroundMessage(
			state,
			action: ConversationMessagesAction<{
				messageId: string
			}>
		) {},
		missingCursorError: {
			reducer(
				state,
				action: ConversationMessagesAction<
					{ cursor: 'before' | 'after' | 'anchor' },
					string
				>
			) {},
			prepare(args: {
				conversationId: string
				cursor: 'before' | 'after' | 'anchor'
				error: string
			}) {
				return {
					payload: {
						conversationId: args.conversationId,
						cursor: args.cursor,
					},
					error: args.error,
				}
			},
		},

		setFilterForConversationId(
			state,
			action: ConversationMessagesAction<{
				filter: ConversationMessagesFilter
			}>
		) {
			ensureInitialState(state, action)
			conversationMessagesAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					filter: action.payload.filter,
				},
			})
		},

		// pagination actions
		paginationRequest(
			state,
			action: ConversationMessagesAction<{
				resetPagination?: boolean
				updateType: UpdateType
			}>
		) {
			ensureInitialState(state, action)
			if (action.payload.resetPagination) {
				// It is best to reset the pagination in the request action
				// Reseting pagination in the success action could lead to race conditions
				resetPagination(state, action)
			}

			const changesForUpdateType = {
				[UpdateType.Append]: {
					loadingAscending: true,
				},
				[UpdateType.Prepend]: {
					loadingDescending: true,
				},
			}
			conversationMessagesAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: changesForUpdateType[action.payload.updateType],
			})
		},
		paginationSuccess(
			state,
			action: ConversationMessagesPaginationAction<{
				messages: FSMessage[]
				startsFromEdge?: boolean
				updateType: UpdateType
			}>
		) {
			const previousState = conversationMessagesAdapter
				.getSelectors()
				.selectById(state, action.payload.conversationId)
			const oldMessageIds = previousState?.messageIds

			const changesForUpdateType = {
				[UpdateType.Append]: {
					loadingAscending: false,
					...(action.payload.startsFromEdge && { fetchedAllDescending: true }),
					// We can use the `before` in this response if there's no other
					// `before` that it will overwrite, eg if this is the first page fetched
					before: previousState?.before ?? action.payload.pagination?.before,
					messageIds: union(
						oldMessageIds,
						action.payload.pagination?.ascendingIds
					),
					after: action.payload.pagination?.after,
					fetchedAllAscending: action.payload.pagination?.fetchedAll,
				},

				[UpdateType.Prepend]: {
					loadingDescending: false,
					fetchedAllDescending: action.payload.pagination?.fetchedAll,
					before: action.payload.pagination?.before,
					messageIds: union(
						action.payload.pagination?.ascendingIds,
						oldMessageIds
					),
					// We can use the `after` in this response if there's no other
					// `after` that it will overwrite, eg if this is the first page fetched
					after: previousState?.after ?? action.payload.pagination?.after,
					...(action.payload.startsFromEdge && {
						fetchedAllAscending: true,
					}),
				},
			}

			conversationMessagesAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: changesForUpdateType[action.payload.updateType],
			})
		},
		paginationFailure: {
			reducer(
				state,
				action: ConversationMessagesPaginationAction<
					{ updateType: UpdateType },
					string
				>
			) {
				ensureInitialState(state, action)
				const changesForUpdateType = {
					[UpdateType.Append]: {
						loadingAscending: false,
						loadingErrorAscending: action.error,
					},
					[UpdateType.Prepend]: {
						loadingDescending: false,
						loadingErrorDescending: action.error,
					},
				}

				conversationMessagesAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: changesForUpdateType[action.payload.updateType],
				})
			},
			prepare(args: {
				conversationId: string
				updateType: UpdateType
				error: Error
			}) {
				return {
					payload: {
						conversationId: args.conversationId,
						updateType: args.updateType,
					},
					error: args.error.message,
				}
			},
		},

		// Paginate reducer actions
		conversationMessagesRequest(
			state,
			action: ConversationMessagesPaginationAction<{
				context: ConversationMessagesContext
			}>
		) {},
		conversationMessagesSuccess(
			state,
			action: ConversationMessagesPaginationAction<{
				messages: FSMessage[]
				context: ConversationMessagesContext
			}>
		) {},
		conversationMessagesFailure: {
			reducer(
				state,
				action: ConversationMessagesPaginationAction<
					{
						context: ConversationMessagesContext
					},
					string
				>
			) {},
			prepare(args: {
				conversationId: string
				error: string
				context: ConversationMessagesContext
			}) {
				return {
					payload: {
						conversationId: args.conversationId,
						context: args.context,
					},
					error: args.error,
				}
			},
		},
		conversationMessagesReset(
			state,
			action: ConversationMessagesPaginationAction<{
				context: ConversationMessagesContext
			}>
		) {},
	},
	extraReducers: builder =>
		builder.addCase(auth.actions.signOutSuccess, () =>
			conversationMessagesAdapter.getInitialState()
		),
})

export default conversationMessagesPagination

export const conversationMessagesBidirectionalPaginationSelectors =
	conversationMessagesAdapter.getSelectors<StoreState>(
		state => state.conversationMessagesPagination
	)

export const conversationMessagesPaginationSelectors =
	paginationAdapter.getSelectors<StoreState>(
		state => state.pagination.conversationMessages
	)
