import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'
export function assignFromAction<T, K extends keyof T>(
	target: T,
	{ payload }: Readonly<PayloadAction<Pick<T, K>>>
): T {
	for (const id in payload) {
		const value = payload[id]
		target[id] = value!
	}
	return target
}

/**
 * The Request Adapter is modelled after redux toolkit's entity adapter
 * to treat request patterns in a systematic way.
 * One difference is that the adapter gets instantiated with a `name` that is
 * used to access its state container in its parent state, compared to the entity
 * adapter which operates directly on its state, requiring manual drilling before calls.
 * The request adapter can be used direcetly as caseReducer when creating a slice
 * `actionNameSuccess: requestAdapter.success`
 * or it can be called via `requestAdapter.success(state, action)`
 */

// TODO: make a requestadapter on top of entity adapter to support multiple request?
export interface RequestAdapterState {
	loading: boolean
	error?: string
	success: boolean
}

export type RequestAdapterAction = AnyAction

export type RequestAdapterErrorAction = PayloadAction<
	any,
	string,
	never,
	string
>

interface RequestAdapterRequest<N extends string> {
	(
		state: WriteableRequestAdapterContainer<N>,
		action: RequestAdapterAction
	): void
}

interface RequestAdapterSuccess<N extends string>
	extends RequestAdapterRequest<N> {}

interface RequestAdapterFailure<N extends string> {
	(
		state: WriteableRequestAdapterContainer<N>,
		action: RequestAdapterErrorAction
	): void
	reducer(
		state: WriteableRequestAdapterContainer<N>,
		action: RequestAdapterErrorAction
	): any
	prepare(args: { error: string }): Omit<RequestAdapterErrorAction, 'type'>
}

type RequestName<N extends string> = `${N}Request`
type FailureName<N extends string> = `${N}Failure`
type SuccessName<N extends string> = `${N}Success`

// TODO: is there a better way to do this?
type CaseReducers<N extends string> = {
	[P in RequestName<N>]: RequestAdapterRequest<N>
} &
	{
		[P in FailureName<N>]: RequestAdapterFailure<N>
	} &
	{
		[P in SuccessName<N>]: RequestAdapterSuccess<N>
	}

export interface RequestAdapter<N extends string> {
	getInitialState(): RequestAdapterState
	request: RequestAdapterRequest<N>
	failure: RequestAdapterFailure<N>
	success: RequestAdapterRequest<N>
	caseReducers: CaseReducers<N>
}

type WriteableRequestAdapterContainer<N extends string> = WritableDraft<
	{
		[K in N]: RequestAdapterState
	}
>

export function createRequestAdapter<N extends string>({
	name,
}: {
	name: N
}): RequestAdapter<N> {
	const failure: RequestAdapterFailure<N> = function failure(state, action) {
		state[name].loading = false
		state[name].error = action.error
		state[name].success = false
	}
	failure.reducer = failure
	failure.prepare = function prepare({ error }: { error: string }) {
		return { payload: {}, error }
	}

	const request: RequestAdapterRequest<N> = function request(state, action) {
		state[name].loading = true
		state[name].error = undefined
		state[name].success = false
	}

	const success: RequestAdapterSuccess<N> = function success(state, action) {
		state[name].loading = false
		state[name].success = true
	}

	return {
		getInitialState() {
			return { loading: false, error: undefined, success: false }
		},
		request,
		success,
		failure,
		caseReducers: {
			[`${name}Request`]: request,
			[`${name}Success`]: success,
			[`${name}Failure`]: failure,
		} as CaseReducers<N>,
	}
}
