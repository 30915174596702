import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiClient } from '../network/api/apiClient'
import { AuthClient } from '../network/auth/authClient'

export const sagaContext = createSlice({
	name: 'sagaContext',
	initialState: {},
	reducers: {
		setSagaContext(
			state,
			action: PayloadAction<{
				gqlClient: ApolloClient<NormalizedCacheObject> | null
				apiClient: ApiClient | null
				authClient: AuthClient | null
			}>
		) {},
		setSagaContextError: {
			reducer(state, action: PayloadAction<unknown, string, never, string>) {},
			prepare({ error }: { error: string }) {
				return { payload: {}, error }
			},
		},
	},
})

export default sagaContext
