import { FC } from 'react'
import styled, { css, useTheme } from 'styled-components'

export enum IconShape {
	None,
	Circle,
	Square,
}

export type IconProps = {
	title?: string
	color?: string
	size?: number | string
	className?: string
} & (
	| {
			shape?: IconShape.None
	  }
	| {
			shape: Exclude<IconShape, IconShape.None>
			shapeSize: number
			shapeColor?: string
	  }
)

type Props = IconProps & {
	viewBox?: string
}

const Icon: FC<Props> = props => {
	const theme = useTheme()
	const color = props.color ?? theme.colors.iconFill
	const noShape = props.shape === undefined || props.shape === IconShape.None
	const content = (
		<svg
			width={props.size ?? 32}
			height={props.size ?? 32}
			className={noShape ? props.className : undefined}
			viewBox={props.viewBox ?? '0 0 32 32'}
			fill={color}
			stroke={color}
		>
			{!!props.title && <title>{props.title}</title>}
			{props.children}
		</svg>
	)
	switch (props.shape) {
		case IconShape.Circle:
			return (
				<Circle
					className={props.className}
					$size={props.shapeSize}
					$color={props.shapeColor}
				>
					{content}
				</Circle>
			)
		case IconShape.Square:
			return (
				<Circle
					className={props.className}
					$size={props.shapeSize}
					$color={props.shapeColor}
					$borderRadius="25%"
				>
					{content}
				</Circle>
			)
		case IconShape.None:
		default:
			return content
	}
}

const Circle = styled.div<{
	$color?: string
	$size: number
	$borderRadius?: string
}>(
	({ theme, $color, $size, $borderRadius = '50%' }) => css`
		border-radius: ${$borderRadius};
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		white-space: nowrap;
		background-color: ${$color ?? theme.colors.primary};
		${$size && {
			height: `${$size}px`,
			width: `${$size}px`,
		}}
	`
)

export default Icon
