import styled, { css } from 'styled-components'

import { colorOrDefault } from '../../utils/renderHelper'

export const ColoredBackground = styled.div<{ $colorValue?: string }>(
	({ theme, $colorValue }) => css`
		background: ${colorOrDefault($colorValue, theme.colors.mono500)};
		height: 100%;
	`
)
