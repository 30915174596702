import { GkGates } from '../network/api'
import { StoreState } from '../store'

export const getApi = (state: StoreState) => state.api

export const getApiInitialized = (state: StoreState) =>
	getApi(state).initialized

export const isOnHardWaitlist = (gkGates: GkGates) => {
	if (!gkGates.hardWaitlistLimitations && !gkGates.hardWaitlistStatus) {
		return true
	}

	if (!gkGates.hardWaitlistLimitations) {
		return (
			gkGates.hardWaitlistStatus === 'joined' ||
			gkGates.hardWaitlistStatus === 'intended'
		)
	}

	return gkGates.hardWaitlistLimitations === 'yes'
}

export const getIsOnHardWaitlist = (state: StoreState) => {
	const api = getApi(state)
	if (!api.gkGates) {
		return true
	}
	return isOnHardWaitlist(api.gkGates)
}

export default getApi
