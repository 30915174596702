import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

// we can't import from outside of the src folder
import favicon16 from './favicons/favicon-16x16.png'
import favicon16Dirty from './favicons/favicon-16x16_dirty.png'
import favicon32 from './favicons/favicon-32x32.png'
import favicon32Dirty from './favicons/favicon-32x32_dirty.png'
import favicon from './favicons/favicon.ico'
import faviconDirty from './favicons/favicon_dirty.ico'

const Favicon: FC = () => {
	useEffect(() => {
		// helm cannot target these, as they have real urls
		// so delete the static ones within the index.html file
		// and just use the helm ones afterwards
		const elements = Array.from(
			document.getElementsByClassName('favicon-remove')
		)
		elements.forEach(element => document.head.removeChild(element))
		return () => {
			elements.forEach(element => document.head.appendChild(element))
		}
	}, [])

	const isDirty = useSelector(state => state.favicon.isDirty)

	return (
		<Helmet>
			<link
				rel="icon"
				type="image/png"
				href={isDirty ? favicon32Dirty : favicon32}
				sizes="32x32"
			/>
			<link
				rel="icon"
				type="image/png"
				href={isDirty ? favicon16Dirty : favicon16}
				sizes="16x16"
			/>
			<link rel="shortcut icon" href={isDirty ? faviconDirty : favicon} />
		</Helmet>
	)
}

export default Favicon
