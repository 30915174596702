import { push } from 'connected-react-router'
import { call, put, race, select, take } from 'typed-redux-saga'

import { pathBuilders } from '../routes'
import api from '../slices/api'
import auth from '../slices/auth'
import sphereSidebar from '../slices/sphereSidebar'

function* watchSphereSidebar() {
	while (true) {
		const action = yield* take(
			sphereSidebar.actions.spheresFinishedLoading.match
		)

		const state = yield* select(state => state.sphereSidebar)

		if (!state.isOpen || state.activeSphereId) {
			continue
		}

		const loadedSpheres = action.payload
		const validSphere = loadedSpheres.length && loadedSpheres[0]

		if (validSphere) {
			yield* put(push(pathBuilders.contentFeed({ sphereId: validSphere })))
		} else {
			yield* put(push(pathBuilders.default()))
		}
	}
}

export default function* activeSphereSaga() {
	while (true) {
		const action = yield* take(api.actions.initSuccess.type)
		if (api.actions.initSuccess.match(action)) {
			yield* race({
				task: call(watchSphereSidebar),
				signout: take(auth.actions.signOutRequest.type),
			})
		}
	}
}
