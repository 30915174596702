export enum UpdateType {
	Append,
	Prepend,
}

export interface Pagination {
	ascendingIds: string[]
	before?: string | null
	after?: string | null
	fetchedAll: boolean
	order?: 'asc' | 'desc'
	updateType?: UpdateType
}
