import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import serviceWorker from './serviceWorker'

interface NotificationsState {
	initialized: boolean
	supported: boolean | null
	requestingPermission: boolean
	permission: NotificationPermission | null
	requestDismissed: boolean
}

interface WebAppInstallState {
	deferredEvent: Event | null
	installing: boolean
	installingError: string | null
	outcome: 'accepted' | 'dismissed' | null
}

export interface BrowserState {
	shareApiSupported: boolean
	notifications: NotificationsState
	webappInstall: WebAppInstallState
}

const initialState: BrowserState = {
	shareApiSupported: false,
	notifications: {
		initialized: false,
		requestingPermission: false,
		permission: null,
		supported: null,
		requestDismissed: false,
	},
	webappInstall: {
		installing: false,
		installingError: null,
		deferredEvent: null,
		outcome: null,
	},
}

const browser = createSlice({
	name: 'browser',
	initialState,
	reducers: {
		shareApiInit: {
			reducer(state, action: PayloadAction<{ supported: boolean }>) {
				state.shareApiSupported = action.payload.supported
			},
			prepare() {
				return {
					payload: {
						supported: 'share' in window.navigator,
					},
				}
			},
		},
		// Uses Notifications API to display local notifications
		notificationInit: {
			reducer(
				state,
				action: PayloadAction<{
					supported: boolean
					permission: NotificationPermission | null
				}>
			) {
				state.notifications.initialized = true
				state.notifications.supported = action.payload.supported
				state.notifications.permission = action.payload.permission
			},
			prepare() {
				const isNotificationSupported = 'Notification' in window
				const notificationPermission = isNotificationSupported
					? Notification.permission
					: null
				return {
					payload: {
						supported: isNotificationSupported,
						permission: notificationPermission,
					},
				}
			},
		},
		notificationPermissionRequest(state, action: PayloadAction) {
			state.notifications.requestingPermission = true
		},
		// TODO: check why noone is calling this
		notificationPermissionChanged(
			state,
			action: PayloadAction<{
				permission: NotificationPermission
			}>
		) {
			state.notifications.requestingPermission = false
			state.notifications.permission = action.payload.permission
		},
		webappInstallEventDeferred(
			state,
			action: PayloadAction<{
				event: Event
			}>
		) {
			state.webappInstall.deferredEvent = action.payload.event
		},
		webappInstallPromptDeny(state, action: PayloadAction) {
			state.webappInstall.deferredEvent = null
		},
		webappInstallPromptRequest(state, action: PayloadAction) {
			state.webappInstall.installing = true
			state.webappInstall.outcome = null
			state.webappInstall.installingError = null
		},
		webappInstallPromptSuccess(
			state,
			action: PayloadAction<{
				outcome: 'accepted' | 'dismissed'
			}>
		) {
			state.webappInstall.installing = false
			state.webappInstall.outcome = action.payload.outcome
			state.webappInstall.deferredEvent = null
		},
		webappInstallPromptFailure: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {
				state.webappInstall.installing = false
				state.webappInstall.installingError = action.error
				state.webappInstall.deferredEvent = null
			},
			prepare({ error }: { error: string }) {
				return { payload: {}, error }
			},
		},
		notificationPermissionDismissed(state, action: PayloadAction) {
			state.notifications.requestDismissed = true
		},
		// empty reducers from src/actions/browser.ts
		dismissNotificationPermissionRequest() {},
	},
	extraReducers: builder =>
		builder
			.addCase(serviceWorker.actions.pushSubscribeRequest, state => {
				state.notifications.requestingPermission =
					state.notifications.permission === 'default'
			})
			.addCase(serviceWorker.actions.pushSubscribeSuccess, state => {
				state.notifications.requestingPermission = false
				state.notifications.permission = 'granted'
			})
			.addCase(serviceWorker.actions.pushSubscribeFailure, (state, action) => {
				state.notifications.requestingPermission = false
				state.notifications.permission = action.payload.permission
			}),
})

export default browser
