import { FontTokens, Typography, Font } from './types'

/**
 * Took inspiration from:
 * https://atlassian.design/guidelines/product/foundations/typography
 */
export const fontTokens: FontTokens = {
	primaryFontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	monospacedFontFamily:
		"'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace",
}

// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping
export const fontWeights: Record<string, number> = {
	thin: 100,
	extraLight: 200,
	light: 300,
	regular: 400,
	normal: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
	extraBold: 800,
	black: 900,
	extraBlack: 950,
}

function generateFont(
	fontSize: number,
	lineHeight: number,
	fontWeight: number
): Font {
	return {
		fontFamily: fontTokens.primaryFontFamily,
		fontSize: `${fontSize}px`,
		fontWeight,
		lineHeight: `${lineHeight}px`,
	}
}

export const typography: Typography = {
	DisplayBold: generateFont(24, 29, fontWeights.bold),
	DisplayMedium: generateFont(24, 29, fontWeights.medium),
	DisplayRegular: generateFont(24, 29, fontWeights.regular),

	HeaderBold: generateFont(19, 24, fontWeights.bold),
	HeaderMedium: generateFont(19, 24, fontWeights.medium),
	HeaderRegular: generateFont(19, 24, fontWeights.regular),

	TitleBold: generateFont(17, 22, fontWeights.bold),
	TitleMedium: generateFont(17, 22, fontWeights.medium),
	TitleRegular: generateFont(17, 22, fontWeights.regular),

	ParagraphBold: generateFont(15, 20, fontWeights.bold),
	ParagraphMedium: generateFont(15, 20, fontWeights.medium),
	ParagraphRegular: generateFont(15, 20, fontWeights.regular),

	LabelBold: generateFont(13, 18, fontWeights.bold),
	LabelMedium: generateFont(13, 18, fontWeights.medium),
	LabelRegular: generateFont(13, 18, fontWeights.regular),

	FootnoteBold: generateFont(12, 17, fontWeights.bold),
	FootnoteMedium: generateFont(12, 17, fontWeights.medium),
	FootnoteRegular: generateFont(12, 17, fontWeights.regular),

	FootnoteUppercase: {
		...generateFont(12, 17, fontWeights.bold),
		textTransform: 'uppercase',
	},
}

export default typography
