import { takeLatest, getContext, put, call, take } from 'typed-redux-saga/macro'

import { beginAuthenticatedUserSession } from '../actions/auth'
import { initializeFirebase } from '../actions/firebase'

import {
	AUTH_CLIENT_SAGA_CONTEXT_KEY,
	API_CLIENT_SAGA_CONTEXT_KEY,
} from '../constants/config'
import { FetchResult, UserConfigurationResponsePayload } from '../network/api'
import { isOnHardWaitlist } from '../selectors/api'
import api from '../slices/api'
import { auth } from '../slices/auth'
import { toSafeError } from '../utils/error'

export function* requestUserConfig() {
	const apiClient = yield* getContext(API_CLIENT_SAGA_CONTEXT_KEY)

	yield* put(api.actions.initRequest())

	try {
		const response = yield* call(apiClient.getUserConfiguration.bind(apiClient))

		const { data, error } =
			response as FetchResult<UserConfigurationResponsePayload>

		if (error) {
			yield* put(api.actions.initFailure(error))
		} else {
			yield* put(api.actions.initSuccess(data))
		}

		return {
			error,
			data,
		}
	} catch (unknownError) {
		const error = toSafeError(unknownError)
		yield* put(api.actions.initFailure(error.message))
		return { error: error.message }
	}
}

export function* userConfigurationSaga() {
	const authClient = yield* getContext(AUTH_CLIENT_SAGA_CONTEXT_KEY)
	if (!authClient.isAuthenticated()) {
		const beginAuthThunkAction = yield* call(beginAuthenticatedUserSession)
		yield put(beginAuthThunkAction)
		yield* take(auth.actions.authTokenRefreshSuccess.type)
	}

	const { data, error } = yield* call(requestUserConfig)

	if (data && !error) {
		const { firebaseToken, firebaseChatWebConfig, gkGates } =
			data as UserConfigurationResponsePayload

		if (
			authClient.isAuthenticated() &&
			gkGates &&
			!isOnHardWaitlist(gkGates) &&
			firebaseToken &&
			firebaseChatWebConfig
		) {
			const initializeFirebaseThunkAction = yield* call(initializeFirebase, {
				authToken: firebaseToken,
				appConfig: firebaseChatWebConfig,
			})
			yield* put(initializeFirebaseThunkAction)
		}
	}
}

export default function* userConfigurationWatcherSaga() {
	yield* takeLatest(api.actions.configurationInitiate, userConfigurationSaga)
}
