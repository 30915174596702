import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit'

import {
	Message as FSMessage,
	MessageContent,
	MessageSenderInfo,
} from '../network/firestore'

import auth from './auth'

import { StoreState } from '.'

export interface LocalMessage extends Omit<FSMessage, 'id'> {
	persistedId?: string
	type: 'client_message'
	content: MessageContent
	senderInfo: MessageSenderInfo
	removed: false
	failedToSend?: boolean
}

export const localMessagesAdapter = createEntityAdapter<LocalMessage>({
	selectId(item) {
		return item.uniqueId
	},
})

export type LocalMessagesState = EntityState<LocalMessage>

const localMessages = createSlice({
	name: 'localMessages',
	initialState: localMessagesAdapter.getInitialState(),
	reducers: {
		localMessageCreate: localMessagesAdapter.addOne,
		messageCreateRequest(
			state,
			action: PayloadAction<{
				uniqueId: string
				conversationId: string
			}>
		) {},
		messageCreateSuccess(
			state,
			action: PayloadAction<{
				id: string
				uniqueId: string
				conversationId: string
			}>
		) {
			localMessagesAdapter.updateOne(state, {
				id: action.payload.uniqueId,
				changes: {
					persistedId: action.payload.id,
					failedToSend: false,
				},
			})
		},
		messageCreateFailure: {
			reducer(
				state,
				action: PayloadAction<
					{ uniqueId: string; conversationId: string },
					string,
					never,
					string | undefined
				>
			) {
				localMessagesAdapter.updateOne(state, {
					id: action.payload.uniqueId,
					changes: {
						failedToSend: true,
					},
				})
			},
			prepare({
				uniqueId,
				conversationId,
				error,
			}: {
				uniqueId: string
				conversationId: string
				error?: Error
			}) {
				return { payload: { uniqueId, conversationId }, error: error?.message }
			},
		},
	},
	extraReducers(builder) {
		builder.addCase(auth.actions.signOutSuccess, state =>
			localMessagesAdapter.getInitialState()
		)
	},
})

export default localMessages

export const localMessagesSelectors =
	localMessagesAdapter.getSelectors<StoreState>(state => state.localMessages)
