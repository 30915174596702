import { put, select, takeEvery } from 'typed-redux-saga/macro'

import environment from '../slices/environment'
import favicon from '../slices/favicon'
import messagesByConversation from '../slices/messagesByConversation'
import SuperSegment from '../utils/segmentTypes'

/**
 * runs to continously update the favicon state if the tab currently hasn't focus
 * if the global badge count increases or an open conversation updates, set the dirty state
 * always clear dirty bit when tab is visible again
 */
export function* faviconSaga() {
	yield* takeEvery(
		[
			favicon.actions.newContent.type,
			favicon.actions.setDirty.type,
			favicon.actions.unsetDirty.type,
			environment.actions.setIsVisible.type,
			messagesByConversation.actions.conversationMessagesUpdate.type,
		],
		function* (
			action:
				| ReturnType<typeof favicon.actions.newContent>
				| ReturnType<typeof favicon.actions.setDirty>
				| ReturnType<typeof favicon.actions.unsetDirty>
				| ReturnType<typeof environment.actions.setIsVisible>
				| ReturnType<
						typeof messagesByConversation.actions.conversationMessagesUpdate
				  >
		) {
			switch (true) {
				case messagesByConversation.actions.conversationMessagesUpdate.match(
					action
				): {
					// can skip either way
					if (
						yield* select(
							store => store.environment.isVisible || store.favicon.isDirty
						)
					) {
						return
					}
					const localViewerAgentId = yield* select(store => store.auth.agentId)
					const messages =
						typeof action.payload === 'object' && 'messages' in action.payload
							? action.payload.messages
							: undefined
					// Only trigger the new content behaviour if someone else did send a message
					// Don't trigger it if the user itself is having two tabs
					if (
						messages?.some(
							// TODO: WEB-59
							// This currently is a standin for using the real conversation dependant attendantId
							// of the local user. But there is not great GraphQL-Redux bridge for the moment to
							// have this in a nice way
							message => message.senderInfo?.attendantId !== localViewerAgentId
						)
					) {
						yield* put(favicon.actions.newContent())
					}
					return
				}

				case favicon.actions.newContent.match(action): {
					const [isVisible, isDirty] = yield* select(store => [
						store.environment.isVisible,
						store.favicon.isDirty,
					])
					if (!isVisible && !isDirty) {
						yield* put(favicon.actions.setDirty())
					}
					return
				}

				case environment.actions.setIsVisible.match(action): {
					const isVisible = action.payload
					const isDirty = yield* select(store => store.favicon.isDirty)
					if (isVisible) {
						if (isDirty) {
							yield* put(favicon.actions.unsetDirty())
						}
					}
					return
				}

				case favicon.actions.setDirty.match(action): {
					return SuperSegment.FAVICON_UNREAD_CONTENT_INDICATOR_SHOWN()
				}

				case favicon.actions.unsetDirty.match(action): {
					return SuperSegment.FAVICON_UNREAD_CONTENT_INDICATOR_REMOVED()
				}
			}
		}
	)
}

export default faviconSaga
