import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type FaviconState = {
	isDirty: boolean
}

const initialState: FaviconState = {
	isDirty: false,
}

export const favicon = createSlice({
	name: 'favicon',
	initialState,
	reducers: {
		newContent(state, action: PayloadAction<undefined>) {
			return state
		},
		setDirty(state, action: PayloadAction<undefined>) {
			state.isDirty = true
			return state
		},
		unsetDirty(state, action: PayloadAction<undefined>) {
			state.isDirty = false
			return state
		},
	},
})

export default favicon
