import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

function useIsMobileWidth() {
	const theme = useTheme()

	const width = useSelector(state => state.environment.screenWidth)
	if (!width) {
		return undefined
	}
	return width < theme.breakpoints.md
}

export default useIsMobileWidth
