import { fileUploadsSelectors } from '../slices/files'
import { StoreState } from '../store'

export const getUploadedFiles = fileUploadsSelectors.selectEntities

export function getFileForNameFromProps(
	state: StoreState,
	ownProps: { fileName?: string }
) {
	return ownProps.fileName
		? fileUploadsSelectors.selectById(state, ownProps.fileName)
		: undefined
}
