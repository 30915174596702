import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import type { StoreState } from '.'

export type FileDimensionState = {
	fileId: string
	dimensions: {
		width: number
		height: number
	}
}

export const fileDimensionAdapter = createEntityAdapter<FileDimensionState>({
	selectId: animatedImage => animatedImage.fileId,
})

export type FileDimensionsState = ReturnType<
	typeof fileDimensionAdapter.getInitialState
>

// This slice is used to temporarily save the dimensions of uploaded videos or animated images
// When the user uploads one of these, the backend will later fetch the metadata and dimensions and store it
// Which will be available to the frontend when retrieving the message from firebase
// But directly after sending a message, the local version of that message won't have this metadata
// Thus allow uploading functionality to cache the dimensions here to enable correct sizing for the initial display
// And avoid reflows
export const fileDimensions = createSlice({
	name: 'fileDimensions',
	initialState: fileDimensionAdapter.getInitialState(),
	reducers: {
		animatedImagePreemptiveDownloadStarted: fileDimensionAdapter.addOne,
	},
})

export const fileDimensionsSelectors =
	fileDimensionAdapter.getSelectors<StoreState>(state => state.fileDimensions)

export default fileDimensions
