import moment from 'moment'
import { createContext } from 'react'

import devChatImages from './defaultChatImages/developmentChatImages.json'
import prodChatImages from './defaultChatImages/productionChatImages.json'

const DEFAULT_LOCALE = 'en-gb'

abstract class ConfigValue {
	static readonly isLazyLoadAllowed: boolean = true
	static locale(): string {
		const resolvedOptions = Intl?.DateTimeFormat?.().resolvedOptions?.()
		if (resolvedOptions) {
			return resolvedOptions.locale.toLowerCase()
		}
		return window.navigator.language || DEFAULT_LOCALE
	}
	static getReferenceDate(): moment.Moment {
		return moment()
	}
	static get chatDefaultImages(): string[] {
		if (window.config?.cloudinaryCloudName === 'sphere-dev') {
			return devChatImages as string[]
		}
		return prodChatImages as string[]
	}
	static readonly isShowingDevLogs: boolean = false
}
class TestConfig extends ConfigValue {
	static readonly isLazyLoadAllowed = false
	static locale() {
		return DEFAULT_LOCALE
	}
	static getReferenceDate() {
		return moment('20200124')
	}
}
class DevelopmentConfig extends ConfigValue {
	static readonly isLazyLoadAllowed = false
	static readonly isShowingDevLogs = true
}

class ProductionConfig extends ConfigValue {}

export const EnvironmentConfig =
	process.env.NODE_ENV === 'test'
		? TestConfig
		: process.env.NODE_ENV === 'development'
		? DevelopmentConfig
		: ProductionConfig

const EnvironmentConfigContext = createContext(EnvironmentConfig)
export default EnvironmentConfigContext
