import styled from 'styled-components'

export const Root = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
`

export const Content = styled.div`
	position: relative;
	width: 100%;
	flex: 1 1 0;
	min-height: 0px;
	background: ${({ theme }) => theme.colors.backgroundPrimary};
	display: flex;
	flex-flow: column;

	${({ theme }) => theme.layout.desktopLayoutMediaQuery} {
		flex-flow: row;
	}
`

export const Sidebar = styled.div`
	position: relative;
	display: flex;
	flex-flow: row;

	width: 100%;

	border-bottom-width: ${({ theme }) => theme.borders.border100.borderWidth};
	border-bottom-style: ${({ theme }) => theme.borders.border100.borderStyle};
	border-bottom-color: ${({ theme }) => theme.borders.border100.borderColor};

	${({ theme }) => theme.layout.desktopLayoutMediaQuery} {
		flex: 0 0 auto;
		flex-flow: column;

		width: initial;

		border-right-width: ${({ theme }) => theme.borders.border100.borderWidth};
		border-right-style: ${({ theme }) => theme.borders.border100.borderStyle};
		border-right-color: ${({ theme }) => theme.borders.border100.borderColor};

		border-bottom-width: 0px;
		border-bottom-style: none;
	}
`

export const Main = styled.div`
	position: relative;
	flex: 1 1 0;
	display: flex;

	${({ theme }) => theme.layout.desktopLayoutMediaQuery} {
		min-width: 0px;
		height: 100%;
	}

	background: ${({ theme }) => theme.colors.backgroundPrimary};
`
