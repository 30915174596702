import { createSelector } from 'reselect'

import { getApi, getIsOnHardWaitlist } from '../api'
import { getAuth } from '../auth'
import { getConfig } from '../config'

const getProps = createSelector(
	getApi,
	getAuth,
	getConfig,
	getIsOnHardWaitlist,
	(api, auth, config, isOnHardWaitlist) => ({
		config,
		auth: {
			authenticated: Boolean(auth.agentId),
			authenticating: Boolean(auth.refreshLoading),
			authenticationFailed: Boolean(auth.refreshError),
			waitlistStatusLoaded: Boolean(api.initialized),
			waitlistStatusFailed: Boolean(api.initializeError),
			isOnHardWaitlist,
		},
	})
)

export default getProps
