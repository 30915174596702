import { Cloudinary } from 'cloudinary-core'
import { Crop } from 'react-image-crop'

import { AnimatedImageModel } from '../domain/AnimatedImage'
import { Attachment } from '../network/firestore'
import { FileState } from '../slices/files'

const DEFAULT_IMAGE_TRANSFORM = 'q_auto,f_auto'

export function getCloudinaryCloudName(): string {
	return window.config?.cloudinaryCloudName ?? 'sphere-dev'
}

export function getCloudinaryUploadUrl(): string {
	return `https://api.cloudinary.com/v1_1/${getCloudinaryCloudName()}/upload`
}

export function getCloudinaryIdForGiphyId(giphyId: string): string {
	return `giphy/${giphyId}/giphy`
}

export const CL = new Cloudinary({
	cloud_name: getCloudinaryCloudName(),
	secure: true,
})

// TODO: add distinction between formats, they should be read from the server
export function getCloudinaryUploadLimit(): number {
	return window.config?.cloudinaryUploadLimit ?? 104857600
}

export function getImageURL(
	filename: string,
	rawTransformation: string | string[] = DEFAULT_IMAGE_TRANSFORM
): string {
	if (typeof rawTransformation === 'object') {
		rawTransformation = rawTransformation.join(',')
	}
	return CL.url(filename, {
		rawTransformation,
		resource_type: 'image',
	})
}

export function getAnimatedImageUrl(image: AnimatedImageModel): string {
	return CL.url(image.fileId, {
		resource_type: 'video',
	})
}

export function getAnimatedImagePreemptiveDownloadUrl(
	image: AnimatedImageModel,
	format = 'mp4'
) {
	return `${CL.url(image.fileId, {
		resource_type: 'video',
	})}.${format}`
}

export function getAttachmentUrl(attachment: Attachment): string {
	return CL.url(attachment.fileId, {
		resource_type: attachment.resourceType,
		flags: 'attachment',
	})
}

interface ImageContent {
	imageFilename?: string
	imageDimensions?: {
		width: number
		height: number
	}
}

export function getImageContentFromCloudinaryResponse(
	fileState?: FileState
): ImageContent | null {
	if (!fileState) {
		return null
	}

	const {
		public_id: publicId = false,
		format = false,
		width = false,
		height = false,
	} = fileState?.response

	if (!publicId || !format || !width || !height) {
		return null
	}

	return {
		imageFilename: `${publicId}.${format}`,
		imageDimensions: {
			width,
			height,
		},
	}
}

export function getTransformationFromCrop(crop: Crop): string {
	return `x_${crop.x},y_${crop.y},w_${crop.width},h_${crop.height},c_crop`
}
