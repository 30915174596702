import {
	ChangeEventHandler,
	FormEventHandler,
	PointerEventHandler,
	FC,
	RefObject,
} from 'react'
import { useTheme } from 'styled-components'

import CloudinaryImage from '../CloudinaryImage'
import EditProfileActions from '../EditProfileActions'
import ProgressCircle from '../ProgressCircle'
import ImageUploadAdd from '../SVGs/ImageUploadAdd'
import ImageUploadChange from '../SVGs/ImageUploadChange'

import { FileState } from '../../slices/files'
import { emojiSafeStringLength } from '../../utils/stringHelper'

import {
	ProfileCard,
	ProfilePicture,
	ProfilePictureFilter,
	ImageUploadButton,
	ImageUploadInput,
	ImageUploadingCover,
	ProfileContent,
	ProfileDescriptionInput,
	StyledTextInput,
} from './styled-components'

const USER_NAME_MAX_LENGTH = 50
const USER_DESCRIPTION_MAX_LENGTH = 180

export type Props = {
	filename?: string
	name: string
	description: string
	attendantColor?: string
	descriptionRef?: RefObject<HTMLTextAreaElement>
	onNameChange: ChangeEventHandler<HTMLInputElement>
	onDescriptionChange: ChangeEventHandler<HTMLTextAreaElement>
	imageFileState?: FileState
	submitError: string | false
	updateLoading: boolean
	onCancelClick: PointerEventHandler<HTMLButtonElement>
	onSubmit: FormEventHandler<HTMLFormElement>
	onImageUploadChange: ChangeEventHandler<HTMLInputElement>
	onImageUploadClick: PointerEventHandler<HTMLButtonElement>
}

const IMAGE_TRANSFORMS = ['w_400', 'h_300', 'f_png', 'q_auto', 'c_fill']

const Profile: FC<Props> = props => {
	const theme = useTheme()
	const nameLength = emojiSafeStringLength(props.name)
	const nameLengthError =
		nameLength > USER_NAME_MAX_LENGTH &&
		`${nameLength} / ${USER_NAME_MAX_LENGTH} characters`
	const descriptionLength = emojiSafeStringLength(props.description)
	const descriptionLengthError =
		descriptionLength > USER_DESCRIPTION_MAX_LENGTH &&
		`${descriptionLength} / ${USER_DESCRIPTION_MAX_LENGTH} characters`
	const lengthError = nameLengthError || descriptionLengthError
	return (
		<ProfileCard>
			<ProfilePicture $attendantColor={props.attendantColor}>
				{props.imageFileState?.isUploading && (
					<ImageUploadingCover>
						<ProgressCircle
							size={40}
							progress={props.imageFileState.progress}
						/>
					</ImageUploadingCover>
				)}
				{props.filename && (
					<CloudinaryImage
						filename={props.filename}
						transforms={IMAGE_TRANSFORMS}
						loading="lazy"
					/>
				)}

				<>
					<ProfilePictureFilter />
					<ImageUploadButton type="button" onClick={props.onImageUploadClick}>
						<ImageUploadInput
							type="file"
							accept="image/*"
							title="Upload a profile picture"
							onChange={props.onImageUploadChange}
						/>
						{props.filename ? (
							<ImageUploadChange
								size={100}
								color={theme.colors.iconFillLight}
							/>
						) : (
							<ImageUploadAdd size={100} color={theme.colors.iconFillLight} />
						)}
					</ImageUploadButton>
				</>
			</ProfilePicture>
			<ProfileContent>
				<form onSubmit={props.onSubmit}>
					<StyledTextInput
						type="text"
						spellCheck="false"
						value={props.name}
						onChange={props.onNameChange}
						placeholder="Add your name"
						$hasError={Boolean(nameLengthError)}
					/>
					<ProfileDescriptionInput
						ref={props.descriptionRef}
						rows={4}
						value={props.description}
						placeholder="Add your description"
						onChange={props.onDescriptionChange}
						$hasError={Boolean(descriptionLengthError)}
					/>
					<EditProfileActions
						formError={lengthError}
						submitError={props.submitError}
						loading={props.updateLoading}
						onCancelClick={props.onCancelClick}
					/>
				</form>
			</ProfileContent>
		</ProfileCard>
	)
}

export default Profile
