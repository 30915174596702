import 'isomorphic-fetch'

import { normalize, Schema } from 'normalizr'
import { Action } from 'redux'
import retry, { OperationOptions } from 'retry'

import { DEFAULT_API_VERSION } from '../constants/config'
import { ApplicationThunk, StoreState } from '../store'

const CONTENT_TYPES = {
	JSON: 'application/json; charset=utf-8',
	TEXT: 'text/plain; charset=utf-8',
	HTML: 'text/html; charset=utf-8',
}

const COMMON_FETCH_OPTIONS: RequestInit = {}
const COMMON_FETCH_HEADERS = { 'Device-Type': 'web' }
export const makeAuthorizationHeader = (accessToken: string) => ({
	Authorization: `Bearer ${accessToken}`,
})
const makeAuthorizedFetchOptions = (
	options: RequestInit,
	accessToken?: string
) => {
	if (!accessToken) {
		return options
	}
	return {
		...options,
		headers: {
			...makeAuthorizationHeader(accessToken),
			...options.headers,
		},
	}
}

type EndpointSelector = (state: StoreState) => string

interface CallOptions<RequestType, SuccessType, FailureType> {
	schema: Schema | false
	types: readonly [RequestType, SuccessType, FailureType]
	options?: RequestInit
	retryOptions?: OperationOptions
	endpoint: string | EndpointSelector
	version?: 'api' | 'v2'
}

export interface ApiSuccessAction<Type = any, Response = any>
	extends Action<Type> {
	response: Response
}

interface ErrorObject {
	message?: string
	errors?: string[]
	error?: string
}

type Error = ErrorObject | string

export const composeURL = (
	apiRoot = '',
	endpoint = '',
	version = DEFAULT_API_VERSION
) => `${apiRoot}/${version}${endpoint}`.replace(/\s/g, '')

// Fetches an API response
export function callApi(
	endpoint: string,
	options?: RequestInit,
	retryOptions?: OperationOptions
) {
	if (!endpoint || !endpoint.length) {
		throw new Error('Specify a valid endpoint URL.')
	}

	const fullOptions: RequestInit = {
		...COMMON_FETCH_OPTIONS,
		...options,
		headers: {
			...COMMON_FETCH_HEADERS,
			...options?.headers,
		},
	}

	return new Promise<Response>((resolve, reject) => {
		const operation = retry.operation({
			retries: 0, // default don't retry
			...retryOptions,
		})

		operation.attempt(() => {
			fetch(endpoint, fullOptions).then((response: Response) => {
				if (!response.ok) {
					const err = new Error('Bad response from server')
					if (operation.retry(err)) {
						return
					}
					reject(response)
					return
				}
				resolve(response)
			})
		})
	})
}

const normalizeWithSchema = (payload: any, schema: Schema | false) => {
	if (typeof schema === 'undefined') {
		throw new Error('Specify one of the exported Schemas.')
	}

	if (schema === false) {
		return payload
	}

	const dataToNormalize = payload.data || payload
	const { paging } = payload
	const parsedData = normalize(dataToNormalize, schema)
	return Object.assign({}, parsedData, { paging })
}

function parseResponse(response: Response) {
	const contentType = response.headers.get('Content-Type')

	if (!contentType) {
		return null
	}

	switch (contentType.toLowerCase()) {
		case CONTENT_TYPES.JSON:
			return response.json()
		case CONTENT_TYPES.TEXT:
		case CONTENT_TYPES.HTML:
			return response.text()
		default:
			throw new Error('Unexpected response content type.')
	}
}

function extractErrorMessage(error: Error): string {
	if (typeof error === 'string') {
		return error
	}

	if (error.message) {
		return error.message
	}

	if (error.error) {
		return error.error
	}

	if (error.errors) {
		return error.errors[0]
	}

	return 'Something bad happened'
}

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape,
// regardless of how nested it was.
// TODO options as actual fetch options
function callApiAndNormalize(
	endpoint: string,
	options: RequestInit,
	{
		schema,
		retryOptions,
	}: { schema: false | Schema<any>; retryOptions?: OperationOptions }
) {
	let responseStatus: number
	let isFailed = false

	return callApi(endpoint, options, retryOptions)
		.catch((response: Response) => {
			isFailed = true
			responseStatus = response.status
			return response
		})
		.then(parseResponse)
		.catch((error: any) => {
			if (isFailed) {
				return { message: 'Request failed' }
			}
			throw error
		})
		.then((payload: any) => {
			if (isFailed) {
				return { status: responseStatus, error: payload }
			}
			return { result: normalizeWithSchema(payload, schema) }
		})
}

export function makeApiCall<Payload, RequestType, SuccessType, FailureType>(
	callOptions: CallOptions<RequestType, SuccessType, FailureType>,
	actionProps?: {
		bypassEntityStore?: boolean
		[key: string]: string | boolean | undefined
	}
): ApplicationThunk<
	Promise<
		| { type: FailureType; status?: number }
		| { type: SuccessType; response: Payload }
	>
> {
	return (dispatch, getState) => {
		const { config, auth } = getState()
		const accessToken = auth.accessToken

		let { endpoint } = callOptions
		const { schema, types, options = {}, version, retryOptions } = callOptions

		const authorizedFetchOptions = makeAuthorizedFetchOptions(
			options,
			accessToken ?? undefined
		)

		if (typeof endpoint === 'function') {
			endpoint = endpoint(getState())
		}

		const [requestType, successType, failureType] = types

		dispatch({ type: requestType, ...actionProps })

		const url = composeURL(config.apiRoot, endpoint, version)

		return callApiAndNormalize(url, authorizedFetchOptions, {
			schema,
			retryOptions,
		}).then(({ result, error, status }) => {
			if (error) {
				return dispatch({
					type: failureType,
					status,
					error: extractErrorMessage(error),
					...actionProps,
				})
			}
			return dispatch({
				type: successType,
				response: result as Payload,
				...actionProps,
			})
		})
	}
}
