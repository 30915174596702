import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FirebaseState {
	initializing: boolean
	initialized: boolean
	appName?: string
	authenticated: boolean
	authenticating: boolean
	authenticationError?: string
	authenticatedUser?: object
	deleting: boolean
	deletingError?: string
}

const initialState: FirebaseState = {
	initializing: false,
	initialized: false,
	authenticated: false,
	authenticating: false,
	deleting: false,
}

const firebase = createSlice({
	name: 'firebase',
	initialState,
	reducers: {
		firebaseAppInitRequest(state, action: PayloadAction) {
			state.initializing = true
		},
		firebaseAppInitSuccess(state, action: PayloadAction<{ appName: string }>) {
			state.initializing = false
			state.initialized = true
			state.appName = action.payload.appName
		},
		firebaseAppInitFailure(state, action: PayloadAction) {
			state.initializing = false
		},
		firebaseAuthRequest(state, action: PayloadAction) {
			state.authenticating = true
		},
		firebaseAuthSuccess(
			state,
			action: PayloadAction<{
				user?: object
			}>
		) {
			state.authenticating = false
			state.authenticated = true
			state.authenticatedUser = action.payload.user
		},
		firebaseAuthFailure: {
			reducer(
				state,
				action: PayloadAction<unknown, string, never, string | undefined>
			) {
				state.authenticationError = action.error
				state.authenticating = false
			},
			prepare({ error }: { error?: string }) {
				return { payload: {}, error }
			},
		},
		firebaseDisconnectRequest(state, action: PayloadAction) {
			state.deleting = true
		},
		firebaseDisconnectSuccess(state, action: PayloadAction) {
			return initialState
		},
		firebaseDisconnectFailure: {
			reducer(
				state,
				action: PayloadAction<unknown, string, never, string | undefined>
			) {
				state.deletingError = action.error
				state.deleting = false
			},
			prepare({ error }: { error?: string }) {
				return { payload: {}, error }
			},
		},
	},
})

export default firebase
