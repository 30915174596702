import { Children, cloneElement, ReactElement } from 'react'
import {
	CSSObject,
	CSSKeyframes,
	SimpleInterpolation,
	keyframes,
	DefaultTheme,
} from 'styled-components'

export function stubToShorthand(stub?: CSSObject): string {
	if (!stub) {
		return ''
	}
	return Object.values(stub).join(' ')
}

export function themedKeyframes(
	strings: TemplateStringsArray | CSSKeyframes,
	...interpolations: (
		| SimpleInterpolation
		| ((props: { theme: DefaultTheme }) => SimpleInterpolation)
	)[]
) {
	return (theme: DefaultTheme) =>
		keyframes(
			strings,
			...interpolations.map(interpolation => {
				if (typeof interpolation === 'function') {
					return interpolation({ theme })
				}
				return interpolation
			})
		)
}

/** use as styled(ChildrenWithClassname) to directly apply styles to every child without introducing a wrapping dom element */
export const ChildrenWithClassname = ({
	children,
	className = '',
}: {
	children?: ReactElement
	className?: string
}) =>
	Children.map(children, child =>
		child
			? cloneElement(child, {
					className: `${child.props.className} ${className ?? ''}`.trim(),
			  })
			: null
	)

type RGBColor = [number, number, number]
export const extractRGBValuesFromHexColor = (
	hexColorString: string
): RGBColor => {
	const hexColorStringStrippedOfLeadingHashtag =
		hexColorString[0] === '#' ? hexColorString.substring(1) : hexColorString
	if (
		!(
			hexColorStringStrippedOfLeadingHashtag.length === 6 ||
			hexColorStringStrippedOfLeadingHashtag.length === 3
		)
	) {
		throw new Error('Expected a proper hex color')
	}

	const colorSubpartsLength = hexColorStringStrippedOfLeadingHashtag.length / 3
	const redHexString = hexColorStringStrippedOfLeadingHashtag
		.substring(0, colorSubpartsLength)
		.repeat(2)
		.substring(0, 2)
	const greenHexString = hexColorStringStrippedOfLeadingHashtag
		.substring(colorSubpartsLength, 2 * colorSubpartsLength)
		.repeat(2)
		.substring(0, 2)
	const blueHexString = hexColorStringStrippedOfLeadingHashtag
		.substring(2 * colorSubpartsLength)
		.repeat(2)
		.substring(0, 2)

	return [
		parseInt(redHexString, 16),
		parseInt(greenHexString, 16),
		parseInt(blueHexString, 16),
	]
}

export function convertHexColorToRGBA(
	hexColor: string,
	alphaValue: number = 1
): string {
	const [r, g, b] = extractRGBValuesFromHexColor(hexColor)

	return `rgba(${r}, ${g}, ${b}, ${alphaValue})`
}

const exports = {
	stubToShorthand,
	convertHexColorToRGBA,
}

export default exports
