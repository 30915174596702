import files from '../slices/files'
import { makeApiCall } from '../utils/apiHelper'

export function getFileSignature(options?: any) {
	return makeApiCall({
		types: [
			files.actions.fileSignatureRequest.type,
			files.actions.fileSignatureSuccess.type,
			files.actions.fileSignatureFailure.type,
		],
		endpoint: '/imageSignatures',
		options: {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(options),
		},
		schema: false,
	})
}
