import { FC, PointerEventHandler } from 'react'

import { ButtonVariant } from '../AKButton'

import { Row, ErrorMessage, StyledButton } from './styled-components'

export interface Props {
	formError?: string | false
	submitError?: string | false
	loading: boolean
	onCancelClick: PointerEventHandler<HTMLButtonElement>
}

const ProfileActions: FC<Props> = ({
	formError,
	submitError,
	loading,
	onCancelClick,
}) => (
	<Row>
		<StyledButton
			onClick={onCancelClick}
			variant={ButtonVariant.Secondary}
			disabled={loading}
		>
			Cancel
		</StyledButton>
		<StyledButton
			type="submit"
			isLoading={loading}
			disabled={Boolean(loading || formError)}
		>
			Save
		</StyledButton>
		<ErrorMessage>{formError || submitError}</ErrorMessage>
	</Row>
)

export default ProfileActions
