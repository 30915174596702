import { memo, FC } from 'react'

import CloudinaryImage from '../CloudinaryImage'
import RoundIcon from '../RoundIcon'

import { ColoredBackground } from './styled-components'

export interface Props {
	imageFilename?: string | null
	color?: string | null
	name?: string
	size?: number
	lazyLoad?: boolean
	className?: string
}

const UserIcon: FC<Props> = memo(
	({ name, imageFilename, color, size = 32, lazyLoad, className }) => (
		<RoundIcon size={size} className={className}>
			<ColoredBackground $colorValue={color ?? undefined}>
				{imageFilename && (
					<CloudinaryImage
						filename={imageFilename}
						transforms={[
							`h_${2 * size}`,
							`w_${2 * size}`,
							'f_auto',
							'q_auto:good',
							'c_fill',
						]}
						width={size}
						height={size}
						alt={name}
						title={name}
						loading={lazyLoad ? 'lazy' : undefined}
					/>
				)}
			</ColoredBackground>
		</RoundIcon>
	)
)

export default UserIcon
