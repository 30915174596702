import {
	createEntityAdapter,
	createSlice,
	EntityState,
	isAnyOf,
} from '@reduxjs/toolkit'

import messageReactions from './messageReactions'

import { Message as FSMessage, MessageReaction } from '../network/firestore'

import { LegacyEmojiReactionTable } from './config'
import conversationMessagesPagination from './conversationMessagesPagination'
import message from './message'
import messagesByConversation from './messagesByConversation'

import { StoreState } from '.'

export type FirestoreMessagesState = EntityState<FSMessage>

const firestoreMessagesAdapter = createEntityAdapter<FSMessage>({
	selectId(entry) {
		return entry.id
	},
})

export const firestoreMessages = createSlice({
	name: 'firestoreMessages',
	initialState: firestoreMessagesAdapter.getInitialState(),
	reducers: {},
	// Arguably some of these cases could be defined here, but that would expose to the calling site where the message resides
	// Having the storage indepent message slices facades that and exposes on api
	extraReducers: builder =>
		builder
			.addCase(message.actions.optimisticUIRemoveMessage, (state, action) => {
				firestoreMessagesAdapter.updateOne(state, {
					id: action.payload.messageId,
					changes: {
						removed: true,
					},
				})
			})
			.addCase(
				message.actions.optimisticUIUndoRemoveMessage,
				(state, action) => {
					firestoreMessagesAdapter.updateOne(state, {
						id: action.payload.messageId,
						changes: {
							removed: false,
						},
					})
				}
			)
			.addCase(message.actions.conversationMessageSuccess, (state, action) => {
				firestoreMessagesAdapter.upsertMany(state, action.payload.messages)
			})
			.addMatcher(
				isAnyOf(
					messagesByConversation.actions.conversationMessagesUpdate.match,
					conversationMessagesPagination.actions.conversationMessagesSuccess
						.match,
					conversationMessagesPagination.actions.paginationSuccess.match
				),
				(state, action) => {
					firestoreMessagesAdapter.upsertMany(state, action.payload.messages)
				}
			)
			.addMatcher(
				isAnyOf(
					messageReactions.actions.createRequest.match,
					messageReactions.actions.deleteFailure.match
				),
				(state, action) => {
					const oldReactions =
						firestoreMessagesAdapter
							.getSelectors()
							.selectById(state, action.payload.messageId)?.reactions ?? []

					const { reaction: newReaction, legacyEmojiReactionTable } =
						action.payload

					const didNewReactionAlreadyExist = !!oldReactions.find(
						reaction =>
							reaction.attendantId === newReaction.attendantId &&
							selectUniqueFSMessageReactionType(
								reaction,
								legacyEmojiReactionTable
							) ===
								selectUniqueFSMessageReactionType(
									newReaction,
									legacyEmojiReactionTable
								)
					)

					if (didNewReactionAlreadyExist) {
						return state
					}

					firestoreMessagesAdapter.updateOne(state, {
						id: action.payload.messageId,
						changes: {
							reactions: [...oldReactions, newReaction],
						},
					})
				}
			)
			.addMatcher(
				isAnyOf(
					messageReactions.actions.createFailure.match,
					messageReactions.actions.deleteRequest.match
				),
				(state, action) => {
					const oldReactions =
						firestoreMessagesAdapter
							.getSelectors()
							.selectById(state, action.payload.messageId)?.reactions ?? []

					const { reaction: payloadReaction, legacyEmojiReactionTable } =
						action.payload

					firestoreMessagesAdapter.updateOne(state, {
						id: action.payload.messageId,
						changes: {
							reactions: oldReactions.filter(
								reaction =>
									!(
										reaction.attendantId === payloadReaction.attendantId &&
										selectUniqueFSMessageReactionType(
											reaction,
											legacyEmojiReactionTable
										) ===
											selectUniqueFSMessageReactionType(
												payloadReaction,
												legacyEmojiReactionTable
											)
									)
							),
						},
					})
				}
			),
})

export default firestoreMessages

export const firestoreMessagesSelectors =
	firestoreMessagesAdapter.getSelectors<StoreState>(
		state => state.firestoreMessages
	)

function selectUniqueFSMessageReactionType(
	reaction: MessageReaction,
	legacyEmojiReactionTable: LegacyEmojiReactionTable
): string {
	if (reaction.type === 'EMOJI') {
		return reaction.emojiId
	}
	if (reaction.type === 'APPRECIATION') {
		return reaction.appreciationId
	}

	return legacyEmojiReactionTable[reaction.type].emojiId
}
