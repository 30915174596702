import { AccessTokenRefreshManager } from '../auth/accessTokenRefreshManager'

export type PubsubChannelTokenFetcher = () => Promise<string>

export class PubsubChannelMaintainer {
	protected accessTokenPromise?: Promise<string>
	protected resolveAccessTokenPromise?: (
		value: string | PromiseLike<string>
	) => void
	protected rejectAccessTokenPromise?: (reason?: any) => void
	protected accessTokenRefreshManager: AccessTokenRefreshManager

	constructor(channelTokenFetcher: PubsubChannelTokenFetcher) {
		this.accessTokenRefreshManager = new AccessTokenRefreshManager(
			channelTokenFetcher
		)
		this.accessTokenRefreshManager.maintainFreshAccessToken({
			onRefreshSuccess: (accessToken: string) =>
				this.resolveAccessTokenPromise?.(accessToken),
			onRefreshFailure: (errorMessage?: string) =>
				this.rejectAccessTokenPromise?.(errorMessage),
			onError: (errorMessage?: string) =>
				this.rejectAccessTokenPromise?.(errorMessage),
			onRefreshRequest: () => {
				this.createNewPromise()
			},
		})
	}

	protected createNewPromise() {
		const oldResolve = this.resolveAccessTokenPromise
		const oldReject = this.rejectAccessTokenPromise

		this.accessTokenPromise = new Promise<string>((resolve, reject) => {
			this.resolveAccessTokenPromise = resolve
			this.rejectAccessTokenPromise = reject
		})

		// pass the new access token on in case that there are old listeners
		// this is branched off and won't influence other places 'then'ing this promise
		this.accessTokenPromise.then(oldResolve).catch(oldReject)

		return this.accessTokenPromise
	}

	getAccessToken(): Promise<string> {
		return this.accessTokenPromise || this.createNewPromise()
	}

	stop() {
		this.accessTokenRefreshManager.stopRefreshingAccessToken()
		this.rejectAccessTokenPromise?.('refreshing stopped')
	}
}

export default PubsubChannelMaintainer
