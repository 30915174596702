import { Entity } from '../shared/types'

export enum SphereEventInviteeRsvpState {
	Pending = 'pending',
	Going = 'going',
	Thinking = 'thinking',
	NotGoing = 'notGoing',
}

export enum SphereEventRsvp {
	Going = 'Going',
	Thinking = 'Thinking',
	NotGoing = 'NotGoing',
}

export interface SphereEventInviteeModel extends Entity {
	rsvp?: SphereEventRsvp | null
	displayName?: string
	displayColor?: string
	displayImageFilename?: string | null
	agentId?: string
}
