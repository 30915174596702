import { Middleware } from 'redux'

import * as events from '../constants/EventNames'
import browser from '../slices/browser'
import { StoreState } from '../store'
import { SegmentClient } from '../utils/segmentProxy'

const analyticsMiddleware: Middleware<{}, StoreState> =
	() => next => action => {
		if (browser.actions.notificationPermissionChanged.match(action)) {
			SegmentClient.track(
				action.payload.permission === 'granted'
					? events.WEB_NOTIFICATIONS_GRANTED
					: events.WEB_NOTIFICATIONS_REJECTED
			)
		}
		return next(action)
	}

export default analyticsMiddleware
