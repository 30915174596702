import { AnyAction } from 'redux'
import { put, takeLatest, take, call } from 'typed-redux-saga/macro'

import files from '../slices/files'
import profile from '../slices/profile'

function* uploadProfileImageSaga(
	uploadProfileInitiateAction: ReturnType<
		typeof profile.actions.profileImageUploadInitiate
	>
) {
	const {
		objectURL: newProfileImageObjectURL,
		file,
		updateProfileImage,
	} = uploadProfileInitiateAction.payload
	// Start upload to cloudinary
	yield* put(
		files.actions.uploadRequest({
			objectURL: newProfileImageObjectURL,
			resource: file,
		})
	)

	// wait for the resolution of this specific image upload
	const fullfilledAction = yield* take(
		(action: AnyAction) =>
			(files.actions.uploadSuccess.match(action) ||
				files.actions.uploadFailure.match(action)) &&
			action.payload.objectURL === newProfileImageObjectURL
	)

	if (files.actions.uploadFailure.match(fullfilledAction)) {
		// clear the redux profile image upload state
		yield* put(
			profile.actions.profileImageUploadClear({
				objectURL: newProfileImageObjectURL,
			})
		)
	}

	if (
		files.actions.uploadSuccess.match(fullfilledAction) &&
		updateProfileImage
	) {
		// update the agent with the new cloudinary image
		const { public_id: publicId, format } =
			fullfilledAction.payload.response ?? {}
		yield* call(updateProfileImage, `${publicId}.${format}`)
		// clear the redux profile image upload state
		yield* put(
			profile.actions.profileImageUploadClear({
				objectURL: newProfileImageObjectURL,
			})
		)
	}
}

export default function* uploadProfileImageUploadWatcherSaga() {
	yield* takeLatest(
		profile.actions.profileImageUploadInitiate.type,
		uploadProfileImageSaga
	)
}
