import { connectRouter } from 'connected-react-router/immutable'
import type { History } from 'history'
import { combineReducers, Reducer, ReducersMapObject } from 'redux'

import type { WebCore } from '~WebCore'

import messageReactions from './messageReactions'

import { getConversationMessagesPaginationKey } from '../utils/entitiesHelper'

import announcementPersonalResponsesCache from './announcementPersonalResponsesCache'
import api from './api'
import { auth } from './auth'
import browser from './browser'
import bundle from './bundle'
import config from './config'
import contentFeed from './contentFeed'
import conversationMessagesPagination from './conversationMessagesPagination'
import conversations from './conversations'
import conversationsInput from './conversationsInput'
import conversationsUI from './conversationsUI'
import debug from './debug'
import entities from './entities'
import environment from './environment'
import favicon from './favicon'
import fileDimensions from './fileDimensions'
import files from './files'
import firebase from './firebase'
import firestoreAttendantsSlice from './firestoreAttendants'
import firestoreMessages from './firestoreMessages'
import importantMessages from './importantMessages'
import intercom from './intercom'
import launchDarkly from './launchDarkly'
import loadingState from './loadingState'
import localMessages from './localMessages'
import message from './message'
import messagesByConversation from './messagesByConversation'
import paginate from './paginate'
import pinnedMessages from './pinnedMessages'
import pollPersonalResponsesCache from './pollPersonalResponsesCache'
import profile from './profile'
import pubsub from './pubsub'
import serviceWorker from './serviceWorker'
import sphereEventForm from './sphereEventForm'
import sphereSidebar from './sphereSidebar'
import theme from './theme'
import typingIndicators from './typingIndicators'
import WebCoreKickstart from './WebCoreKickstart'
import webPushSubscription from './webPushSubscription'

const pagination = combineReducers({
	conversationMessages: paginate({
		name: 'conversationMessages',
		mapActionToKey(action) {
			return getConversationMessagesPaginationKey({
				conversationId: action.payload.conversationId,
				context: action.payload.context,
			})
		},
		types: [
			conversationMessagesPagination.actions.conversationMessagesRequest,
			conversationMessagesPagination.actions.conversationMessagesSuccess,
			conversationMessagesPagination.actions.conversationMessagesFailure,
			conversationMessagesPagination.actions.conversationMessagesReset,
		],
	}).reducer,
})

const loaders = combineReducers({
	createLoginPasswordRetrieveToken: loadingState({
		name: 'createLoginPasswordRetrieveToken',
		mapActionToKey() {
			return 'index'
		},
		types: [
			auth.actions.loginPasswordRetrieveTokenCreateRequest,
			auth.actions.loginPasswordRetrieveTokenCreateSuccess,
			auth.actions.loginPasswordRetrieveTokenCreateFailure,
		],
	}).reducer,
})

function getStaticReducers(history: History) {
	return {
		announcementPersonalResponsesCache:
			announcementPersonalResponsesCache.reducer,
		api: api.reducer,
		auth: auth.reducer,
		browser: browser.reducer,
		bundle: bundle.reducer,
		config: config.reducer,
		contentFeed: contentFeed.reducer,
		conversationMessagesPagination: conversationMessagesPagination.reducer,
		conversations: conversations.reducer,
		conversationsInput: conversationsInput.reducer,
		conversationsUI: conversationsUI.reducer,
		debug: debug.reducer,
		entities: entities.reducer,
		environment: environment.reducer,
		favicon: favicon.reducer,
		files: files.reducer,
		fileDimensions: fileDimensions.reducer,
		firebase: firebase.reducer,
		firestoreAttendants: firestoreAttendantsSlice.reducer,
		firestoreMessages: firestoreMessages.reducer,
		importantMessages: importantMessages.reducer,
		intercom: intercom.reducer,
		launchDarkly: launchDarkly.reducer,
		loaders,
		localMessages: localMessages.reducer,
		message: message.reducer,
		messagesByConversation: messagesByConversation.reducer,
		messageReactions: messageReactions.reducer,
		pagination,
		pinnedMessages: pinnedMessages.reducer,
		pollPersonalResponsesCache: pollPersonalResponsesCache.reducer,
		pubsub: pubsub.reducer,
		profile: profile.reducer,
		// https://github.com/supasate/connected-react-router/issues/416
		router: connectRouter(history) as Reducer<unknown>,
		serviceWorker: serviceWorker.reducer,
		sphereEventForm: sphereEventForm.reducer,
		sphereSidebar: sphereSidebar.reducer,
		theme: theme.reducer,
		typingIndicators: typingIndicators.reducer,
		WebCoreKickstart: WebCoreKickstart.reducer,
		webPushSubscription: webPushSubscription.reducer,
	} as const
}

// these async reducers are included by type only so they don't get eagerly imported at runtime
// instead they will be injected when needed
// but to be able to be accessed on store they need to be declared here
// caveat: they could be accessed while not having being loaded, unsure about a good solution there
//
// if you add a reducer here and want it included with saga tests, also add to src/sagas/tests/utils.ts
type AsyncReducersObject = {
	WebCore: typeof WebCore.reducer
}

export function createRootReducer(
	history: History,
	asyncReducers: ReducersMapObject<any, any> = {}
) {
	return combineReducers({
		...getStaticReducers(history),
		...(asyncReducers as AsyncReducersObject),
	})
}
export default createRootReducer

type StaticReducersState = ReturnType<
	typeof getStaticReducers
> extends ReducersMapObject<infer S>
	? S
	: never

type AsyncsReducersState = Partial<
	AsyncReducersObject extends ReducersMapObject<infer S> ? S : never
>

export type StoreState = StaticReducersState & AsyncsReducersState
