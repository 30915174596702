import { ViewerAttendant, ViewerAttendantModel } from '../Attendant'
import Conversation, { ConversationModel } from '../Conversation'

import { ChatCardModel, ChatCardState, ConversationType } from './types'

export class ChatCard {
	static isEnded(card: Pick<ChatCardModel, 'state'>): boolean | undefined {
		return card.state !== undefined
			? card.state === ChatCardState.Ended
			: undefined
	}

	static isLive(card: Pick<ChatCardModel, 'state'>): boolean | undefined {
		return card.state !== undefined
			? card.state === ChatCardState.Live
			: undefined
	}

	static isDm(
		card: Pick<ChatCardModel, 'conversationType'>
	): boolean | undefined {
		return card.conversationType === ConversationType.Dm
	}

	static isEventChat(card: Pick<ChatCardModel, 'conversationType'>) {
		return card.conversationType === ConversationType.Event
	}

	static isPrivate(
		card: Pick<ChatCardModel, 'isPrivate'>
	): boolean | undefined {
		return card.isPrivate
	}

	static isOneOnOneDm(
		card: Pick<ChatCardModel, 'conversationType' | 'conversation'>
	): boolean | undefined {
		return (
			ChatCard.isDm(card) &&
			Conversation.getNumberOfAttendants(card.conversation ?? {}) === 2
		)
	}

	static getChatRouteDocumentTitle(card: Pick<ChatCardModel, 'title'>): string {
		return card.title ? `${card.title} - Sphere` : 'Sphere'
	}

	static getConversation(
		chatCard: Pick<ChatCardModel, 'conversation'>
	): ConversationModel | null | undefined {
		return chatCard.conversation
	}

	static getConversationId(
		chatCard: Pick<ChatCardModel, 'conversation'>
	): string | null | undefined {
		const conversation = ChatCard.getConversation(chatCard)
		if (conversation) {
			return Conversation.getId(conversation)
		}

		return
	}

	static getCreatorAgentId(
		chatCard: Pick<ChatCardModel, 'creatorAgentId'>
	): string | null | undefined {
		return chatCard.creatorAgentId
	}

	static getViewerAttendant(
		chatCard: Pick<ChatCardModel, 'viewerAttendant'>
	): ViewerAttendantModel | null | undefined {
		return chatCard.viewerAttendant
	}

	static getViewerAttendantId(
		chatCard: Pick<ChatCardModel, 'viewerAttendant'>
	): string | null | undefined {
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		return viewerAttendant && viewerAttendant.id
	}

	static hasViewerJoined(
		chatCard: Pick<ChatCardModel, 'viewerAttendant'>
	): boolean | undefined {
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		if (viewerAttendant !== undefined) {
			return viewerAttendant !== null
		}
		return
	}

	static hasViewerFollowed(
		chatCard: Pick<ChatCardModel, 'viewerAttendant'>
	): boolean | undefined {
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		if (viewerAttendant) {
			return ViewerAttendant.isFollowing(viewerAttendant)
		} else if (viewerAttendant === null) {
			return false
		}
		return
	}

	static canViewerJoin(
		chatCard: Pick<ChatCardModel, 'conversationType' | 'viewerAttendant'>
	): boolean | undefined {
		const isDm = ChatCard.isDm(chatCard)
		if (isDm) {
			return false
		} else if (isDm === undefined) {
			return
		}

		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		if (viewerAttendant !== undefined) {
			return viewerAttendant === null
		}

		return
	}

	static canDmChatCardBeFollowed(): false {
		return false
	}

	static canDmChatCardBeUnfollowed(): false {
		return false
	}

	static canDmChatCardBeDeleted(): false {
		return false
	}

	static canViewerFollow(
		chatCard: Pick<ChatCardModel, 'conversationType' | 'viewerAttendant'>
	): boolean | undefined {
		// Handle DMs
		const isDm = ChatCard.isDm(chatCard)
		if (isDm === true) {
			return ChatCard.canDmChatCardBeFollowed()
		} else if (isDm === undefined) {
			return
		}

		// Handle non-DMs
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		// The viewer must have an attendant in order to follow a conversation
		if (viewerAttendant) {
			if (ViewerAttendant.isFollowing(viewerAttendant) === false) {
				return true
			} else if (ViewerAttendant.isFollowing(viewerAttendant) === true) {
				return false
			}
		}
		// Otherwise they need to join the conversation first
		else if (viewerAttendant === null) {
			return false
		}
		return
	}

	static canViewerUnfollow(
		chatCard: Pick<ChatCardModel, 'viewerAttendant' | 'conversationType'>
	): boolean | undefined {
		// Handle DMs
		const isDm = ChatCard.isDm(chatCard)
		if (isDm) {
			return ChatCard.canDmChatCardBeUnfollowed()
		} else if (isDm === undefined) {
			return
		}

		// Handle non-DMs
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		// The viewer can only unfollow if they have an attendant...
		if (viewerAttendant) {
			// .. and they currently follow the conversation
			if (ViewerAttendant.isFollowing(viewerAttendant) === true) {
				return true
			} else if (ViewerAttendant.isFollowing(viewerAttendant) === false) {
				return false
			}
		}
		// The viewer cannot unfollow if they haven't joined first
		else if (viewerAttendant === null) {
			return false
		}
		return
	}

	static canViewerDelete(
		chatCard: Pick<
			ChatCardModel,
			'viewerAttendant' | 'conversationType' | 'creatorAgentId'
		>
	): boolean | undefined {
		// Handle DMs
		const isDm = ChatCard.isDm(chatCard)
		if (isDm) {
			return ChatCard.canDmChatCardBeDeleted()
		} else if (isDm === undefined) {
			return
		}

		// Handle non-DMs
		// TODO: change to capability
		return ChatCard.isViewerTheCreator(chatCard)
	}

	static hasViewerMuted(
		chatCard: Pick<ChatCardModel, 'viewerAttendant'>
	): boolean | undefined {
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		if (viewerAttendant) {
			return ViewerAttendant.hasMuted(viewerAttendant)
		} else if (viewerAttendant === null) {
			return false
		}
		return
	}

	static canViewerMute(
		chatCard: Pick<ChatCardModel, 'viewerAttendant'>
	): boolean | undefined {
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		if (viewerAttendant) {
			return ViewerAttendant.hasMuted(viewerAttendant) === false
		} else if (viewerAttendant === null) {
			return false
		}
		return
	}

	static canViewerUnmute(
		chatCard: Pick<ChatCardModel, 'viewerAttendant'>
	): boolean | undefined {
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)

		if (viewerAttendant) {
			return ViewerAttendant.hasMuted(viewerAttendant) === true
		} else if (viewerAttendant === null) {
			return false
		}
		return
	}

	static isViewerTheCreator(
		chatCard: Pick<ChatCardModel, 'creatorAgentId' | 'viewerAttendant'>
	): boolean | undefined {
		const viewerAttendant = ChatCard.getViewerAttendant(chatCard)
		const creatorAgentId = ChatCard.getCreatorAgentId(chatCard)

		if (creatorAgentId === null) {
			return false
		} else if (creatorAgentId === undefined) {
			return
		}

		if (viewerAttendant) {
			const viewerAgentId = ViewerAttendant.getAgentId(viewerAttendant)
			return viewerAgentId === undefined
				? undefined
				: viewerAgentId === creatorAgentId
		} else if (viewerAttendant === null) {
			return false
		}
		return
	}
}

export default ChatCard
