import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
	Selector,
} from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'

import auth from './auth'

import { StoreState } from '.'

export type BaseConversationsUIAction<A = unknown> = PayloadAction<
	A & { conversationId: string }
>

interface ConversationUIState {
	conversationId: string
	active: boolean
	highlightMessageId: string | null
	messageIdToRemove: string | null
	typingIndicatorsForAttendantIds: {
		[key: string]: boolean
	}
	latestScrollToBottomRequestTimestamp?: number
	openReactionsTrayMessageId: string | null
	actionsOverflowMenuOpenForMessageId: string | null
	appreciationsSelectorOpenedForMessageId: string | null
}

const conversationUIAdapter = createEntityAdapter<ConversationUIState>({
	selectId(item) {
		return item.conversationId
	},
})

export type ConversationsUIState = EntityState<ConversationUIState>

export function getInitialConversationUIState(
	conversationId: string
): ConversationUIState {
	return {
		conversationId,
		active: false,
		highlightMessageId: null,
		messageIdToRemove: null,
		typingIndicatorsForAttendantIds: {},
		openReactionsTrayMessageId: null,
		actionsOverflowMenuOpenForMessageId: null,
		appreciationsSelectorOpenedForMessageId: null,
	}
}

function ensureInitialState(
	state: WritableDraft<EntityState<ConversationUIState>>,
	action: BaseConversationsUIAction
) {
	if (
		!conversationUIAdapter
			.getSelectors()
			.selectById(state, action.payload.conversationId)
	) {
		conversationUIAdapter.addOne(
			state,
			getInitialConversationUIState(action.payload.conversationId)
		)
	}
}

export const conversationsUI = createSlice({
	name: 'conversationsUI',
	initialState: conversationUIAdapter.getInitialState(),
	reducers: {
		conversationUIEnter(
			state,
			action: BaseConversationsUIAction<{ chatCardId: string }>
		) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					active: true,
				},
			})
		},
		conversationUIExit(state, action: BaseConversationsUIAction) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					active: false,
				},
			})
		},
		scrollToMessage(
			state,
			action: BaseConversationsUIAction<{
				messageId: string
			}>
		) {},
		setHighlightMessage(
			state,
			action: BaseConversationsUIAction<{ messageId: string }>
		) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					highlightMessageId: action.payload.messageId,
				},
			})
		},
		clearHighlightMessage(
			state,
			action: BaseConversationsUIAction<{ messageId: string }>
		) {
			ensureInitialState(state, action)
			const current = conversationUIAdapter
				.getSelectors()
				.selectById(state, action.payload.conversationId)
			if (current?.highlightMessageId === action.payload.messageId) {
				conversationUIAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: {
						highlightMessageId: null,
					},
				})
			}
		},
		scrollToLatestMessage(state, action: BaseConversationsUIAction) {},
		scrollToBottomRequest: {
			reducer(state, action: BaseConversationsUIAction<{ timestamp: number }>) {
				ensureInitialState(state, action)
				conversationUIAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: {
						latestScrollToBottomRequestTimestamp: action.payload.timestamp,
					},
				})
			},
			prepare({ conversationId }: { conversationId: string }) {
				return { payload: { conversationId, timestamp: new Date().getTime() } }
			},
		},
		conversationUIReactionTrayOpen(
			state,
			action: BaseConversationsUIAction<{ messageId: string }>
		) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					openReactionsTrayMessageId: action.payload.messageId,
				},
			})
		},
		conversationUIReactionTrayClose(
			state,
			action: BaseConversationsUIAction<{ messageId: string }>
		) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					openReactionsTrayMessageId: null,
				},
			})
		},
		appreciationsSelectorOpened(
			state,
			action: BaseConversationsUIAction<{ messageId: string }>
		) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					appreciationsSelectorOpenedForMessageId: action.payload.messageId,
				},
			})
		},
		appreciationsSelectorClosed(state, action: BaseConversationsUIAction) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					appreciationsSelectorOpenedForMessageId: null,
				},
			})
		},
		messageActionsOverflowMenuOpened(
			state,
			action: BaseConversationsUIAction<{ messageId: string }>
		) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					actionsOverflowMenuOpenForMessageId: action.payload.messageId,
				},
			})
		},
		messageActionsOverflowMenuClosed(state, action: BaseConversationsUIAction) {
			ensureInitialState(state, action)
			conversationUIAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					actionsOverflowMenuOpenForMessageId: null,
				},
			})
		},
	},
	extraReducers: builder =>
		builder.addCase(auth.actions.signOutSuccess, () =>
			conversationUIAdapter.getInitialState()
		),
})

export default conversationsUI

export const conversationsUISelectors =
	conversationUIAdapter.getSelectors<StoreState>(state => state.conversationsUI)

interface MessageBasedSelectorInput {
	messageId?: string
	conversationId?: string
}

export function getIsMessageReactionsTrayOpenForMessage({
	messageId,
	conversationId,
}: MessageBasedSelectorInput): Selector<StoreState, boolean> {
	return state => {
		if (!conversationId || !messageId) {
			return false
		}
		return Boolean(
			conversationsUISelectors.selectById(state, conversationId)
				?.openReactionsTrayMessageId === messageId
		)
	}
}

export function getIsMessageActionsOverflowMenuOpen({
	messageId,
	conversationId,
}: MessageBasedSelectorInput): Selector<StoreState, boolean> {
	return state => {
		if (!conversationId || !messageId) {
			return false
		}
		return Boolean(
			conversationsUISelectors.selectById(state, conversationId)
				?.actionsOverflowMenuOpenForMessageId === messageId
		)
	}
}
