export const borders = {
	border100: {
		borderColor: 'hsla(0, 0%, 100%, 0.04)',
		borderStyle: 'solid',
		borderWidth: '1px',
	},
	border200: {
		borderColor: 'hsla(0, 0%, 100%, 0.1)',
		borderStyle: 'solid',
		borderWidth: '1px',
	},
	border300: {
		borderColor: 'hsla(0, 0%, 100%, 0.1)',
		borderStyle: 'solid',
		borderWidth: '2px',
	},
	border400: {
		borderColor: 'hsla(0, 0%, 50%, 0.5)',
		borderStyle: 'solid',
		borderWidth: '2px',
	},
}
