import { call, put, getContext } from 'typed-redux-saga/macro'

import {
	SendMessageMutation,
	SendMessageMutationVariables,
} from '../network/graphql'
import { GQLClient } from '../network/graphql/configureClient'
import { SEND_MESSAGE } from '../network/graphql/mutations'

import { GQL_CLIENT_SAGA_CONTEXT_KEY } from '../constants/config'
import localMessages from '../slices/localMessages'
import { toSafeError } from '../utils/error'

import { ComposedMessageState } from './sendMessage'

async function persistMessage(
	gqlClient: GQLClient,
	uniqueId: string,
	payload: ComposedMessageState
) {
	const result = await gqlClient.mutate<
		SendMessageMutation,
		SendMessageMutationVariables
	>({
		mutation: SEND_MESSAGE,
		variables: {
			conversation_id: payload.conversationId,
			input: {
				unique_id: uniqueId,
				content: {
					image_filename: payload.imageContent?.imageFilename,
					text: payload.text,
					poll_options:
						payload.pollOptions?.map(option => option.optionTitle) ?? undefined,
					attachments: payload.attachments,
				},
				reply_to_message_id: payload.replyToMessageInfo?.messageId,
				mentions: payload.mentions?.map(mention => ({
					mentionable_id: mention.mentionableId,
					offset: mention.offset,
					length: mention.length,
				})),
				important: payload.isImportant,
				permits_multiple_poll_votes: payload.permitsMultiplePollVotes,
			},
		},
	})

	return { id: result?.data?.sendMessage?.id }
}

function* tryCreatePersistedMessage(
	messageData: ComposedMessageState,
	uniqueId: string
) {
	yield* put(
		localMessages.actions.messageCreateRequest({
			uniqueId,
			conversationId: messageData.conversationId,
		})
	)

	try {
		const gqlClient = yield* getContext(GQL_CLIENT_SAGA_CONTEXT_KEY)
		const { id } = yield* call(persistMessage, gqlClient, uniqueId, messageData)
		if (!id) {
			throw new Error('message payload expected')
		}
		yield* put(
			localMessages.actions.messageCreateSuccess({
				id,
				uniqueId,
				conversationId: messageData.conversationId,
			})
		)
	} catch (unknownError) {
		const error = toSafeError(unknownError)
		yield* put(
			localMessages.actions.messageCreateFailure({
				uniqueId,
				conversationId: messageData.conversationId,
				error,
			})
		)
	}
}

export default tryCreatePersistedMessage
