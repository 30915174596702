import { BaseProvider } from 'baseui'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { Provider as StyletronProvider, StandardEngine } from 'styletron-react'

import useFeatureFlags from '../hooks/useFeatureFlags'

import { BASEWEB_PROVIDER_Z } from '../constants/config'

import GlobalStyles from './GlobalStyles'
import SphereDarkTheme, { darkSphereTheme } from './sphereDarkTheme'
import SphereLightTheme, { lightSphereTheme } from './sphereLightTheme'

const StyledApp: FC<{ styletronEngine: StandardEngine }> = ({
	children,
	styletronEngine,
}) => {
	const darkModeFeatureEnabled = useFeatureFlags('dark-mode')
	const darkThemeEnabled = useSelector(
		state =>
			// To avoid flash of brightness before the flag is resolved,
			// bypass the flag until it is resolved.
			darkModeFeatureEnabled !== false &&
			(state.theme === 'dark' ||
				(state.theme === 'auto' && state.environment.prefersDarkColorScheme))
	)

	return (
		<ThemeProvider
			theme={darkThemeEnabled ? darkSphereTheme : lightSphereTheme}
		>
			<StyletronProvider value={styletronEngine}>
				<BaseProvider
					theme={darkThemeEnabled ? SphereDarkTheme : SphereLightTheme}
					overrides={{ AppContainer: { style: { height: '100%' } } }}
					zIndex={BASEWEB_PROVIDER_Z}
				>
					<GlobalStyles />
					{children}
				</BaseProvider>
			</StyletronProvider>
		</ThemeProvider>
	)
}

export default StyledApp
