import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ThemeState = 'light' | 'dark' | 'auto'

const theme = createSlice({
	name: 'theme',
	initialState: 'auto' as ThemeState,
	reducers: {
		setTheme(_, action: PayloadAction<ThemeState>) {
			return action.payload
		},
	},
})

export const actions = theme.actions

export default theme
