import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'

import { FeedItemFragment } from '../network/graphql'

import { ChatCardState } from '../domain/ChatCard'
import {
	MessageInfo as FSMessageInfo,
	Mentionables as FSMentionables,
} from '../network/firestore'

import auth from './auth'
import messagesByConversation from './messagesByConversation'

import { StoreState } from '.'

export interface ConversationState {
	conversationId: string
	chatState?: ChatCardState
	subscribedToUpdates: boolean
	subscribedToUpdatesError?: string
	updatesListenerOffline: boolean
	lastUpdatedMessageInfo?: FSMessageInfo
	mentionables: FSMentionables
	latestMessageId?: string
	lastSeenMessageId?: string | null
	unreadCount: number
	ownAttendantId?: string
}

export const conversationsAdapter = createEntityAdapter<ConversationState>({
	selectId(entry) {
		return entry.conversationId
	},
})

export type ConversationsState = EntityState<ConversationState>

function getInitialConversationMessagesState(
	conversationId: string
): ConversationState {
	return {
		conversationId,
		subscribedToUpdates: false,
		updatesListenerOffline: false,
		mentionables: {},
		unreadCount: 0,
		latestMessageId: undefined,
	}
}

export type ConversationsAction<P = unknown, E = never> = PayloadAction<
	P & {
		conversationId: string
	},
	string,
	never,
	E
>

function ensureInitialState(
	state: WritableDraft<ConversationsState>,
	action: { payload: { conversationId: string } }
): string {
	const conversationId = action.payload.conversationId
	if (!conversationsAdapter.getSelectors().selectById(state, conversationId)) {
		conversationsAdapter.addOne(
			state,
			getInitialConversationMessagesState(conversationId)
		)
	}
	return conversationId
}

const conversations = createSlice({
	name: 'conversations',
	initialState: conversationsAdapter.getInitialState(),
	reducers: {
		conversationRequest(state, action: ConversationsAction) {},
		conversationSuccess(
			state,
			action: ConversationsAction<{
				chatState: ChatCardState
				lastUpdatedMessageInfo: FSMessageInfo
				mentionables: FSMentionables
			}>
		) {
			ensureInitialState(state, action)
			conversationsAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					chatState: action.payload.chatState,
					lastUpdatedMessageInfo: action.payload.lastUpdatedMessageInfo,
					mentionables: action.payload.mentionables ?? {},
				},
			})
		},
		conversationFailure: {
			reducer(state, action: ConversationsAction<unknown, string>) {},
			prepare(args: { conversationId: string; error: string }) {
				return {
					payload: { conversationId: args.conversationId },
					error: args.error,
				}
			},
		},

		conversationStateUpdate(
			state,
			action: ConversationsAction<{
				chatState?: ChatCardState
				lastUpdatedMessageInfo?: FSMessageInfo
				mentionables?: FSMentionables
			}>
		) {
			ensureInitialState(state, action)
			if ('chatState' in action.payload) {
				conversationsAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: {
						chatState: action.payload.chatState,
					},
				})
			}
			if ('lastUpdatedMessageInfo' in action.payload) {
				conversationsAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: {
						lastUpdatedMessageInfo: action.payload.lastUpdatedMessageInfo,
					},
				})
			}
			if ('mentionables' in action.payload) {
				conversationsAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: {
						mentionables: action.payload.mentionables,
					},
				})
			}
		},
		conversationUpdatesSubscribe(state, action: ConversationsAction) {
			ensureInitialState(state, action)
			conversationsAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					subscribedToUpdates: true,
				},
			})
		},
		conversationUpdatesUnsubscribe(state, action: ConversationsAction) {
			ensureInitialState(state, action)
			conversationsAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					subscribedToUpdates: false,
				},
			})
		},
		conversationUpdateError: {
			reducer(state, action: ConversationsAction<unknown, string>) {
				ensureInitialState(state, action)
				conversationsAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: {
						subscribedToUpdatesError: action.error,
					},
				})
			},
			prepare(args: { conversationId: string; error: string }) {
				return {
					payload: { conversationId: args.conversationId },
					error: args.error,
				}
			},
		},
		conversationUpdateListenerOffline(
			state,
			action: ConversationsAction<{ offline: boolean }>
		) {
			ensureInitialState(state, action)
			conversationsAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					updatesListenerOffline: action.payload.offline,
				},
			})
		},
		conversationMarkAsSeenRequest(state, action: ConversationsAction) {
			ensureInitialState(state, action)
			conversationsAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					unreadCount: 0,
				},
			})
		},
		conversationMarkAsSeenSuccess(state, action: ConversationsAction) {},
		conversationMarkAsSeenFailure: {
			reducer(state, action: ConversationsAction<unknown, string>) {},
			prepare(args: { conversationId: string; error: Error }) {
				return {
					payload: { conversationId: args.conversationId },
					error: args.error.message,
				}
			},
		},
		gqlChatCardsUpdate(state, action: PayloadAction<FeedItemFragment[]>) {
			for (const feedItem of action.payload) {
				if (feedItem.conversation_id && feedItem.attendant) {
					ensureInitialState(state, {
						payload: { conversationId: feedItem.conversation_id },
					})
					conversationsAdapter.updateOne(state, {
						id: feedItem.conversation_id,
						changes: {
							lastSeenMessageId: feedItem.attendant.last_seen_message_id,
							unreadCount: feedItem.attendant.unread_message_count,
							ownAttendantId: feedItem.attendant.id,
						},
					})
				}
			}
		},
	},
	extraReducers: builder =>
		builder
			.addCase(
				messagesByConversation.actions.conversationMessagesUpdate,
				(state, action) => {
					ensureInitialState(state, action)
					const prevConversationState = conversationsAdapter
						.getSelectors()
						.selectById(state, action.payload.conversationId)!

					const updatedLatestMessageId = action.payload.messages.length
						? action.payload.messages[action.payload.messages.length - 1].id
						: prevConversationState.latestMessageId

					// TODO: handle the case where there are multiple messages in the update
					const attendantIdOfMessage = action.payload.messages.length
						? action.payload.messages[action.payload.messages.length - 1]
								.senderInfo?.attendantId
						: undefined

					const shouldIncrement = Boolean(
						updatedLatestMessageId &&
							// Only increment on an update to the latestMessageId
							// When registering the subscription, this is not an "update" value per se.
							prevConversationState.latestMessageId &&
							updatedLatestMessageId !==
								prevConversationState.latestMessageId &&
							// The user's own messages shouldn't increment the unread count
							// (eg, if the user sends messages from another tab)
							attendantIdOfMessage !== prevConversationState.ownAttendantId
					)

					conversationsAdapter.updateOne(state, {
						id: action.payload.conversationId,
						changes: {
							latestMessageId: updatedLatestMessageId,
							unreadCount: shouldIncrement
								? prevConversationState.unreadCount + 1
								: prevConversationState.unreadCount,
						},
					})
				}
			)
			.addCase(auth.actions.signOutSuccess, () =>
				conversationsAdapter.getInitialState()
			),
})

export default conversations

export const conversationsSelectors =
	conversationsAdapter.getSelectors<StoreState>(state => state.conversations)
