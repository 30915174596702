import {
	ActionCreatorWithoutPayload,
	createEntityAdapter,
	createSlice,
	EntityId,
	EntityState,
	IdSelector,
	PayloadAction,
	Slice,
} from '@reduxjs/toolkit'

import { now } from '../utils/time'

import auth from './auth'

export type LoadingPayload = {
	response?: any
	status?: number | null
}

export type LoadingAction<P = unknown, E = never> = PayloadAction<
	P & LoadingPayload,
	string,
	never,
	E
>

interface LoadingActionCreator<Common, Error = never> {
	(...args: any[]): PayloadAction<LoadingPayload & Common, string, never, Error>
	type: string
}

export interface LoadingState {
	id: EntityId
	loading?: boolean
	loaded?: boolean
	loadedAt?: number | null
	loadingError?: any
	loadingErrorAt?: number | null
	response?: any
	loadingErrorStatus?: number | null
}

const loadingStateAdapter = createEntityAdapter<LoadingState>({
	selectId(item) {
		return item.id
	},
})

// Creates a reducer managing network requests, given the action types to handle
// and a function telling how to extract the key from an action.
function loadingState<
	Common extends object,
	RequestCreator extends
		| LoadingActionCreator<Common>
		| ActionCreatorWithoutPayload<string>,
	SuccessCreator extends LoadingActionCreator<Common>,
	FailureCreator extends LoadingActionCreator<Common, string>
>({
	types,
	mapActionToKey,
	name,
	clearResponseOnRequest = true,
}: {
	types: [RequestCreator, SuccessCreator, FailureCreator]
	mapActionToKey: IdSelector<
		ReturnType<RequestCreator | SuccessCreator | FailureCreator>
	>
	name: string
	clearResponseOnRequest?: boolean
}): Slice<EntityState<LoadingState>, {}, string> {
	const [requestType, successType, failureType] = types

	const loadingSlice = createSlice({
		name,
		initialState: loadingStateAdapter.getInitialState(),
		reducers: {},
		extraReducers: builder =>
			builder
				.addCase(requestType, function request(state, action) {
					const id = mapActionToKey(action)
					loadingStateAdapter.upsertOne(state, {
						id,
						loading: true,
						loaded: false,
						loadedAt: null,
						loadingError: null,
						loadingErrorAt: null,
						loadingErrorStatus: null,
					})
					if (clearResponseOnRequest) {
						loadingStateAdapter.upsertOne(state, {
							id,
							response: null,
						})
					}
				})
				.addCase(successType, function success(state, action) {
					const id = mapActionToKey(action)
					loadingStateAdapter.upsertOne(state, {
						id,
						loading: false,
						loaded: true,
						loadedAt: now(),
						response: action.payload.response,
					})
				})
				.addCase(failureType, function failure(state, action) {
					const id = mapActionToKey(action)
					loadingStateAdapter.upsertOne(state, {
						id,
						loading: false,
						loadingError: action.error || true,
						loadingErrorAt: now(),
						loadingErrorStatus: action.payload.status,
					})
				})
				.addCase(auth.actions.signOutSuccess, () =>
					loadingStateAdapter.getInitialState()
				),
	})
	return loadingSlice
}

export default loadingState
