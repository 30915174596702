export type WhereOption = readonly [
	string | import('firebase').default.firestore.FieldPath,
	import('firebase').default.firestore.WhereFilterOp,
	any
]

export type OrderByOption =
	| readonly [string]
	| readonly [string, 'asc' | 'desc']

export interface FetchOptions {
	startAt?: string
	startAfter?: string
	endAt?: string
	endBefore?: string
	limit?: number
	limitToLast?: number
	where?: WhereOption[]
	orderBy?: OrderByOption[]
}

export interface Entity {
	id: string
	createdAt: string
}

export interface Timestamp {
	seconds: number
	nanoseconds: number
}

// From api/server/services/chat/entities/attendant/model.ts
enum ConversationRole {
	ConversationCreator = 'conversation_creator',
	ConversationParticipant = 'conversation_participant',
}

// From api/server/services/chat/entities/attendant/model.ts
enum EntryType {
	ManualJoin = 'manual_join',
	Mention = 'mention',
	AddedByCreator = 'added_by_creator',
}

export interface Attendant extends Entity {
	updatedAt: Timestamp
	conversationId: string
	agentId: string
	displayName: string
	displayColor: string
	displayImageFilename: string
	mutedConversation: boolean
	mutedConversationUntil?: Timestamp
	role: ConversationRole
	entryType: EntryType
	outstandingImportantMessages: string[] // message IDs
	followsConversation: boolean
}

type ConversationType = 'persistent' | 'ephemeral'

export type ConversationState = 'live' | 'ended'

export interface Conversation extends Entity {
	updatedAt?: Timestamp
	type: ConversationType
	title: string
	state: ConversationState
	// undefined if of type persistent, if ephemeral length in seconds
	length?: number
	lastUpdatedMessageInfo: MessageInfo
	mentionables: Mentionables
	// attendants: SubCollection[Attendant]
	// messages: SubCollection[Message]
}

export type LegacyMessageReactionType =
	| 'anger'
	| 'joy'
	| 'adoration'
	| 'excitement'
	| 'like'
	| 'sadness'

export type MessageReactionType =
	| LegacyMessageReactionType
	| 'EMOJI'
	| 'APPRECIATION'

export interface BaseMessageReaction {
	attendantId: string
	displayName: string
	// TODO: are these optional, nullable, or both?
	displayImageId?: string | null
	displayColor?: string | null
}

export interface LegacyReaction {
	type: LegacyMessageReactionType
}

interface EmojiReaction {
	type: 'EMOJI'
	emojiId: string
	emojiImageId: string
	emojiUnicode?: string | null
}

interface AppreciationReaction {
	type: 'APPRECIATION'
	color: string
	emojiImageId: string
	appreciationId: string
	appreciationName: string
	emojiUnicode?: string | null
}

export type MessageReaction = BaseMessageReaction &
	(LegacyReaction | EmojiReaction | AppreciationReaction)

export interface PollOption {
	optionId: string
	optionTitle: string
}

interface LinkPreviewImage {
	url: string
}

export type CloudinaryResourceType = 'raw' | 'video' | 'audio'

export type AttachmentType = 'image' | 'video' | 'raw' | 'animated_image'

export interface Attachment {
	fileId: string
	fileName: string
	mimeType: string
	fileSize: number
	resourceType: CloudinaryResourceType
	dimensions?: {
		width: number
		height: number
	}
	attachmentType?: AttachmentType
}

export interface MessageContent {
	text?: string
	imageFilename?: string
	imageDimensions?: {
		width: number
		height: number
	}
	pollOptions?: PollOption[]
	attachments?: Attachment[]
}

export interface MessageInfo {
	messageId: string
	updatedAt: Timestamp
}

export interface MessageLinkPreviews {
	url: string
	images?: LinkPreviewImage[]
	title?: string
	publisher?: string
}

export interface MessageMention {
	attendantId?: string
	displayName?: string
	offset: number
	length: number
}

interface Mentionable {
	name: string
	// FSMentionable has color xor image
	image?: string
	color?: string
}

export type Mentionables = Record<string, Mentionable>

export interface MessageSenderInfo {
	attendantId: string
	displayColor: string
	displayImageFilename?: string
	displayName: string
}

interface ReplyToMessageInfo {
	messageId: string
	content: MessageContent
	senderInfo: MessageSenderInfo
	mentions?: MessageMention[]
	removed: boolean
	important: boolean
}

export interface PinShareTarget {
	conversationId: string
	messageId: string
}

export interface SyncedShareInfo {
	pinShareTargets: PinShareTarget[]
}

export interface Message extends Entity {
	uniqueId: string
	conversationId: string
	type: 'client_message' | 'service_message'
	serviceMessageType?: 'user_joined' | 'user_left'
	content?: MessageContent
	senderInfo?: MessageSenderInfo
	reactions?: MessageReaction[]
	replyToMessageInfo?: ReplyToMessageInfo
	mentions?: MessageMention[]
	removed: boolean
	updatedAt?: Timestamp
	important: boolean
	actionableUntil?: Timestamp | null
	permitsActionFromSender?: boolean // whether the sender attendant can action on the message
	permitsMultiplePollVotes?: boolean // always set for polls
	actions?: {
		acknowledgements?: string[] // attendantIds, present in case of announcements
		dismissals: string[] // attendantIds
		pollVotes?: Record<string, string[]> // a map of poll option ids and attendantIds, present in case of polls
	}
	linkPreviews?: MessageLinkPreviews[]
	syncedShareInfo?: SyncedShareInfo
}

export enum ConversationMessagesFilter {
	None = 'None',
	NoDeleted = 'NoDeleted',
	ImportantOnly = 'ImportantOnly',
	CuratedMessageIds = 'CuratedMessageIds',
}
