import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IntercomState {
	booted: boolean
	unreadCount: number
}

const initialState: IntercomState = {
	booted: false,
	unreadCount: 0,
}

const intercom = createSlice({
	name: 'intercom',
	initialState,
	reducers: {
		intercomBoot(state, action: PayloadAction) {
			state.booted = true
		},
		intercomUnreadCountChange(
			state,
			action: PayloadAction<{
				unreadCount: number
			}>
		) {
			state.unreadCount = action.payload.unreadCount
		},
	},
})

export default intercom
