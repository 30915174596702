import styled, { css } from 'styled-components'

import Button, { ButtonKind, ButtonSize } from '../AKButton'
import Cross from '../SVGs/Cross'

export const CloseIcon = styled(Cross)`
	transform: rotate(45deg);
`

export const CloseButton = styled.button`
	width: ${props => props.theme.sizing.scale850};
	height: ${props => props.theme.sizing.scale850};
	background-color: ${props => props.theme.colors.iconFillGrey};
	border-radius: 50%;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
`

export const ProceedButton = styled(Button).attrs({
	kind: ButtonKind.Minimal,
	size: ButtonSize.Mini,
})`
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.mono500 : theme.colors.primary};
	padding: 0;
	${({ theme }) => theme.typography.ParagraphMedium}
`

export const StyledBody = styled.div`
	flex: 1 1 auto;
	min-height: 0;
	overflow: auto;

	${({ theme }) => theme.typography.ParagraphRegular};

	margin-right: ${({ theme }) => theme.grid.gutter}px;
	margin-left: ${({ theme }) => theme.grid.gutter}px;

	padding-top: ${({ theme }) => theme.grid.margin}px;
	padding-bottom: ${({ theme }) => theme.grid.margin}px;

	border-bottom-left-radius: ${({ theme }) =>
		theme.borders.surfaceBorderRadius};
	border-bottom-right-radius: ${({ theme }) =>
		theme.borders.surfaceBorderRadius};
`

export const StyledFluidBody = styled(StyledBody)`
	margin-left: 0;
	margin-right: 0;

	padding-top: 0;
`

export const StyledHighlightedSection = styled.div<{
	$invertBackground?: boolean
}>(
	({ theme, $invertBackground }) => css`
		position: relative;
		width: 100%;
		background: ${$invertBackground
			? theme.colors.backgroundPrimary
			: theme.colors.backgroundSecondary};

		border-top: ${({ theme }) =>
			theme.utils.stubToShorthand(theme.borders.border100)};
		border-bottom: ${({ theme }) =>
			theme.utils.stubToShorthand(theme.borders.border100)};

		&:first-child {
			border-top: none;
		}
	`
)
