import styled, { css } from 'styled-components'

import { withEllipsisTextOverflow } from '../utils'

export const Root = styled.div<{ $useRoundedCorners: boolean }>(
	({ theme, $useRoundedCorners }) => css`
		position: relative;
		width: 100%;
		flex: 0 0 ${theme.layout.headerHeight}px;
		height: ${theme.layout.headerHeight}px;

		display: flex;
		align-items: center;

		background: ${theme.colors.backgroundSecondary};
		color: ${({ theme }) => theme.colors.contentPrimary};

		border-top-left-radius: ${$useRoundedCorners
			? theme.borders.surfaceBorderRadius
			: 0};
		border-top-right-radius: ${$useRoundedCorners
			? theme.borders.surfaceBorderRadius
			: 0};

		border-bottom-color: ${theme.borders.border100.borderColor};
		border-bottom-style: ${theme.borders.border100.borderStyle};
		border-bottom-width: ${theme.borders.border100.borderWidth};

		padding-left: ${theme.grid.gutter}px;
		padding-right: ${theme.grid.gutter}px;
	`
)

export const Title = styled.div`
	flex: 1 1 0;
	min-width: 0px;
	text-align: center;

	${({ theme }) => theme.typography.TitleMedium}

	${withEllipsisTextOverflow}
`

const sideContentsSizing = css`
	display: flex;
	flex: 0 0 ${({ theme }) => theme.sizing.scale1200};
	width: ${({ theme }) => theme.sizing.scale1200};

	${({ theme }) => theme.mediaQueries.sm} {
		flex: 0 0 ${({ theme }) => theme.sizing.scale1600};
		width: ${({ theme }) => theme.sizing.scale1600};
	}
`

export const ActionsLeft = styled.div`
	${sideContentsSizing};
	justify-content: flex-start;
`

export const ActionsRight = styled.div`
	${sideContentsSizing};
	justify-content: flex-end;
`
