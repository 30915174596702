import styled from 'styled-components'

export const Root = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
	display: flex;
	align-items: center;

	cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

	${({ theme }) => theme.typography.ParagraphMedium};
	color: ${({ theme, $disabled }) =>
		$disabled ? theme.colors.contentSecondary : theme.colors.contentPrimary};
	background-color: ${({ theme }) => theme.colors.backgroundPrimary};

	padding: ${({ theme }) => theme.grid.gutter}px;

	border-width: ${({ theme }) => theme.borders.border300.borderWidth};
	border-color: ${({ theme, $selected }) =>
		$selected ? theme.colors.primary : theme.borders.border300.borderColor};
	border-style: ${({ theme }) => theme.borders.border300.borderStyle};
	border-radius: ${({ theme }) => theme.borders.radius200};
`

export const CheckboxRoot = styled.div`
	margin-right: ${({ theme }) => theme.sizing.scale300};
`
