import {
	FC,
	useRef,
	memo,
	ImgHTMLAttributes,
	useCallback,
	ReactNode,
} from 'react'
import Transition from 'react-transition-group/Transition'

import useAsyncSafeState from '../../hooks/useAsyncSafeState'

import { COPY, PLACEHOLDER_IMAGE_URL } from '../../constants/config'
import { getImageURL } from '../../utils/cloudinary'

import {
	StyledImage,
	SVGPlaceholder,
	Placeholder,
	TRANSITION_DURATION_MS,
} from './styled-components'

const IMAGE_TRANSFORMS = ['f_auto', 'q_auto:good']

type ExposedHTMLImgTagProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>

export interface Props extends ExposedHTMLImgTagProps {
	filename?: string
	animated?: boolean
	inPerfMode?: boolean
	customPlaceholder?: ReactNode
	transforms?: string[]
}

/**
 * This is component is the default way we should render images coming
 * from out image CDN, Cloudinary. The component provides a fallback
 * transformation, but the developer is highly encourage not to rely on it
 * and rather set a context specific transformation. Cloudinary, being a 3rd
 * party service, has cost implications. One should always be careful and
 * load images at an appropriate resolution, taking advantage of the
 * transformations API:
 * https://cloudinary.com/documentation/image_transformations
 *
 * This component is meant to be a thin wrapper around the HTML img tag, and
 * hence exposes many of the img tag's attributes. One of the attirbutes
 * we use is "loading", for browser native lazy image loading. This is
 * a new HTML feature and it doesn't have browser coverage quite to our
 * standards:
 * https://caniuse.com/#feat=loading-lazy-attr
 *
 * Lazy loading is not yet a product requirement, therefore we're ok with
 * using the HTML attribute vs adding a 3rd party library to achieve this
 * behaviour.
 *
 */
const CloudinaryImage: FC<Props> = ({
	filename,
	transforms,
	animated,
	inPerfMode,
	customPlaceholder,
	alt = COPY.IMAGES.PLACEHOLDER_ALT_TEXT,
	...imgProps
}) => {
	const imageRef = useRef<HTMLImageElement>(null)
	const placeholderRef = useRef<HTMLDivElement>(null)
	const [loaded, setLoaded] = useAsyncSafeState(false)

	const handleImageLoaded = useCallback(() => setLoaded(true), [setLoaded])

	const cloudinaryTransforms = transforms ?? IMAGE_TRANSFORMS
	const imgSrc = filename
		? getImageURL(filename, cloudinaryTransforms)
		: PLACEHOLDER_IMAGE_URL

	const PlaceholderComponent = customPlaceholder ? Placeholder : SVGPlaceholder

	// The Transition components can cause performance issues when rendering lots
	// of images at once
	if (inPerfMode) {
		return (
			<>
				<StyledImage
					ref={imageRef}
					src={imgSrc}
					alt={alt}
					onLoad={handleImageLoaded}
					$transitionStatus={loaded ? 'entered' : 'exited'}
					$withAnimation={animated}
					{...imgProps}
				/>
				{!loaded && (
					<PlaceholderComponent
						$transitionStatus="entered"
						$withAnimation={animated}
					>
						{customPlaceholder}
					</PlaceholderComponent>
				)}
			</>
		)
	}

	return (
		<>
			<Transition
				in={loaded}
				timeout={TRANSITION_DURATION_MS}
				nodeRef={imageRef}
			>
				{state => (
					<StyledImage
						ref={imageRef}
						src={imgSrc}
						alt={alt}
						onLoad={handleImageLoaded}
						$transitionStatus={state}
						$withAnimation={animated}
						{...imgProps}
					/>
				)}
			</Transition>

			<Transition
				in={!loaded}
				timeout={TRANSITION_DURATION_MS}
				unmountOnExit
				nodeRef={placeholderRef}
			>
				{state => (
					<PlaceholderComponent
						ref={placeholderRef}
						$transitionStatus={state}
						$withAnimation={animated}
					>
						{customPlaceholder}
					</PlaceholderComponent>
				)}
			</Transition>
		</>
	)
}

export default memo(CloudinaryImage)
