import type firebase from 'firebase'
import { AnyAction } from 'redux'
import { END, EventChannel, eventChannel } from 'redux-saga'
import { put, delay, call, cancelled, take } from 'typed-redux-saga/macro'

import { FetchOptions } from '../network/firestore'
import { observeCollection } from '../network/firestore/firestore'
import { now } from '../utils/time'

export function* callAfterTime<Fn extends (...args: any[]) => any>(
	datetimeMs: number,
	saga: Fn,
	...args: Parameters<Fn>
) {
	const nowMs = yield* call(now)
	const shouldDelayForMs = datetimeMs - nowMs
	if (shouldDelayForMs > 0) {
		yield* delay(datetimeMs - nowMs)
	}
	yield* call(saga, ...args)
}

export function* observeFirebaseCollection<FSType, DomainType>(args: {
	path: string
	fetchOptions: FetchOptions
	nextActionCreator(input: DomainType): AnyAction
	errorActionCreator(error: Error): AnyAction
	transform(input: firebase.firestore.QuerySnapshot<FSType>): DomainType
	firebaseAppName?: string
}) {
	let channel: EventChannel<AnyAction> | null = null
	try {
		channel = eventChannel<AnyAction>(emit => {
			const unobserve = observeCollection<FSType>(
				args.path,
				{
					fetchOptions: args.fetchOptions,
					callbacks: {
						next(snapshot) {
							emit(args.nextActionCreator(args.transform(snapshot)))
						},
						error: args.errorActionCreator,
						complete() {
							emit(END)
						},
					},
				},
				args.firebaseAppName
			)
			return unobserve
		})
		while (true) {
			const action = yield* take(channel)
			yield* put(action)
		}
	} finally {
		if (yield* cancelled()) {
			channel?.close()
		}
	}
}
