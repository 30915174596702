import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ImportantMessagesState = {}

const initialState: ImportantMessagesState = {}

export const importantMessages = createSlice({
	name: 'importantMessages',
	initialState,
	reducers: {
		nextOutstandingImportantMessagesPageClick(
			state,
			action: PayloadAction<{ conversationId: string }>
		) {},
		outstandingImportantMessagesMount(
			state,
			action: PayloadAction<{ conversationId: string }>
		) {},
		outstandingImportantMessagesUnmount(
			state,
			action: PayloadAction<{ conversationId: string }>
		) {},
		outstandingImportantMessagesError: {
			reducer(
				state,
				action: PayloadAction<{ conversationId: string }, string, never, string>
			) {},
			prepare({
				error,
				conversationId,
			}: {
				conversationId: string
				error: Error
			}) {
				return { payload: { conversationId }, error: error.message }
			},
		},
	},
})

export default importantMessages
