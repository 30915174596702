import { AnyAction, isAnyOf } from '@reduxjs/toolkit'
import {
	take,
	call,
	race,
	select,
	fork,
	all,
	takeEvery,
	put,
	getContext,
} from 'typed-redux-saga/macro'

import {
	ChatCardFragment,
	GetChatCardForChatViewQuery,
	GetChatCardForChatViewQueryVariables,
	JoinConversationMutation,
	JoinConversationMutationVariables,
} from '../network/graphql'
import { GQLClient } from '../network/graphql/configureClient'
import { CHAT_VIEW_FRAGMENT } from '../network/graphql/fragments'
import { JOIN_CONVERSATION } from '../network/graphql/mutations'
import { GET_CHAT_CARD_FOR_CHAT_VIEW } from '../network/graphql/queries'

import { GQL_CLIENT_SAGA_CONTEXT_KEY } from '../constants/config'
import { FeedItem } from '../domain/ContentFeed'
import {
	convertQueryResultToContentFeed,
	getContentFeedCollectionName,
	getContentFeedFetchOptions,
	getContentFeedFirebaseAppName,
	initAndAuthContentFeedFirebaseApp,
} from '../network/contentFeedFirestore'
import api from '../slices/api'
import auth from '../slices/auth'
import contentFeed, {
	contentFeedInputSelectors,
	contentFeedSelectors,
} from '../slices/contentFeed'
import localMessages from '../slices/localMessages'
import message from '../slices/message'
import { toSafeError } from '../utils/error'

import { observeFirebaseCollection } from './utils'

function subscriptionUpdateActionCreator(sphereId: string) {
	return (items: FeedItem[]) =>
		contentFeed.actions.subscriptionUpdate({
			sphereId,
			items,
		})
}

function subscriptionErrorActionCreator(sphereId: string) {
	return (error: Error) =>
		contentFeed.actions.subscriptionError({
			sphereId,
			error,
		})
}

function* subscribeToContentFeedForSphere(sphereId: string, agentId: string) {
	yield* call(observeFirebaseCollection, {
		path: getContentFeedCollectionName(sphereId, agentId),
		fetchOptions: getContentFeedFetchOptions(),
		firebaseAppName: getContentFeedFirebaseAppName(),
		nextActionCreator: subscriptionUpdateActionCreator(sphereId),
		errorActionCreator: subscriptionErrorActionCreator(sphereId),
		transform: convertQueryResultToContentFeed,
	})
}

export function* subscribeToFeedOfSphere(sphereId: string, agentId: string) {
	yield* race({
		subscribe: call(subscribeToContentFeedForSphere, sphereId, agentId),
		unsubscribe: take(
			(action: AnyAction) =>
				contentFeed.actions.feedClosed.match(action) &&
				action.payload.sphereId === sphereId
		),
	})
}

function* waitForNewSubscriptions(agentId: string) {
	while (true) {
		const action = yield* take(contentFeed.actions.feedOpened)
		const sphereId = action.payload.sphereId
		yield* fork(subscribeToFeedOfSphere, sphereId, agentId)
	}
}

export function* contentFeedSubscriptionSaga() {
	while (true) {
		const action = yield* take(api.actions.initSuccess.match)
		const config = action.payload.firebaseSphereWebConfig
		const token = action.payload.sphereFirebaseToken

		const agentId = yield* select(state => state.auth.agentId)
		if (!config) {
			throw new Error('no config to initialize content feed firebase app')
		}
		if (!token) {
			throw new Error('missing token to initialize content feed firebase app')
		}
		if (!agentId) {
			throw new Error(
				'agent id missing to initialize content feed firebase app'
			)
		}

		// TODO: WEB-383 extract firebase app
		const contentFeedFirebaseApp = yield* call(
			initAndAuthContentFeedFirebaseApp,
			config,
			token
		)

		// catch up on feeds that should be listened to
		const openFeeds = yield* select(contentFeedSelectors.selectAll)

		yield* race({
			subscriptions: all([
				// add currently open feeds to the subscriptions
				...openFeeds
					.filter(feed => feed.isOpen)
					.map(feed => call(subscribeToFeedOfSphere, feed.sphereId, agentId)),
				// and fork for new subscriptions if additional feeds are opened
				call(waitForNewSubscriptions, agentId),
			]),
			// stop all subscriptions on signout
			signout: take(auth.actions.signOutRequest.type),
		})

		// TODO: WEB-383 extract firebase app
		contentFeedFirebaseApp.delete()
	}
}

export function* contentFeedMessageSendingSaga() {
	yield* takeEvery(
		contentFeed.actions.sendMessageRequest.match,
		function* (request) {
			const { conversationId } = request.payload

			const input = yield* select(state =>
				contentFeedInputSelectors.selectById(state, conversationId)
			)
			if (!input || !input.text) {
				yield* put(
					contentFeed.actions.sendMessageFailure({
						conversationId,
						error: new Error('no input found for sending content feed message'),
					})
				)
				return
			}

			const gqlClient = yield* getContext(GQL_CLIENT_SAGA_CONTEXT_KEY)
			try {
				yield* call(ensureJoinedConversation, gqlClient, conversationId)
			} catch (unknownError) {
				const error = toSafeError(unknownError)
				yield* put(
					contentFeed.actions.sendMessageFailure({
						conversationId,
						error,
					})
				)
				return
			}

			// save the message action so we can use the uniqueId to track completion
			const messageInitiation = message.actions.sendMessageInitiate({
				conversationId,
				text: input.text,
				mentions: null,
				imageObjectUrls: null,
				replyToMessageId: null,
				isImportant: false,
			})

			yield* put(messageInitiation)

			// wait for completion of this specific action
			const result: AnyAction = yield* take(
				(action: AnyAction) =>
					isAnyOf(
						localMessages.actions.messageCreateFailure,
						localMessages.actions.messageCreateSuccess,
						message.actions.sendMessageFailure
					)(action) &&
					action.payload.uniqueId === messageInitiation.payload.uniqueId
			)

			if (
				isAnyOf(
					localMessages.actions.messageCreateFailure,
					message.actions.sendMessageFailure
				)(result)
			) {
				yield* put(
					contentFeed.actions.sendMessageFailure({
						conversationId,
						error: new Error(result.error),
					})
				)
			} else if (isAnyOf(localMessages.actions.messageCreateSuccess)(result)) {
				yield* put(
					contentFeed.actions.sendMessageSuccess({
						conversationId,
					})
				)
			}
		}
	)
}

export default function* contentFeedSaga() {
	yield* all([
		call(contentFeedSubscriptionSaga),
		call(contentFeedMessageSendingSaga),
	])
}

async function ensureJoinedConversation(
	gqlClient: GQLClient,
	conversationId: string
): Promise<{ attendantId: string }> {
	const queryResult = await gqlClient.query<
		GetChatCardForChatViewQuery,
		GetChatCardForChatViewQueryVariables
	>({
		query: GET_CHAT_CARD_FOR_CHAT_VIEW,
		variables: { id: conversationId },
		fetchPolicy: 'cache-first',
	})

	const card = queryResult.data?.viewer?.chatCard

	let attendantId = card?.attendant?.id
	if (attendantId) {
		return { attendantId }
	}

	const mutationResult = await gqlClient.mutate<
		JoinConversationMutation,
		JoinConversationMutationVariables
	>({
		mutation: JOIN_CONVERSATION,
		variables: {
			conversationId,
		},
		// the saga/sendMessage will read from this cache to retrieve attendant data, thus update
		update(cache, result) {
			const viewerAttendant = result.data?.joinConversation
			if (!viewerAttendant || !card) {
				return
			}
			cache.writeFragment<ChatCardFragment>({
				fragment: CHAT_VIEW_FRAGMENT,
				fragmentName: 'chatCard',
				data: {
					...card,
					attendant: viewerAttendant,
				},
			})
		},
	})

	attendantId = mutationResult.data?.joinConversation.id
	if (attendantId) {
		return { attendantId }
	}

	throw new Error('could not ensure conversation joined status')
}
