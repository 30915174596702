import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import api from './api'

export interface EnvironmentState {
	online: boolean | null
	isVisible: boolean
	isMobile: boolean
	isAndroid: boolean
	isIos: boolean
	screenHeight: number | null
	screenWidth: number | null
	systemInfo: any | null
	instance_id: string | null
	prefersDarkColorScheme: boolean | null
	keyTobeDeleted?: string
}

export const initialState: EnvironmentState = {
	online: null,
	isVisible: false,
	isMobile: false,
	isAndroid: false,
	isIos: false,
	screenHeight: null,
	screenWidth: null,
	systemInfo: null,
	instance_id: null,
	prefersDarkColorScheme: null,
	keyTobeDeleted: 'testKey',
}

export const environment = createSlice({
	name: 'environment',
	initialState,
	reducers: {
		setPrefersDarkColorScheme(state, action: PayloadAction<boolean>) {
			state.prefersDarkColorScheme = action.payload
		},
		setIsOnline(state, action: PayloadAction<boolean>) {
			state.online = action.payload
		},
		setIsVisible(state, action: PayloadAction<boolean>) {
			state.isVisible = action.payload
		},
		setIsMobile(
			state,
			action: PayloadAction<{
				isMobile: boolean
				isAndroid: boolean
				isIos: boolean
			}>
		) {
			state.isMobile = action.payload.isMobile
			state.isAndroid = action.payload.isAndroid
			state.isIos = action.payload.isIos
		},
		setWindowDimensions(
			state,
			action: PayloadAction<{ width: number; height: number }>
		) {
			state.screenWidth = action.payload.width
			state.screenHeight = action.payload.height
		},
		setIsSystemInfo(state, action: PayloadAction<any>) {
			state.systemInfo = {
				...state.systemInfo,
				...action.payload,
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(api.actions.initSuccess, (state, action) => {
			if (state.systemInfo) {
				state.systemInfo.ip = action.payload.ip
			}
		})
	},
})

export const actions = environment.actions

export default environment
