import { FC } from 'react'
import { ThemeProvider } from 'styled-components'

import GridLayout from '../AKGridLayout'
import ListItem, { ListItemLabel } from '../AKList'
import SelectableCell from '../AKSelectableCell'
import Toggle from '../AKToggle'
import Well, { FluidWell } from '../AKWell'
import Page from '../StatefulPage'
import UserIcon from '../UserIcon'

import { COPY } from '../../constants/config'
import { DebugFlag } from '../../slices/debug'
import { ThemeState } from '../../slices/theme'
import { darkSphereTheme } from '../../styles/sphereDarkTheme'
import { lightSphereTheme } from '../../styles/sphereLightTheme'
import { Gutter } from '../utils'

import {
	CenteringContainer,
	Email,
	ImageSpacing,
	PrimaryMinimalButton,
	Root,
	Section,
	SectionLabel,
	SignoutButton,
	SlimFluidWell,
	Title,
	Row,
	RowItem,
	IconPositioner,
} from './styled-components'

interface Setting {
	flag: DebugFlag
	value: boolean
}

export interface Props {
	displayName?: string | null
	imageFilename?: string | null
	imageColor?: string | null
	bio?: string | null
	email?: string | null
	onEditProfile(): void
	darkModeFeatureEnabled: boolean
	systemPrefersDarkColorScheme: boolean
	themePreference: ThemeState
	onSetThemePreference(preference: ThemeState): void
	onSignout(): void
	debugSettings?: Setting[]
	onDebugSettingToggle?(flag: DebugFlag, value: boolean): void
}

const Settings: FC<Props> = ({
	displayName,
	imageColor,
	imageFilename,
	bio,
	email,
	darkModeFeatureEnabled,
	systemPrefersDarkColorScheme,
	themePreference,
	onEditProfile,
	onSetThemePreference,
	onSignout,
	debugSettings,
	onDebugSettingToggle,
}) => (
	<Page
		title="Settings"
		endEnhancer={
			<PrimaryMinimalButton onClick={onEditProfile}>
				{COPY.SETTINGS.EDIT_BUTTON_LABEL}
			</PrimaryMinimalButton>
		}
	>
		<Root>
			<GridLayout>
				<Section>
					<Well>
						<CenteringContainer>
							<ImageSpacing>
								<UserIcon
									size={80}
									name={displayName ?? undefined}
									imageFilename={imageFilename}
									color={imageColor}
								/>
							</ImageSpacing>

							{displayName && <Title>{displayName}</Title>}
							{email && <Email>{email}</Email>}
						</CenteringContainer>
					</Well>
				</Section>
				{bio && (
					<Section>
						<SectionLabel>
							{COPY.SETTINGS.DESCRIPTION_SECTION_TITLE}
						</SectionLabel>
						<Well>{bio}</Well>
					</Section>
				)}
				{darkModeFeatureEnabled && (
					<Section>
						<SectionLabel>
							{COPY.SETTINGS.APPEARANCE_SECTION_TITLE}
						</SectionLabel>
						<FluidWell>
							<Gutter>
								<Row>
									<RowItem>
										<ThemeProvider
											theme={
												systemPrefersDarkColorScheme
													? darkSphereTheme
													: lightSphereTheme
											}
										>
											<SelectableCell
												label={COPY.SETTINGS.APPEARANCE_AUTO_LABEL}
												selected={themePreference === 'auto'}
												onClick={() => onSetThemePreference('auto')}
											/>
										</ThemeProvider>
									</RowItem>
									<RowItem>
										<ThemeProvider theme={lightSphereTheme}>
											<SelectableCell
												label={COPY.SETTINGS.APPEARANCE_LIGHT_LABEL}
												selected={themePreference === 'light'}
												onClick={() => onSetThemePreference('light')}
											/>
										</ThemeProvider>
									</RowItem>
									<RowItem>
										<ThemeProvider theme={darkSphereTheme}>
											<SelectableCell
												label={COPY.SETTINGS.APPEARANCE_DARK_LABEL}
												selected={themePreference === 'dark'}
												onClick={() => onSetThemePreference('dark')}
											/>
										</ThemeProvider>
									</RowItem>
								</Row>
							</Gutter>
						</FluidWell>
					</Section>
				)}
				{debugSettings && debugSettings.length > 0 && (
					<Section>
						<SectionLabel>Debug</SectionLabel>
						<Well>
							{debugSettings.map(({ flag, value }) => (
								<ListItem
									key={flag}
									onClick={() => onDebugSettingToggle?.(flag, !value)}
									endEnhancer={
										<IconPositioner>
											<Toggle checked={value} />
										</IconPositioner>
									}
								>
									<ListItemLabel>{flag}</ListItemLabel>
								</ListItem>
							))}
						</Well>
					</Section>
				)}
				<Section>
					<SlimFluidWell>
						<CenteringContainer>
							<SignoutButton onClick={onSignout}>
								{COPY.SETTINGS.SIGNOUT_BUTTON_LABEL}
							</SignoutButton>
						</CenteringContainer>
					</SlimFluidWell>
				</Section>
			</GridLayout>
		</Root>
	</Page>
)

export default Settings
