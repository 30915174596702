import api from '../slices/api'
import auth from '../slices/auth'
import { ApplicationThunk } from '../store'
import { toSafeError } from '../utils/error'

import { pushUnsubscribe } from './browser'
import { firebaseDisconnect } from './firebase'

export function beginAuthenticatedUserSession(): ApplicationThunk<
	Promise<void>
> {
	return async (dispatch, getState, { authClient }) => {
		authClient.beginAuthenticatedSession({
			onRefreshSuccess(accessToken: string) {
				dispatch(
					auth.actions.authTokenRefreshSuccess({
						accessToken,
					})
				)
			},
			onRefreshFailure(error: string) {
				dispatch(auth.actions.authTokenRefreshFailure({ error }))
			},
			onError(error: string) {
				dispatch(auth.actions.authTokenRefreshFailure({ error }))
			},
			onRefreshRequest() {
				dispatch(auth.actions.authTokenRefreshRequest())
			},
		})
	}
}

export function signOut(): ApplicationThunk<Promise<void>> {
	return async (dispatch, getState, { authClient, gqlClient }) => {
		dispatch(auth.actions.signOutRequest())
		try {
			try {
				await Promise.all([
					gqlClient.clearStore(),
					dispatch(firebaseDisconnect()),
					dispatch(pushUnsubscribe()),
				])
			} catch (e) {
				// Errors in the actions above should be handled.
				// If there are any unhandled errors these should not block
				// the signout flow.
			}

			await authClient.endAuthenticatedSession()
			dispatch(auth.actions.signOutSuccess())
		} catch (unknownError) {
			const error = toSafeError(unknownError)
			dispatch(auth.actions.signOutFailure({ error: error.message }))
		}
	}
}

export function signIn(
	email: string,
	password: string
): ApplicationThunk<Promise<void>> {
	return async (dispatch, getState, { authClient }) => {
		dispatch(auth.actions.signInRequest())
		try {
			const { data, error, status } =
				await authClient.logInWithEmailAndPassword({
					email,
					password,
				})

			if (error) {
				dispatch(auth.actions.signInFailure({ error, status }))
			} else {
				dispatch(auth.actions.signInSuccess({ response: data }))
				dispatch(api.actions.configurationInitiate())
			}
		} catch (error) {
			dispatch(auth.actions.signInFailure({ error: (error as Error).message }))
		}
	}
}
