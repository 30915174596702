import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import { FIREBASE_CONTENT_FEED_APP_NAME } from '../../constants/config'
import { FirebaseConfig } from '../api'
import { FetchOptions } from '../firestore'

import { CONTENT_FEED_VERSION_SUPPORTED } from './types'

export function getContentFeedCollectionName(
	sphereId: string,
	agentId: string
) {
	return `/spheres/${sphereId}/sphereMemberships/${agentId}/feed`
}

export function getContentFeedFetchOptions(): FetchOptions {
	return {
		where: [
			['feedVersion', 'in', CONTENT_FEED_VERSION_SUPPORTED],
			['archived', '==', false],
		],
		orderBy: [
			['prioritized', 'desc'],
			['lastActivityAt', 'desc'],
		],
	}
}

export function getContentFeedFirebaseAppName() {
	return FIREBASE_CONTENT_FEED_APP_NAME
}

export async function initAndAuthContentFeedFirebaseApp(
	config: FirebaseConfig,
	token: string
): Promise<firebase.app.App> {
	const firebaseApp = firebase.initializeApp(
		config,
		getContentFeedFirebaseAppName()
	)
	await firebaseApp.auth().signInWithCustomToken(token)
	return firebaseApp
}
