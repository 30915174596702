import { AnyAction } from '@reduxjs/toolkit'
import {
	call,
	delay,
	fork,
	put,
	race,
	select,
	take,
	takeEvery,
} from 'typed-redux-saga/macro'

import { TYPING_INDICATOR_LIFETIME_FALLBACK } from '../constants/config'
import pubsub, { channelNameForConversation } from '../slices/pubsub'
import typingIndicators, {
	decodeAttendantTypingPubsubEventPayload,
	encodeAttendantTypingEvent,
	isTypingMessageWrapper,
	TypingMessageWrapper,
} from '../slices/typingIndicators'

/**
 * Start a timer to clear an attendants typing state after the configured lifetime
 */
function* attendantTypingTimeout(
	action: ReturnType<typeof typingIndicators.actions.attendantTypingStateUpdate>
) {
	// product spec talks about 3 seconds, but config seems to be 600ms, a bit short
	const lifetime = yield* select(
		store =>
			store.api.clientConfig?.typing_indicator_lifetime ??
			TYPING_INDICATOR_LIFETIME_FALLBACK
	)
	// Should we indeed use the remote timestamp to calculate the visible lifetime?
	// Spec seems to say that
	// But on a slow connection with one-way ping of 600ms (current lifetime)
	// we wouldn't show any typing indicators
	const passedTime = Date.now() - new Date(action.payload.createdAt).getTime()
	const timeout = lifetime - passedTime
	if (timeout > 0) {
		yield* delay(timeout)
	}

	// check if this clear belongs to the last set
	const lastCreatedAtForAttendant = yield* select(state => {
		const conversation =
			state.typingIndicators.entities[action.payload.conversationId]
		const attendant =
			conversation?.attendantsTyping.entities[action.payload.attendantId]
		return attendant?.createdAt
	})
	// if it doesn't a later set did update the state and its clear is responsible now
	if (lastCreatedAtForAttendant === action.payload.createdAt) {
		yield* put(
			typingIndicators.actions.attendantTypingStateUpdate({
				conversationId: action.payload.conversationId,
				attendantId: action.payload.attendantId,
				action: 'clear',
				createdAt: new Date().toISOString(),
			})
		)
	}
}

/**
 * Handle own typing events for a single conversation
 * Publish local typing to the followed conversation
 */
function* publishingOwnTyping(channelName: string) {
	yield* takeEvery(
		typingIndicators.actions.ownAttendantIsTyping.match,
		function* (action) {
			yield* put(
				pubsub.actions.publishMessageRequest({
					channelName,
					event: 'client-typing',
					message: encodeAttendantTypingEvent(action.payload),
				} as TypingMessageWrapper)
			)
		}
	)
}

/**
 * Handle remote typing events for a single conversation
 * Filter received pubsub events to the followed conversation
 * and creates remote typings events for the typing indicator slice to store
 * Automatically create timeouts for all typing states of the conversation
 */
function* receivingRemoteTyping(conversationId: string, channelName: string) {
	yield* takeEvery(
		pubsub.actions.messageReceived.match,
		function* (pubsubAction) {
			// pipe remote typing into redux if they are from our followed conversation channel
			if (pubsubAction.payload.channelName === channelName) {
				if (isTypingMessageWrapper(pubsubAction.payload)) {
					const message = pubsubAction.payload.message
					const typingAction =
						typingIndicators.actions.attendantTypingStateUpdate({
							conversationId,
							...decodeAttendantTypingPubsubEventPayload(message),
						})
					yield* put(typingAction)
					if (message.action === 'set') {
						yield* fork(attendantTypingTimeout, typingAction)
					}
				}
			}
		}
	)
}

/**
 * Subscribe to typing indicator events of a specific conversation
 * And cancel this subscribtion when the unsubscribe arrives
 * @param {string} conversationId
 */
function* subscribeTo({
	conversationId,
	channelName,
}: {
	conversationId: string
	channelName: string
}) {
	try {
		yield* put(
			pubsub.actions.subscribeToConversationChannelRequest({
				channelName,
				conversationId,
			})
		)

		yield* race({
			remoteTyping: call(receivingRemoteTyping, conversationId, channelName),
			ownTyping: call(publishingOwnTyping, channelName),
			unsubscribe: take(
				(action: AnyAction) =>
					typingIndicators.actions.unsubscribe.match(action) &&
					action.payload.conversationId === conversationId
			),
		})
	} catch (error) {
		yield* put(typingIndicators.actions.error({ error }))
	} finally {
		// always make sure to unsubscrib
		yield* put(
			pubsub.actions.unsubscribeFromConversationChannelRequest({
				channelName,
			})
		)
	}
}

/**
 * The Typing Indicator Saga
 * Waiting for subscribtion actions to kick things off
 */
export function* typingSaga() {
	yield* takeEvery(
		typingIndicators.actions.subscribe.match,
		function* ({ payload: { conversationId } }) {
			const channelName = channelNameForConversation(conversationId)
			yield* fork(subscribeTo, { conversationId, channelName })
		}
	)
}

export default typingSaga
