import colors from '../../colors/tokens'
import { ColorTokens } from '../types'

// inspired by https://github.com/uber/baseweb/blob/master/src/themes/light-theme/color-tokens.js

const lightColorTokens: ColorTokens = {
	// Primary Palette
	primary: colors.sky500,
	primary100: colors.sky100,
	primary200: colors.sky200,
	primary300: colors.sky300,
	primary400: colors.sky400,
	primary500: colors.sky500,
	primary600: colors.sky600,
	primary700: colors.sky700,
	primary800: colors.sky800,
	primary900: colors.sky900,

	// Negative Palette
	negative: colors.fire500,
	negative100: colors.fire100,
	negative200: colors.fire200,
	negative300: colors.fire300,
	negative400: colors.fire400,
	negative500: colors.fire500,
	negative600: colors.fire600,
	negative700: colors.fire700,
	negative800: colors.fire800,
	negative900: colors.fire900,

	// Warning Palette
	warning: colors.sun500,
	warning100: colors.sun100,
	warning200: colors.sun200,
	warning300: colors.sun300,
	warning400: colors.sun400,
	warning500: colors.sun500,
	warning600: colors.sun600,
	warning700: colors.sun700,
	warning800: colors.sun800,
	warning900: colors.sun900,

	// Positive Palette
	positive: colors.grass500,
	positive100: colors.grass100,
	positive200: colors.grass200,
	positive300: colors.grass300,
	positive400: colors.grass400,
	positive500: colors.grass500,
	positive600: colors.grass600,
	positive700: colors.grass700,
	positive800: colors.grass800,
	positive900: colors.grass900,

	// Magnificent Palette
	magnificent: colors.royal500,
	magnificent100: colors.royal100,
	magnificent200: colors.royal200,
	magnificent300: colors.royal300,
	magnificent400: colors.royal400,
	magnificent500: colors.royal500,
	magnificent600: colors.royal600,
	magnificent700: colors.royal700,
	magnificent800: colors.royal800,
	magnificent900: colors.royal900,

	// Monochrome Palette
	white: colors.snow,
	black: colors.charcoal,
	mono100: colors.snow,
	mono200: colors.cloud,
	mono300: colors.silver,
	mono400: colors.cloud700,
	mono500: colors.slate,
	mono600: colors.slate600,
	mono700: colors.slate800,
	mono800: colors.charcoal100,
	mono900: colors.charcoal,
}

export default lightColorTokens
