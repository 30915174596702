import jsonrpc, { IParsedObjectNotification } from 'jsonrpc-lite'

import { PubsubMessage, PubsubMessageParams } from './types'

type RpcParsedObjectNotificationParams =
	| {
			channel?: string
			event?: string
			message?: string
	  }
	| undefined

/**
 * Extract relevant data from jsonRPC notification
 * @param socket
 */
function extractMessageFromNotification(
	object: IParsedObjectNotification
): PubsubMessage | null {
	const params = object.payload.params as RpcParsedObjectNotificationParams
	const channelName = params?.channel
	const event = params?.event
	try {
		const message = params?.message ? JSON.parse(params?.message) : undefined
		if (channelName && event && message) {
			return { channelName, event, message }
		}
	} catch (e) {
		return null
	}
	return null
}

export function streamWebSocketMessageEventJsonRPC(handlers: {
	message?(message: PubsubMessage): void
	messageNotParseable?(params?: PubsubMessageParams): void
	sendAcknowledged?(id: string, payload: unknown): void
	sendErrored?(id: string, error: unknown): void
}) {
	return function stream(event: MessageEvent<any>) {
		let parsedObjects = jsonrpc.parse(event.data)
		if (!Array.isArray(parsedObjects)) {
			parsedObjects = [parsedObjects]
		}

		for (const parsed of parsedObjects) {
			switch (parsed.type) {
				case 'notification': {
					const message = extractMessageFromNotification(parsed)
					if (message) {
						handlers.message?.(message)
					} else {
						handlers.messageNotParseable?.(parsed.payload.params)
					}
					break
				}
				case 'success':
					handlers.sendAcknowledged?.(
						`${parsed.payload.id}`,
						parsed.payload.jsonrpc
					)
					break
				case 'error':
					handlers.sendErrored?.(`${parsed.payload.id}`, parsed.payload.error)
					break
				default:
					break
			}
		}
	}
}
