import { FC, ReactNode, SyntheticEvent } from 'react'
import styled, { useTheme } from 'styled-components'

import Button from '../AKButton'
import { ButtonKind, ButtonSize } from '../AKButton/types'
import { ArrowLeft } from '../AKIcons'
import SurfaceHeader from '../AKSurfaceHeader'

export type PageProps = {
	title: string
	endEnhancer?: ReactNode
	onNavigateBack?: (event: SyntheticEvent<HTMLButtonElement>) => void
}

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`

const Content = styled.div`
	position: relative;
	width: 100%;
	flex: 1 1 0;
	overflow: auto;
`

const ButtonLabel = styled.span`
	display: none;

	color: ${p => p.theme.colors.contentPrimary};
	${({ theme }) => theme.mediaQueries.sm} {
		display: initial;
	}
`

const Page: FC<PageProps> = ({
	onNavigateBack,
	endEnhancer,
	title,
	children,
}) => {
	const theme = useTheme()
	return (
		<Container>
			<SurfaceHeader
				useRoundedCorners={false}
				leftEnhancer={
					onNavigateBack && (
						<Button
							size={ButtonSize.Mini}
							kind={ButtonKind.Minimal}
							onClick={onNavigateBack}
							startEnhancer={<ArrowLeft color={theme.colors.iconFillDark} />}
						>
							<ButtonLabel>Back</ButtonLabel>
						</Button>
					)
				}
				rightEnhancer={endEnhancer}
			>
				{title}
			</SurfaceHeader>
			<Content>{children}</Content>
		</Container>
	)
}

export default Page
