import { createSlice } from '@reduxjs/toolkit'

import { createRequestAdapter, RequestAdapterState } from '../utils/slices'

type WebcoreKickstartState = {
	initialize: RequestAdapterState
}

const initializeRequestAdapter = createRequestAdapter({ name: 'initialize' })

const initialState: WebcoreKickstartState = {
	initialize: initializeRequestAdapter.getInitialState(),
}

export const WebCoreKickstart = createSlice({
	name: 'WebCoreKickstart',
	initialState,
	reducers: {
		...initializeRequestAdapter.caseReducers,
	},
})

export default WebCoreKickstart
