import {
	ApolloClient,
	InMemoryCache,
	NormalizedCacheObject,
	from,
	createHttpLink,
	split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { compact } from 'lodash'

import * as npmPackage from '../../../package.json'
import { Config } from '../../slices/config'
import { makeAuthorizationHeader } from '../../utils/apiHelper'

import { TypedTypePolicies } from './apollo-helpers'
import {
	EmojiList,
	ImageSearchResults,
	PagingOrder,
	ViewerImageSearchArgs,
	SphereEmojisArgs,
} from './schema.d'

// https://www.apollographql.com/docs/react/migrating/boost-migration/
const configureErrorLink = () =>
	onError(({ graphQLErrors, networkError }) => {
		if (networkError) {
			console.error(`[Network error]: ${networkError}`)
		}
		if (graphQLErrors)
			graphQLErrors.forEach(error => {
				console.error(
					`[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
				)
			})
	})

// https://www.apollographql.com/docs/react/networking/authentication/#header
const configureAuthorizationHeaderLink = (
	getAccessToken: () => string | null
) =>
	setContext((_, { headers }) => {
		const accessToken = getAccessToken()
		return {
			headers: {
				...headers,
				...(accessToken ? makeAuthorizationHeader(accessToken) : {}),
			},
		}
	})

const configureNetworkLinks = (
	config: Config,
	getAccessToken: () => string | null
) =>
	split(
		({ query }) => {
			const definition = getMainDefinition(query)
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			)
		},
		new WebSocketLink({
			uri: config.wsEndpoint,
			options: {
				// Don't connect until we make a subscription request
				lazy: true,
				reconnect: true,
				// The connectionParams only get sent in the initial request
				// When the access token expires we will continue to be authenticated
				// as long as the connection stays open
				connectionParams: () => ({
					authToken: getAccessToken(),
				}),
			},
		}),
		createHttpLink({
			uri: `${config.apiRoot}/graphql`,
			headers: {
				supportsnewchatservice: true,
			},
		})
	)

export type GQLClient = ReturnType<typeof configureClient>

const typePolicies: TypedTypePolicies = {
	Query: {
		fields: {
			viewer: {
				merge(existing, incoming, { mergeObjects }) {
					return mergeObjects(existing, incoming)
				},
			},
		},
	},
	Viewer: {
		fields: {
			imageSearch: {
				keyArgs: ['term'],
				merge(
					existing: ImageSearchResults | undefined,
					incoming: ImageSearchResults,
					{ args }
				) {
					const { after, before } =
						(args as ViewerImageSearchArgs)?.paging ?? {}
					const previousAfter = existing?.paging.cursors.after
					const previousBefore = existing?.paging.cursors.before

					const order = args?.paging?.order

					// We can't determine the order of the existing data unless
					// we always store ascending
					const incomingData =
						order === PagingOrder.Descending
							? [...(incoming?.data ?? [])].reverse()
							: incoming?.data

					const shouldAppend =
						typeof after === 'string' && after === previousAfter
					const shouldPrepend =
						typeof before === 'string' && before === previousBefore

					if (!shouldAppend && !shouldPrepend) {
						return {
							...incoming,
							data: incomingData,
						}
					}

					const nextData = shouldAppend
						? [...(existing?.data ?? []), ...(incomingData ?? [])]
						: [...(incomingData ?? []), ...(existing?.data ?? [])]

					return {
						...incoming,
						data: nextData,
					}
				},
			},
		},
	},
	Sphere: {
		fields: {
			membership: {
				merge(existing, incoming, { mergeObjects }) {
					return mergeObjects(existing, incoming)
				},
			},
			emojis: {
				keyArgs(args, { variables }) {
					const typedArgs = args as SphereEmojisArgs | null
					return compact([variables?.sphereId, typedArgs?.filter?.searchTerm])
				},
				merge(
					existing: EmojiList | undefined | null,
					incoming: EmojiList | undefined | null,
					{ args }
				) {
					const startAfter = (args as SphereEmojisArgs)?.startAfter
					const previousAfter = existing?.paging?.end

					const shouldAppend =
						typeof startAfter === 'string' && startAfter === previousAfter

					if (!shouldAppend) {
						return incoming
					}

					const nextData = [
						...(existing?.data ?? []),
						...(incoming?.data ?? []),
					]

					return {
						...incoming,
						data: nextData,
					}
				},
			},
		},
	},
	SphereEvent: {
		fields: {
			inviteeCounts: {
				merge(existing, incoming, { mergeObjects }) {
					return mergeObjects(existing, incoming)
				},
			},
			invitees: {
				merge(existing, incoming, { mergeObjects }) {
					return mergeObjects(existing, incoming)
				},
			},
			viewerCapabilities: {
				merge(existing, incoming, { mergeObjects }) {
					return mergeObjects(existing, incoming)
				},
			},
		},
	},
	SphereEventInvitees: {
		fields: {
			pending: {
				keyArgs: false,
			},
			going: {
				keyArgs: false,
			},
			thinking: {
				keyArgs: false,
			},
			notGoing: {
				keyArgs: false,
			},
		},
	},
	SphereMember: {
		keyFields: false,
	},
	SphereCapability: {
		keyFields: false,
	},
	SphereMemberInfo: {
		keyFields: false,
	},
	SenderInfo: {
		keyFields: ['attendant_id'],
	},
	AttendantInfo: {
		keyFields: ['attendant_id'],
	},
	Attendant: {
		keyFields: ['id', 'conversation_id'],
		fields: {
			capabilities: {
				merge(existing, incoming, { mergeObjects }) {
					return mergeObjects(existing, incoming)
				},
			},
		},
	},
	ChatCapabilities: {
		keyFields: false,
	},
	MessageContent: {
		keyFields: false,
	},
	Message: {
		fields: {
			content: {
				merge(existing, incoming, { mergeObjects }) {
					return mergeObjects(existing, incoming)
				},
			},
		},
	},
	FeatureSetting: {
		keyFields: ['name'],
	},
}

export const configureCache = () =>
	new InMemoryCache({
		typePolicies,
	})

const configureClient = (config: Config, getAccessToken: () => string | null) =>
	new ApolloClient<NormalizedCacheObject>({
		name: npmPackage.name,
		version: npmPackage.version,
		link: from([
			configureErrorLink(),
			configureAuthorizationHeaderLink(getAccessToken),
			configureNetworkLinks(config, getAccessToken),
		]),
		cache: configureCache(),
	})

export default configureClient
