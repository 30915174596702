import { gql } from '@apollo/client'

const LAST_MESSAGE_SENDER_INFO_FRAGMENT = gql`
	fragment lastMessageSenderInfo on SenderInfo {
		attendant_id
		display_name
		display_color
		display_image_filename
	}
`

const LAST_MESSAGE_FRAGMENT = gql`
	${LAST_MESSAGE_SENDER_INFO_FRAGMENT}
	fragment lastMessage on Message {
		id
		created_at
		sender_info {
			...lastMessageSenderInfo
		}
		content {
			text
			image_filename
			attachments {
				fileId
				mimeType
			}
		}
	}
`

export const OWN_ATTENDANT_FRAGMENT = gql`
	fragment ownAttendant on Attendant {
		id
		agent_id
		conversation_id
		display_name
		display_color
		display_image_filename
		unread_message_count
		role
		muted
		follows_conversation
		outstanding_important_messages
		last_seen_message_id
	}
`

export const TYPING_INDICATOR_ATTENDANT_INFO_FRAGMENT = gql`
	fragment typingIndicatorAttendantInfo on AttendantInfo {
		attendant_id
		conversationId
		display_name
	}
`

export const PRESENCE_ATTENDANT_INFO_FRAGMENT = gql`
	fragment presenceAttendantInfo on AttendantInfo {
		attendant_id
		conversationId
		online
		last_seen
	}
`

export const FEED_ITEM_FRAGMENT = gql`
	${LAST_MESSAGE_FRAGMENT}
	${OWN_ATTENDANT_FRAGMENT}
	fragment feedItem on ChatCard {
		id
		creator_id
		title
		description
		image_filename
		image_color
		attendant {
			...ownAttendant
		}
		created_at
		conversation_id
		chat_state
		chat_type
		private
		conversationType
		conversation {
			id
			attendant_count
			last_message {
				...lastMessage
			}
		}
	}
`

export const UNREAD_COUNT_CHAT_CARD_FRAGMENT = gql`
	fragment unreadCountChatCard on ChatCard {
		id
		attendant {
			id
			conversation_id
			unread_message_count
		}
	}
`

export const SPHERE_SUMMARY_INFO_FRAGMENT = gql`
	fragment sphereSummary on Sphere {
		id
		title
		description
		membership {
			agent_id
		}
		image_filename
		public
		badge_count
	}
`

export const SPHERE_FEED_INFO = gql`
	fragment sphereFeedInfo on Sphere {
		id
		title
		image_filename
		public
		badge_count
	}
`

export const SPHERE_MEMBER_INFO = gql`
	fragment sphereMemberInfo on Sphere {
		members {
			data {
				is_admin
				agent_id
				info {
					display_name
					display_color
					display_image_filename
				}
				capabilities {
					type
				}
			}
		}
		member_count
		membership {
			is_admin
			agent_id
			info {
				display_name
				display_color
				display_image_filename
			}
			capabilities {
				type
			}
		}
	}
`

export const MESSAGE_REACTION_FRAGMENT = gql`
	fragment messageReaction on MessageReaction {
		attendant_id
		display_name
		type
		agent_id
	}
`

/**
 * @deprecated use usecase specific fragments instead
 */
export const SPHERE_MEMBER_FRAGMENT = gql`
	fragment sphereMember on SphereMember {
		is_admin
		agent_id
		info {
			display_name
			display_color
			display_image_filename
			online
			last_seen
		}
		canBeDirectMessagedByViewer
		agent {
			person {
				about
			}
		}
		capabilities {
			type
		}
	}
`

export const FEED_EVENT_FRAGMENT = gql`
	fragment feedEvent on SphereEvent {
		id
		displayImageId
		name
		startDate
		endDate
		sphereId
		createdAt
		viewerInvitee {
			id
			rsvp
		}
		viewerCapabilities {
			rsvp {
				permitted
			}
		}
	}
`

/**
 * @deprecated use usecase specific fragments instead
 */
export const SPHERE_FRAGMENT = gql`
	${FEED_ITEM_FRAGMENT}
	${SPHERE_MEMBER_FRAGMENT}
	${FEED_EVENT_FRAGMENT}
	fragment fullSphere on Sphere {
		id
		title
		description
		image_filename
		created_at
		public
		chats {
			data {
				...feedItem
			}
		}
		chat_count
		members {
			data {
				...sphereMember
			}
		}
		member_count
		membership {
			...sphereMember
		}
		badge_count
		pendingUpcomingEvents {
			data {
				...feedEvent
			}
		}
	}
`

/**
 * @deprecated use usecase specific fragments instead
 */
export const ATTENDANT_INFO_FRAGMENT = gql`
	fragment attendantInfo on AttendantInfo {
		attendant_id
		conversationId
		role
		bio
		display_color
		display_image_filename
		display_name
	}
`

export const ATTENDANT_INFO_PROFILE_FRAGMENT = gql`
	fragment profileAttendantInfo on AttendantInfo {
		attendant_id
		conversationId
		display_name
		display_image_filename
		display_color
		bio
		online
		last_seen
		role
		canBeDirectMessagedByViewer
	}
`

export const CHAT_CARD_FRAGMENT = gql`
	${OWN_ATTENDANT_FRAGMENT}
	fragment chatCard on ChatCard {
		id
		conversation_id
		attendant {
			...ownAttendant
		}
		conversationType
		conversation {
			id
			activity_info {
				recently_active
			}
		}
		title
		description
		image_filename
		image_color
		chat_state
		private
		spheres {
			id
		}
	}
`

export const CAPABILITY_FRAGMENT = gql`
	fragment capability on Capability {
		permitted
	}
`

export const CHAT_VIEW_CAPABILITIES_FRAGMENT = gql`
	${CAPABILITY_FRAGMENT}
	fragment chatViewCapabilities on Attendant {
		id
		capabilities {
			sendMessage {
				...capability
			}
			reactToMessage {
				...capability
			}
			addAppreciationReactionToMessage {
				...capability
			}
			removeAppreciationReactionFromMessage {
				...capability
			}
			pinMessageToAdminPinboard {
				...capability
			}
			removeOwnMessage {
				...capability
			}
			removeAnyMessage {
				...capability
			}
			replyToMessage {
				...capability
			}
			actionImportantMessage {
				...capability
			}
			sendPollMessage {
				permitted
				importantPermitted
				importantDefault
			}
			sendAnnouncementMessage {
				...capability
			}
		}
	}
`

export const CHAT_VIEW_FRAGMENT = gql`
	${CHAT_CARD_FRAGMENT}
	${CHAT_VIEW_CAPABILITIES_FRAGMENT}
	fragment chatView on ChatCard {
		...chatCard
		attendant {
			id
			...chatViewCapabilities
		}
	}
`

export const EVENT_INFO_INVITEE_FRAGMENT = gql`
	fragment eventInfoInvitee on SphereEventInvitee {
		id
		rsvp
		displayName
		displayColor
		displayImageId
		agentId
	}
`

export const RSVP_EVENT_FRAGMENT = gql`
	${EVENT_INFO_INVITEE_FRAGMENT}
	fragment rsvpEvent on SphereEvent {
		id
		sphereId
		inviteeCounts {
			pending
			going
			thinking
			notGoing
		}
		viewerInvitee {
			...eventInfoInvitee
		}
	}
`

export const CHAT_CARD_CHAT_HEADER_FRAGMENT = gql`
	fragment chatCardChatHeader on ChatCard {
		id
		title
		image_filename
		image_color
		private
		conversationType
		sphereEventId
		attendant {
			id
			conversation_id
			follows_conversation
		}
		conversation {
			id
			attendant_count
			activity_info {
				recently_active
			}
		}
	}
`

export const ATTENDANT_PRESENCE_FRAGMENT = gql`
	fragment attendantPresence on AttendantPresence {
		attendant_id
		last_seen
		online
	}
`

export const CHAT_INFO_VIEWER_ATTENDANT_FRAGMENT = gql`
	fragment chatInfoViewerAttendant on Attendant {
		id
		conversation_id
		display_name
		display_color
		display_image_filename
		role
		muted
		follows_conversation
		# TODO: make this better filterable and improve the capabilities use
		capabilities {
			addMembersToChat {
				permitted
			}
			removeAttendantsFromChat {
				permitted
			}
			unfollowChat {
				permitted
			}
			setChatTitle {
				permitted
			}
			setChatImage {
				permitted
			}
			endChat {
				permitted
			}
		}
	}
`

export const CHAT_INFO_ATTENDANT_INFO_FRAGMENT = gql`
	fragment chatInfoAttendantInfo on AttendantInfo {
		attendant_id
		conversationId
		display_name
		display_image_filename
		display_color
		role
		online
		last_seen
		canBeRemovedByViewer
	}
`

export const CHAT_CARD_CHAT_INFO_FRAGMENT = gql`
	fragment chatCardChatInfo on ChatCard {
		id
		title
		description
		image_filename
		image_color
		chat_state
		private
		conversation_id
		attendant {
			...chatInfoViewerAttendant
		}
		conversationType
		conversation {
			id
			attendants_info {
				...chatInfoAttendantInfo
			}
		}
	}
	${CHAT_INFO_VIEWER_ATTENDANT_FRAGMENT}
	${CHAT_INFO_ATTENDANT_INFO_FRAGMENT}
`

export const CHAT_HEADER_PRESENCE_ATTENDANT_INFO_FRAGMENT = gql`
	fragment chatHeaderPresenceAttendantInfoFragment on AttendantInfo {
		attendant_id
		conversationId
		online
		last_seen
	}
`

export const ADD_MEMBER_LIST_ADDABLE_MEMBER_FRAGMENT = gql`
	fragment addMemberListAddableMemberFragment on SphereMember {
		agent_id
		info {
			display_name
			display_color
			display_image_filename
			online
			last_seen
		}
	}
`

export const DM_MEMBER_LIST_SPHERE_MEMBER_FRAGMENT = gql`
	fragment dmMemberListSphereMemberFragment on SphereMember {
		agent_id
		info {
			display_name
			display_color
			display_image_filename
			online
			last_seen
		}
	}
`

export const SPHERE_EVENT_LIST_EVENT_FRAGMENT = gql`
	fragment sphereEventListEvent on SphereEvent {
		id
		sphereId
		displayImageId
		name
		createdAt
		startDate
		endDate
		viewerInvitee {
			id
			agentId
			rsvp
		}
		viewerCapabilities {
			rsvp {
				permitted
			}
		}
	}
`

export const SPHERE_VIEW_FRAGMENT = gql`
	fragment sphereView on Sphere {
		adminPinboard {
			cardId
			conversationId
		}
	}
`

export const APPRECIATION_FRAGMENT = gql`
	fragment appreciationFragment on Appreciation {
		id
		sphereId
		name
		color
		active
		emoji {
			id
			imageId
		}
	}
`

export const EMOJI_FRAGMENT = gql`
	fragment emojiFragment on Emoji {
		id
		unicode
		name
		category
		imageId
	}
`
