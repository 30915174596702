import type firebase from 'firebase'

import { FeedItem } from '../../domain/ContentFeed'
import { SphereEventRsvp } from '../../domain/SphereEventInvitee'
import { purifyTimestamps } from '../../utils/firebase'

import {
	FeedItem as FeedItemFS,
	SphereEventRsvp as SphereEventRsvpFS,
	FeedItemType as FeedItemTypeFS,
} from './types'

function convertSphereEventRsvp(
	input: SphereEventRsvpFS | null
): SphereEventRsvp | null {
	switch (input) {
		case null:
			return null
		case SphereEventRsvpFS.Going:
			return SphereEventRsvp.Going
		case SphereEventRsvpFS.NotGoing:
			return SphereEventRsvp.NotGoing
		case SphereEventRsvpFS.Thinking:
			return SphereEventRsvp.Thinking
	}
}

export function toFeedItem(item: FeedItemFS, id: string): FeedItem {
	const input: FeedItemFS = purifyTimestamps(item)
	switch (input.type) {
		case FeedItemTypeFS.SphereChat:
			return { ...input, id }
		case FeedItemTypeFS.SphereEvent:
			return {
				...input,
				eventInfo: {
					...input.eventInfo,
					rsvp: convertSphereEventRsvp(input.eventInfo.rsvp),
				},
				id,
			}
	}
}

export type ContentFeedQueryResult =
	firebase.firestore.QuerySnapshot<FeedItemFS>

export function convertQueryResultToContentFeed(
	result: ContentFeedQueryResult
): FeedItem[] {
	return result.docs.map(doc => toFeedItem(doc.data(), doc.id))
}
