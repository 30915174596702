/** AUTOGENERATED: DO NOT EDIT
Change ./generateTokens.js instead.
Then run:
yarn generate-colors

Inspired by https://github.com/uber/baseweb/blob/master/src/tokens/colors.js and
https://github.com/twbs/bootstrap/blob/6914efe8dd73ce403a9e04a43a05fe4642ad87f9/scss/_variables.scss
*/

const tokens = {
	// sky
	sky: '#5ECBF8',
	sky100: '#def4fd',
	sky200: '#7ed5f9',
	sky300: '#6ed0f8',
	sky400: '#66cdf8',
	sky500: '#5ECBF8',
	sky600: '#59c0eb',
	sky700: '#54b6df',
	sky800: '#4ba2c6',
	sky900: '#122831',
	// charcoal
	charcoal: '#202020',
	charcoal100: '#d2d2d2',
	charcoal200: '#4c4c4c',
	charcoal300: '#363636',
	charcoal400: '#2b2b2b',
	charcoal500: '#202020',
	charcoal600: '#1e1e1e',
	charcoal700: '#1c1c1c',
	charcoal800: '#191919',
	charcoal900: '#060606',
	// slate
	slate: '#B1B3B3',
	slate100: '#efefef',
	slate200: '#c0c2c2',
	slate300: '#b8baba',
	slate400: '#b4b6b6',
	slate500: '#B1B3B3',
	slate600: '#a8aaaa',
	slate700: '#9fa1a1',
	slate800: '#8d8f8f',
	slate900: '#232323',
	// silver
	silver: '#F2F2F2',
	silver100: '#fcfcfc',
	silver200: '#f4f4f4',
	silver300: '#f3f3f3',
	silver400: '#f2f2f2',
	silver500: '#F2F2F2',
	silver600: '#e5e5e5',
	silver700: '#d9d9d9',
	silver800: '#c1c1c1',
	silver900: '#303030',
	// cloud
	cloud: '#FAFAFA',
	cloud100: '#fefefe',
	cloud200: '#fbfbfb',
	cloud300: '#fafafa',
	cloud400: '#fafafa',
	cloud500: '#FAFAFA',
	cloud600: '#ededed',
	cloud700: '#e1e1e1',
	cloud800: '#c8c8c8',
	cloud900: '#313131',
	// snow
	snow: '#FFFFFF',
	snow100: '#fff',
	snow200: '#fff',
	snow300: '#fff',
	snow400: '#fff',
	snow500: '#FFFFFF',
	snow600: '#f2f2f2',
	snow700: '#e5e5e5',
	snow800: '#ccc',
	snow900: '#323232',
	// royal
	royal: '#9C87FE',
	royal100: '#ebe7fe',
	royal200: '#af9ffe',
	royal300: '#a593fe',
	royal400: '#a08dfe',
	royal500: '#9C87FE',
	royal600: '#9480f1',
	royal700: '#8c79e4',
	royal800: '#7c6ccb',
	royal900: '#1f1a32',
	// autumn
	autumn: '#FD7D63',
	autumn100: '#fee5df',
	autumn200: '#fd9782',
	autumn300: '#fd8a72',
	autumn400: '#fd836a',
	autumn500: '#FD7D63',
	autumn600: '#f0765e',
	autumn700: '#e37059',
	autumn800: '#ca644f',
	autumn900: '#321813',
	// sun
	sun: '#FFC014',
	sun100: '#fff2d0',
	sun200: '#ffcc43',
	sun300: '#ffc62b',
	sun400: '#ffc31f',
	sun500: '#FFC014',
	sun600: '#f2b613',
	sun700: '#e5ac12',
	sun800: '#cc9910',
	sun900: '#322603',
	// water
	water: '#5B82FF',
	water100: '#dee6ff',
	water200: '#7b9bff',
	water300: '#6b8eff',
	water400: '#6388ff',
	water500: '#5B82FF',
	water600: '#567bf2',
	water700: '#5175e5',
	water800: '#4868cc',
	water900: '#121932',
	// grass
	grass: '#9BE06E',
	grass100: '#ebf8e2',
	grass200: '#afe68b',
	grass300: '#a5e37c',
	grass400: '#a0e175',
	grass500: '#9BE06E',
	grass600: '#93d468',
	grass700: '#8bc963',
	grass800: '#7cb358',
	grass900: '#1e2c15',
	// fire
	fire: '#F86A5F',
	fire100: '#fde1df',
	fire200: '#f9877f',
	fire300: '#f8786f',
	fire400: '#f87167',
	fire500: '#F86A5F',
	fire600: '#eb645a',
	fire700: '#df5f55',
	fire800: '#c6544c',
	fire900: '#311512',
}

export default tokens
export type TokensT = typeof tokens
