import jwt from 'jsonwebtoken'

import { now } from './time'

export function getPropertyFromJWT(token: string, key: string): unknown {
	const decodedToken = jwt.decode(token)

	if (!decodedToken || typeof decodedToken === 'string') {
		return null
	}

	return decodedToken[key]
}

export function getExpiresAtMsFromJWT(token: string): number | null {
	const exp = getPropertyFromJWT(token, 'exp')

	if (typeof exp !== 'number') {
		return null
	}

	// There was an error in the way we implemented this claim:
	// https://git.sph.re/backend/auth/-/merge_requests/97
	// This logic makes it compatible with both implementation and should
	// be removed once non compliant tokens will all have been expired.
	if (exp < 1600000000000) {
		return exp * 1000
	}
	return exp
}

/**
 * Checks a jwt for it's expiration date
 * Note that the expiration time is expected to be encoded as an `exp` property
 * on the token, as per the standard expiration time claim:
 * https://tools.ietf.org/html/rfc7519#section-4.1
 * if `exp` is missing, the token is considered expired
 * @param jwt The jsonWebToken to decode for an expiration time
 * @param getCurrentTime optional method for getting the current time in MS, defaults to Date.now
 */
export function checkIfTokenHasExpired(
	jwt: string,
	getCurrentTime: () => number = now
): boolean {
	const expiresAt = getExpiresAtMsFromJWT(jwt)
	if (expiresAt === null) {
		return true
	}
	return getCurrentTime() >= expiresAt
}
