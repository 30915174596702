import { eventChannel, EventChannel } from 'redux-saga'
import {
	apply,
	call,
	delay,
	getContext,
	put,
	race,
	select,
	take,
	takeEvery,
} from 'typed-redux-saga/macro'

import {
	GetSocketChannelTokenForChatCardByIdQuery,
	GetSocketChannelTokenForChatCardByIdQueryVariables,
} from '../network/graphql'
import { GQLClient } from '../network/graphql/configureClient'
import { GET_SOCKET_CHANNEL_TOKEN_FOR_CHAT_CARD_BY_ID } from '../network/graphql/queries'

import { GQL_CLIENT_SAGA_CONTEXT_KEY } from '../constants/config'
import { PubsubChannelTokenFetcher } from '../network/pubsub/PubsubChannelMaintainer'
import { PubsubClient } from '../network/pubsub/PubsubClient'
import { PubsubMessage } from '../network/pubsub/types'
import auth from '../slices/auth'
import pubsub from '../slices/pubsub'
import { toSafeError } from '../utils/error'

const {
	subscribeToConversationChannelRequest,
	subscribeToConversationChannelSuccess,
	subscribeToConversationChannelFailure,
	unsubscribeFromConversationChannelRequest,
	unsubscribeFromConversationChannelSuccess,
	unsubscribeFromConversationChannelFailure,
	publishMessageRequest,
	publishMessageSuccess,
	publishMessageFailure,
	messageReceived,
	websocketErrorOccured,
	clientErrorOccured,
	clientInitialized,
	clientClosed,
	websocketOpened,
	websocketClosed,
} = pubsub.actions

type ReceiveChannelActions =
	| { type: 'message'; message: PubsubMessage }
	| { type: 'event'; event: 'open' | 'close' }
	| { type: 'error'; error: string }

type ReceiveChannel = EventChannel<ReceiveChannelActions>

/**
 * Turn WebSocket events into an EventChannel our sagas can handle better
 */
function PubsubMessagesEventChannelAdapter(
	pubsubClient: PubsubClient
): ReceiveChannel {
	return eventChannel<ReceiveChannelActions>(emit => {
		pubsubClient.registerListeners({
			onEvent(eventName) {
				emit({ type: 'event', event: eventName })
			},
			onError() {
				emit({ type: 'error', error: 'websocket error' })
			},
			onMessage(message) {
				emit({ type: 'message', message })
			},
			onMessageNotParseable(params) {
				emit({ type: 'error', error: 'message not parseable' })
			},
		})
		return pubsubClient.close.bind(pubsubClient)
	})
}

export function createPubsubClient(endpoint: string, accessToken: string) {
	return new PubsubClient(endpoint, accessToken)
}

function conversationChannelTokenFetcherFactory(
	gqlClient: GQLClient,
	conversationId: string
): PubsubChannelTokenFetcher {
	return async function conversationChannelTokenFetcher() {
		const result = await gqlClient.query<
			GetSocketChannelTokenForChatCardByIdQuery,
			GetSocketChannelTokenForChatCardByIdQueryVariables
		>({
			query: GET_SOCKET_CHANNEL_TOKEN_FOR_CHAT_CARD_BY_ID,
			variables: {
				id: conversationId,
			},
		})

		const channelToken =
			result.data?.viewer?.chatCard?.conversation?.socket_channel_token
		if (!channelToken) {
			throw new Error('Unable to refresh conversation socket channel token')
		}
		return channelToken
	}
}

function* subscribeToConversationChannelOnPubsubClient(
	pubsubClient: PubsubClient,
	channelName: string,
	conversationId: string
) {
	const gqlClient = yield* getContext(GQL_CLIENT_SAGA_CONTEXT_KEY)
	const tokenFetcher = conversationChannelTokenFetcherFactory(
		gqlClient,
		conversationId
	)

	try {
		yield* apply(pubsubClient, pubsubClient.subscribeToChannel, [
			channelName,
			tokenFetcher,
		])
		yield* put(subscribeToConversationChannelSuccess({ channelName }))
	} catch (e) {
		yield* put(subscribeToConversationChannelFailure({ channelName }))
	}
}

/**
 * (Un)subscribtion to pubsub channels
 */
function* subscribing(pubsubClient: PubsubClient) {
	// wait for the socket to have opened
	yield* take(websocketOpened.type)

	// potenially resubscribe in case we lost connectivity
	const channels = yield* select(state => state.pubsub.channels)
	for (const channelName in channels) {
		yield* call(
			subscribeToConversationChannelOnPubsubClient,
			pubsubClient,
			channelName,
			channels[channelName].conversationId
		)
	}

	yield* takeEvery(
		[
			subscribeToConversationChannelRequest.type,
			unsubscribeFromConversationChannelRequest.type,
		],
		function* (action) {
			if (subscribeToConversationChannelRequest.match(action)) {
				yield* call(
					subscribeToConversationChannelOnPubsubClient,
					pubsubClient,
					action.payload.channelName,
					action.payload.conversationId
				)
			} else if (unsubscribeFromConversationChannelRequest.match(action)) {
				const { channelName } = action.payload
				try {
					yield* apply(pubsubClient, pubsubClient.unsubscribeFromChannel, [
						channelName,
					])
					yield* put(unsubscribeFromConversationChannelSuccess({ channelName }))
				} catch (e) {
					yield* put(unsubscribeFromConversationChannelFailure({ channelName }))
				}
			}
			yield
		}
	)
}

/**
 * Publication of typing events
 */
function* publishing(pubsubClient: PubsubClient) {
	// wait for the socket to have opened
	yield* take(websocketOpened.type)

	yield* takeEvery([publishMessageRequest.type], function* (action) {
		if (publishMessageRequest.match(action)) {
			const { id } = action.payload
			const { channelName, event, message } = action.payload
			try {
				const ack = yield* apply(pubsubClient, pubsubClient.publish, [
					channelName,
					{
						event,
						message,
					},
				])
				yield* put(publishMessageSuccess({ id, message: ack.message }))
			} catch (e) {
				yield* put(publishMessageFailure({ id }))
			}
		}
	})
}

/**
 * Handle web socket event stream and potenially split RPC reponses
 * @param pubsubReceiveChannel redux-saga channel of the incoming messages from a websocket
 */
function* receiving(pubsubReceiveChannel: ReceiveChannel) {
	receivingLoop: while (true) {
		const receivedEvent = yield* take(pubsubReceiveChannel)
		switch (receivedEvent.type) {
			case 'error':
				yield* put(
					websocketErrorOccured({ error: new Error(receivedEvent.error) })
				)
				break
			case 'event':
				if (receivedEvent.event === 'close') {
					yield* put(websocketClosed())
					break receivingLoop
				} else {
					yield* put(websocketOpened())
				}
				break
			case 'message': {
				const { channelName, event, message } = receivedEvent.message
				if (channelName && event && message) {
					const action = messageReceived({ channelName, event, message })
					yield* put(action)
				}
			}
		}
	}
}

/**
 * Cancelable saga for the pubsub websocket
 * @param {string} endpoint
 * @param {string} accessToken
 */
export function* pubsubRunner(endpoint: string, accessToken: string) {
	let pubsubClient: PubsubClient | null = null
	try {
		pubsubClient = yield* call(createPubsubClient, endpoint, accessToken)
		const rawReceiveChannel = yield* call(
			PubsubMessagesEventChannelAdapter,
			pubsubClient!
		)

		yield* put(clientInitialized())
		// in case on of the 3 terminates, the whole client will terminate
		yield* race({
			subscribing: call(subscribing, pubsubClient!),
			publishing: call(publishing, pubsubClient!),
			receiving: call(receiving, rawReceiveChannel),
		})
	} catch (unknownError) {
		const error = toSafeError(unknownError)
		yield* put(clientErrorOccured({ error }))
	} finally {
		// close potential left-overs
		pubsubClient?.close()
		yield* put(clientClosed())
	}
}

/**
 *The Pubsub Saga
 *Waiting for local auth token to create a websocket
 */
export function* pubsubSaga(delayMs = 1500) {
	while (true) {
		let accessToken = yield* select(store => store.auth.accessToken)

		if (!accessToken) {
			const authSuccess = yield* take(
				auth.actions.authTokenRefreshSuccess.match
			)
			accessToken = authSuccess.payload.accessToken
		}

		const endpoint = yield* select(store => store.config.pubsubEndpoint)

		yield* race({
			runner: call(pubsubRunner, endpoint, accessToken),
			signout: take(auth.actions.signOutSuccess.type),
		})

		// delay in case of an error/signout to let network settle in case of a network timeout
		// should move to exponential backoff?
		yield* delay(delayMs)
	}
}

export default pubsubSaga
