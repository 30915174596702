import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProfileState {
	imageUploadObjectURL: string | null
}

const initialState: ProfileState = {
	imageUploadObjectURL: null,
}

export const profile = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		profileImageUploadInitiate(
			state,
			action: PayloadAction<{
				objectURL: string
				file: File
				updateProfileImage(imageFilename: string): Promise<any>
			}>
		) {
			state.imageUploadObjectURL = action.payload.objectURL
		},
		profileImageUploadClear(
			state,
			action: PayloadAction<{
				objectURL: string
			}>
		) {
			state.imageUploadObjectURL = null
		},
	},
})

export default profile
