import {
	ThemeColorToken,
	ThemeColorTokens,
	ColorOrColorToken,
} from './themes/types'
import typography, { fontTokens } from './typography'
import utils from './utils'

export type { ThemeColorToken as ColorToken, ColorOrColorToken }

const UNIT = 4

const sizing = {
	scale0: `${0.25 * UNIT}px`,
	scale50: `${0.5 * UNIT}px`,
	scale100: `${1 * UNIT}px`,
	scale200: `${1.5 * UNIT}px`,
	scale300: `${2 * UNIT}px`,
	scale400: `${2.5 * UNIT}px`,
	scale500: `${3 * UNIT}px`,
	scale550: `${3.5 * UNIT}px`,
	scale600: `${4 * UNIT}px`,
	scale650: `${4.5 * UNIT}px`,
	scale700: `${5 * UNIT}px`,
	scale750: `${5.5 * UNIT}px`,
	scale800: `${6 * UNIT}px`,
	scale850: `${7 * UNIT}px`,
	scale900: `${8 * UNIT}px`,
	scale1000: `${10 * UNIT}px`,
	scale1200: `${12 * UNIT}px`,
	scale1300: `${13 * UNIT}px`,
	scale1400: `${14 * UNIT}px`,
	scale1600: `${16 * UNIT}px`,
	scale1800: `${18 * UNIT}px`,
	scale2000: `${20 * UNIT}px`,
	scale2400: `${24 * UNIT}px`,
	scale3200: `${32 * UNIT}px`,
	scale4800: `${48 * UNIT}px`,
	scale9200: `${92 * UNIT}px`,
}

const breakpoints = {
	xs: 0,
	sm: 476,
	md: 672,
	lg: 992,
	xl: 1200,
	xxl: 1600,
}

const getMediaQuery = (breakpoint: number): string =>
	`@media screen and (min-width: ${breakpoint}px)`

const mediaQueries = {
	sm: getMediaQuery(breakpoints.sm),
	md: getMediaQuery(breakpoints.md),
	lg: getMediaQuery(breakpoints.lg),
	xl: getMediaQuery(breakpoints.xl),
	xxl: getMediaQuery(breakpoints.xxl),
}

const borders = {
	border100: {
		borderColor: 'hsla(0, 0%, 0%, 0.04)',
		borderStyle: 'solid',
		borderWidth: '1px',
	},
	border200: {
		borderColor: 'hsla(0, 0%, 0%, 0.1)',
		borderStyle: 'solid',
		borderWidth: '1px',
	},
	border300: {
		borderColor: 'hsla(0, 0%, 0%, 0.1)',
		borderStyle: 'solid',
		borderWidth: '2px',
	},
	border400: {
		borderColor: 'hsla(0, 0%, 0%, 0.2)',
		borderStyle: 'solid',
		borderWidth: '2px',
	},
	radius200: '6px',
	radius300: '8px',

	// Buttons
	buttonBorderRadius: '23px',
	// Well, Modal, Toast, Notification
	surfaceBorderRadius: '8px',
	popoverBorderRadius: '8px',

	// Checkbox, badge, tag, radios
	badgeRadius: '12px',
	tagRadius: '6px',
	checkBoxRadius: '32px',

	// Inputs
	imageInputRadius: '24px',
	messageInputRadius: '22px',

	/**
	 * This is intended to be used to create a "pill" shape,
	 * equivalent to a radius 0.5x the smaller side of the element.
	 * It relies on border-radius' corner-curve overlap avoidance.
	 * border-radius scales non-percantage values down until none
	 * of the radii overlap on either axis.
	 * https://drafts.csswg.org/css-backgrounds-3/#corner-overlap
	 *
	 * n.b. This effect scales all corners by the same value;
	 * using this radius for only some of the corners of an element
	 * may result in unexpected behaviour
	 *
	 * n.b. It will not work if the element's smaller side is larger than 2x the
	 * value of this radius.
	 */
	forcPillShapeRadius: '10000px',
}

const lighting = {
	shadow100: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
	shadow200: '0 4px 8px rgba(0, 0, 0, 0.3)',
	shadow600: '0 4px 16px hsla(0, 0%, 0%, 0.16)',
}

const grid = {
	maxWidth: 740,
	gutter: 20,
	margin: 20,
}

const layout = {
	headerHeight: 60,
	maxScrollbarWidth: 50,
	sphereTrayDesktopWidth: 86,
	feedMaxWidth: 368,
	feedMinWidth: 300,
	feedItemHeight: 72,
	chatViewMinWidth: 320,
	notDesktopLayoutMediaQuery: mediaQueries.md.replace('@media', '@media not'),
	desktopLayoutMediaQuery: mediaQueries.md,
	tooltipMaxWidth: 320,
	sphereHeaderMinHeight: 52,
	tabMinWidth: 120,
	inlineDefaultGap: 4,
}

const dialog = {
	minWidth: 360,
	maxWidth: 430,
	bodyMaxWidth: 320,
}

const animation = {
	timing200: '0.2s',
	timing400: '0.4s',
}

const menu = {
	width: 280,
}

const list = {
	minHeight: 50,
}

const Z = {
	newMessagesBanner: 1,
	gotoConversationBottomButton: 2,
}

const primitives = {
	UNIT,
	fontTokens,
}

type Behaviour = {
	elevateByShadows: boolean
}

const defaultBehaviour: Behaviour = {
	elevateByShadows: true,
}

function createTheme(colors: ThemeColorTokens, behaviour = defaultBehaviour) {
	return {
		animation,
		borders,
		breakpoints,
		colors,
		dialog,
		grid,
		layout,
		lighting,
		list,
		mediaQueries,
		menu,
		primitives,
		sizing,
		typography,
		utils,
		Z,
		behaviour,
	}
}

export type SphereTheme = ReturnType<typeof createTheme>

export default createTheme
