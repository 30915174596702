import { FC } from 'react'

import { HiddenInput, Slider, ToggleContainer } from './styled-components'
import { Props } from './types'

const Toggle: FC<Props> = props => (
	<ToggleContainer onClick={props.onClick}>
		<HiddenInput
			type="checkbox"
			checked={props.checked}
			disabled={props.disabled}
			readOnly
		/>
		<Slider />
	</ToggleContainer>
)

export default Toggle
