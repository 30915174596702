import { FC, PropsWithChildren, ReactNode } from 'react'

import { ActionsLeft, ActionsRight, Title, Root } from './styled-components'

export type SurfaceHeaderPropsT = PropsWithChildren<{
	useRoundedCorners?: boolean
	rightEnhancer?: ReactNode
	leftEnhancer?: ReactNode
}>

const SurfaceHeader: FC<SurfaceHeaderPropsT> = ({
	children,
	leftEnhancer = null,
	rightEnhancer = null,
	useRoundedCorners = true,
}) => {
	const giveActionsSpace = rightEnhancer || leftEnhancer

	return (
		<Root $useRoundedCorners={useRoundedCorners}>
			{giveActionsSpace && <ActionsLeft>{leftEnhancer}</ActionsLeft>}
			<Title>{children}</Title>
			{giveActionsSpace && <ActionsRight>{rightEnhancer}</ActionsRight>}
		</Root>
	)
}

export default SurfaceHeader
