import url from 'url'

import qs, { IStringifyOptions } from 'qs'

type ResolvePathOptions = {
	queryParamOptions?: IStringifyOptions
	stripTrailingSlash?: boolean
}

const DEFAULT_QUERY_PARAM_OPTIONS: IStringifyOptions = {
	// The Sphere RESTful api expects comma seperated arrays, eg 'a=b,c'
	// Other options are:
	// - 'indices': 'a[0]=b&a[1]=c'
	// - 'brackets': 'a[]=b&a[]=c'
	// - 'repeat': 'a=b&a=c'
	arrayFormat: 'comma',
}

// https://github.com/contiamo/restful-react/blob/master/src/util/constructUrl.test.ts
export function constructUrl<TQueryParams extends object>(
	base: string,
	path: string,
	queryParams?: TQueryParams,
	resolvePathOptions: ResolvePathOptions = {}
) {
	const { queryParamOptions, stripTrailingSlash } = resolvePathOptions

	const normalizedBase = base.endsWith('/') ? base : `${base}/`
	const trimmedPath = path.startsWith('/') ? path.slice(1) : path

	const encodedPathWithParams = Object.keys(queryParams || {}).length
		? `${trimmedPath}?${qs.stringify(queryParams, {
				...DEFAULT_QUERY_PARAM_OPTIONS,
				...queryParamOptions,
		  })}`
		: trimmedPath

	const composed = encodedPathWithParams
		? url.resolve(normalizedBase, encodedPathWithParams)
		: normalizedBase

	return stripTrailingSlash && composed.endsWith('/')
		? composed.slice(0, -1)
		: composed
}
