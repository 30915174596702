import { AnyAction } from 'redux'
import {
	put,
	take,
	getContext,
	call,
	select,
	takeLatest,
	fork,
	cancel,
} from 'typed-redux-saga/macro'

import {
	MarkLatestMessageAsSeenMutation,
	MarkLatestMessageAsSeenMutationVariables,
} from '../network/graphql'
import { GQLClient } from '../network/graphql/configureClient'
import { MARK_LATEST_MESSAGE_AS_SEEN } from '../network/graphql/mutations'
import { seeConversationOptimisticUpdate } from '../network/graphql/optimisticUI'

import { GQL_CLIENT_SAGA_CONTEXT_KEY } from '../constants/config'
import { Message as FSMessage } from '../network/firestore'
import { getFetchedAllAscendingForConversation } from '../selectors/conversations'
import conversationMessagesPagination from '../slices/conversationMessagesPagination'
import conversations, { conversationsSelectors } from '../slices/conversations'
import conversationsUI, {
	conversationsUISelectors,
} from '../slices/conversationsUI'
import { actions as environmentActions } from '../slices/environment'
import messagesByConversation from '../slices/messagesByConversation'
import { toSafeError } from '../utils/error'

type ConversationUIEnterAction = ReturnType<
	typeof conversationsUI.actions.conversationUIEnter
>

function markConversationAsSeenMutation(
	client: GQLClient,
	conversationId: string,
	chatCardId?: string
) {
	return client.mutate<
		MarkLatestMessageAsSeenMutation,
		MarkLatestMessageAsSeenMutationVariables
	>({
		mutation: MARK_LATEST_MESSAGE_AS_SEEN,
		variables: { conversation_id: conversationId },
		update: seeConversationOptimisticUpdate(chatCardId),
	})
}

export function* markConversationAsSeenSaga(
	conversationId: string,
	chatCardId?: string
) {
	yield* put(
		conversations.actions.conversationMarkAsSeenRequest({
			conversationId,
		})
	)

	try {
		const gqlClient = yield* getContext(GQL_CLIENT_SAGA_CONTEXT_KEY)

		const event = yield* call(
			markConversationAsSeenMutation,
			gqlClient,
			conversationId,
			chatCardId
		)

		if (event.data) {
			yield* put(
				conversations.actions.conversationMarkAsSeenSuccess({
					conversationId,
				})
			)
			return
		} else {
			yield* put(
				conversations.actions.conversationMarkAsSeenFailure({
					conversationId,
					error: new Error(event.errors?.join(' ') ?? 'Something went wrong'),
				})
			)
		}
	} catch (unknownError) {
		const error = toSafeError(unknownError)
		yield* put(
			conversations.actions.conversationMarkAsSeenFailure({
				conversationId,
				error,
			})
		)
	}
}

function* markConversationAsSeenWhenConversationIsInViewSaga(
	conversationId: string,
	chatCardId?: string
) {
	const disableMarkMessagesAsSeenDebugValue = yield* select(
		state => state.debug.disableMarkMessagesAsSeen
	)
	if (disableMarkMessagesAsSeenDebugValue) {
		return
	}

	// Wait until the application is visible and all the unread
	// messages have been fetched
	let environmentIsVisible = yield* select(state => state.environment.isVisible)
	let hasFetchedAllAscending = yield* select(state =>
		getFetchedAllAscendingForConversation(state, { conversationId })
	)

	while (!environmentIsVisible || !hasFetchedAllAscending) {
		const action = yield* take()

		if (environmentActions.setIsVisible.match(action)) {
			environmentIsVisible = action.payload
		} else if (
			conversationMessagesPagination.actions.paginationSuccess.match(action) &&
			action.payload.conversationId === conversationId
		) {
			hasFetchedAllAscending = yield* select(state =>
				getFetchedAllAscendingForConversation(state, { conversationId })
			)
		}
	}

	// Check if the converstion is still active
	const conversationIsActive = yield* select(
		state => conversationsUISelectors.selectById(state, conversationId)?.active
	)
	if (!conversationIsActive) {
		return
	}

	yield* call(markConversationAsSeenSaga, conversationId, chatCardId)
}

function* conversationSeeLatestMessageSaga(action: ConversationUIEnterAction) {
	const { conversationId, chatCardId } = action.payload

	// Mark the unread messages as seen
	let lastTask = yield* fork(
		markConversationAsSeenWhenConversationIsInViewSaga,
		conversationId,
		chatCardId
	)

	// Mark each new socketed message as seen
	while (true) {
		const lastSeenMessageId = yield* select(
			state =>
				conversationsSelectors.selectById(state, conversationId)
					?.lastSeenMessageId
		)
		yield* take((action: AnyAction) => {
			if (
				messagesByConversation.actions.conversationMessagesUpdate.match(action)
			) {
				return (
					action.payload.conversationId === conversationId &&
					(action.payload.messages as FSMessage[]).some(
						message => message.id !== lastSeenMessageId
					)
				)
			}
			return false
		})

		if (lastTask) {
			yield* cancel(lastTask)
		}
		lastTask = yield* fork(
			markConversationAsSeenWhenConversationIsInViewSaga,
			conversationId,
			chatCardId
		)
	}
}

export default function* seeMessageWatcherSaga() {
	yield* takeLatest(
		conversationsUI.actions.conversationUIEnter.match,
		conversationSeeLatestMessageSaga
	)
}
