import tokens from '../../colors/tokens'
import utils from '../../utils'
// shouldn't be used really
import { SemanticColorTokens } from '../types'

import colorTokens from './colorTokens'

const darkSemanticColorTokens: SemanticColorTokens = {
	contentPrimary: colorTokens.white,
	contentSecondary: colorTokens.mono500,

	backgroundPrimary: colorTokens.mono800,
	backgroundSecondary: colorTokens.black,

	buttonDisabledText: colorTokens.mono500,
	buttonDisabledFill: colorTokens.mono300,
	buttonInvertedDisabledBorder: colorTokens.mono500,

	buttonPrimaryBase: colorTokens.primary,
	buttonPrimaryContrast: colorTokens.white,
	buttonPrimaryHover: colorTokens.primary600,
	buttonPrimaryActive: colorTokens.primary700,

	buttonSecondaryBase: colorTokens.mono300,
	buttonSecondaryContrast: colorTokens.black,
	buttonSecondaryHover: colorTokens.mono400,
	buttonSecondaryActive: colorTokens.mono500,

	buttonSecondaryAltBase: colorTokens.mono500,
	buttonSecondaryAltContrast: colorTokens.white,
	buttonSecondaryAltHover: colorTokens.mono300,
	buttonSecondaryAltActive: colorTokens.mono600,

	buttonDangerBase: colorTokens.negative,
	buttonDangerContrast: colorTokens.white,
	buttonDangerHover: colorTokens.negative300,
	buttonDangerActive: colorTokens.negative600,

	buttonPollBase: colorTokens.magnificent,
	buttonPollContrast: colorTokens.white,
	buttonPollHover: colorTokens.magnificent300,
	buttonPollActive: colorTokens.magnificent600,

	buttonEventBase: tokens.autumn500,
	buttonEventContrast: colorTokens.white,
	buttonEventHover: tokens.autumn300,
	buttonEventActive: tokens.autumn600,

	buttonEventAltBase: colorTokens.white,
	buttonEventAltContrast: tokens.autumn200,
	buttonEventAltHover: colorTokens.mono200,
	buttonEventAltActive: colorTokens.mono300,

	hyperlinkText: colorTokens.primary500,

	feedItemActiveBackground: tokens.charcoal300,
	feedItemHoverBackground: colorTokens.mono800,

	mentionText: colorTokens.primary,
	mentionFill: utils.convertHexColorToRGBA(colorTokens.primary, 0.1),
	mentionHover: utils.convertHexColorToRGBA(colorTokens.primary, 0.2),
	mentionHighlightedFill: utils.convertHexColorToRGBA(
		colorTokens.positive,
		0.3
	),
	mentionHighlightedHover: utils.convertHexColorToRGBA(
		colorTokens.positive,
		0.4
	),

	menuTriggerHover: colorTokens.mono600,

	modalDarkBackdrop: 'rgba(91, 112, 131, 0.4)',

	badgePrimary: colorTokens.primary,
	badgeSecondary: colorTokens.mono500,
	badgeSuccess: colorTokens.positive,
	badgeTextPrimary: colorTokens.white,
	badgeDanger: colorTokens.negative,

	tagPrimaryBackground: colorTokens.primary,
	tagPrimaryTextLight: colorTokens.white,
	tagPrimaryTextDark: colorTokens.black,

	toggleUncheckedBackground: tokens.charcoal200,
	toggleCheckedBackground: colorTokens.primary,

	iconFillLight: colorTokens.mono300,
	iconFillGrey: colorTokens.mono600,
	iconFill: colorTokens.mono200,
	iconFillDark: colorTokens.white,

	contentNegative: colorTokens.negative,

	pollMessagePrimaryContent: colorTokens.magnificent,
	// TODO: figure this out with design
	pollMessageBackground: 'rgba(235, 231, 254, 0.9)',
	pollMessageProgressBarBackground: colorTokens.magnificent100,
	pollMessageContent: colorTokens.black,
	announcementMessagePrimaryContent: colorTokens.primary,
	// TODO: figure this out with design
	announcementMessageBackground: 'rgba(222, 244, 253, 0.9)',
	announcementMessageContent: colorTokens.black,

	fileIconBackground: tokens.grass500,
	messageAttachementBackground: tokens.charcoal300,

	tooltipText: colorTokens.mono100,
	tooltipBackground: colorTokens.mono700,

	reactionCounterFill: colorTokens.mono700,
	reactionCounterText: colorTokens.black,
	reactionCounterHighlighted: colorTokens.primary100,
	reactionCounterHover: colorTokens.mono600,
	reactionCounterActive: tokens.charcoal100,

	datePickerCalendarHeaderBackground: colorTokens.mono800,
	datePickerCalendarRangeBackground: colorTokens.black,
	datePickerCalendarSelectedBackground: colorTokens.primary,
	datePickerCalendarSelectedText: colorTokens.white,

	calendarIconFill: tokens.autumn500,
	appreciationsIconBackground: tokens.sun200,
	dmIconFill: tokens.slate400,

	searchBarBackground: colorTokens.mono900,

	addToListFill: colorTokens.primary100,

	lightDivider: colorTokens.mono900,
}

export default darkSemanticColorTokens
