import { Saga } from 'redux-saga'
import { spawn, call, all, take, setContext, put } from 'typed-redux-saga/macro'

import {
	GQL_CLIENT_SAGA_CONTEXT_KEY,
	API_CLIENT_SAGA_CONTEXT_KEY,
	AUTH_CLIENT_SAGA_CONTEXT_KEY,
	INJECTABLE_STORE_SAGA_CONTEXT_KEY,
} from '../constants/config'
import sagaContext from '../slices/sagaContext'
import type { InjectableStore } from '../store'

import contentFeedSaga from './contentFeed'
import conversationMessagesPaginationSaga from './conversationMessages'
import datadogSaga from './datadog'
import faviconSaga from './favicon'
import { uploadRequestWatcherSaga } from './file'
import launchDarkly from './launchDarkly'
import messageErrorsSaga from './messageErrors'
import observeAttendantUpdates from './observeAttendantUpdates'
import observeMessageUpdates from './observeMessageUpdates'
import outstandingImportantMessagesWatcherSaga from './outstandingImportantMessages'
import uploadProfileImageUploadWatcherSaga from './profile'
import pubsubSaga from './pubsub'
import seeMessageWatcherSaga from './seeMessage'
import sphereSidebar from './sphereSidebar'
import submitMessageWatcherSaga from './submitMessage'
import typingSaga from './typingIndicators'
import userConfigurationWatcherSaga from './userConfiguration'
import WebCoreKickstart from './WebCoreKickstart'

// https://redux-saga.js.org/docs/advanced/RootSaga.html#keeping-everything-alive
const rootSaga: Saga = function* rootSaga(injectableStore: InjectableStore) {
	const action = yield* take(sagaContext.actions.setSagaContext)
	if (!action.payload.gqlClient) {
		yield* put(
			sagaContext.actions.setSagaContextError({
				error: 'gqlClient missing in action',
			})
		)
	}
	if (!action.payload.apiClient) {
		yield* put(
			sagaContext.actions.setSagaContextError({
				error: 'apiClient missing in action',
			})
		)
	}
	if (!action.payload.authClient) {
		yield* put(
			sagaContext.actions.setSagaContextError({
				error: 'authClient missing in action',
			})
		)
	}
	if (!injectableStore) {
		yield* put(
			sagaContext.actions.setSagaContextError({
				error: 'InjectableStore missing',
			})
		)
	}

	// setContext must be called in the top level of rootSaga
	// for the context to be available to all of rootSaga's forks/children
	yield* setContext({
		[GQL_CLIENT_SAGA_CONTEXT_KEY]: action.payload.gqlClient,
		[API_CLIENT_SAGA_CONTEXT_KEY]: action.payload.apiClient,
		[AUTH_CLIENT_SAGA_CONTEXT_KEY]: action.payload.authClient,
		[INJECTABLE_STORE_SAGA_CONTEXT_KEY]: injectableStore,
	})

	const sagas = [
		contentFeedSaga,
		conversationMessagesPaginationSaga,
		datadogSaga,
		faviconSaga,
		launchDarkly,
		messageErrorsSaga,
		observeAttendantUpdates,
		observeMessageUpdates,
		outstandingImportantMessagesWatcherSaga,
		pubsubSaga,
		seeMessageWatcherSaga,
		sphereSidebar,
		submitMessageWatcherSaga,
		typingSaga,
		uploadProfileImageUploadWatcherSaga,
		uploadRequestWatcherSaga,
		userConfigurationWatcherSaga,
		WebCoreKickstart,
	]

	yield* all(
		sagas.map(saga =>
			spawn(function* () {
				while (true) {
					try {
						yield* call(saga)
						break
					} catch (e) {
						console.error(e)
					}
				}
			})
		)
	)
}

export default rootSaga
