import { ViewerAttendantModel } from '../Attendant'
import { ConversationModel } from '../Conversation'

import { Entity } from '../shared/types'

// TODO: these should be lowercase long term as backend is switching casing
export enum ChatCardState {
	Live = 'Live',
	Ended = 'Ended',
	Upcoming = 'Upcoming',
	Scheduled = 'Scheduled',
}

// TODO: these should be lowercase long term as backend is switching casing
export enum ConversationType {
	Channel = 'Channel',
	Dm = 'Dm',
	Event = 'Event',
	Pinboard = 'Pinboard',
}

export interface ChatCardModel extends Entity {
	createdAt?: string | null
	conversation?: ConversationModel | null
	conversationType?: ConversationType
	creatorAgentId?: string | null
	sphereEventId?: string | null
	description?: string | null
	isPrivate?: boolean
	imageColor?: string | null
	imageFilename?: string | null
	title?: string
	viewerAttendant?: ViewerAttendantModel | null
	state?: ChatCardState
}

export enum MessageOrdering {
	OldestToNewest,
	NewestToToOldest,
}

export enum MessageDisplayEntryPointType {
	Oldest,
	Newest,
	SpecificMessage,
}

export type MessageDisplayEntryPoint =
	| {
			type: MessageDisplayEntryPointType.Oldest
	  }
	| {
			type: MessageDisplayEntryPointType.Newest
	  }
	| {
			type: MessageDisplayEntryPointType.SpecificMessage
			messageId: string
	  }
