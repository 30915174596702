import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { getUserAgentId } from '../selectors/userContext'

export interface ViewerAuthInfo {
	userAgentId: string | null
	userName: string | null
	userEmail: string | null
}

const makeGetViewerAuthInfo = () =>
	createSelector(
		getUserAgentId,
		({ api }) =>
			api.person
				? api.person &&
				  `${api.person.first_name ?? ''} ${api.person.last_name ?? ''}`.trim()
				: null,
		({ api }) => api.person?.email ?? null,
		(userAgentId, userName, userEmail) => ({ userAgentId, userName, userEmail })
	)

export function useViewerAuthInfo(): ViewerAuthInfo {
	const getViewerAuthInfo = useMemo(makeGetViewerAuthInfo, [])
	return useSelector(getViewerAuthInfo)
}

export default useViewerAuthInfo
