import {
	configureStore as toolkitConfigureStore,
	ReducersMapObject,
} from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router/immutable'
import { History } from 'history'
import LogRocket from 'logrocket'
import createSagaMiddleware from 'redux-saga'

import { GQLClient } from '../network/graphql/configureClient'
import rootSaga from '../sagas'

import analyticsMiddleware from '../middleware/analytics'
import { ApiClient } from '../network/api/apiClient'
import { AuthClient } from '../network/auth/authClient'
import { createRootReducer, StoreState } from '../slices'

import type { InjectableStore } from './injectableStore'

// uncomment import for serializability check
// import sagaContext from '../slices/sagaContext'

export type { StoreState }

interface ThunkExtraArgument {
	apiClient: ApiClient
	authClient: AuthClient
	gqlClient: GQLClient
}

export function configureStore(
	initialState: Partial<StoreState>,
	thunkExtraArgument: ThunkExtraArgument,
	history: History
) {
	const sagaMiddleware = createSagaMiddleware()

	const asyncReducers: ReducersMapObject<any, any> = {}

	const store = toolkitConfigureStore({
		reducer: createRootReducer(history, asyncReducers),
		preloadedState: initialState,
		middleware(getDefaultMiddleware) {
			const defaultMiddleware = getDefaultMiddleware({
				thunk: {
					extraArgument: thunkExtraArgument,
				},
				// The serializableCheck is running over the whole state in dev mode which imo is excessive and taking too much time
				// Currently proposing enabling configuration which only checks the current action
				// https://github.com/reduxjs/redux-toolkit/issues/885
				// https://github.com/reduxjs/redux-toolkit/blob/ba8c911c53e67365499ac00982f640b369360a64/src/serializableStateInvariantMiddleware.ts#L193
				// serializableCheck: {
				//	ignoredActions: [sagaContext.actions.setSagaContext.type],
				// },
				serializableCheck: false,
			})
			// concat used to keep types
			const defaultWithSaga = defaultMiddleware.concat([
				sagaMiddleware,
				routerMiddleware(history),
			])
			type Middleware = typeof defaultWithSaga
			// analytics and logrocket middleware don't have full types, thus ignore them for type generation as they would widen
			return defaultWithSaga
				.concat([analyticsMiddleware])
				.concat([LogRocket.reduxMiddleware()]) as Middleware
		},
	})

	type OriginalStore = typeof store
	type AugmentedStore = OriginalStore & InjectableStore

	// see https://redux.js.org/usage/code-splitting#defining-an-injectreducer-function
	const augmentedStore: AugmentedStore = Object.assign(store, {
		injectSlice(slice) {
			asyncReducers[slice.name] = slice.reducer
			store.replaceReducer(createRootReducer(history, asyncReducers))
		},
	} as InjectableStore)

	sagaMiddleware.run(rootSaga, augmentedStore)

	return augmentedStore as OriginalStore
}

export default configureStore

export type ApplicationDispatch = ReturnType<typeof configureStore>['dispatch']
export type ApplicationThunk<R = unknown> = (
	dispatch: ApplicationDispatch,
	getState: () => StoreState,
	extraArgument: ThunkExtraArgument
) => R
