import { useMutation, MutationHookOptions } from '@apollo/client'
import { useCallback } from 'react'

import {
	UpdateUserMutation,
	UpdateUserMutationVariables,
} from '../network/graphql'
import { UPDATE_USER } from '../network/graphql/mutations'

export function useUpdateUserMutation(
	options?: MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
	const [triggerUpdateUserMutation, updateUserMutationState] = useMutation<
		UpdateUserMutation,
		UpdateUserMutationVariables
	>(UPDATE_USER, options)

	const updateUser = useCallback(
		({
			agentId,
			name,
			description,
			imageFilename,
		}: {
			agentId: string
			name?: string
			description?: string
			imageFilename?: string
		}) => {
			const nameIsEmptyString = name?.length === 0
			const descriptionIsEmptyString = description?.length === 0
			return triggerUpdateUserMutation({
				variables: {
					agent_id: agentId,
					input: {
						name: nameIsEmptyString ? null : name,
						about: descriptionIsEmptyString ? null : description,
						image_filename: imageFilename,
					},
				},
			})
		},
		[triggerUpdateUserMutation]
	)
	return [updateUser, updateUserMutationState] as const
}
