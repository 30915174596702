import { Modal as BaseUIModal, SIZE, ROLE } from 'baseui/modal'
import { FC } from 'react'
import { useTheme } from 'styled-components'

import { ModalPropsT } from './types'

const MODAL_WIDTH = 700
const FLOW_MODAL_HEIGHT = 700

export const Modal: FC<ModalPropsT> = ({
	children,
	isOpen,
	closeable,
	onClose,
	size = SIZE.default,
	style,
	'data-testid': dataTestId,
	useFlowHeight: flowHeight = false,
}) => {
	const theme = useTheme()

	return (
		<BaseUIModal
			role={ROLE.dialog}
			isOpen={isOpen}
			onClose={onClose}
			closeable={closeable}
			// Suppress warnings, will be default behaviour in next major release
			unstable_ModalBackdropScroll
			overrides={{
				Root: {
					props: {
						'data-testid': dataTestId,
					},
				},
				// We handle the close button ourselves
				Close: () => null,
				DialogContainer: {
					style: {
						height: '100%',
						padding: `${theme.grid.gutter}px`,
						overflow: 'auto',
						backgroundColor: theme.colors.modalDarkBackdrop,
					},
				},
				Dialog: {
					style: {
						...(size === SIZE.default && {
							width: `${MODAL_WIDTH}px`,
						}),
						...(flowHeight && {
							height: `${FLOW_MODAL_HEIGHT}px`,
						}),
						display: 'flex',
						flexFlow: 'column',
						padding: 0,
						marginTop: 0,
						marginBottom: 0,
						marginLeft: 0,
						marginRight: 0,
						maxHeight: '100%',
						borderTopLeftRadius: theme.borders.surfaceBorderRadius,
						borderTopRightRadius: theme.borders.surfaceBorderRadius,
						borderBottomLeftRadius: theme.borders.surfaceBorderRadius,
						borderBottomRightRadius: theme.borders.surfaceBorderRadius,
						...style,
					},
				},
			}}
			size={size}
		>
			{children}
		</BaseUIModal>
	)
}

export default Modal
