import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'
import { union } from 'lodash'

import { Message as FSMessage } from '../network/firestore'
import { UpdateType } from '../network/pagination'

import auth from './auth'
import conversationMessagesPagination from './conversationMessagesPagination'
import localMessages from './localMessages'
import { PaginationAction } from './paginate'

import { StoreState } from '.'

export interface ConversationMessages {
	conversationId: string
	subscribedToMessageUpdates: boolean
	subscribedToMessageUpdatesError?: string
	socketedMessageIds: string[]
	localMessageUniqueIds: string[]
	messageIds: string[]
}

export const conversationMessagesAdapter =
	createEntityAdapter<ConversationMessages>({
		selectId(entry) {
			return entry.conversationId
		},
	})

export type MessagesByConversationState = EntityState<ConversationMessages>

function getInitialConversationMessagesState(
	conversationId: string
): ConversationMessages {
	return {
		conversationId,
		messageIds: [],
		socketedMessageIds: [],
		localMessageUniqueIds: [],
		subscribedToMessageUpdates: false,
	}
}

export type ConversationMessagesAction<P = unknown, E = never> = PayloadAction<
	P & {
		conversationId: string
	},
	string,
	never,
	E
>

export type ConversationMessagesPaginationAction<
	P = unknown,
	E = never
> = PaginationAction<
	P & {
		conversationId: string
	},
	E
>

function ensureInitialState(
	state: WritableDraft<EntityState<ConversationMessages>>,
	action: { payload: { conversationId: string } }
): string {
	const conversationId = action.payload.conversationId
	if (
		!conversationMessagesAdapter
			.getSelectors()
			.selectById(state, conversationId)
	) {
		conversationMessagesAdapter.addOne(
			state,
			getInitialConversationMessagesState(conversationId)
		)
	}
	return conversationId
}

function resetPagination(
	state: WritableDraft<EntityState<ConversationMessages>>,
	action: { payload: { conversationId: string } }
) {
	conversationMessagesAdapter.updateOne(state, {
		id: action.payload.conversationId,
		changes: {
			socketedMessageIds: [],
			localMessageUniqueIds: [],
			messageIds: [],
		},
	})
}

const messagesByConversation = createSlice({
	name: 'messagesByConversation',
	initialState: conversationMessagesAdapter.getInitialState(),
	reducers: {
		// Subscription actions
		conversationMessagesUpdatesSubscribe(
			state,
			action: ConversationMessagesAction
		) {
			ensureInitialState(state, action)
			conversationMessagesAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					subscribedToMessageUpdates: true,
				},
			})
		},
		conversationMessagesUpdatesUnsubscribe(
			state,
			action: ConversationMessagesAction
		) {
			ensureInitialState(state, action)
			conversationMessagesAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					subscribedToMessageUpdates: false,
				},
			})
		},
		conversationMessagesUpdateError: {
			reducer(state, action: ConversationMessagesAction<unknown, string>) {
				ensureInitialState(state, action)
				conversationMessagesAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: {
						subscribedToMessageUpdatesError: action.error,
					},
				})
			},
			prepare(args: { conversationId: string; error: string }) {
				return {
					payload: { conversationId: args.conversationId },
					error: args.error,
				}
			},
		},
		conversationMessagesUpdate(
			state,
			action: ConversationMessagesAction<{ messages: FSMessage[] }>
		) {
			ensureInitialState(state, action)
			const prevConversationState = conversationMessagesAdapter
				.getSelectors()
				.selectById(state, action.payload.conversationId)!

			const socketedMessageIdsMerged = union(
				prevConversationState.socketedMessageIds,
				action.payload.messages.map(message => message.id)
			)
			conversationMessagesAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					socketedMessageIds: socketedMessageIdsMerged,
				},
			})
		},
	},
	extraReducers: builder =>
		builder
			.addCase(localMessages.actions.localMessageCreate, (state, action) => {
				ensureInitialState(state, action)
				const previous = conversationMessagesAdapter
					.getSelectors()
					.selectById(state, action.payload.conversationId)!
				conversationMessagesAdapter.updateOne(state, {
					id: action.payload.conversationId,
					changes: {
						localMessageUniqueIds: [
							...previous.localMessageUniqueIds,
							action.payload.uniqueId,
						],
					},
				})
			})
			.addCase(
				conversationMessagesPagination.actions.paginationRequest,
				(state, action) => {
					ensureInitialState(state, action)
					if (action.payload.resetPagination) {
						// It is best to reset the pagination in the request action
						// Reseting pagination in the success action could lead to race conditions
						resetPagination(state, action)
					}
				}
			)
			.addCase(
				conversationMessagesPagination.actions.paginationSuccess,
				(state, action) => {
					const previousState = conversationMessagesAdapter
						.getSelectors()
						.selectById(state, action.payload.conversationId)
					const oldMessageIds = previousState?.messageIds

					conversationMessagesAdapter.updateOne(state, {
						id: action.payload.conversationId,
						changes: {
							messageIds:
								action.payload.updateType === UpdateType.Append
									? union(
											oldMessageIds,
											action.payload.pagination?.ascendingIds
									  )
									: union(
											action.payload.pagination?.ascendingIds,
											oldMessageIds
									  ),
						},
					})
				}
			)
			.addCase(auth.actions.signOutSuccess, () =>
				conversationMessagesAdapter.getInitialState()
			),
})

export default messagesByConversation

export const messagesByConversationSelectors =
	conversationMessagesAdapter.getSelectors<StoreState>(
		state => state.messagesByConversation
	)
