import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import debug, { DebugFlag, DebugFlags } from '../../slices/debug'

interface Setting {
	flag: DebugFlag
	value: boolean
}

type SetDebugValueFn = (flag: DebugFlag, value: boolean) => void
type ReturnTuple = [settings: Setting[], setDebugValueFn: SetDebugValueFn]

function useDebugSettings(): ReturnTuple {
	const dispatch = useDispatch()
	const isStaffAdmin = useSelector(state => state.api.isStaffAdmin)
	const debugState = useSelector(state => state.debug)
	const debugSettings: Setting[] = useMemo(() => {
		if (!isStaffAdmin) {
			return []
		}
		return DebugFlags.map(flag => ({ flag, value: debugState[flag] }))
	}, [isStaffAdmin, debugState])

	const setValue = useCallback(
		(flag: DebugFlag, value: boolean) => {
			dispatch(debug.actions.setValue({ flag, value }))
		},
		[dispatch]
	)

	return useMemo(() => [debugSettings, setValue], [debugSettings, setValue])
}

export default useDebugSettings
