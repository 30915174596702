import { StoreState } from '../store'

export const getEnvironment = (state: StoreState) => state.environment

export const getBrowser = (state: StoreState) => state.browser

export const getIntercom = (state: StoreState) => state.intercom

export const getIntercomBooted = (state: StoreState) =>
	Boolean(state.intercom.booted)

export const makeGetExtraParam =
	<ExtraParam>() =>
	(_: StoreState, extra: ExtraParam): ExtraParam =>
		extra
