import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import message from './message'

import { StoreState } from '.'

export enum PollPersonalResponseState {
	HasToIgnore,
	CanVote,
	HasVoted,
	HasDismissed,
}

export type PollPersonalResponseContainer<VoteContainer> =
	| {
			state: PollPersonalResponseState.HasVoted
			votes: VoteContainer
	  }
	| {
			state: Exclude<
				PollPersonalResponseState,
				PollPersonalResponseState.HasVoted
			>
			votes?: undefined
	  }

export type PollPersonalResponseWithSet = PollPersonalResponseContainer<
	Set<string>
>
export type PollPersonalResponseWithArray = PollPersonalResponseContainer<
	string[]
>

export type PollPersonalResponseCacheEntry = PollPersonalResponseWithArray & {
	messageId: string
}

export const responseAdapter =
	createEntityAdapter<PollPersonalResponseCacheEntry>({
		selectId: response => response.messageId,
	})

export type PollPersonalResponsesCacheState = ReturnType<
	typeof responseAdapter.getInitialState
>

export const pollPersonalResponsesCache = createSlice({
	name: 'pollPersonalResponsesCache',
	initialState: responseAdapter.getInitialState(),
	reducers: {
		updateResponse: responseAdapter.upsertOne,
	},
	extraReducers: builder =>
		builder.addCase(
			message.actions.conversationMessageSuccess,
			(state, action) =>
				responseAdapter.removeOne(state, action.payload.messageId)
		),
})

export default pollPersonalResponsesCache

const pollPersonalResponsesCacheSelectors =
	responseAdapter.getSelectors<StoreState>(
		state => state.pollPersonalResponsesCache
	)

export { pollPersonalResponsesCacheSelectors }
