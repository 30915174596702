import { gql } from '@apollo/client'

import {
	ATTENDANT_INFO_FRAGMENT,
	SPHERE_FRAGMENT,
	ATTENDANT_INFO_PROFILE_FRAGMENT,
	SPHERE_SUMMARY_INFO_FRAGMENT,
	OWN_ATTENDANT_FRAGMENT,
	SPHERE_FEED_INFO,
	CHAT_CARD_FRAGMENT,
	EVENT_INFO_INVITEE_FRAGMENT,
	CHAT_CARD_CHAT_HEADER_FRAGMENT,
	TYPING_INDICATOR_ATTENDANT_INFO_FRAGMENT,
	CHAT_CARD_CHAT_INFO_FRAGMENT,
	CHAT_HEADER_PRESENCE_ATTENDANT_INFO_FRAGMENT,
	ADD_MEMBER_LIST_ADDABLE_MEMBER_FRAGMENT,
	CHAT_INFO_VIEWER_ATTENDANT_FRAGMENT,
	DM_MEMBER_LIST_SPHERE_MEMBER_FRAGMENT,
	SPHERE_EVENT_LIST_EVENT_FRAGMENT,
	CHAT_VIEW_FRAGMENT,
	SPHERE_VIEW_FRAGMENT,
	APPRECIATION_FRAGMENT,
	EMOJI_FRAGMENT,
} from './fragments'

export const GET_CHAT_CARD_BY_ID = gql`
	${CHAT_CARD_FRAGMENT}
	query getChatCardByConversationId($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				...chatCard
			}
		}
	}
`

export const GET_CHAT_CARD_FOR_CHAT_VIEW = gql`
	${CHAT_VIEW_FRAGMENT}
	query getChatCardForChatView($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				...chatView
			}
		}
	}
`

export const GET_SOCKET_CHANNEL_TOKEN_FOR_CHAT_CARD_BY_ID = gql`
	query getSocketChannelTokenForChatCardById($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				id
				conversation {
					id
					socket_channel_token
				}
			}
		}
	}
`

export const GET_VIEWER_ATTENDANT_FOR_CHAT_CARD_BY_ID = gql`
	${OWN_ATTENDANT_FRAGMENT}
	query getViewerAttendantIdForChatCardById($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				id
				attendant {
					...ownAttendant
				}
			}
		}
	}
`

export const GET_IMAGES = gql`
	query imageSearch($searchTerm: String!, $paging: PagingInput) {
		viewer {
			imageSearch(term: $searchTerm, paging: $paging) {
				data {
					source {
						url
						width
						height
					}
					thumbnail {
						url
						width
						height
					}
				}
				paging {
					cursors {
						after
						before
					}
				}
			}
		}
	}
`

export const GET_SPHERE_SUMMARY_INFOS = gql`
	${SPHERE_SUMMARY_INFO_FRAGMENT}
	query getSphereSummaryInfos {
		viewer {
			spheres {
				data {
					...sphereSummary
				}
			}
		}
	}
`

export const GET_SPHERE = gql`
	${SPHERE_FRAGMENT}
	query getSphere($id: ID!) {
		viewer {
			sphere(sphere_id: $id) {
				...fullSphere
			}
		}
	}
`

export const GET_FEED_SPHERE = gql`
	${SPHERE_FEED_INFO}
	query getFeedSphere($id: ID!) {
		viewer {
			sphere(sphere_id: $id) {
				...sphereFeedInfo
			}
		}
	}
`

export const GET_SPHERES_FEED_INFO = gql`
	${SPHERE_FEED_INFO}
	query getSpheresFeedInfo {
		viewer {
			spheres {
				data {
					...sphereFeedInfo
				}
			}
		}
	}
`

export const GET_SUMMARY_INFO_OF_SPHERES_AND_SPHERE_BY_ID = gql`
	${SPHERE_SUMMARY_INFO_FRAGMENT}
	query getSpheresAndSphere($id: ID!) {
		viewer {
			sphere(sphere_id: $id) {
				...sphereSummary
			}
			spheres {
				data {
					...sphereSummary
				}
			}
		}
	}
`

export const GET_SUMMARY_INFO_OF_SPHERE = gql`
	${SPHERE_SUMMARY_INFO_FRAGMENT}
	query getSphereSummary($id: ID!) {
		viewer {
			sphere(sphere_id: $id) {
				...sphereSummary
			}
		}
	}
`

export const GET_ATTENDANT_INFO_FOR_PROFILE = gql`
	${ATTENDANT_INFO_PROFILE_FRAGMENT}
	query getAttendantInfoForProfile($conversationId: ID!, $attendantId: ID!) {
		viewer {
			chatCard(conversationId: $conversationId) {
				id
				conversation {
					id
					attendant_info(attendant_id: $attendantId) {
						...profileAttendantInfo
					}
				}
			}
		}
	}
`

export const GET_ATTENDANT_INFO_FOR_TYPING_INDICATOR_BY_ID = gql`
	${TYPING_INDICATOR_ATTENDANT_INFO_FRAGMENT}
	query getAttendantInfoForTypingIndicatorById(
		$conversationId: ID!
		$attendantId: ID!
	) {
		viewer {
			chatCard(conversationId: $conversationId) {
				id
				conversation {
					id
					attendant_info(attendant_id: $attendantId) {
						...typingIndicatorAttendantInfo
					}
				}
			}
		}
	}
`

export const GET_OWN_PROFILE = gql`
	query getOwnProfile {
		viewer {
			agent {
				id
				name
				image_filename
				color
			}
			person {
				id
				about
				email
			}
		}
	}
`
export const GET_CHAT_CARD_ATTENDANTS = gql`
	${ATTENDANT_INFO_FRAGMENT}
	query getChatCardAttendantsByCardId($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				id
				conversation {
					id
					attendants_info {
						attendant_id
						...attendantInfo
					}
				}
			}
		}
	}
`

export const GET_OUTSTANDING_IMPORTANT_MESSAGES_PER_SPHERE = gql`
	query getOutstandingImportantMessagesPerSphere {
		viewer {
			spheres {
				data {
					id
					chats {
						data {
							id
							attendant {
								id
								conversation_id
								outstanding_important_messages
							}
						}
					}
				}
			}
		}
	}
`

export const GET_EVENT_FOR_EVENT_INFO = gql`
	${EVENT_INFO_INVITEE_FRAGMENT}
	query getEventInfo($sphereId: ID!, $sphereEventId: ID!, $inviteeLimit: Int) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				event(sphereEventId: $sphereEventId) {
					id
					sphereId
					conversationId
					displayImageId
					name
					description
					location {
						name
					}
					link
					startDate
					endDate
					createdAt
					inviteeCounts {
						pending
						going
						thinking
						notGoing
					}
					viewerInvitee {
						...eventInfoInvitee
					}
					invitees {
						pending(limit: $inviteeLimit) {
							data {
								...eventInfoInvitee
							}
						}
						going(limit: $inviteeLimit) {
							data {
								...eventInfoInvitee
							}
						}
						thinking(limit: $inviteeLimit) {
							data {
								...eventInfoInvitee
							}
						}
						notGoing(limit: $inviteeLimit) {
							data {
								...eventInfoInvitee
							}
						}
					}
					creator {
						displayName
						displayColor
						displayImageId
						agentId
					}
					viewerCapabilities {
						rsvp {
							permitted
						}
						delete {
							permitted
						}
						remindToRsvp {
							permitted
						}
						edit {
							permitted
						}
					}
				}
			}
		}
	}
`

export const GET_ALL_PENDING_EVENT_INFO_INVITEES = gql`
	${EVENT_INFO_INVITEE_FRAGMENT}
	query getPendingEventInvitees($sphereId: ID!, $eventId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				event(sphereEventId: $eventId) {
					id
					sphereId
					inviteeCounts {
						pending
					}
					invitees {
						pending {
							data {
								...eventInfoInvitee
							}
						}
					}
				}
			}
		}
	}
`

export const GET_ALL_GOING_EVENT_INFO_INVITEES = gql`
	${EVENT_INFO_INVITEE_FRAGMENT}
	query getGoingEventInvitees($sphereId: ID!, $eventId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				event(sphereEventId: $eventId) {
					id
					sphereId
					inviteeCounts {
						going
					}
					invitees {
						going {
							data {
								...eventInfoInvitee
							}
						}
					}
				}
			}
		}
	}
`

export const GET_ALL_NOT_GOING_EVENT_INFO_INVITEES = gql`
	${EVENT_INFO_INVITEE_FRAGMENT}
	query getNotGoingEventInvitees($sphereId: ID!, $eventId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				event(sphereEventId: $eventId) {
					id
					sphereId
					inviteeCounts {
						notGoing
					}
					invitees {
						notGoing {
							data {
								...eventInfoInvitee
							}
						}
					}
				}
			}
		}
	}
`

export const GET_ALL_THINKING_EVENT_INFO_INVITEES = gql`
	${EVENT_INFO_INVITEE_FRAGMENT}
	query getThinkingEventInvitees($sphereId: ID!, $eventId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				event(sphereEventId: $eventId) {
					id
					sphereId
					inviteeCounts {
						thinking
					}
					invitees {
						thinking {
							data {
								...eventInfoInvitee
							}
						}
					}
				}
			}
		}
	}
`

export const GET_CHAT_CARD_FOR_CHAT_HEADER = gql`
	query getChatCardForChatHeader($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				...chatCardChatHeader
			}
		}
	}
	${CHAT_CARD_CHAT_HEADER_FRAGMENT}
`

export const GET_CHAT_CARD_FOR_CHAT_INFO = gql`
	query getChatCardForChatInfo($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				...chatCardChatInfo
			}
		}
	}
	${CHAT_CARD_CHAT_INFO_FRAGMENT}
`

export const GET_VIEWER_ATTENDANT_FOR_CHAT_INFO = gql`
	query getViewerAttendantForChatInfo($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				id
				attendant {
					...chatInfoViewerAttendant
				}
			}
		}
	}
	${CHAT_INFO_VIEWER_ATTENDANT_FRAGMENT}
`

export const GET_CHAT_CARD_ATTENDANT_INFO_PRESENCE_FOR_CHAT_HEADER = gql`
	query getChatCardAttendantInfoPresenceForChatHeader($id: ID!) {
		viewer {
			chatCard(conversationId: $id) {
				id
				conversation {
					id
					attendants_info {
						...chatHeaderPresenceAttendantInfoFragment
					}
				}
			}
		}
	}
	${CHAT_HEADER_PRESENCE_ATTENDANT_INFO_FRAGMENT}
`

export const GET_SPHERE_MEMBERS_ADDABLE_FOR_CONVERSATION = gql`
	${ADD_MEMBER_LIST_ADDABLE_MEMBER_FRAGMENT}
	query getSphereMembersAddableForConversation(
		$sphereId: ID!
		$conversationId: ID = null
	) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				members(filter: { canBeAddedToConversation: $conversationId }) {
					data {
						...addMemberListAddableMemberFragment
					}
				}
			}
		}
	}
`

export const GET_DM_CHAT_CARD_WITH_SPHERE_MEMBERS = gql`
	query getDmChatCardWithSphereMembers($sphereId: ID!, $agentIds: [String!]!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				dmConversationWithMembers(agentIds: $agentIds) {
					id
					conversation_id
				}
			}
		}
	}
`

export const GET_DM_CHAT_CARD_WITH_CONVERSATION_ATTENDAT = gql`
	query getDmChatCardWithConversationAttendant(
		$sphereId: ID!
		$conversationId: String!
		$attendantId: String!
	) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				dmConversationWithAttendantOfConversation(
					conversationId: $conversationId
					attendantId: $attendantId
				) {
					id
					conversation_id
				}
			}
		}
	}
`

export const GET_SPHERE_MEMBERS_VIEWER_CAN_DIRECT_MESSAGE = gql`
	query getSphereMembersViewerCanDirectMessage($sphereId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				members(filter: { canBeDirectMessagedByViewer: true }) {
					data {
						...dmMemberListSphereMemberFragment
					}
				}
			}
		}
	}
	${DM_MEMBER_LIST_SPHERE_MEMBER_FRAGMENT}
`

export const GET_ALL_EVENTS_OF_SPHERE = gql`
	${SPHERE_EVENT_LIST_EVENT_FRAGMENT}
	query getAllEventsOfSphere($sphereId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				attendedUpcomingEvents {
					data {
						...sphereEventListEvent
					}
				}
				notAttendedUpcomingEvents {
					data {
						...sphereEventListEvent
					}
				}
				attendedPastEvents {
					data {
						...sphereEventListEvent
					}
				}
				notAttendedPastEvents {
					data {
						...sphereEventListEvent
					}
				}
			}
		}
	}
`

export const GET_SPHERE_FOR_SPHERE_VIEW = gql`
	${SPHERE_VIEW_FRAGMENT}
	query getSphereView($sphereId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				...sphereView
			}
		}
	}
`

export const GET_SPHERE_IDS = gql`
	query getSphereIds {
		viewer {
			spheres {
				data {
					id
				}
			}
		}
	}
`

export const GET_APPRECIATIONS_FOR_SPHERE = gql`
	${APPRECIATION_FRAGMENT}
	query getAppreciationsForSphere($sphereId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				membership {
					agent_id
					capabilities {
						type
					}
				}
				appreciations {
					...appreciationFragment
				}
			}
		}
	}
`

export const GET_EMOJI_IDS_USED_IN_APPRECIATIONS = gql`
	query getEmojiIdsUsedInAppreciations($sphereId: ID!) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				appreciations {
					id
					active
					emoji {
						id
					}
				}
			}
		}
	}
`

export const GET_SPHERE_EMOJIS = gql`
	${EMOJI_FRAGMENT}
	query getEmojisForSphere(
		$sphereId: ID!
		$searchTerm: String
		$startAfter: ID
		$limit: Int!
	) {
		viewer {
			sphere(sphere_id: $sphereId) {
				id
				emojis(
					filter: { searchTerm: $searchTerm }
					startAfter: $startAfter
					limit: $limit
				) {
					data {
						...emojiFragment
					}
					paging {
						start
						end
						hasNextPage
					}
				}
			}
		}
	}
`

export const GET_SPHERE_HEADER = gql`
	query getSphereHeaderSphere($id: ID!) {
		viewer {
			sphere(sphere_id: $id) {
				id
				title
			}
		}
	}
`
