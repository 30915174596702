import { throttle } from 'lodash'

import { actions } from '../slices/environment'
import { ApplicationThunk } from '../store'
import { browserReportSync } from '../utils/browserReport'

type NoHiddenPropertyDocument = Omit<Document, 'hidden'>

interface MozBrowserDocument extends NoHiddenPropertyDocument {
	mozHidden: boolean
}

interface WebkitBrowserDocument extends NoHiddenPropertyDocument {
	webkitHidden: boolean
}

interface MsBrowserDocument extends NoHiddenPropertyDocument {
	msHidden: boolean
}

interface OnFocusSupportingDocument {
	onfocusin: EventListenerOrEventListenerObject
	onfocusout: EventListenerOrEventListenerObject
}

type AnyDocument =
	| Document
	| MozBrowserDocument
	| WebkitBrowserDocument
	| MsBrowserDocument
	| OnFocusSupportingDocument

// the environment code is borrowed from Andrew Ngu,
// https://github.com/andrewngu/sound-redux

// Uses the browser user agent string to detect the device type
function initUserAgent(): ApplicationThunk {
	return dispatch => {
		const report = browserReportSync()
		const isMobile =
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		return dispatch(
			actions.setIsMobile({
				isMobile,
				isAndroid: ((report || {}).os || {}).name === 'Android',
				isIos: ((report || {}).os || {}).name === 'iOS',
			})
		)
	}
}

// Adds window listeners to keep track of width and height
function initWindowSize(): ApplicationThunk {
	return dispatch => {
		dispatch(
			actions.setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		)
		window.onresize = throttle(() => {
			dispatch(
				actions.setWindowDimensions({
					width: window.innerWidth,
					height: window.innerHeight,
				})
			)
		}, 200)
	}
}

function initOnlineState(): ApplicationThunk {
	return dispatch => {
		if ('onLine' in window.navigator) {
			dispatch(actions.setIsOnline(window.navigator.onLine))
		}

		window.addEventListener('offline', () => {
			dispatch(actions.setIsOnline(false))
		})
		window.addEventListener('online', () => {
			dispatch(actions.setIsOnline(true))
		})
	}
}

type WindowEvent =
	| {
			type: string
			target?: any
	  }
	| undefined

// Uses Page Visibility API to determine if the tab is in view
function initWindowVisibility(): ApplicationThunk {
	return dispatch => {
		const document = window.document as AnyDocument
		let hidden: 'hidden' | 'mozHidden' | 'webkitHidden' | 'msHidden' | undefined

		function onchange(event: WindowEvent = window.event) {
			if (!hidden) {
				return
			}

			const eventToVisibilityMap = {
				focus: true,
				focusin: true,
				pageshow: true,
				blur: false,
				focusout: false,
				pagehide: false,
			}

			const isVisible =
				!!event &&
				eventToVisibilityMap[
					event.type as keyof typeof eventToVisibilityMap
				] !== undefined
					? eventToVisibilityMap[
							event.type as keyof typeof eventToVisibilityMap
					  ]
					: !!event && !event.target[hidden]

			dispatch(actions.setIsVisible(isVisible))
		}

		// Standards:
		if ('hidden' in document) {
			hidden = 'hidden'
			document.addEventListener('visibilitychange', onchange)
		} else if ('mozHidden' in document) {
			hidden = 'mozHidden'
			document.addEventListener('mozvisibilitychange', onchange)
		} else if ('webkitHidden' in document) {
			hidden = 'webkitHidden'
			document.addEventListener('webkitvisibilitychange', onchange)
		} else if ('msHidden' in document) {
			hidden = 'msHidden'
			document.addEventListener('msvisibilitychange', onchange)
		} else if ('onfocusin' in document) {
			// IE 9 and lower:
			document.onfocusin = onchange
			document.onfocusout = onchange
		} else {
			// All others:
			window.onpageshow = onchange
			window.onpagehide = onchange
			window.onfocus = onchange
			window.onblur = onchange
		}

		// set the initial state, only if browser supports the Page Visibility API
		const isPageVisibilitySupported =
			hidden !== undefined && (document as any)[hidden] !== undefined
		if (isPageVisibilitySupported) {
			if (hidden) {
				onchange({ type: (document as any)[hidden] ? 'blur' : 'focus' })
			}
		}
	}
}

function initDarkMode(): ApplicationThunk {
	return dispatch => {
		if (!window.matchMedia) {
			return
		}

		dispatch(
			actions.setPrefersDarkColorScheme(
				window.matchMedia('(prefers-color-scheme: dark)').matches
			)
		)

		const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')
		if (mediaQueryList) {
			if (mediaQueryList.addEventListener) {
				mediaQueryList.addEventListener('change', event => {
					dispatch(actions.setPrefersDarkColorScheme(event.matches))
				})
			} else if (mediaQueryList.addListener) {
				// Support for Safari ...
				mediaQueryList.addListener(event => {
					dispatch(actions.setPrefersDarkColorScheme(event.matches))
				})
			}
		}
	}
}

export function initEnvironment(): ApplicationThunk {
	return dispatch => {
		dispatch(initUserAgent())
		dispatch(initDarkMode())
		dispatch(initWindowSize())
		dispatch(initWindowVisibility())
		dispatch(initOnlineState())
	}
}

export default initEnvironment
