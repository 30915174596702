import firebase from 'firebase/app'
import 'firebase/firestore'

import { FIREBASE_APP_NAME } from '../../constants/config'
import { Pagination } from '../pagination'

import { FetchOptions } from './types'
import { parametriseCollectionReference } from './utils'

export interface FirestoreResult<T> {
	data: T[]
	pagination?: Pagination
}

export function createCollectionReference<
	FSCollectionType = firebase.firestore.DocumentData
>(
	collectionName: string,
	firebaseAppName = FIREBASE_APP_NAME
): firebase.firestore.CollectionReference<FSCollectionType> {
	const firebaseApp = firebase.app(firebaseAppName)
	const firestore = firebaseApp.firestore()
	const collection = firestore.collection(collectionName)
	return collection as firebase.firestore.CollectionReference<FSCollectionType>
}

export function getCollection(
	collectionName: string,
	options: FetchOptions
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
	const collectionReference = createCollectionReference(collectionName)

	const query = parametriseCollectionReference(collectionReference, options)
	return query.get()
}

export function getFirestoreDoc(
	collectionName: string,
	firestoreDocName: string
): Promise<
	firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
> {
	const collection = createCollectionReference(collectionName)
	const fireStoreDoc = collection.doc(firestoreDocName)
	return fireStoreDoc.get()
}

type UnobserveFn = () => void

export function observeCollection<
	FSCollectionType = firebase.firestore.DocumentData
>(
	collectionName: string,
	options: {
		fetchOptions: FetchOptions
		callbacks: {
			next?: (
				snapshot: firebase.firestore.QuerySnapshot<FSCollectionType>
			) => void
			error?: (error: Error) => void
			complete?: () => void
		}
		includeMetadataChanges?: boolean
	},
	firebaseAppName = FIREBASE_APP_NAME
): UnobserveFn {
	const collection = createCollectionReference<FSCollectionType>(
		collectionName,
		firebaseAppName
	)
	const query = parametriseCollectionReference(collection, options.fetchOptions)

	const unobserveCollection = query.onSnapshot(
		{ includeMetadataChanges: options.includeMetadataChanges },
		options.callbacks
	)
	return unobserveCollection
}

export function observeDocument(
	collectionName: string,
	firestoreDocName: string,
	options: {
		callbacks: {
			next?: (
				snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
			) => void
			error?: (error: Error) => void
			complete?: () => void
		}
		includeMetadataChanges?: boolean
	}
): UnobserveFn {
	const collection = createCollectionReference(collectionName)
	const document = collection.doc(firestoreDocName)
	const unobserveCollection = document.onSnapshot(
		{ includeMetadataChanges: options.includeMetadataChanges },
		options.callbacks
	)
	return unobserveCollection
}
