import { includes } from '../../utils/guards'

import { LegacyMessageReactionType } from './types'

export const legacyReactionTypes: LegacyMessageReactionType[] = [
	'anger',
	'joy',
	'adoration',
	'excitement',
	'like',
	'sadness',
]

export function reactionTypeIsLegacy(
	type: string
): type is LegacyMessageReactionType {
	return includes(legacyReactionTypes, type)
}
