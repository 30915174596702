import styled, { css, DefaultTheme } from 'styled-components'

import { ButtonKind, ButtonSize, ButtonShape, ButtonVariant } from './types'

const getBorderRadiusFromSCProps = ({
	$kind,
	$shape,
	theme,
}: {
	$kind: ButtonKind
	$shape: ButtonShape
	theme: DefaultTheme
}): string | number => {
	if ($shape === ButtonShape.Round) {
		return '50%'
	}
	if ($kind === ButtonKind.Minimal) {
		return 0
	}
	return theme.borders.buttonBorderRadius
}

const borderRadii = css<{
	$kind: ButtonKind
	$size: ButtonSize
	$shape: ButtonShape
}>`
	border-top-right-radius: ${getBorderRadiusFromSCProps};
	border-top-left-radius: ${getBorderRadiusFromSCProps};
	border-bottom-right-radius: ${getBorderRadiusFromSCProps};
	border-bottom-left-radius: ${getBorderRadiusFromSCProps};
`

const miniSizePillPaddings = css`
	padding-top: ${({ theme }) => theme.sizing.scale200};
	padding-bottom: ${({ theme }) => theme.sizing.scale200};
	padding-left: ${({ theme }) => theme.sizing.scale100};
	padding-right: ${({ theme }) => theme.sizing.scale100};
`

const miniSizeRoundPaddings = css`
	padding-top: ${({ theme }) => theme.sizing.scale200};
	padding-bottom: ${({ theme }) => theme.sizing.scale200};
	padding-left: ${({ theme }) => theme.sizing.scale200};
	padding-right: ${({ theme }) => theme.sizing.scale200};
`

const defaultPillPaddings = css`
	padding-top: ${({ theme }) => theme.sizing.scale400};
	padding-bottom: ${({ theme }) => theme.sizing.scale500};
	padding-left: ${({ theme }) => theme.sizing.scale600};
	padding-right: ${({ theme }) => theme.sizing.scale600};
`

const defaultRoundPaddings = css`
	padding-top: ${({ theme }) => theme.sizing.scale600};
	padding-bottom: ${({ theme }) => theme.sizing.scale600};
	padding-left: ${({ theme }) => theme.sizing.scale600};
	padding-right: ${({ theme }) => theme.sizing.scale600};
`

const paddings = css<{
	$kind: ButtonKind
	$size: ButtonSize
	$shape: ButtonShape
}>`
	${({ $size, $shape }) => {
		if ($size === ButtonSize.Mini && $shape === ButtonShape.Round) {
			return miniSizeRoundPaddings
		}
		if ($size === ButtonSize.Mini) {
			return miniSizePillPaddings
		}
		if ($size === ButtonSize.Compact) {
			return null
		}
		if ($shape === ButtonShape.Round) {
			return defaultRoundPaddings
		}
		return defaultPillPaddings
	}}
`
const font = css<{ $kind: ButtonKind; $size: ButtonSize }>`
	${({ theme }) => theme.typography.ParagraphBold};
`

interface VariantColors {
	base: string
	contrast: string
	active: string
	hover: string
}

const getColorsForVariant = (
	theme: DefaultTheme,
	variant: ButtonVariant
): VariantColors => {
	switch (variant) {
		case ButtonVariant.Primary: {
			return {
				base: theme.colors.buttonPrimaryBase,
				contrast: theme.colors.buttonPrimaryContrast,
				hover: theme.colors.buttonPrimaryHover,
				active: theme.colors.buttonPrimaryActive,
			}
		}
		case ButtonVariant.Secondary: {
			return {
				base: theme.colors.buttonSecondaryBase,
				contrast: theme.colors.buttonSecondaryContrast,
				hover: theme.colors.buttonSecondaryHover,
				active: theme.colors.buttonSecondaryActive,
			}
		}
		case ButtonVariant.SecondaryAlt: {
			return {
				base: theme.colors.buttonSecondaryAltBase,
				contrast: theme.colors.buttonSecondaryAltContrast,
				hover: theme.colors.buttonSecondaryAltHover,
				active: theme.colors.buttonSecondaryAltActive,
			}
		}
		case ButtonVariant.Danger: {
			return {
				base: theme.colors.buttonDangerBase,
				contrast: theme.colors.buttonDangerContrast,
				hover: theme.colors.buttonDangerHover,
				active: theme.colors.buttonDangerActive,
			}
		}
		case ButtonVariant.Poll: {
			return {
				base: theme.colors.buttonPollBase,
				contrast: theme.colors.buttonPollContrast,
				hover: theme.colors.buttonPollHover,
				active: theme.colors.buttonPollActive,
			}
		}
		case ButtonVariant.Event: {
			return {
				base: theme.colors.buttonEventBase,
				contrast: theme.colors.buttonEventContrast,
				hover: theme.colors.buttonEventHover,
				active: theme.colors.buttonEventActive,
			}
		}
		case ButtonVariant.EventAlt: {
			return {
				base: theme.colors.buttonEventAltBase,
				contrast: theme.colors.buttonEventAltContrast,
				hover: theme.colors.buttonEventAltHover,
				active: theme.colors.buttonEventAltActive,
			}
		}
	}
}

const fluidStyles = css<{ $fluid: boolean }>`
	${({ $fluid }) =>
		$fluid && {
			width: '100%',
		}}
`

const noWrapStyles = css<{ $noWrap: boolean }>`
	${({ $noWrap }) =>
		$noWrap && {
			whiteSpace: 'nowrap',
		}}
`

const getFullKindStyles =
	(colors: VariantColors) =>
	({ theme }: { theme: DefaultTheme }) =>
		css`
			color: ${colors.contrast};
			background-color: ${colors.base};

			/* Makes Full buttons the same height as Inverted buttons */
			background-clip: border-box;
			border-style: solid;
			border-color: transparent;
			border-width: 2px;

			&:hover {
				background-color: ${colors.hover};
			}

			&:focus {
				background-color: ${colors.hover};
			}

			&:active {
				color: ${colors.contrast};
				background-color: ${colors.active};
			}

			&:disabled {
				color: ${theme.colors.buttonDisabledText};
				background-color: ${theme.colors.buttonDisabledFill};
			}
		`

const getInvertedKindStyles =
	(colors: VariantColors) =>
	({ theme }: { theme: DefaultTheme }) =>
		css`
			color: ${colors.base};
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}

			&:focus {
				background-color: transparent;
			}

			&:active {
				color: ${colors.base};
				background-color: transparent;
			}

			border-style: solid;
			border-color: ${colors.base};
			border-width: 2px;

			&:disabled {
				color: ${theme.colors.buttonDisabledText};
				background-color: transparent;
				border-color: ${theme.colors.buttonInvertedDisabledBorder};
			}
		`

const getMinimalKindStyles =
	(colors: VariantColors) =>
	({ theme }: { theme: DefaultTheme }) =>
		css`
			color: ${colors.base};
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}

			&:focus {
				background-color: transparent;
			}

			&:active {
				color: ${colors.base};
				background-color: transparent;
			}

			&:disabled {
				color: ${theme.colors.buttonDisabledText};
				background-color: transparent;
			}
		`

const variantKindStyles = css<{ $kind: ButtonKind; $variant: ButtonVariant }>`
	${({ $kind, $variant, theme }) => {
		const colors = getColorsForVariant(theme, $variant)

		switch ($kind) {
			case ButtonKind.Full:
				return getFullKindStyles(colors)
			case ButtonKind.Inverted:
				return getInvertedKindStyles(colors)
			case ButtonKind.Minimal:
				return getMinimalKindStyles(colors)
		}
	}}
`

export const StyledButton = styled.button<{
	$kind: ButtonKind
	$variant: ButtonVariant
	$size: ButtonSize
	$shape: ButtonShape
	$fluid: boolean
	$noWrap: boolean
}>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	outline: none;
	margin: 0;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
	}

	${borderRadii}
	${paddings}
  ${font}
  ${variantKindStyles}
  ${fluidStyles}
	${noWrapStyles}
`

export const StyledStartEnhancer = styled.div`
	display: flex;
	margin-right: ${({ theme }) => theme.sizing.scale100};
`

export const StyledEndEnhancer = styled.div`
	display: flex;
	margin-left: ${({ theme }) => theme.sizing.scale100};
`
