import { createGlobalStyle, css } from 'styled-components'
import styledNormalize from 'styled-normalize'
import styledReset from 'styled-reset'

export const additionalReset = css`
	*,
	*:after,
	*:before {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	*:focus {
		outline: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	button {
		border: none;
		text-align: initial;
		margin: 0;
		padding: 0;
		background: transparent;

		&:active {
			color: inherit;
		}
	}
`

export const GlobalStyles = createGlobalStyle(
	({ theme }) => css`
		${styledReset}
		${styledNormalize}
		${additionalReset}	

		a {
			text-decoration: none;

			&:active {
				color: black;
			}
		}

		html,
		body {
			height: 100%;
			margin: 0;
			padding: 0;
			overflow-x: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			font-family: ${theme.primitives.fontTokens.primaryFontFamily};
			text-rendering: optimizeLegibility;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			-moz-font-feature-settings: 'liga' on;
			font-feature-settings: 'liga' on;
		}

		html,
		body {
			position: relative;
			height: 100%;
			width: 100%;
		}

		#app-root {
			height: 100%;
		}
	`
)

export default GlobalStyles
