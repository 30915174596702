import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import auth from './auth'

export interface SphereSidebarState {
	isOpen: boolean
	activeSphereId?: string
}

const initialState: SphereSidebarState = {
	isOpen: false,
	activeSphereId: undefined,
}

export const sphereSidebar = createSlice({
	name: 'sphereSidebar',
	initialState,
	reducers: {
		opened(state, action: PayloadAction<void>) {
			state.isOpen = true
		},
		closed(state, action: PayloadAction<void>) {
			state.isOpen = false
			state.activeSphereId = undefined
		},
		switchedToSphere(state, action: PayloadAction<{ sphereId?: string }>) {
			state.activeSphereId = action.payload.sphereId
		},
		spheresFinishedLoading(state, action: PayloadAction<string[]>) {},
	},
	extraReducers(builder) {
		builder.addCase(auth.actions.signOutSuccess, state => ({
			...initialState,
		}))
	},
})

export default sphereSidebar
