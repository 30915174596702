import styled, { css } from 'styled-components'

import { CloseButton } from '../AKModal'
import Cross from '../SVGs/Cross'

const sharedInputStyles = css<{ $hasError: boolean }>`
	width: 100%;
	margin-bottom: ${p => p.theme.sizing.scale300};
	padding-top: ${({ theme }) => theme.sizing.scale200};
	padding-bottom: ${({ theme }) => theme.sizing.scale200};
	padding-left: ${({ theme }) => theme.sizing.scale100};
	padding-right: ${({ theme }) => theme.sizing.scale100};

	background: ${({ theme }) => theme.colors.backgroundPrimary};
	color: ${({ theme }) => theme.colors.contentPrimary};

	outline: none;

	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: ${({ theme }) =>
		theme.utils.stubToShorthand(theme.borders.border300)};
	border-radius: 0;

	${({ theme, $hasError }) =>
		$hasError &&
		css`
			border-color: ${theme.colors.negative};
		`}

	:focus {
		border-color: ${({ theme, $hasError }) =>
			$hasError ? theme.colors.negative : theme.colors.primary};
	}
`

export const CloseIcon = styled(Cross)`
	transform: rotate(45deg);
`

export const ProfileCard = styled.div`
	position: relative;
	width: 300px;

	border-top-left-radius: ${p => p.theme.borders.radius300};
	border-top-right-radius: ${p => p.theme.borders.radius300};
	box-shadow: none;
	background-color: ${p => p.theme.colors.backgroundSecondary};

	display: flex;
	flex-direction: column;

	${p => p.theme.mediaQueries.sm} {
		width: 376px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
		border-radius: ${p => p.theme.borders.radius300};
	}
`

export const ProfilePicture = styled.div<{ $attendantColor?: string }>`
	position: relative;
	height: 200px;
	width: 100%;
	border-top-right-radius: ${p => p.theme.borders.radius300};
	border-top-left-radius: ${p => p.theme.borders.radius300};

	display: flex;
	overflow: hidden;
	background-color: ${({ $attendantColor, theme }) =>
		$attendantColor ? `#${$attendantColor}` : theme.colors.mono200};

	${p => p.theme.mediaQueries.sm} {
		height: 282px;
	}
`

export const ProfilePictureFilter = styled.div`
	position: absolute;
	height: 50%;
	width: 100%;
	bottom: 0;
	left: 0;
	background: linear-gradient(
		to top,
		${p => p.theme.colors.black},
		transparent
	);
	opacity: 0.7;
`

export const ImageUploadButton = styled.button`
	position: absolute;
	bottom: 0;
	left: 0;
`

export const ImageUploadInput = styled.input`
	position: absolute;
	right: 0px;
	top: 0px;
	height: 100%;
	width: 100%;
	margin: 0px;

	padding: 0px;
	opacity: 0;
	cursor: pointer;
`

export const ImageUploadingCover = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	top: 0;
`

export const ProfileContent = styled.div`
	min-height: 144px;

	padding: ${p => p.theme.grid.gutter}px;
`

export const ProfileName = styled.div`
	${({ theme }) => theme.typography.HeaderBold}
	margin-bottom: ${p => p.theme.sizing.scale200};

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const ProfileDescription = styled.div`
	${({ theme }) => theme.typography.ParagraphRegular}
	color: ${p => p.theme.colors.contentSecondary};

	margin-bottom: ${p => p.theme.sizing.scale200};
`

export const ProfileDescriptionInput = styled.textarea<{ $hasError: boolean }>`
	resize: none;
	${sharedInputStyles}
`

export const StyledCloseButton = styled(CloseButton)`
	position: absolute;
	top: ${p => p.theme.sizing.scale600};
	right: ${p => p.theme.sizing.scale600};
`

export const StyledTextInput = styled.input<{ $hasError: boolean }>`
	${sharedInputStyles};
`

export const ProfileDetails = styled.div`
	margin-bottom: ${p => p.theme.sizing.scale500};
`

export const ProfileActions = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`
