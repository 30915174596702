import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	ClientConfig,
	GkGates,
	Person,
	UserConfigurationResponsePayload,
} from '../network/api'

import auth from './auth'

export interface ApiState {
	initializing: boolean
	initializeError?: string
	initialized?: boolean
	isStaffAdmin?: boolean
	gkGates?: GkGates
	intercomHash?: string
	clientConfig?: ClientConfig
	person?: Person
	giphyApiKey?: string
}

const initialState: ApiState = {
	initializing: false,
}
export const api = createSlice({
	name: 'api',
	initialState,
	reducers: {
		configurationInitiate() {},
		initRequest(state) {
			state.initializing = true
		},
		initSuccess(
			state,
			action: PayloadAction<UserConfigurationResponsePayload>
		) {
			state.initializing = false
			state.initialized = true
			state.isStaffAdmin = !!action.payload.isStaffAdmin
			state.intercomHash = action.payload.intercom_agent_id_hash
			state.clientConfig = action.payload.clientConfig
			state.person = action.payload.person
			state.giphyApiKey = action.payload.giphyApiKey
			state.gkGates = action.payload.gkGates
			state.initializeError = undefined
		},
		initFailure: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {
				state.initializing = false
				state.initializeError = action.error
			},
			prepare(error: string) {
				return { payload: {}, error }
			},
		},
	},
	extraReducers(builder) {
		builder.addCase(auth.actions.signOutSuccess, state => ({
			...initialState,
			initialized: true,
		}))
	},
})

export default api
