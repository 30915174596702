import { useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import intercom from '../slices/intercom'
import { IntercomClient } from '../utils/intercomClient'

import { ViewerAuthInfo } from './useViewerAuthInfo'

export function useIntercom({
	userAgentId,
	userName,
	userEmail,
}: ViewerAuthInfo) {
	const dispatch = useDispatch()
	const { intercomAppId, intercomHash, intercomBooted } = useSelector(
		({ config, api, intercom }) => ({
			intercomAppId: config.intercomAppId,
			intercomHash: api.intercomHash,
			intercomBooted: intercom.booted,
		}),
		shallowEqual
	)
	useEffect(() => {
		if (intercomAppId) {
			// this is a singleton
			new IntercomClient(intercomAppId)
		}
		return () => {
			IntercomClient.shutdown()
		}
	}, [intercomAppId])

	useEffect(() => {
		if (intercomHash && userAgentId) {
			IntercomClient.boot(
				{
					user_hash: intercomHash,
					user_id: userAgentId,
				},
				{
					onBoot: () => dispatch(intercom.actions.intercomBoot()),
					onUnreadCountChange: unreadCount =>
						dispatch(
							intercom.actions.intercomUnreadCountChange({ unreadCount })
						),
				}
			)
			return () => {
				IntercomClient.shutdown()
			}
		}
	}, [dispatch, intercomHash, userAgentId])

	useEffect(() => {
		if (intercomBooted) {
			IntercomClient.update({
				user_hash: intercomHash ?? undefined,
				user_id: userAgentId ?? undefined,
				name: userName ?? undefined,
				email: userEmail ?? undefined,
			})
		}
	}, [intercomBooted, intercomHash, userName, userEmail, userAgentId])
}

export default useIntercom
