import { FIREBASE_APP_NAME } from '../constants/config'
import { FirebaseConfig } from '../network/api'
import firebaseSlice from '../slices/firebase'
import { ApplicationThunk } from '../store'
import { toSafeError } from '../utils/error'

// TODO: WEB-383 check firebase bundle sizing
async function importFirebase() {
	await import('firebase/auth')
	await import('firebase/firestore')
	const firebase = await (await import('firebase/app')).default
	return firebase
}

function authenticateFirebase(
	appName: string,
	token: string
): ApplicationThunk<Promise<void>> {
	return async (dispatch, getState) => {
		dispatch(firebaseSlice.actions.firebaseAuthRequest())
		try {
			const firebase = await importFirebase()
			const firebaseApp = firebase.app(appName)

			if (!firebaseApp) {
				throw new Error(`Couldn't find firebase app named ${appName}`)
			}

			const userCredential = await firebaseApp
				.auth()
				.signInWithCustomToken(token)

			dispatch(
				firebaseSlice.actions.firebaseAuthSuccess({
					user: userCredential.user?.toJSON(),
				})
			)
		} catch (unknownError) {
			const error = toSafeError(unknownError)
			dispatch(
				firebaseSlice.actions.firebaseAuthFailure({
					error: error.message,
				})
			)
		}
	}
}

export interface FirebaseInitOptions {
	authToken: string
	appConfig: FirebaseConfig
}

export function initializeFirebase(
	options: FirebaseInitOptions
): ApplicationThunk<Promise<void>> {
	return async (dispatch, getState) => {
		const { authToken, appConfig } = options
		const { config } = getState()
		const { firestoreSettings, bypassFirebaseAuth } = config

		dispatch(firebaseSlice.actions.firebaseAppInitRequest())

		const firebase = await importFirebase()

		const firebaseApp = firebase.initializeApp(appConfig, FIREBASE_APP_NAME)

		try {
			if (!bypassFirebaseAuth) {
				await dispatch(authenticateFirebase(firebaseApp.name, authToken))
			}

			const firestore = firebaseApp.firestore()

			if (firestoreSettings) {
				firestore.settings(firestoreSettings)
			}

			dispatch(
				firebaseSlice.actions.firebaseAppInitSuccess({
					appName: firebaseApp.name,
				})
			)
		} catch (e) {
			dispatch(firebaseSlice.actions.firebaseAppInitFailure())
		}
	}
}

export function firebaseDisconnect(): ApplicationThunk<Promise<void>> {
	return async (dispatch, getState) => {
		dispatch(firebaseSlice.actions.firebaseDisconnectRequest())

		const { firebase: firebaseState } = getState()
		const { initialized, appName } = firebaseState

		if (!initialized || !appName) {
			dispatch(
				firebaseSlice.actions.firebaseDisconnectFailure({
					error: 'Firebase not initialized',
				})
			)
			return Promise.resolve()
		}

		const firebase = await importFirebase()
		const firebaseApp = firebase.app(appName)
		try {
			await firebaseApp.delete()
			dispatch(firebaseSlice.actions.firebaseDisconnectSuccess())
		} catch (error) {
			dispatch(
				firebaseSlice.actions.firebaseDisconnectFailure({
					error: 'Failed to delete the firebase app',
				})
			)
		}
	}
}
