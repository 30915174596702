import styled from 'styled-components'

export const GridLayout = styled.div`
	position: relative;
	width: 100%;
	max-width: ${({ theme }) => theme.grid.maxWidth}px;
	margin: 0 auto;

	padding-left: ${({ theme }) => theme.grid.gutter}px;
	padding-right: ${({ theme }) => theme.grid.gutter}px;
`

export default GridLayout
