import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'

export interface LaunchDarklyState {
	initializing: boolean
	initializingError: string | null
	closing: boolean
	closingError: string | null
	connected: boolean
	flags: LDFlagSet
}

const initialState: LaunchDarklyState = {
	initializing: false,
	initializingError: null,
	closing: false,
	closingError: null,
	connected: false,
	flags: {},
}

export const launchDarkly = createSlice({
	name: 'launchDarkly',
	initialState,
	reducers: {
		initializeRequest(state) {
			state.initializing = true
		},
		initializeSuccess(state) {
			state.initializing = false
			state.connected = true
		},
		initializeFailure: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {
				state.initializing = false
				state.initializingError = action.error
				state.connected = false
			},
			prepare({ error }) {
				return { error, payload: {} }
			},
		},
		flagUpdated(state, action: PayloadAction<LDFlagSet>) {
			state.flags = { ...state.flags, ...action.payload }
		},
		error: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {},
			prepare({ error }) {
				return { error, payload: {} }
			},
		},
		closeRequest(state) {
			state.closing = true
			state.closingError = null
		},
		closeSuccess(state) {
			state.closing = false
			state.connected = false
		},
		closeFailure: {
			reducer(state, action: PayloadAction<{}, string, never, string>) {
				state.closing = false
				state.closingError = action.error
			},
			prepare({ error }) {
				return { error, payload: {} }
			},
		},
	},
})

export const actions = launchDarkly.actions

export default launchDarkly
