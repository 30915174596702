import { MessageModel } from '../Message/types'

import { ConversationModel } from './types'

export class Conversation {
	static getId(
		conversation: Pick<ConversationModel, 'id'>
	): string | undefined {
		return conversation.id
	}

	static getRecentlyActiveAttendantCount(
		conversation: Pick<ConversationModel, 'recentlyActiveAttendantCount'>
	): number | undefined {
		return conversation.recentlyActiveAttendantCount
	}

	static getNumberOfAttendants(
		conversation: Pick<ConversationModel, 'attendantCount'>
	): number | undefined {
		return conversation.attendantCount
	}

	static getLatestMessage(
		conversation: Pick<ConversationModel, 'lastMessage'>
	): MessageModel | null | undefined {
		return conversation.lastMessage
	}
}

export default Conversation
