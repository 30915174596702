import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { WebPushSubscription } from '../network/api'
import {
	mergeDictionaryCollections,
	mergeKeepLatest,
} from '../utils/entitiesHelper'

import auth from './auth'

export interface EntitiesState {
	webPushSubscriptions: Record<string, WebPushSubscription>
}

const initialState: EntitiesState = {
	webPushSubscriptions: {},
}

export const entities = createSlice({
	name: 'entities',
	initialState,
	reducers: {
		entityDeleteLocal(
			state,
			action: PayloadAction<{
				entityType: keyof typeof initialState
				entityId: string
			}>
		) {
			delete state[action.payload.entityType][action.payload.entityId]
		},
	},
	extraReducers: builder =>
		builder
			.addCase(auth.actions.signOutSuccess, () => initialState)
			.addDefaultCase((state, action) => {
				if (
					action.response &&
					action.response.entities &&
					!action.bypassEntityStore
				) {
					return mergeDictionaryCollections(
						state,
						action.response.entities,
						mergeKeepLatest
					)
				}
				return state
			}),
})

// Updates an entity cache in response to any action with response.entities.
export default entities
