import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'

import { TextInputState } from '../containers/RichTextInput'

import { StoreState } from '.'

interface FileUpload {
	objectURL: string | null
	fileName: string | null
	fileType: string | null
}

export interface ConversationInputState {
	conversationId: string
	savedTextInputState: TextInputState
	fileUploads: Record<string, FileUpload | null>
	replyToMessageId: string | null
}

type ConversationInputAction<A = unknown> = PayloadAction<
	A & {
		conversationId: string
	}
>

export const conversationInputAdapter =
	createEntityAdapter<ConversationInputState>({
		selectId(item) {
			return item.conversationId
		},
	})

export type ConversationsInputState = EntityState<ConversationInputState>

function ensureInitialState(
	state: WritableDraft<EntityState<ConversationInputState>>,
	action: ConversationInputAction
): string {
	const conversationId = action.payload.conversationId
	if (
		!conversationInputAdapter.getSelectors().selectById(state, conversationId)
	) {
		conversationInputAdapter.addOne(state, {
			conversationId: action.payload.conversationId,
			savedTextInputState: null,
			fileUploads: {},
			replyToMessageId: null,
		})
	}
	return conversationId
}

const conversationsInput = createSlice({
	name: 'conversationsInput',
	initialState: conversationInputAdapter.getInitialState(),
	reducers: {
		conversationInputSave(
			state,
			action: ConversationInputAction<{ textInputState: TextInputState }>
		) {
			ensureInitialState(state, action)
			conversationInputAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					savedTextInputState: action.payload.textInputState,
				},
			})
		},
		conversationInputClear(state, action: ConversationInputAction) {
			conversationInputAdapter.upsertOne(state, {
				conversationId: action.payload.conversationId,
				savedTextInputState: null,
				fileUploads: {},
				replyToMessageId: null,
			})
		},
		conversationInputFileUploadInitiate(
			state,
			action: ConversationInputAction<{
				objectURL: string
				fileName: string | null
				fileType: string | null
			}>
		) {
			ensureInitialState(state, action)
			conversationInputAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					fileUploads: {
						// TODO v2: allow more than one file attached at a time eg:
						// ...state[action.conversationId].fileUploads
						// Currently not in scope for product
						[action.payload.objectURL]: {
							objectURL: action.payload.objectURL,
							fileName: action.payload.fileName,
							fileType: action.payload.fileType,
						},
					},
				},
			})
		},
		conversationInputFileUploadCancel(
			state,
			action: ConversationInputAction<{ objectURL: string }>
		) {
			ensureInitialState(state, action)
			conversationInputAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					fileUploads: {
						[action.payload.objectURL]: {
							objectURL: null,
							fileName: null,
							fileType: null,
						},
					},
				},
			})
		},
		conversationInputSetMessageReplyingTo(
			state,
			action: ConversationInputAction<{ messageId: string }>
		) {
			ensureInitialState(state, action)
			conversationInputAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					replyToMessageId: action.payload.messageId,
				},
			})
		},
		conversationInputClearMessageReplyingTo(
			state,
			action: ConversationInputAction
		) {
			ensureInitialState(state, action)
			conversationInputAdapter.updateOne(state, {
				id: action.payload.conversationId,
				changes: {
					replyToMessageId: null,
				},
			})
		},
	},
})

export default conversationsInput

export const conversationInputSelectors =
	conversationInputAdapter.getSelectors<StoreState>(
		state => state.conversationsInput
	)
