import { useEffect } from 'react'

import { uuid } from '../utils/entitiesHelper'
import { SegmentClient } from '../utils/segmentProxy'

import useConfig from './useConfig'
import { ViewerAuthInfo } from './useViewerAuthInfo'

export function useSegment({
	userAgentId,
	userName,
	userEmail,
}: ViewerAuthInfo) {
	const config = useConfig()
	const { statsEndpoint, segmentWriteKey } = config ?? {}

	useEffect(() => {
		if (statsEndpoint && segmentWriteKey) {
			const instanceId = uuid()
			new SegmentClient(
				{
					statsEndpoint,
					segmentWriteKey,
				},
				instanceId
			)
		}
	}, [segmentWriteKey, statsEndpoint])

	useEffect(() => {
		if (userAgentId) {
			SegmentClient.identify(userAgentId, {
				name: userName,
				email: userEmail,
			})
		} else {
			SegmentClient.reset()
		}
	}, [userAgentId, userName, userEmail])
}

export default useSegment
