// StyledSpinnerNext is going to become the main Spinner in the next release
import { withStyle } from 'baseui'
import { StyledSpinnerNext } from 'baseui/spinner'
import { FC, forwardRef } from 'react'
import { useTheme } from 'styled-components'

import { colorPrefixer } from '../../utils/renderHelper'

export interface SpinnerProps {
	size?: number
	color?: string
	className?: string
}

const DEFAULT_SIZE = 20

const LoadingSpinner: FC<SpinnerProps> = forwardRef(
	({ size = DEFAULT_SIZE, color, className }, ref) => {
		const theme = useTheme()

		const borderWidth = (size ?? DEFAULT_SIZE) / 8

		const activeColor = color ? colorPrefixer(color) : theme.colors.primary
		const trackColor = theme.colors.backgroundPrimary

		const StyledSpinner = withStyle(StyledSpinnerNext, {
			width: `${size ?? DEFAULT_SIZE}px`,
			height: `${size ?? DEFAULT_SIZE}px`,
			borderTopWidth: `${borderWidth}px`,
			borderBottomWidth: `${borderWidth}px`,
			borderLeftWidth: `${borderWidth}px`,
			borderRightWidth: `${borderWidth}px`,
			borderTopColor: activeColor,
			borderRightColor: trackColor,
			borderBottomColor: trackColor,
			borderLeftColor: trackColor,
		})

		return <StyledSpinner role="progressbar" ref={ref} className={className} />
	}
)
export default LoadingSpinner
