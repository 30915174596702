import { compact } from 'lodash'
import { createSelector } from 'reselect'

import { firestoreMessagesSelectors } from '../slices/firestoreMessages'

import { makeGetExtraParam } from './misc'

export const getFirestoreMessages = firestoreMessagesSelectors.selectEntities

// TODO: simplify with entityAdapters
export const makeGetFirestoreMessagesByIds = () =>
	createSelector(
		getFirestoreMessages,
		makeGetExtraParam<{ messageIds: string[] }>(),
		(firestoreMessages, { messageIds }) =>
			compact(messageIds.map(messageId => firestoreMessages[messageId]))
	)
