import { Redirect as ReactRouterRedirect, RedirectProps } from 'react-router'

export enum RoutePath {
	Sphere = '/s/:sphereId',
	SphereInfo = '/s/:sphereId/info',
	SphereEvents = '/s/:sphereId/events',
	Chat = '/s/:sphereId/c/:conversationId',
	ChatImportantMessages = '/s/:sphereId/c/:conversationId/important-messages',
	ChatInfo = '/s/:sphereId/c/:conversationId/info',
	EventInfo = '/s/:sphereId/e/:eventId',
	Signin = '/signin',
	Settings = '/settings',
	Default = '/s',
	AdminPinboard = '/s/:sphereId/pinned-messages',
	SphereAppreciations = '/s/:sphereId/appreciations',
	ContentFeed = '/s/:sphereId/flow',
	ConversationMessage = '/s/:sphereId/c/:conversationId/:messageId',
	WebCore = '/s/:sphereId/WebCore',
}

export const pathBuilders = {
	sphere: ({ sphereId }: { sphereId: string }) => `/s/${sphereId}`,
	sphereInfo: ({ sphereId }: { sphereId: string }) => `/s/${sphereId}/info`,
	sphereEvents: ({ sphereId }: { sphereId: string }) => `/s/${sphereId}/events`,
	adminPinboard: ({ sphereId }: { sphereId: string }) =>
		`/s/${sphereId}/pinned-messages`,
	appreciations: ({ sphereId }: { sphereId: string }) =>
		`/s/${sphereId}/appreciations`,
	contentFeed: ({ sphereId }: { sphereId: string }) => `/s/${sphereId}/flow`,
	chat: ({
		sphereId,
		conversationId,
	}: {
		sphereId: string
		conversationId: string
	}) => `/s/${sphereId}/c/${conversationId}`,
	conversationMessage: ({
		sphereId,
		conversationId,
		messageId,
	}: {
		sphereId: string
		conversationId: string
		messageId: string
	}) => `/s/${sphereId}/c/${conversationId}/${messageId}`,
	chatInfo: ({
		sphereId,
		conversationId,
	}: {
		sphereId: string
		conversationId: string
	}) => `/s/${sphereId}/c/${conversationId}/info`,
	chatImportantMessages: ({
		sphereId,
		conversationId,
	}: {
		sphereId: string
		conversationId: string
	}) => `/s/${sphereId}/c/${conversationId}/important-messages`,
	eventInfo: ({ sphereId, eventId }: { sphereId: string; eventId: string }) =>
		`/s/${sphereId}/e/${eventId}`,
	WebCore({ sphereId }: { sphereId: string }) {
		return `/s/${sphereId}/webcore`
	},
	settings: () => RoutePath.Settings,
	signin: () => RoutePath.Signin,
	default: () => RoutePath.Default,
}

interface ExposedRedirectProps extends Pick<RedirectProps, 'push'> {}
interface SphereIdProp {
	sphereId: string
}
interface ConversationIdProp {
	conversationId: string
}
interface EventIdProp {
	eventId: string
}

export const Redirect = {
	Sphere: (props: ExposedRedirectProps & SphereIdProp) => (
		<ReactRouterRedirect to={pathBuilders.sphere(props)} push={props.push} />
	),
	SphereInfo: (props: ExposedRedirectProps & SphereIdProp) => (
		<ReactRouterRedirect to={pathBuilders.sphereInfo(props)} />
	),
	SphereEvents: (props: ExposedRedirectProps & SphereIdProp) => (
		<ReactRouterRedirect to={pathBuilders.sphereEvents(props)} />
	),
	AdminPinboard: (props: ExposedRedirectProps & SphereIdProp) => (
		<ReactRouterRedirect to={pathBuilders.adminPinboard(props)} />
	),
	Chat: (props: ExposedRedirectProps & SphereIdProp & ConversationIdProp) => (
		<ReactRouterRedirect to={pathBuilders.chat(props)} push={props.push} />
	),
	ChatInfo: (
		props: ExposedRedirectProps & SphereIdProp & ConversationIdProp
	) => (
		<ReactRouterRedirect to={pathBuilders.chatInfo(props)} push={props.push} />
	),
	ChatImportantMessages: (
		props: ExposedRedirectProps & SphereIdProp & ConversationIdProp
	) => (
		<ReactRouterRedirect
			to={pathBuilders.chatImportantMessages(props)}
			push={props.push}
		/>
	),
	EventInfo: (props: ExposedRedirectProps & SphereIdProp & EventIdProp) => (
		<ReactRouterRedirect to={pathBuilders.eventInfo(props)} push={props.push} />
	),
	Signin: (props: ExposedRedirectProps) => (
		<ReactRouterRedirect to={pathBuilders.signin()} push={props.push} />
	),
	Default: (props: ExposedRedirectProps) => (
		<ReactRouterRedirect to={pathBuilders.default()} push={props.push} />
	),
}
