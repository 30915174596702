import {
	createEntityAdapter,
	createSlice,
	EntityId,
	EntityState,
	IdSelector,
	PayloadAction,
	Slice,
} from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'
import { union } from 'lodash'

import { Pagination, UpdateType } from '../network/pagination'

import auth from './auth'

export type PaginationPayload = {
	resetPagination?: boolean
	pagination?: Pagination
}

export type PaginationAction<P = unknown, E = never> = PayloadAction<
	P & PaginationPayload,
	string,
	never,
	E
>

export interface PaginationItemState {
	id: EntityId
	isFetching: boolean
	loadingError: any | null
	beforeCursor: string | null
	afterCursor: string | null
	order?: 'asc' | 'desc' | null
	ids: string[] | null
	newIds: string[] | null
	fetchedAll?: boolean | null
}

function getInitialPaginationItemState(id: EntityId): PaginationItemState {
	return {
		id,
		isFetching: false,
		loadingError: null,
		beforeCursor: null,
		afterCursor: null,
		order: null,
		ids: null,
		newIds: null,
		fetchedAll: null,
	}
}

export const paginationAdapter = createEntityAdapter<PaginationItemState>({
	selectId(item) {
		return item.id
	},
})

interface PaginationActionCreator<Common, Error = never> {
	(...args: any[]): PayloadAction<
		PaginationPayload & Common,
		string,
		never,
		Error
	>
	type: string
}

// Creates a slice managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.
function paginate<
	Common extends object,
	RequestCreator extends PaginationActionCreator<Common>,
	SuccessCreator extends PaginationActionCreator<Common>,
	FailureCreator extends PaginationActionCreator<Common, string>,
	ResetCreator extends PaginationActionCreator<Common>
>({
	types,
	mapActionToKey,
	name,
}: {
	types:
		| [RequestCreator, SuccessCreator, FailureCreator]
		| [RequestCreator, SuccessCreator, FailureCreator, ResetCreator]
	mapActionToKey: IdSelector<
		ReturnType<RequestCreator | SuccessCreator | FailureCreator | ResetCreator>
	>
	name: string
}): Slice<EntityState<PaginationItemState>, {}, string> {
	const [requestCreator, successCreator, failureCreator, resetCreator] = types

	function ensureInitialState(
		state: WritableDraft<EntityState<PaginationItemState>>,
		action: ReturnType<
			RequestCreator | SuccessCreator | FailureCreator | ResetCreator
		>
	): EntityId {
		const id = mapActionToKey(action)
		const entry = paginationAdapter.getSelectors().selectById(state, id)
		if (!entry) {
			paginationAdapter.addOne(state, getInitialPaginationItemState(id))
		}
		return id
	}

	const paginationSlice = createSlice({
		name,
		initialState: paginationAdapter.getInitialState(),
		reducers: {},
		extraReducers(builder) {
			builder
				.addCase(requestCreator, function paginationRequest(state, action) {
					const id = ensureInitialState(state, action)
					paginationAdapter.updateOne(state, {
						id,
						changes: {
							isFetching: true,
						},
					})
					if (action.payload.resetPagination) {
						paginationAdapter.updateOne(state, {
							id,
							changes: {
								ids: [],
							},
						})
					}
				})
				.addCase(successCreator, function paginationSuccess(state, action) {
					const id = ensureInitialState(state, action)
					const current = paginationAdapter.getSelectors().selectById(state, id)
					paginationAdapter.updateOne(state, {
						id,
						changes: {
							isFetching: false,
						},
					})
					const pagination = action.payload.pagination
					if (pagination) {
						paginationAdapter.updateOne(state, {
							id,
							changes: {
								// Always stored in ascending order
								// Assumes pages are being fetched continuously, eg:
								// 	(asc after ids[ids.length -1]) || (desc before ids[0])
								ids:
									pagination.updateType === UpdateType.Prepend
										? union(pagination.ascendingIds, current?.ids)
										: union(current?.ids, pagination.ascendingIds),
								newIds: pagination.ascendingIds,
								beforeCursor: pagination.before,
								afterCursor: pagination.after,
								order: pagination.order,
								fetchedAll: pagination.fetchedAll,
							},
						})
					}
				})
				.addCase(failureCreator, function paginationFailure(state, action) {
					const id = ensureInitialState(state, action)
					paginationAdapter.updateOne(state, {
						id,
						changes: {
							isFetching: false,
							loadingError: action.error,
						},
					})
				})
			if (resetCreator) {
				builder.addCase(resetCreator, function paginationReset(state, action) {
					const id = mapActionToKey(action)
					paginationAdapter.removeOne(state, id)
					paginationAdapter.addOne(state, getInitialPaginationItemState(id))
				})
			}

			builder.addCase(auth.actions.signOutSuccess, () =>
				paginationAdapter.getInitialState()
			)
		},
	})

	return paginationSlice
}

export default paginate
